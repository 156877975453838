import React, { useEffect } from "react";
import "../styles/loginpage.css";
import { useAuth0 } from "@auth0/auth0-react";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    // Use the Auth0 loginWithRedirect function
    loginWithRedirect();
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "#97b9e9",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Drop shadow
      }}
    >
      <div
        className="frame"
        style={{
          borderRadius: "8px",
          width: "80%", // Reduced size by 20%
          transform: "scale(0.85)", // Scale down by 20%
        }}
      >
        <div className="closed-for-beta">
          <div className="div">
            <p className="text-wrapper">
              © 2023 NanoNotify. All rights reserved.
            </p>
            <div className="text-wrapper-2">Privacy Policy</div>
            <div className="text-wrapper-2">Terms of Service</div>
            <div className="text-wrapper-2">Cookie Settings</div>
          </div>
          <img
            className="logo"
            alt="Logo"
            src="/assets/illustrations/genericLogo.png"
          />
          <div className="heading">Simplify SMS with NanoNotify.</div>
          <p className="body-text">
            Say goodbye to tedious tapping and template juggling just to send
            your customers SMS updates. We make updating your customers fast,
            effective, and accessible to your team, regardless of it’s size. No
            unnecessary features. No app download required.
          </p>
          <p className="tagline">
            Customize status updates however you want. Manage multiple
            locations, users, and individual location hours.
          </p>
          <p className="p">
            Multiple plans that fit your business size and communication
            requirements
          </p>
          <p className="tagline-2">
            No learning curve, no added costs – just simple, effective
            communication with a few clicks.
          </p>
          <p className="closedfor-beta">
            NanoNotify is currently in closed beta and is invite only while we
            finish development. Be sure to sign up for updates below and get
            notified when we launch to everyone.
          </p>
          <div className="sign-up-box" />
          <button className="sign-up-button">
            <button className="button">Sign Up</button>
          </button>
          <div className="divider" />
          <div className="request-access" style={{ marginLeft: "-15px" }}>
            <div className="text-wrapper-3">Request Access</div>
            <button
              className="login-button"
              style={{ marginLeft: "30px" }}
              onClick={handleLogin}
            >
              <div className="text-wrapper-4">Login</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
