import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon as Iconify } from "@iconify/react";
import { sentenceCase } from "change-case";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Grid,
  Avatar,
  Fade,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { visuallyHidden } from "@mui/utils";
import { useAuth0 } from "@auth0/auth0-react";
import CustomerEditModal from "../components/modal/CustomerEditModal";
import LinearProgress from "@mui/material/LinearProgress";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { useAccountContext } from "../context/AccountContext"; // Import AppProvider from AccountContext
import CustomerDetailsDialog from "src/components/modal/CustomerDetailsDialog";
import { useAppContext } from "../context/AppContext";
import fetchGlobalNotifications from "../utils/notificationUtils";
import SendIcon from "../public/assets/icons/navbar/send.png";
import EditIcon from "../public/assets/icons/icons/editLight.png";
import NewNotification from "src/components/Custom-Notifications/NewNotification";
import { add, set } from "lodash";
import { useCustomersContext } from "../context/CustomersContext"; // Import the useCustomersContext hook
import DataLoadingAlert from "src/components/alerts/components/DataLoadingAlert";
import { AddButton } from "src/components/Buttons/AddDeleteEditButtons";
import CreateCustomerModal from "src/components/modal/CreateCustomerModal";
import { useAuth } from "../context/AuthContext";
import LoginModal from "src/components/modal/LoginModal";
import ContactListIcon from "src/public/assets/icons/icons/contactList.svg";
import phoneIcon from "src/public/assets/icons/messageModal/phone.png";

// Check if single_location is true in accountData

const CustomersPage = () => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterName, setFilterName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const userSub = user?.sub; // Access user.sub safely, may be null or undefined
  const { accountData } = useAccountContext(); // Use the accountData from the context
  const isSingleUser = accountData?.single_user; // Check if single_user is true in accountData
  const isSingleLocation = accountData?.single_location; // Check if single_user is true in accountData
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openNewMultiModal, setOpenNewMultiModal] = useState(false);
  const { reloadComponents, handleReloadComponents, showSnackBar } =
    useAppContext();
  const {
    customers,
    totalCustomers,
    lastMessages,
    handleUpdateCustomer,
    handleDeleteCustomer,
    loading,
    loadingCustomerData,
    fetchCustomers,
  } = useCustomersContext(); // Use the customers and totalCustomers from the context
  const [isLoading, setIsLoading] = useState(false);
  const [notificationHistory, setNotificationHistory] = useState([]);
  const [openAddCustomerModal, setOpenAddCustomerModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [customerSaved, setCustomerSaved] = useState(false);
  const [showNoRecordsWarning, setShowNoRecordsWarning] = useState(false);
  const {
    username,
    subUserData,
    isLoggedIn,
    shouldShowLoginModal,
    setShouldShowLoginModal,
  } = useAuth();
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  const handleOpenAddCustomerModal = () => {
    setOpenAddCustomerModal(true);
    setFieldDisabled(false);
    setPhoneNumber("");
    setIsPhoneNumberValid(false);
  };

  const handleCloseAddCustomerModal = () => {
    setOpenAddCustomerModal(false);
    setFieldDisabled(true);
  };

  console.log(customers);

  useEffect(() => {
    if (customerSaved) {
      fetchCustomers();
      setCustomerSaved(false);
    }
  }, [customerSaved]);

  const handlePhoneNumberChange = (event) => {
    let input = event.target.value;

    // Remove any non-numeric characters except parentheses, spaces, and dashes
    input = input.replace(/[^\d]/g, "");

    // Limit the input to 10 digits
    if (input.length > 10) {
      input = input.slice(0, 10);
    }

    // Format the phone number to (XXX) XXX-XXXX
    let formattedPhoneNumber = input;

    if (input.length > 6) {
      formattedPhoneNumber = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
    } else if (input.length > 3) {
      formattedPhoneNumber = `(${input.slice(0, 3)}) ${input.slice(3, 6)}`;
    } else if (input.length > 0) {
      formattedPhoneNumber = `(${input.slice(0, 3)}`;
    }

    setPhoneNumber(formattedPhoneNumber);
    setIsPhoneNumberValid(input.length === 10);
  };

  const columns = [
    {
      id: "name",
      label: "Customers Name",
    },
    {
      id: "phone_number",
      label: "Phone Number",
    },
    {
      id: "last_message_sent_date",
      label: "Last Notification Sent",
    },

    ...(isSingleLocation
      ? []
      : [
          {
            id: "last_status_update_sent",
            label: "Location",
          },
        ]),
    {
      id: "date_created",
      label: "Date Created",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;
    const [openModal, setOpenModal] = useState(false);
    const [editedCustomer, setEditedCustomer] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const isSingleUser = accountData?.single_user;

    useEffect(() => {
      document.body.style.overflow = "hidden"; // Disable scrollbar
      return () => {
        document.body.style.overflow = ""; // Re-enable scrollbar on component unmount
      };
    }, []);

    return (
      <TableHead>
        <TableRow style={{ height: "0px" }}>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#48768C",
                fontWeight: 600,
                fontSize: 13.5,
                borderBottom: "1px solid rgba(173, 216, 230, 0.2)",
                pt: 1,
                pb: 1,
                pl: 8,
                pr: 2,
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  useEffect(() => {
    // Access userData and subUserData wherever you need in your component
  }, [accountData]);

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scrollbar
    return () => {
      document.body.style.overflow = ""; // Re-enable scrollbar on component unmount
    };
  }, []);

  const fetchNotificationHistory = async (customer) => {
    try {
      if (!customer) return; // Exit early if customer is null

      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      // Fetch notification history for the customer using their phone number and userSub
      const response = await fetch(
        `${apiUrl}/notifications/${userSub}/${customer.phone_number}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch notification history");
      }

      const data = await response.json();
      return data; // Return data for further use
    } catch (error) {
      console.error("Error fetching notification history:", error);
      throw error; // Re-throw error for further handling
    }
  };

  const handleRowClick = async (customer) => {
    setIsLoading(true); // Start loading state

    try {
      // Fetch the data
      const data = await fetchNotificationHistory(customer);

      if (!data || data.length === 0) {
        console.log("No notification history found");
        setShowNoRecordsWarning(true);

        // Automatically clear the warning after 3 seconds
        setTimeout(() => {
          setShowNoRecordsWarning(false);
        }, 3000);

        return; // Exit early if no data is found
      }

      const dataWithDetails = data.map((notification) => ({
        ...notification,
        phone_number: customer.phone_number,
        customer_name: `${customer.first_name} ${customer.last_name}`, // Concatenate first_name and last_name
      }));

      setNotificationHistory(dataWithDetails); // Update state with modified data
      setSelectedRow(customer); // Set selected row
      setOpenDialog(true); // Open dialog
      setOpenModal(false); // Ensure modal is closed when dialog opens
      console.log("data with details", dataWithDetails);
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setIsLoading(false); // End loading state
    }
  };

  const handleOpenModal = (customer) => {
    setSelectedRow(customer);
    setOpenModal(true);
    setOpenDialog(false); // Ensure dialog is closed when modal opens
  };

  const handleOpenNewMultiModal = (customer) => {
    setSelectedRow(customer);
    setOpenNewMultiModal(true);
  };

  const handleCloseNewMultiModal = () => {
    setOpenNewMultiModal(false);
    setSelectedRow(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleClearSearch = () => {
    setFilterName("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customers.length) : 0;

  const filteredCustomers = customers.filter((customer) => {
    const name = `${customer.first_name} ${customer.last_name}`;
    return name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1;
  });

  const formatDate = (date) => {
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    };

    const formattedDate = date.toLocaleString("en-US", options);
    const [datePart] = formattedDate.split(","); // Split date and time
    return `${datePart}`; // Combine date and time with a space
  };

  const formatDateCreated = (date) => {
    if (!date) return "";

    const options = { month: "numeric", day: "numeric", year: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date,
    );

    return formattedDate;
  };

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scrollbar
    return () => {
      document.body.style.overflow = ""; // Re-enable scrollbar on component unmount
    };
  }, []);

  const isCustomerNotFound = filteredCustomers.length === 0;

  const isDeleteButtonVisible = selected.length > 0;

  const statusColors = {
    "Ready for Pickup": "#2E7D32",
    "Parts Arrived": "#0277BD",
    "Action Needed": "#F57F17",
    "Pending Approval": "#C62828",
  };

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0) : "";
    const lastInitial = lastName ? lastName.charAt(0) : "";
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  if (!isLoggedIn) {
    return (
      <LoginModal
        open={shouldShowLoginModal}
        onClose={() => setShouldShowLoginModal(false)} // Close the modal
        onLogin={() => {
          setShouldShowLoginModal(false); // Close the modal when login is successful
        }}
        accountData={accountData} // Pass the account data if needed
      />
    );
  }

  return (
    <Box sx={{ width: "100%", px: "4px", borderRadius: "10px", mt: -1 }}>
      <Box
        sx={{
          width: "100%",
          mt: 0.5,
          borderRadius: "10px",
          overflow: "auto",
          backgroundColor: "#FFFFFF",
          boxShadow:
            "0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            pt: 1.5,
            color: "#48768C",
            fontWeight: 700,
            fontSize: 17,
            backgroundColor: "rgba(255, 255, 255, 1.9)",
            borderBottom: "none",
            pl: "18px",
            mb: "2px",
          }}
        >
          Your Customers{" "}
        </Typography>
        <Typography
          sx={{
            color: "#8BA9B7",
            fontSize: 14.5,
            backgroundColor: "rgba(255, 255, 255, 1.9)",
            pl: "18px",
            fontWeight: 600,
            mb: -3,
          }}
        >
          {totalCustomers} Saved Contacts
        </Typography>
        <Stack direction="row" sx={{ width: "100%", pr: 1, mb: 1.25 }}>
          <TextField
            variant="outlined"
            placeholder="Search customer by name"
            value={filterName}
            onChange={handleFilterByName}
            sx={{
              color: "#2A3D4F",
              ml: "auto",
              mr: 2,
              "& .MuiOutlinedInput-root": {
                height: "35px",
                fontSize: "14px",
                width: "250px",
                border: "1px solid #c2d7df",
                borderRadius: "8px", // Maintain rounded corners
                backgroundColor: "#FFFFFF", // Set the background color
                "&:hover": {
                  borderColor: "#90A4AE", // Border color on hover
                },
                "&.Mui-focused": {
                  borderColor: "#48768C", // Border color on focus
                  borderWidth: "2px",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove default MUI fieldset border
              },
              "& input::placeholder": {
                marginLeft: "8px", // Match placeholder's padding
                color: "#48768C",
                lineHeight: 0,
                fontWeight: 500,
                fontSize: "13.75px",
                fontFamily: "Source Sans Pro, sans-serif",
                opacity: 0.88,
              },
            }}
            InputLabelProps={{
              style: { color: "#2A3D4F", fontSize: "14px" }, // Updated label color
            }}
            InputProps={{
              style: { color: "#2A3D4F" }, // Updated text color to white
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="bi:search" style={{ color: "#90A4AE" }} />{" "}
                  {/* Updated icon color */}
                </InputAdornment>
              ),
              endAdornment: filterName && (
                <InputAdornment position="end" sx={{ marginRight: "3px" }}>
                  <Fade in={true} timeout={200}>
                    <IconButton
                      onClick={handleClearSearch}
                      edge="end"
                      sx={{ color: "#6595ab", padding: 0 }}
                    >
                      <CancelOutlinedIcon
                        sx={{ fontSize: 20, opacity: 0.85 }}
                      />
                    </IconButton>
                  </Fade>
                </InputAdornment>
              ),
            }}
          />

          <AddButton
            text="Create New Customer"
            onClick={handleOpenAddCustomerModal}
          />
        </Stack>

        <TableContainer
          component={Paper}
          sx={{
            display: "flex",
            height: "100%",
            color: "#48768C",
            borderRadius: "12px",
            border: "1.25px solid rgba(173, 216, 230, 0.5)",
            flexDirection: "column",
          }}
        >
          <Table sx={{ pl: 2 }}>
            <EnhancedTableHead
              numSelected={selected.length}
              rowCount={customers.length}
            />

            <TableBody sx={{ minHeight: "80vh" }}>
              {loadingCustomerData && (
                <TableRow>
                  <TableCell
                    colSpan={10}
                    sx={{
                      textAlign: "center",
                      color: "#48768C",
                      backgroundColor: "rgba(173, 216, 230, 0.2)",
                    }}
                  >
                    <Typography
                      align="center"
                      sx={{
                        width: "100%",
                        padding: "20px",
                        fontSize: ".95rem",
                        fontWeight: 600,
                      }}
                    >
                      Loading your customers...
                    </Typography>
                    <LinearProgress
                      color="secondary"
                      sx={{
                        height: 2,
                        "& .MuiLinearProgress-barColorSecondary": {
                          backgroundColor: "#2BBB9E",
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}

              {loadingCustomerData ? null : filteredCustomers.length === 0 ? (
                customers.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        color: "#48768C",
                        backgroundColor: "rgba(173, 216, 230, 0.2)",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="#48768C"
                        align="center"
                        sx={{
                          fontSize: "1.05rem",
                          fontWeight: 600,
                          width: "100%",
                          padding: "10px",
                          borderBottomLeftRadius: "20px",
                          borderBottomRightRadius: "20px",
                          letterSpacing: "-0.02em",
                          mt: 2,
                        }}
                      >
                        No Saved Customers
                      </Typography>
                      <Typography
                        variant="body1"
                        color="#6595ab"
                        align="center"
                        sx={{
                          fontWeight: 600,
                          fontSize: ".94rem",
                          width: "100%",
                          padding: "10px",
                          borderBottomLeftRadius: "20px",
                          borderBottomRightRadius: "20px",
                          mb: 1.5,
                          letterSpacing: "-0.01em",
                        }}
                      >
                        Save customers to your contact list to send
                        notifications even faster!
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        color: "#48768C",
                        backgroundColor: "rgba(173, 216, 230, 0.2)",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="#48768C"
                        align="center"
                        sx={{
                          fontSize: "1.05rem",
                          fontWeight: 600,
                          width: "100%",
                          padding: "10px",
                          borderBottomLeftRadius: "20px",
                          borderBottomRightRadius: "20px",
                          letterSpacing: "-0.02em",
                          mt: 2,
                          mb: 1,
                        }}
                      >
                        No Matching Customers Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                filteredCustomers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((customer) => {
                    const {
                      phone_number,
                      first_name,
                      last_name,
                      date_created,
                      location_name,
                    } = customer;
                    const selectedCustomer =
                      selected.indexOf(phone_number) !== -1;
                    const formattedPhoneNumber = phone_number.replace(
                      /\D/g,
                      "",
                    );
                    const lastMessage = lastMessages[formattedPhoneNumber];

                    return (
                      <TableRow
                        key={phone_number}
                        tabIndex={-1}
                        selected={selectedCustomer}
                        sx={{
                          height: "10px",
                          cursor: !isLoading ? "pointer" : "default",
                          "&:hover": {
                            backgroundColor: "rgba(173, 216, 230, 0.1)",
                          },
                        }} // Set the cursor style to 'pointer'
                        onClick={() => handleRowClick(customer)} // Add this line
                        disabled={isLoading}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            backgroundColor: "rgba(173, 216, 230, 0.2)",
                            borderBottom: "none",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start", // Adjusted alignment to flex-start
                            }}
                          >
                            <Avatar
                              sx={{
                                fontSize: 16,
                                width: 40,
                                height: 40,
                                background:
                                  "radial-gradient(circle, #EEF6F9, #FFFFFF)",
                                border: "2px solid rgba(173, 216, 230, 0.9)",
                                borderRadius: "20px",
                                padding: "4px",
                                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
                                mr: "15px",
                                color: "#48768C",
                                fontWeight: 600,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  letterSpacing: "0.03em",
                                  fontWeight: 600,
                                  textAlign: "center",
                                }}
                              >
                                {getInitials(first_name, last_name)}
                              </Typography>
                            </Avatar>
                            <Typography
                              sx={{
                                fontSize: 14,
                                letterSpacing: "0.02em",
                                fontWeight: 600,
                                color: "#48768C",
                              }}
                              noWrap
                            >
                              {first_name} {last_name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            pl: 7.75,

                            fontSize: 14,
                            letterSpacing: "0.02em",
                            backgroundColor: "rgba(173, 216, 230, 0.2)",
                            color: "#48768C",
                            borderBottom: "none",
                          }}
                        >
                          {phone_number}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 14,
                            letterSpacing: "0.02em",
                            backgroundColor: "rgba(173, 216, 230, 0.2)",
                            color: "#48768C",
                            borderBottom: "none",
                            pl: 12,
                          }}
                        >
                          {lastMessage ? formatDate(new Date(lastMessage)) : ""}
                        </TableCell>
                        {!accountData.single_location && (
                          <TableCell
                            sx={{
                              fontSize: 14,
                              letterSpacing: "0.02em",
                              backgroundColor: "rgba(173, 216, 230, 0.2)",
                              color: "#48768C",
                              borderBottom: "none",
                              pl: 8,
                            }}
                          >
                            {location_name}
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            fontSize: 14,
                            letterSpacing: "0.02em",
                            backgroundColor: "rgba(173, 216, 230, 0.2)",
                            color: "#48768C",
                            borderBottom: "none",
                            pl: 9,
                          }}
                        >
                          {date_created
                            ? formatDateCreated(new Date(date_created))
                            : ""}
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "rgba(173, 216, 230, 0.2)",
                            color: "#48768C",
                            borderBottom: "none",
                          }}
                        >
                          <Box
                            sx={{
                              ml: -1,
                              flexDirection: "row",
                              justifyContent: "space-around",

                              display: "flex",
                            }}
                          >
                            <Paper
                              elevation={3}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleOpenNewMultiModal(customer);
                              }}
                              sx={{
                                color: "#48768C",
                                padding: 0,
                                cursor: "pointer", // Added a cursor for better interactivity
                                transition: "background-color 0.3s ease",
                                "&:hover": {
                                  backgroundColor: "#D7E3E8",
                                  color: "#F0F0F0",
                                },
                              }}
                            >
                              <i
                                style={{
                                  color: "#86A4B2",
                                  fontSize: "18px",
                                  border: ".25px solid #90A4AE",
                                  padding: "6px",
                                  borderRadius: "8px",
                                }}
                                className="fa-regular fa-paper-plane"
                              ></i>
                            </Paper>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Paper
                                elevation={3}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleOpenModal(customer);
                                }}
                                disabled={isLoading}
                                sx={{
                                  marginLeft: "35px",
                                  color: "#48768C",
                                  padding: 0,
                                  cursor: "pointer", // Added a cursor for better interactivity
                                  transition: "background-color 0.3s ease",
                                  "&:hover": {
                                    backgroundColor: "#D7E3E8",
                                    color: "#F0F0F0",
                                  },
                                }}
                              >
                                <i
                                  style={{
                                    color: "#86A4B2",
                                    fontSize: "18px",
                                    border: ".25px solid #90A4AE",
                                    padding: "6px",
                                    borderRadius: "8px",
                                  }}
                                  className="fa-solid fa-pencil"
                                ></i>
                              </Paper>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={customers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            sx={{
              color: "#48768C",
              backgroundColor: "rgba(173, 216, 230, 0.4)",
            }}
          />
        </TableContainer>

        {isLoading && (
          <DataLoadingAlert
            title="Loading..."
            alertText="Loading notification history. Please wait."
          />
        )}

        {showNoRecordsWarning && (
          <DataLoadingAlert
            title="No Records To Display"
            alertText="This customer hasn't been sent any notifications yet."
          />
        )}
      </Box>
      <CustomerDetailsDialog
        open={openDialog}
        onClose={handleCloseDialog}
        customer={selectedRow ? selectedRow : {}} // Pass selectedRow as customer if it exists, otherwise pass an empty object
        userSub={userSub}
        phone_number={selectedRow ? selectedRow.phone_number : ""}
        notificationHistory={notificationHistory}
      />

      <NewNotification
        open={openNewMultiModal}
        onClose={handleCloseNewMultiModal}
        customer={selectedRow}
        userSub={userSub}
        phone_number={selectedRow ? selectedRow.phone_number : ""} // Assuming phone_number is a property of selectedRow
        ContactListIcon={ContactListIcon}
        phoneIcon={phoneIcon}
      />

      <CustomerEditModal
        open={openModal}
        onClose={handleCloseModal}
        customer={selectedRow}
        onUpdate={handleUpdateCustomer}
        loading={false}
      />

      <CreateCustomerModal
        open={openAddCustomerModal}
        onClose={handleCloseAddCustomerModal}
        selectedPhoneNumber={phoneNumber}
        setCustomerSaved={setCustomerSaved}
        handlePhoneNumberChange={handlePhoneNumberChange}
        isPhoneNumberValid={isPhoneNumberValid}
      />
    </Box>
  );
};

const ProtectedMessagesFeed = withAuthenticationRequired(CustomersPage, {
  // Custom options, if needed
  // For example, onRedirecting, returnTo, etc.
});

export default CustomersPage;
