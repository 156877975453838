import React, { useState, useEffect } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Box,
  OutlinedInput,
  InputAdornment,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Stack,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../../context/AuthContext"; // Import the useAuth hook
import { AccountCircle } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useAppContext } from "../../context/AppContext"; // Import the useAppContext hook
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import { useAccountContext } from "src/context/AccountContext";

const LoginModal = ({ open, onClose, onLogin }) => {
  const [username, setUsername] = useState("");
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const { setAccessToken, login } = useAuth();
  const { logout: customLogout } = useAuth(); // Assuming useAuth provides a logout function
  const { resetContext } = useAuth();
  const { isAuthenticated: isAuth0Authenticated, logout: auth0Logout } =
    useAuth0();
  const { handleReloadComponents } = useAppContext(); // Import handleReloadComponents from AppContext
  const [loading, setLoading] = useState(false);
  const { accountData } = useAccountContext();

  const apiUrl = process.env.REACT_APP_API_URL;

  console.log("Username:", username);
  console.log("user:", user);

  const handleLogout = () => {
    auth0Logout({ returnTo: window.location.origin });
    customLogout();
    resetContext(); // Reset the context to clear user state
  };

  const handleLoginFailure = () => {
    setError("Incorrect username or pin.");
    setTimeout(() => {
      setError("");
    }, 3000); // Hide the error message after 3 seconds (3000 milliseconds)
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/api/users/${user.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        setError("User not found");
        handleLoginFailure();
        setLoading(false);
        return;
      }

      const userData = await response.json();

      if (userData.rows && userData.rows.length > 0) {
        const matchingUser = userData.rows.find(
          (user) => user.username === username && user.pin === pin,
        );

        if (matchingUser) {
          // Fetch user profile data using the new endpoint
          const profileResponse = await fetch(
            `${apiUrl}/api/users/profile/${user.sub}/${username}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );

          if (profileResponse.ok) {
            const subUserProfileData = await profileResponse.json();

            setIsLoginSuccessful(true);
            onLogin(username, pin, subUserProfileData); // Pass subUserProfileData to onLogin

            setAccessToken(username);
            login(username, subUserProfileData); // Set the username and subUserData in the context
            setLoading(false);
            handleReloadComponents(); // Reload components to reflect the new user data after login
            onClose(); // Close the modal on successful login
          } else {
            setError("Failed to fetch subUser profile data.");
            setLoading(false);
            handleLoginFailure();
          }
        } else {
          setError("Incorrect username or pin");
          setLoading(false);
          handleLoginFailure();
        }
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("Failed to process login");
      setLoading(false);
      handleLoginFailure();
    }
  };

  useEffect(() => {
    // Check if login is successful, then close the modal
    if (isLoginSuccessful) {
      onClose();
    }
  }, [isLoginSuccessful, onClose]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      event.stopPropagation(); // Prevent propagation to avoid closing the modal
    } else {
      onClose(event, reason);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      closeAfterTransition={true}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          borderRadius: "22px",
          border: "1px solid rgba(173, 216, 230, 0.5)",
          backgroundColor: "#ffffff", // Set solid white background to avoid bloom effect
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)", // Add shadow for better contrast
        },
      }}
      sx={{
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(2px)", // Stronger blur for backdrop
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent black backdrop
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignText: "center",
          alignItems: "center",
          justifyContent: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          pt: 5,
          width: "100%",
          pb: 4.45,
          color: "#374247",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            color: "#48768C",
            fontSize: "20px",
            fontWeight: 600,
            alignText: "center",
            width: "100%",
            justifyContent: "center",
            lineSpacing: "-2%",
            letterSpacing: "-2%",
          }}
        >
          Employee Login{" "}
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          width: "490px",
          backgroundColor: "#FFFFFF",
          pt: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >
        <form onSubmit={handleLogin}>
          <OutlinedInput
            placeholder="Username"
            size="small"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              backgroundColor: "#F2F9FF",
              borderRadius: "20px",
              mt: 1,
              mb: 1.5,
              width: "99%", // Adjust width as needed
              height: "44px", // Adjust height as needed
              "&:hover": {
                borderColor: "#2F5C71", // Border color on hover
              },
              "&.Mui-focused": {
                borderColor: "#48768CBA", // Focused border color
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(173, 216, 230, 0.9)", // Default border color
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2F5C71", // Border color on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#48768CBA", // Border color on focus
              },
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "#2F5C71", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#48768CBA", // Focused border color
                },
              },

              "& input::placeholder": {
                marginLeft: "8px", // Match placeholder's padding
                color: "#647D9E",
                lineHeight: 0,
                fontWeight: 500,
                fontSize: "16px",
                fontFamily: "Source Sans Pro, sans-serif",
              },
            }}
            inputProps={{
              style: {
                marginLeft: "12px", // Match placeholder's padding
                marginTop: "2px",
                fontSize: "17px",
                fontWeight: 500,
                color: "#647D9E",
                fontFamily: "Source Sans Pro, sans-serif",
              },
            }}
            endAdornment={
              <>
                <Paper
                  elevation={1}
                  sx={{
                    display: "flex",
                    borderRadius: "10px",
                    padding: "2px",
                    border: "1.5px solid rgba(173, 216, 230, 0.9)",
                    backgroundColor: "#E3F2FD",
                    color: "#48768C",
                  }}
                >
                  <AccountCircle
                    sx={{
                      padding: "2px",
                      backgroundColor: "#E3F2FD",
                      color: "#48768C",
                      fontSize: "20px",
                    }}
                  />
                </Paper>
              </>
            }
          />

          <OutlinedInput
            placeholder="PIN"
            size="small"
            required
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            fullWidth
            type="password"
            onKeyDown={(e) => {
              // Allow typing if key is numeric, backspace, or Command+A (or Ctrl+A)
              if (
                !(
                  (e.key >= "0" && e.key <= "9") ||
                  e.key === "Backspace" ||
                  ((e.metaKey || e.ctrlKey) && e.key === "a")
                )
              ) {
                e.preventDefault();
              }
              // Trigger login on Enter key press
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
            sx={{
              backgroundColor: "#F2F9FF",
              fontSize: "94px",

              borderRadius: "20px",
              mt: 0.5,
              width: "99%", // Adjust width as needed
              height: "44px", // Adjust height as needed
              "&:hover": {
                borderColor: "#2F5C71", // Border color on hover
              },
              "&.Mui-focused": {
                borderColor: "#48768CBA", // Focused border color
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(173, 216, 230, 0.9)", // Default border color
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2F5C71", // Border color on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#48768CBA", // Border color on focus
              },
              "& input::placeholder": {
                marginLeft: "12px", // Match placeholder's padding
                color: "#647D9E",
                lineHeight: 0,
                fontWeight: 500,
                fontSize: "16px",
                fontFamily: "Source Sans Pro, sans-serif",
              },
              "& input": {
                fontSize: "46px",
              },
            }}
            inputProps={{
              inputMode: "numeric", // Set input mode to numeric
              pattern: "[0-9]*", // Allow only numeric characters
              maxLength: 6, // Set max length to 6
              style: {
                marginLeft: "12px", // Match placeholder's padding
                fontSize: "24px",

                marginTop: "-2px",
                fontSize: "24px",
                fontWeight: 500,
                color: "#647D9E",
                fontFamily: "Source Sans Pro, sans-serif",
              },
            }}
            endAdornment={
              <>
                <Paper
                  elevation={1}
                  sx={{
                    display: "flex",
                    borderRadius: "10px",
                    padding: "2px",
                    border: "1.5px solid rgba(173, 216, 230, 0.9)",
                    backgroundColor: "#E3F2FD",
                    color: "#48768C",
                  }}
                >
                  <LockIcon
                    sx={{
                      padding: "2px",
                      backgroundColor: "#E3F2FD",
                      color: "#48768C",
                      fontSize: "20px",
                    }}
                  />
                </Paper>
              </>
            }
          />
        </form>

        {error && (
          <Typography
            color="#8C4350"
            sx={{
              mt: "9px", // Adjust top margin as needed
              mb: 0,
              fontSize: "15px", // Adjust font size as needed
              textAlign: "center", // Center the error message
            }}
          >
            {error}
          </Typography>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Stack
          direction="column"
          spacing={0.75}
          sx={{ width: "100%", display: "flex" }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <PrimaryButton
              disabled={loading}
              sx={{
                width: "92%",
                height: "44px",
                fontSize: "17px",
                mb: 1.25,
                backgroundColor: "#DBEAF0C2",
                border: "1px solid #43637A45",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#E5EDF0",
                },
                "&:active": {
                  backgroundColor: "#E5EDF0",
                },
                "&.Mui-disabled": {
                  color: "#A0A0A0D9", // Disabled text color
                  backgroundColor: "#48768C21", // Disabled background color
                  borderColor: "rgba(128, 128, 128, 0.3)", // Disabled border color
                  boxShadow: "none", // Disabled shadow, if you want to remove it
                },
              }}
              onClick={handleLogin}
              variant="contained"
            >
              {loading ? "Logging in..." : "Login"}
            </PrimaryButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              mt: 0.5,
              pb: 2,
            }}
          >
            <SecondaryButton
              onClick={handleLogout}
              sx={{
                px: 2,
                py: "5px",
                width: "60%",
              }}
            >
              <LogoutOutlinedIcon
                sx={{
                  mr: "3px",
                  color: "#A1848F",
                  fontSize: "18px",
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#48768C",
                  fontSize: "13px",
                }}
              >
                Sign Out Of{" "}
                {accountData?.company_name
                  ? accountData.company_name
                  : "Your Account"}
              </Typography>
            </SecondaryButton>
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default LoginModal;
