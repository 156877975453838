import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Fade,
} from "@mui/material";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../../context/AuthContext";
import CloseIcon from "@mui/icons-material/Close";
import bellIcon from "../../public/assets/icons/messageModal/bell.png";
import openSign from "../../public/assets/icons/icons/openSign.png";
import closedSign from "../../public/assets/icons/icons/closedSign.png";
import hoursIcon from "../../public/assets/icons/icons/hours.png";
import { useAppContext } from "src/context/AppContext";
import { set } from "lodash";
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext";
import { dismissItem } from "src/components/NewUserChecklist/dismissItem";
import { useAccountContext } from "src/context/AccountContext";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";

const apiUrl = process.env.REACT_APP_API_URL;

const WhiteTimePicker = styled(TimeField)(({ isAdmin }) => ({
  "& input": {
    color: "#48768C",
    fontWeight: 600,
    fontSize: 13,
    textAlign: isAdmin ? "center" : "center",
  },
  "& .MuiSvgIcon-root": {
    color: "#48768C",
  },
  "& .MuiIconButton-root": {
    color: "#48768C",
  },
  "& .MuiPickersClockNumber-clockNumberSelected": {
    backgroundColor: "#48768C",
  },
}));

const EditHoursModal = ({
  isOpen,
  onClose,
  onUpdateHours,
  locationName,
  confirmHours,
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [loadingHours, setLoadingHours] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disabled state
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const { fetchCurrentHours } = useUserSelectedNotifications();
  const {
    businessHours,
    startTimeValues,
    endTimeValues,
    setBusinessHours,
    setResetHours,
  } = useUserSelectedNotifications();
  const { accountData, fetchOnboardingRecord } = useAccountContext();
  const [item, setItem] = useState("confirmHours");
  const [error, setError] = useState({
    Monday: {
      start_time: false,
      end_time: false,
    },
    Tuesday: {
      start_time: false,
      end_time: false,
    },
    Wednesday: {
      start_time: false,
      end_time: false,
    },
    Thursday: {
      start_time: false,
      end_time: false,
    },
    Friday: {
      start_time: false,
      end_time: false,
    },
    Saturday: {
      start_time: false,
      end_time: false,
    },
    Sunday: {
      start_time: false,
      end_time: false,
    },
  });

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { username, subUserData } = useAuth();

  const handleCloseModal = () => {
    onClose();
    setResetHours(true);
  };

  const OpenIconSwitch = styled(Switch)(({ theme }) => ({
    width: 50.15, // Reduced from 59
    height: 27.2, // Reduced from 32
    padding: 5.95, // Reduced from 7
    "& .MuiSwitch-switchBase": {
      margin: 0.85, // Reduced from 1
      padding: 0,
      transform: "translateX(5.1px)", // Reduced from 6px
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(18.7px)", // Reduced from 22px
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url(${closedSign})`,
          backgroundSize: "contain",
          width: "18.7px", // Reduced from 22px
          height: "18.7px", // Reduced from 22px
          content: "''",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
      "&.Mui-checked + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#647D9E" : "#647D9E",
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#236381 " : "#236381",
      width: 23.8, // Reduced from 28
      height: 23.8, // Reduced from 28
      "&::before": {
        backgroundImage: `url(${openSign})`,
        backgroundSize: "contain",
        width: "20.4px", // Reduced from 24px
        height: "20.4px", // Reduced from 24px
        content: "''",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#AAC5E7" : "#AAC5E7",
      borderRadius: 8.5, // Reduced from 10
    },
  }));

  // Function to check if the time is in HH:mm format
  const isValidTimeFormat = (time) => {
    const regex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/; // HH:mm format regex
    return regex.test(time);
  };

  const handleTimeChange = (dayOfWeek, field, value) => {
    // Check if the field is "is_closed" and if it is disabled (not allowed to process times)
    if (field === "is_closed" && value === true) {
      // If the switch is toggled to 'closed', we don't need valid time inputs
      setBusinessHours((prev) => ({
        ...prev,
        [dayOfWeek]: {
          ...prev[dayOfWeek],
          [field]: value,
          start_time: "09:00 AM", // Set a default valid time if needed
          end_time: "05:00 PM", // Set a default valid time if needed
        },
      }));

      // Clear any existing errors for the day
      setError((prev) => ({
        ...prev,
        [dayOfWeek]: {}, // Clear all field errors for this day
      }));

      // Enable the button since errors are cleared
      setIsButtonDisabled(false);
    } else if (field === "start_time" || field === "end_time") {
      // Check if the time is valid
      const isValidTime = isValidTimeFormat(value);

      if (isValidTime) {
        setBusinessHours((prev) => ({
          ...prev,
          [dayOfWeek]: {
            ...prev[dayOfWeek],
            [field]: value,
          },
        }));
        // Clear error for the field if the time is valid
        setError((prev) => ({
          ...prev,
          [dayOfWeek]: {
            ...(prev[dayOfWeek] || {}),
            [field]: false, // Clear error
          },
        }));
      } else {
        // Set error for the field if the time is invalid
        setError((prev) => ({
          ...prev,
          [dayOfWeek]: {
            ...(prev[dayOfWeek] || {}),
            [field]: true, // Set error
          },
        }));
      }

      // Calculate hasErrors based on the existing errors and the current field's validity
      setError((prevError) => {
        const updatedError = {
          ...prevError,
          [dayOfWeek]: {
            ...(prevError[dayOfWeek] || {}),
            [field]: !isValidTime, // Update the error for the field
          },
        };

        const hasErrors = Object.values(updatedError).some((dayErrors) =>
          Object.values(dayErrors || {}).some((fieldError) => fieldError),
        );

        setIsButtonDisabled(hasErrors);
        return updatedError; // Return the updated error object
      });
    } else {
      // For other fields, simply update the businessHours
      setBusinessHours((prev) => ({
        ...prev,
        [dayOfWeek]: {
          ...prev[dayOfWeek],
          [field]: value,
        },
      }));
    }
  };

  const handleUpdateChecklist = async () => {
    const userId = user.sub;
    if (accountData.onboarding_complete === false) {
      await dismissItem(item, getAccessTokenSilently, userId);
      await fetchOnboardingRecord();
      return;
    } else {
      return;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) return;
    setLoadingHours(true);
    try {
      const token = await getAccessTokenSilently(); // Get the access token
      if (!token) throw new Error("Access token not available");

      const saveRequests = daysOfWeek.map(async (dayOfWeek) => {
        const isClosed = businessHours[dayOfWeek]?.is_closed || false;
        const dataToSend = {
          userId: user.sub,
          username: username || "",
          confirmHours,
          businessHours: {
            dayOfWeek,
            startTime: businessHours[dayOfWeek]?.start_time,
            endTime: businessHours[dayOfWeek]?.end_time,
            isClosed,
          },
        };

        const response = await fetch(`${apiUrl}/api/saveUserTimes`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        });

        if (!response.ok) {
          setLoadingHours(false);
          showSnackBar("Failed to update hours. Please try again.", false);
          onClose();
          throw new Error("Failed to save user times");
        }

        return await response.json();
      });
      await handleUpdateChecklist();
      await fetchCurrentHours(); // Fetch the updated hours
      await Promise.all(saveRequests); // Wait for all requests to complete

      setLoadingHours(false);
      showSnackBar("Hours updated successfully!", true);
      onClose();
    } catch (error) {
      setLoadingHours(false);
      showSnackBar("Failed to update hours. Please try again.", false);
      console.error("Error saving user times:", error);
      onClose();
    }
  };

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  if (businessHours && businessHours.error) {
    return <div>Error: {businessHours.error}</div>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        open={isOpen}
        onClose={handleCloseModal}
        PaperProps={{ sx: { borderRadius: "13px" } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>
        <DialogTitle
          sx={{
            alignItems: "center",
            height: "50px",
            backgroundColor: "#FFFFFF",
            pt: 2,
            color: "#374247",
          }}
        >
          <img
            src={hoursIcon}
            alt="bell"
            style={{
              border: "1.25px solid rgba(173, 216, 230, 0.7)",
              width: "auto",
              height: "30px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "3px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              borderRadius: "6px",
            }}
          />
        </DialogTitle>

        <DialogContent
          sx={{
            width: "100%",
            backgroundColor: "#FFFFFF",
            display: "flex",
            alignItems: "left",
            justifyContent: "left",
            zIndex: 0,
            flexDirection: "column", // Stack items vertically (optional)
            alignItems: "flex-start", // Align items to the left
            justifyContent: "flex-start", // Align content to the left
          }}
        >
          {subUserData && subUserData.is_admin && (
            <Typography
              sx={{
                color: "#48768C",
                fontSize: 19,
                mb: 2.5,
                fontWeight: 600,
                mt: 0.25,
              }}
            >
              Edit {subUserData.location_name}'s Hours of Operation{" "}
            </Typography>
          )}
          <>
            {subUserData && !subUserData.is_admin && (
              <Typography
                sx={{
                  display: "flex",
                  color: "#48768C",
                  fontSize: 20,
                  mb: 2,
                  mt: 0.5,
                  fontWeight: 600,
                  alignContent: "left",
                }}
              >
                {subUserData.location_name}'s Hours of Operation{" "}
              </Typography>
            )}
          </>

          <Box
            sx={{
              ml: "-2px",
              backgroundColor: "rgba(191, 218, 231, 0.08)",
              border: "1px solid rgba(173, 216, 230, 0.7)",
              borderRadius: "10px",
              width: "100%",
              py: "20px",
              display: "flex",
              flexDirection: "column", // Stack items vertically (optional)
              alignItems: "flex-start", // Align items to the left
              justifyContent: "flex-start", // Align content to the left
            }}
          >
            {daysOfWeek.map((dayOfWeek) => (
              <Box
                key={dayOfWeek}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  my: "3px",
                  mb: 1,
                  mx: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    display: "flex",
                    color: "#48768C",
                    flex: "0 0 125px", // Fixed width for the day label
                    ml: 2,
                    mr: subUserData && subUserData.is_admin ? "-20px" : 3, // Conditional mr value
                    fontWeight: 600, // Increased font weight for emphasis
                    mb: 0.5,
                    alignSelf: "center",
                    mt: 0.5,
                  }}
                >
                  {dayOfWeek}
                </Typography>
                {businessHours[dayOfWeek]?.is_closed && (
                  <Fade in={businessHours[dayOfWeek]?.is_closed} timeout={300}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundImage:
                          "linear-gradient(to bottom, rgba(72, 118, 140, 0.1), rgba(72, 118, 140, 0.1))",

                        width: "100%",
                        height: "30px",
                        borderRadius: "6px",
                      }}
                    >
                      <Typography
                        align="center"
                        sx={{
                          width: "57%",
                          color: "#48768C",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          borderRadius: "4px",
                          fontSize: 15.5,
                          lineHeight: 1.5,
                          fontWeight: 600,
                        }}
                      >
                        Closed
                      </Typography>
                    </Box>
                  </Fade>
                )}

                {!businessHours[dayOfWeek]?.is_closed && (
                  <>
                    <TimeField
                      value={startTimeValues[dayOfWeek] || null} // Use null initially
                      onChange={(time) =>
                        handleTimeChange(
                          dayOfWeek,
                          "start_time",
                          time && time.format("HH:mm"),
                        )
                      }
                      ampm={true}
                      disabled={
                        (subUserData && !subUserData.is_admin) ||
                        businessHours[dayOfWeek]?.is_closed
                      }
                      error={error[dayOfWeek]?.start_time || false}
                      sx={{
                        flexGrow: 1,
                        width: "115px",
                        "& input": {
                          color: "#48768C",
                          fontWeight: 600,
                          fontSize: 13, // Adjust for your desired scaling
                          textAlign: "center", // Center the text
                          padding: 0.5,
                          height: "28px",
                          display: "flex", // Flex for centering
                          alignItems: "center", // Center content vertically
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#2F5C715E", // Default border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#2F5C71", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#2F5C71B0", // Border color on focus
                          },
                        },
                        "&.Mui-error .MuiOutlinedInput-root fieldset": {
                          borderColor: "#D32F2F", // Error border color
                        },
                        // Adjust hover state when error is true
                        "&.Mui-error .MuiOutlinedInput-root:hover fieldset": {
                          borderColor: "#D32F2F", // Adjusted hover color for error state
                        },
                        // Adjust focus state when error is true
                        "&.Mui-error .MuiOutlinedInput-root.Mui-focused fieldset":
                          {
                            borderColor: "#D32F2F", // Adjusted focus color for error state
                          },

                        "& .MuiSvgIcon-root, & .MuiIconButton-root, & .MuiInputAdornment-root":
                          {
                            display: "none",
                          },
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          inputProps: {
                            inputMode: "numeric",
                          },
                        },
                      }}
                    />

                    <Box
                      sx={{
                        width: "85px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{ color: "#B0B0B0", fontWeight: 600, fontSize: 25 }}
                      >
                        -
                      </Typography>
                    </Box>
                    <TimeField
                      value={endTimeValues[dayOfWeek] || null || dayjs()}
                      onChange={(time) =>
                        handleTimeChange(
                          dayOfWeek,
                          "end_time",
                          time && time.format("HH:mm"),
                        )
                      }
                      ampm={true}
                      disabled={
                        (subUserData && !subUserData.is_admin) ||
                        businessHours[dayOfWeek]?.is_closed
                      }
                      error={error[dayOfWeek]?.end_time || false}
                      sx={{
                        flexGrow: 1,
                        width: "115px",
                        "& input": {
                          color: "#48768C",
                          fontWeight: 600,
                          fontSize: 13, // Adjust for your desired scaling
                          textAlign: "center", // Center the text
                          padding: 0.5,
                          height: "28px",
                          display: "flex", // Flex for centering
                          alignItems: "center", // Center content vertically
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#2F5C715E", // Default border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#2F5C71", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#2F5C71B0", // Border color on focus
                          },
                        },
                        "&.Mui-error .MuiOutlinedInput-root fieldset": {
                          borderColor: "#D32F2F", // Error border color
                        },
                        // Adjust hover state when error is true
                        "&.Mui-error .MuiOutlinedInput-root:hover fieldset": {
                          borderColor: "#D32F2F", // Adjusted hover color for error state
                        },
                        // Adjust focus state when error is true
                        "&.Mui-error .MuiOutlinedInput-root.Mui-focused fieldset":
                          {
                            borderColor: "#D32F2F", // Adjusted focus color for error state
                          },

                        "& .MuiSvgIcon-root, & .MuiIconButton-root, & .MuiInputAdornment-root":
                          {
                            display: "none",
                          },
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          inputProps: {
                            inputMode: "numeric",
                          },
                        },
                      }}
                    />
                  </>
                )}

                <>
                  {subUserData && subUserData.is_admin ? (
                    <FormControlLabel
                      control={
                        <OpenIconSwitch
                          checked={businessHours[dayOfWeek]?.is_closed || false}
                          onChange={(e) => {
                            handleTimeChange(
                              dayOfWeek,
                              "is_closed",
                              e.target.checked,
                            );
                            setIsButtonDisabled(false);
                          }}
                          sx={{ ml: 5 }}
                        />
                      }
                    />
                  ) : (
                    <Box sx={{ width: 10, height: 40, ml: 5 }} /> // Matches switch size for spacing
                  )}
                </>

                <Box sx={{ width: 23, flex: 1, display: "flex" }}> </Box>
              </Box>
            ))}

            {subUserData && subUserData.is_admin && (
              <Box sx={{ width: "100%", px: 3, mt: 0.5 }}>
                <PrimaryButton
                  onClick={handleSave}
                  disabled={loadingHours || isButtonDisabled} // Disable the button when loadingHours is true
                  sx={{
                    fontSize: "18px",
                    width: "100%",
                    height: "45px",
                    mt: 4,
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1.25px solid #48768C7A",
                  }}
                >
                  {
                    loadingHours
                      ? "Saving..." // Show "Loading..." if loading
                      : confirmHours
                        ? "Confirm Hours" // Show "Confirm Hours" if confirmHours is true
                        : "Update Location Hours" // Show "Save" otherwise
                  }
                </PrimaryButton>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </LocalizationProvider>
  );
};

export default EditHoursModal;
