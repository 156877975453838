// src/components/StepOne.js
import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Paper,
} from "@mui/material";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

const StepOne = ({
  name,
  nameTouched,
  handleNameChange,
  email,
  emailTouched,
  handleEmailChange,
  phone,
  phoneTouched,
  handlePhoneChange,
  validateEmail,
  validatePhoneNumber,
  isFormValid,
  isLoading,
  handleSaveLocationClick,
  locationNameTaken,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F2F9FF",
        border: "1.25px solid rgba(173, 216, 230, 0.5)",
        borderRadius: "14px",
        padding: 2,
        mt: 2,
      }}
    >
      <Typography
        sx={{
          color: "#48768C",
          mb: 1.5,
          fontWeight: 600,
          lineHeight: "-2%",
          letterSpacing: "-2%",
        }}
      >
        Enter your new location's contact info
      </Typography>
      <Typography
        sx={{
          color: "#48768C",
          mb: 2.5,
          fontWeight: 600,
          lineHeight: "-2%",
          letterSpacing: "-2%",
          fontSize: "13px",
        }}
      >
        Your location's email and phone number will automatically be included in
        your notifications
      </Typography>
      <TextField
        error={(!name.trim() && nameTouched) || locationNameTaken}
        helperText={
          // If the name is empty and touched, show "Please enter a valid name"
          !name.trim() && nameTouched
            ? "Please enter a valid name"
            : locationNameTaken // If the name is already taken, show "Location name is already in use"
              ? "Location name is already in use"
              : ""
        }
        variant="outlined"
        placeholder="Location Name"
        size="small"
        fullWidth
        value={name}
        onChange={handleNameChange}
        sx={{
          color: "textSecondary",
          pb: 2,
          "& input": {
            width: "100%",
            textAlign: "left",
            color: "#48768C",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  borderRadius: "6px",
                  padding: "2px",
                  border: "1.5px solid rgba(173, 216, 230, 0.9)",
                  backgroundColor: "#E3F2FD",
                  color: "#48768C",
                }}
              >
                <DriveFileRenameOutlineOutlinedIcon
                  sx={{
                    backgroundColor: "#E3F2FD",
                    color: "#48768C",
                    fontSize: "16px",
                  }}
                />
              </Paper>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        error={!validateEmail(email) && emailTouched}
        helperText={
          !validateEmail(email) && emailTouched && "Please enter a valid email"
        }
        variant="outlined"
        placeholder="Location Email"
        size="small"
        fullWidth
        value={email}
        onChange={handleEmailChange}
        sx={{
          color: "textSecondary",
          pb: 2,
          "& input": {
            width: "100%",
            textAlign: "left",
            color: "#48768C",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  borderRadius: "6px",
                  padding: "2px",
                  border: "1.5px solid rgba(173, 216, 230, 0.9)",
                  backgroundColor: "#E3F2FD",
                  color: "#48768C",
                }}
              >
                <AlternateEmailOutlinedIcon
                  sx={{
                    backgroundColor: "#E3F2FD",
                    color: "#48768C",
                    fontSize: "16px",
                  }}
                />
              </Paper>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        error={!validatePhoneNumber(phone) && phoneTouched}
        helperText={
          !validatePhoneNumber(phone) &&
          phoneTouched &&
          "Please enter a valid phone number"
        }
        variant="outlined"
        placeholder="Location Phone"
        size="small"
        fullWidth
        value={phone}
        onChange={handlePhoneChange}
        sx={{
          color: "textSecondary",
          pb: 2,
          "& input": {
            width: "100%",
            textAlign: "left",
            color: "#48768C",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  borderRadius: "6px",
                  padding: "2px",
                  border: "1.5px solid rgba(173, 216, 230, 0.9)",
                  backgroundColor: "#E3F2FD",
                  color: "#48768C",
                }}
              >
                <LocalPhoneOutlinedIcon
                  sx={{
                    backgroundColor: "#E3F2FD",
                    color: "#48768C",
                    fontSize: "16px",
                  }}
                />
              </Paper>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default StepOne;
