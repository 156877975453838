const EditUsersSubmit = async (
  getAccessTokenSilently,
  userId,
  locationId,
  right,
) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  try {
    // Get the access token
    const accessToken = await getAccessTokenSilently();

    // Iterate over the `right` array to send individual requests
    for (const user of right) {
      const locationData = {
        userId, // Constant from function argument
        locationId, // Constant from function argument
        username: user.username, // Extracted from `right`
      };

      // Send a PUT request for each user
      const response = await fetch(
        `${apiUrl}/manage/locations/update-location`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Include the access token
          },
          body: JSON.stringify(locationData),
        },
      );

      if (!response.ok) {
        throw new Error(
          `Failed to update location for user ${user.username}: ${response.statusText}`,
        );
      }

      const result = await response.json();
    }
  } catch (error) {
    console.error("Error updating user locations:", error);
    throw error; // Reject with error on failure
  }
};

// Export the function as the default export
export default EditUsersSubmit;
