import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  TextField,
  IconButton,
  Typography,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import {
  checkIfOpen,
  checkIfScheduledOpen,
  sendWhenOpen,
} from "./AfterHoursDialog";

const convertToStandardTime = (abbr) => {
  switch (abbr) {
    case "EDT":
      return "EST";
    case "CDT":
      return "CST";
    case "MDT":
      return "MST";
    case "PDT":
      return "PST";
    case "AKDT":
      return "AKST";
    case "HADT":
      return "HAST";
    default:
      return abbr; // If no conversion needed, return the original abbreviation
  }
};

const ScheduleNotificationDialog = ({
  open,
  onClose,
  onSelectDateTime,
  businessHours,
}) => {
  const [openDateTime, setOpenDateTime] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [userTimeZone, setUserTimeZone] = useState("");
  const [timezone, setTimezone] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState("");

  useEffect(() => {
    if (open) {
      if (checkIfOpen(businessHours)) {
        setSelectedDateTime(getCurrentTimePlus15Minutes());
        setIsButtonDisabled(false);
      } else {
        const nextAvailableTime = sendWhenOpen(
          businessHours,
          setSelectedDateTime,
        );
        if (nextAvailableTime) {
          setSelectedDateTime(
            dayjs(nextAvailableTime, "dddd, MM/DD/YYYY h:mm A"),
          );
          setIsButtonDisabled(false); // Enable the button since a valid next available time was set
        } else {
          setIsButtonDisabled(true);
        }
      }
    }
  }, [open, businessHours]);

  const getCurrentTimePlus15Minutes = () => {
    let currentTime = dayjs(); // Use dayjs for current time
    currentTime = currentTime.add(31, "minute"); // Add 15 minutes and reassign
    return currentTime;
  };

  useEffect(() => {
    // Get current date and time
    const currentDate = new Date();

    // Get the time zone abbreviation without considering the offset
    const timeZoneAbbr = currentDate
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];

    // Convert the time zone abbreviation if it's in daylight saving time
    const convertedTimeZoneAbbr = convertToStandardTime(timeZoneAbbr);

    setUserTimeZone(convertedTimeZoneAbbr);
  }, []);

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(userTimezone);
  }, []);

  const handleScheduleClick = () => {
    onSelectDateTime(selectedDateTime); // Pass selected date and time to parent component
    onClose(); // Call the onClose prop to close the dialog
  };

  const handleOldDateTimeChange = (newDateTime) => {
    const now = new Date();
    const minimumDateTime = new Date(now.getTime() + 30 * 60000); // 30 minutes from now

    if (newDateTime < minimumDateTime) {
      setIsButtonDisabled(true);
      setShowWarning(
        "Invalid date/time: Must be scheduled at least 30 minutes before",
      );
    } else {
      // Check if the selected date/time is during open hours
      const isScheduledOpen = checkIfScheduledOpen(businessHours, newDateTime);

      if (!isScheduledOpen) {
        setIsButtonDisabled(true);
        setShowWarning(
          "Invalid date/time: Location is closed at the selected time",
        );
      } else {
        setIsButtonDisabled(false);
        setShowWarning(""); // No warning if everything is valid
        setSelectedDateTime(newDateTime);
      }
    }
  };

  const handleDateTimeChange = (newDateTime) => {
    const now = new Date();
    const minimumDateTime = new Date(now.getTime() + 30 * 60000); // 30 minutes from now

    // Use the new date/time value to compare
    if (newDateTime.isBefore(dayjs(minimumDateTime))) {
      setIsButtonDisabled(true);
      setShowWarning(
        "Invalid date/time: Must be scheduled at least 30 minutes before",
      );
    } else {
      // Check if the selected date/time is during open hours
      const isScheduledOpen = checkIfScheduledOpen(businessHours, newDateTime);

      if (!isScheduledOpen) {
        setIsButtonDisabled(true);
        setShowWarning("Location is closed at the selected time");
      } else {
        setSelectedDateTime(newDateTime); // Update the state with the new date/time
        setIsButtonDisabled(false);
        setShowWarning(""); // No warning if everything is valid
      }
    }
  };

  const handleCloseDateTime = () => {
    setOpenDateTime(false);
  };

  const handleDialogCloseClick = () => {
    onClose(); // Call the onClose prop to close the dialog
  };

  const handleCancelScheduleClick = () => {
    setSelectedDateTime(null);
    setShowWarning(false);
    onClose(); // Call the onClose prop to close the dialog
  };

  const WhiteTimePicker = styled(DateTimePicker)({
    "& input": {
      color: "#48768C",
      fontWeight: 600,
      fontSize: 13,
    },
    // Other styles omitted for brevity
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <IconButton
        aria-label="close"
        onClick={handleCancelScheduleClick}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>

      <DialogContent
        sx={{
          width: "550px",
          backgroundColor: "#FFFFFFF",
          padding: "29px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#48768C",
            mb: 2.5,
            fontWeight: 600,
            letterSpacing: "-2%",
            lineHeight: "-2%",
          }}
        >
          Schedule Your Notification
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ width: "75%" }}>
            <DateTimePicker
              disablePast
              value={selectedDateTime}
              onChange={handleDateTimeChange}
              closeOnSelect={false}
              sx={{
                flex: "0 0 125px",
                marginRight: "1rem",
                mb: "3px",
                borderRadius: "8px",
                boxShadow:
                  "1px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                color: "#48768C",
                fontWeight: 600,
                fontSize: 13,
              }}
              slotProps={{
                popper: {
                  sx: {
                    "& .MuiList-root": {
                      backgroundColor: "#FFFFFF",
                      color: "#48768C",
                    },
                    "& .MuiDateCalendar-root": {
                      backgroundColor: "#FFFFFF",
                      color: "#48768C",
                    },
                    "& .MuiPickersDay-root": {
                      color: "#48768C",
                    },
                    "& .MuiPickersDay-root.Mui-selected": {
                      backgroundColor: "#FFFFFF !important",
                      color: "#48768C",
                      border: "1px solid #B0BEC5",
                      borderRadius: "8px",
                    },
                    "& .MuiMenuItem-root": {
                      "&.Mui-selected": {
                        backgroundColor: "#FFFFFF !important",
                        color: "#48768C",
                        border: "1px solid #B0BEC5",
                        borderRadius: "8px",
                        fontWeight: 600,
                      },
                      "&:hover": {
                        backgroundColor: "#FFFFFF !important",
                        color: "#48768C",
                        borderRadius: "8px",
                      },
                    },

                    "& .MuiDialogActions-root .MuiButton-textPrimary": {
                      color: "#48768C",
                      border: "1px solid #B0BEC5",
                      borderRadius: "10px",
                      marginTop: "10px",
                    },
                    "& .MuiDialogActions-root": {
                      backgroundColor: "#FFFFFF",
                      borderTop: "none",
                    },
                    "& .css-dplwbx-MuiPickersCalendarHeader-label": {
                      display: "none",
                    },
                    ".css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon":
                      {
                        display: "none",
                      },
                  },
                },
                textField: {
                  InputProps: {
                    sx: {
                      color: "#48768C",
                      fontWeight: 600,
                      fontSize: 13,
                    },
                  },
                },
              }}
              TextFieldComponent={(props) => (
                <TextField
                  {...props}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      order: -1,
                      marginRight: "8px",
                    },
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </Box>
        </LocalizationProvider>
        {showWarning && (
          <Typography
            sx={{ color: "#8C4350", fontSize: 13, mt: 2, fontWeight: 600 }}
          >
            {showWarning}
          </Typography>
        )}
        {selectedDateTime && !isButtonDisabled && (
          <Typography
            sx={{ color: "#647D9E", fontSize: "14px", mt: 2, fontWeight: 600 }}
          >
            {`Your notification will be sent on: ${selectedDateTime.format(
              "ddd, MMM D YYYY hh:mm A",
            )} ${userTimeZone}`}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#FFFFFF" }}>
        <Button
          disabled={isButtonDisabled}
          sx={{
            display: "flex",
            border: "1px solid #48768C",
            borderRadius: "10px",
            padding: "5px",
            color: "#48768C",
            backgroundColor: isButtonDisabled ? "#CCCCCC" : "#FFFFFF",
            mb: 1,
            mt: -3,
            mr: 1,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: isButtonDisabled ? "#CCCCCC" : "#DBEAF0",
            },
            "&:active": {
              backgroundColor: isButtonDisabled ? "#CCCCCC" : "#DBEAF0", // Adjust active state color if needed
            },
          }}
          onClick={handleScheduleClick}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleNotificationDialog;
