import { Typography, TextField, InputAdornment } from "@mui/material";

const BugDescriptionForm = ({ bugDetails, handleChange }) => {
  return (
    <>
     
     <Typography
                   

                   sx={{
                     fontSize: "1.15rem",
                     fontWeight: 600,
                     pb: "5px",
                 
                     width: "100%", // Makes sure it takes up full width
                     letterSpacing: "-0.029em", // Adds letter spacing
                     color: "#48768c",
                 
                   }}
                 >       
                 Give us some details about the issue you're experiencing.
                       </Typography>
     
                       <Typography 
         color="textSecondary"
      sx={{ fontSize: ".93rem", fontWeight: 600, pl: "2px", pb: "10px", mt: "15px", pt:'5px' }}>
       Feel free to be as detailed as possible. The more information you provide, the better we can work towards resolving the problem.
      </Typography>

      <TextField
        sx={{
          marginBottom: 3.5,
          border:'none',
          "& textarea": {
            minHeight: "50px", // Set the minimum height of the textarea
          },
          "& .MuiOutlinedInput-root": {
            border:'none',  
            "&:hover": {
              borderColor: "none", // Remove the border on hover
            },
          },
        
        }}
        
        placeholder="Describe the problem in detail"
        name="description"
        value={bugDetails.description}
        onChange={handleChange}
        multiline
        fullWidth
        required
        rows={1}
        InputProps={{
          endAdornment: bugDetails.description.length > 0 && bugDetails.description.length < 20 ? (
            <InputAdornment position="end" sx={{ fontSize: "0.75rem", color: "#d32f2f", position: "absolute", bottom: "15px", right: "10px" }}>
           
           
           <Typography 
         color="textSecondary"
      sx={{ fontSize: ".93rem", fontWeight: 600, }}>
            Your description needs to be least 20 characters.
            </Typography>
            </InputAdornment>
          ) : null,
        }}
      />
    </>
  );
};

export default BugDescriptionForm;