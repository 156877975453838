import { useAuth0 } from "@auth0/auth0-react";

const useCreateCustomer = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getAccessTokenSilently } = useAuth0();

  // Function to create a customer in Stripe via the backend API
  const createCustomer = async (customerData) => {
    try {
      // Get the access token using Auth0's getAccessTokenSilently
      const token = await getAccessTokenSilently();

      // Send a POST request to your backend with the access token
      const response = await fetch(
        `${apiUrl}/subscriptions/manage/create-customer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass the access token in the Authorization header
          },
          body: JSON.stringify(customerData), // Send the customer data
        },
      );

      if (!response.ok) {
        throw new Error("Failed to create customer");
      }

      const data = await response.json();
      return data; // Return the response data
    } catch (error) {
      console.error("Error creating customer:", error);
      throw error;
    }
  };

  return { createCustomer };
};

export default useCreateCustomer;
