import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAppContext } from "src/context/AppContext";
import useAddLocation from "src/components/payments/hooks/useAddLocation";
import useAddNewUser from "src/components/payments/hooks/useAddNewUser.js";
import usePlanDetails from "src/components/payments/hooks/usePlanDetails";
import { Button, Box, Fade } from "@mui/material";
import PlanCard from "./planCard";

// Load the Stripe object outside the component
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({
  clientSecret,
  customerId,
  priceId,
  apiUrl,
  onSubscriptionUpdate,
  refreshSubscription,
  onClose,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showSnackBar } = useAppContext();
  const { addNewLocation } = useAddLocation();
  const { addNewUser } = useAddNewUser();
  const { planDetails } = usePlanDetails();
  const [paymentElementLoaded, setPaymentElementLoaded] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      setError("Stripe has not loaded or clientSecret is missing");
      return;
    }

    setLoading(true);

    try {
      // Step 1: Confirm the SetupIntent
      const { setupIntent, error: setupError } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          // Return URL if needed
        },
        redirect: "if_required",
      });

      if (setupError) {
        setError(setupError.message);
        setLoading(false);
        return;
      }

      // Step 2: Extract payment method from SetupIntent
      const paymentMethodId = setupIntent.payment_method;

      // Step 3: Create the subscription with the payment method
      const response = await fetch(
        `${apiUrl}/subscriptions/create-subscription`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            customerId,
            planDetails,
            paymentMethodId, // Include the payment method in the request
          }),
        },
      );

      const subscriptionResponse = await response.json();

      if (response.ok) {
        // Optional: Handle the first invoice payment if required
        if (subscriptionResponse.latest_invoice) {
          const paymentIntentClientSecret =
            subscriptionResponse.latest_invoice.payment_intent.client_secret;

          const { error: paymentError } = await stripe.confirmPayment({
            clientSecret: paymentIntentClientSecret,
            elements,
            confirmParams: {
              return_url: `http://${apiUrl}/dashboard/Account/Billing`, // Your return URL
            },
          });

          if (paymentError) {
            setError(paymentError.message);
            setLoading(false);
            return;
          }
        }

        // Success handling
        onSubscriptionUpdate();
        refreshSubscription();
        onClose();
        showSnackBar(
          "Subscription created and charged successfully",
          "success",
        );
      } else {
        setError(subscriptionResponse.error || "Failed to create subscription");
      }
    } catch (error) {
      console.error("Error during subscription creation:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {paymentElementLoaded && (
        <Fade in={paymentElementLoaded} timeout={500}>
          <Box align="center" sx={{ mt: 2, mb: 2 }}>
            <PlanCard planDetails={planDetails} />
          </Box>
        </Fade>
      )}

      <form onSubmit={handleSubmit}>
        {clientSecret && (
          <Box sx={{ mx: 0.25, pt: 1 }}>
            <PaymentElement onReady={() => setPaymentElementLoaded(true)} />
          </Box>
        )}

        {paymentElementLoaded && (
          <Button
            type="submit"
            disabled={!stripe || !clientSecret || loading}
            sx={{
              display: "flex",
              border: "1px solid #48768C",
              borderRadius: "10px",
              padding: "8px",
              color: "#48768C",
              backgroundColor: "#FFFFFF",
              mt: 2,
              justifyContent: "center",
              alignItems: "center",
              ml: "auto",
              mr: "auto",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              width: "98%",
              "&:hover": {
                backgroundColor: "#DBEAF0",
              },
              "&:active": {
                backgroundColor: "#DBEAF0",
              },
            }}
            color="primary"
          >
            {loading ? "Processing..." : "Subscribe"}
          </Button>
        )}
      </form>
    </>
  );
};

// Wrapper component to ensure Elements is loaded correctly
const PaymentFormWrapper = ({
  customerId,
  priceId,
  apiUrl,
  onSubscriptionUpdate,
  onClose,
  clientSecret,
  refreshSubscription,
}) => {
  const appearance = {
    theme: "stripe", // You can also try 'night', 'flat', 'none', etc.
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#7D4E4F",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
    },
    rules: {
      ".Label": {
        color: "#6c90a2",
        fontSize: "16.5px",
        marginBottom: "3px",
      },
      ".Input": {
        backgroundColor: "#F2F9FF",
        border: "1px solid #e1e6ef",
        fontSize: "14.5px",
        padding: 0,
        color: "#4E617D",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "10px",
        height: "auto",
        fontFamily: "Source Sans Pro, sans-serif",
      },
      ".Input--invalid": {
        color: "#7D4E4F",
      },
      ".PaymentElement": {
        backgroundColor: "#f9fbfd",
        padding: "12px",
        borderRadius: "4px",
        boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15)",
      },
      ".Input::placeholder": {
        color: "#48768CCC", // Correct selector for placeholder text
      },
    },
  };

  // Enable the skeleton loader UI for optimal loading.
  const loader = "auto";

  return (
    <div>
      {clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance, loader }}
        >
          <PaymentForm
            clientSecret={clientSecret}
            customerId={customerId}
            priceId={priceId}
            apiUrl={apiUrl}
            onSubscriptionUpdate={onSubscriptionUpdate}
            onClose={onClose}
            refreshSubscription={refreshSubscription}
          />
        </Elements>
      ) : (
        <div>Loading Payment Information...</div>
      )}
    </div>
  );
};

export default PaymentFormWrapper;
