import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  Modal,
  Select,
  MenuItem,
  Box,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import editUser from "../../public/assets/icons/icons/editUser.png";
import userDetailsIcon from "../../public/assets/icons/icons/userDetails.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import { useSingleUser } from "../../context/SingleUserContext";
import {
  PrimaryButton,
  CancelButton,
} from "src/components/Buttons/ModalButtons";

const EditProfile = ({
  isOpen,
  onClose,
  handleChangeTitle,
  handleFirstNameChange,
  handleLastNameChange,
  editData,
  handleSave,
  loading,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { reloadComponents, showSnackBar } = useAppContext();
  const { username, subUserData, setSubUserData } = useAuth();

  const userId = user.sub;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (isOpen) {
      // Initialize phone and email independently from editData
      setFirstName(editData.first_name || "");
      setLastName(editData.last_name || "");
      setTitle(editData.title || "");
    }
  }, [isOpen]);

  useEffect(() => {
    // Disable the button if phone and email match the initial editData
    if (
      firstName === editData.first_name &&
      lastName === editData.last_name &&
      title === editData.title
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    firstName,
    lastName,
    title,
    editData.first_name,
    editData.last_name,
    editData.title,
  ]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>
        <DialogTitle
          sx={{
            padding: 3,
            width: "550px",
            display: "flex",
            alignItems: "center",
            height: "55px",
            backgroundColor: "#FFFFFF",
            pt: 3,
            pb: 2.45,
            color: "#374247",
          }}
        >
          <img
            src={editUser}
            alt="editUser"
            style={{
              border: "1.25px solid rgba(173, 216, 230, 0.7)",
              width: "auto",
              height: "30px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "3px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              borderRadius: "6px",
            }}
          />
        </DialogTitle>

        <DialogContent
          sx={{
            padding: 2,
            width: "100%",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "left",
            zIndex: 0,
          }}
        >
          <Typography
            sx={{
              color: "#48768C",
              fontSize: 19,
              mb: 1.5,
              fontWeight: 600,
              pl: 0.95,
            }}
          >
            Edit Your Profile{" "}
          </Typography>
          <Box
            sx={{
              borderRadius: "20px",
              border: "1px solid rgba(173, 216, 230, 0.7)",
              py: 2,
              width: "auto",
              borderRadius: "15px",
              mb: 1.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "flex-start",
                width: "100%",
                ml: 1,
                mb: "20px",
              }}
            >
              <img
                src={userDetailsIcon}
                alt="addUserIcon"
                style={{
                  border: "1px solid #B0BEC5",
                  marginLeft: "1px",
                  width: "auto",
                  height: "28px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  padding: "2px",
                  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                  borderRadius: "6px",
                }}
              />{" "}
              <Typography
                sx={{
                  ml: 0.5,
                  alignSelf: "flex-end",
                  color: "#48768C",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Your Personal Information
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                pr: 3,
                ml: 1,
                mt: 1,
              }}
            >
              <TextField
                label="First Name"
                variant="outlined"
                size="small"
                fullWidth
                value={editData.first_name}
                onChange={handleFirstNameChange}
                sx={{
                  color: "#000",
                  pb: 2,
                  mr: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#2F5C71", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#48768CBA", // Focused border color
                    },
                  },
                  "& input": {
                    width: "100%",
                    textAlign: "left",
                    color: "#2F5C71", // Text color inside the input
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2F5C71", // Default label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#2F5C71", // Label color when focused
                  },
                }}
              />

              <TextField
                label="Last Name"
                variant="outlined"
                size="small"
                fullWidth
                value={editData.last_name}
                onChange={handleLastNameChange}
                sx={{
                  color: "#48768C",
                  pb: 2,

                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#2F5C71", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#48768CBA", // Focused border color
                    },
                  },
                  "& input": {
                    width: "100%",
                    textAlign: "left",
                    color: "#2F5C71", // Text color inside the input
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2F5C71", // Default label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#2F5C71", // Label color when focused
                  },
                }}
              />
            </Box>
            <FormControl
              sx={{
                width: "95.75%",
                mb: 1,
                ml: "7.5px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #FFFFFF",
              }}
            >
              <InputLabel
                sx={{
                  color: "#2F5C71",
                  "&.Mui-focused": {
                    color: "#2F5C71", // Keep the same color on focus
                  },
                  "&.MuiFormLabel-filled": {
                    color: "#2F5C71", // Keep the same color when filled
                  },
                }}
                htmlFor="outlined-adornment-role"
              >
                {" "}
                Role
              </InputLabel>
              <Select
                label="Role"
                size="small"
                variant="outlined"
                value={editData.title}
                onChange={handleChangeTitle} // Efficient state update
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: "#2F5C71",

                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2F5C71", // Hover state border color
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#48768CBA", // Focused border color
                  },
                  "& .Mui-focused .MuiInputLabel-root": {
                    color: "#2F5C71", // Focused label color
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #2F5C715E",
                      borderTop: "none",
                    },
                  },
                }}
              >
                <MenuItem
                  value="Employee"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Employee
                </MenuItem>
                <MenuItem
                  value="Manager"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Manager
                </MenuItem>
                <MenuItem
                  value="Owner"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Owner
                </MenuItem>
                <MenuItem
                  value="Regional Manager"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Regional Manager
                </MenuItem>
                <MenuItem
                  value="Account Administrator"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Account Administrator
                </MenuItem>
                <MenuItem
                  value="Director of Operations"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Director of Operations
                </MenuItem>
                <MenuItem
                  value="Assistant Manager"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Assistant Manager
                </MenuItem>
                <MenuItem
                  value="Shift Lead"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Shift Lead
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <DialogActions
            sx={{
              pt: 3,
              backgroundColor: "#FFFFFF",
              width: "100%",
              mx: "auto", // Set left and right margin to auto to center horizontally
              my: "auto",
              display: "flex", // Use flexbox
              justifyContent: "center", // Center content horizontally
            }}
          >
            <CancelButton
              sx={{
                borderRadius: "10px",
                fontSize: "17px",
                mb: 1,
                height: "51px",
                width: "25%",
              }}
              onClick={onClose}
            >
              Cancel
            </CancelButton>
            <PrimaryButton
              sx={{
                width: "80%", // Adjust width as needed
                height: "51px",
                fontSize: "18px",
                mb: 1,
              }}
              disabled={
                !editData.first_name ||
                !editData.last_name ||
                loading ||
                disabled
              }
              onClick={handleSave}
              variant="contained"
            >
              {loading ? "Saving..." : "Save Changes"}
            </PrimaryButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default EditProfile;
