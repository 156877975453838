import { useEffect, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import AffectedAreasForm from "./Forms/AffectedAreasForm";
import BugDescriptionForm from "./Forms/BugDescriptionForm";
import SeverityForm from "./Forms/SeverityForm";
import StepsToReproduceForm from "./Forms/StepsToReproduceForm";
import {
    PrimaryButton,
    CancelButton,
    SecondaryButton,
  } from "src/components/Buttons/ModalButtons";
  import ReviewForm from "./Forms/ReviewForm";

const steps = ["Affected Areas", "Bug Description", "Steps To Reproduce", "Review & Submit"];

export default function BugReportStepper({ bugDetails, handleChange, handleSubmit, onClose, isLoading }) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };


  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  useEffect(() => {
    const checkButtonDisabled = () => {  
      if (activeStep === 0) {
        return !bugDetails.bugType || !bugDetails.severity;
      } else if (activeStep === 1) {
        return bugDetails.description.length < 20;
      } else if (activeStep === 2) {
        return !bugDetails.stepsToReproduce;
      }
      return true; // Default case if no step matches
    };
  
    setIsNextButtonDisabled(checkButtonDisabled());
  }, [activeStep, bugDetails]); // Re-run effect when either activeStep or bugDetails changes

  return (
    <Stack direction="row" sx={{ height: "100%", width: "100%", }}>
      {/* Left Stepper Section (30% Width, 60% Height) */}
      <Box
  sx={{
    width: "29%", // Width based on viewport width, will shrink and grow with the window
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Center the Stepper horizontally in the Box
    justifyContent: "flex-start", // Center content vertically if needed
    p: 2,
    height: "20vh", // 45% of viewport height
    mt:-1.35,
    
    

  }}
>
      
        <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
      <Step key={label}>
        <StepLabel
          sx={{
            height: "33px", 
            width: "12vw", 
            backgroundColor: index === activeStep ? "#d6e7f7" : "transparent", // Background color for active step
            borderRadius: "5px", 
            alignItems: "center", 
            display: "flex", // Make the content flex   
            justifyContent: "center", // Center the content horizontally
            pr:3,
            
                "& .MuiStepLabel-label": {
                    marginLeft: "10px", // Add margin-left to all labels
                    fontSize: ".94rem", // Font size for all labels
                  },
               "& .MuiStepLabel-completed": {
                 color: "#48768C", // Text color for completed steps
               },
               "& .MuiStepLabel-label.Mui-completed": {
                 color: "#48768C", // Text color for completed labels
                    fontWeight: 600, // Bold font for completed labels
               },
               "& .MuiStepLabel-label.Mui-active": {
                 color: "#48768C", // Text color for active labels
                 fontWeight: 600, // Bold font for active labels
                 marginLeft: "10px", 
               },
               "& .MuiStepIcon-text": {
                 fill: "#48768C", // Icon text color for the active step
                 transform: "scale(1)", 
                 transformOrigin: "center",
               },
               "& .MuiStepIcon-root.Mui-completed": {
                color: "#c2d7df", // Icon color for active step
                border:  'none'  , // Border color for active step
                transform: "scale(1.628)", 
                transformOrigin: "center", 
                borderRadius: "50%", 
                fontWeight: 600, // Bold font for active step
                border:  "2px solid #97bac9"  , // Border color for active step

                

               },
               // Active step icon color and background
               "& .MuiStepIcon-root.Mui-active": {
                 color: "#FFFFFF", // Icon color for active step
                 border:  index === activeStep ? "1.5px solid #48768C" : "1px solid #97bac9"  , // Border color for active step
                 transform: "scale(1.728)", 
                 transformOrigin: "center", 
                 borderRadius: "50%", 
                 fontWeight: 600, // Bold font for active step
               },
               
               // Icon color for non-active and non-completed steps
               "& .MuiStepIcon-root": {
                color: "#FFFFFF", // Icon color for active step
                border: "1px solid #c2d7df", // Border color for active step
                transform: "scale(1.728)", 
                transformOrigin: "center", 
                borderRadius: "50%", 
               },
             }}
           >
             {label}
           </StepLabel>
         </Step>
          ))}
        </Stepper>
      </Box>

      {/* Vertical Divider */}
      <Divider
  orientation="vertical"
  flexItem
  sx={{ boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.3)", }} 
/>
      {/* Right Form Section (70%) */}
      <Box
        sx={{
          width: "70%",
          backgroundColor: "#FFFFFF",
          p: 1,
          alignItems: "center", // Center the Stepper horizontally in the Box
          justifyContent: "flex-start", // Center content vertically if needed
          mt:-.75,
          pl:3,
          overflowY: "auto",
          
        }}
      >
        <form onSubmit={handleSubmit}>
        {activeStep === 0 && (
            <>
            <AffectedAreasForm bugDetails={bugDetails} handleChange={handleChange} />
            <SeverityForm bugDetails={bugDetails} handleChange={handleChange} />
            </>
        )}
        {activeStep === 1 && <BugDescriptionForm bugDetails={bugDetails} handleChange={handleChange} />}
        {activeStep === 2 && <StepsToReproduceForm bugDetails={bugDetails} handleChange={handleChange} />}
        {activeStep === 3 && <ReviewForm bugDetails={bugDetails} handleChange={handleChange} />}
        </form>

        {/* Navigation Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2.5, alignItems: "left", gap: 2,       zIndex: 1000, position: "sticky", bottom: 0, backgroundColor: "#FFFFFF", mb:1}}>
          <CancelButton
          sx={{ width: "125px" }}
          disabled={activeStep === 0} onClick={handleBack}>
            Back
          </CancelButton>
          {activeStep < steps.length - 1 ? (
            <PrimaryButton sx={{ width: "125px" }}
            disabled={isNextButtonDisabled}
              onClick={handleNext}>
              Next
            </PrimaryButton>
          ) : (
            <PrimaryButton
            sx={{width: "125px"}}
              variant="contained"
              onClick={handleSubmit}
              disabled={
                !bugDetails.bugType ||
                !bugDetails.description ||
                !bugDetails.severity ||
                !bugDetails.stepsToReproduce ||
                isLoading
              }
            >
              {isLoading ? "Submitting..." : "Submit"}
            </PrimaryButton>
          )}
        </Box>
      </Box>
    </Stack>
  );
}