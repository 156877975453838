import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  Fade,
} from "@mui/material";
import { Stack, styled } from "@mui/system";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "src/components/payments/CheckoutForm.js";
import ActivePlanCard from "../billingComponents/activePlanCard";
import { useAccountContext } from "src/context/AccountContext";
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useCancelSubscription from "src/components/payments/hooks/useCancelSubscription.js";
import NewSubscription from "src/components/payments/components/NewSubscription.js";
import { useSubscriptionDetails } from "src/components/payments/routes/useSubscriptionDetails";
import { useValidateSubscription } from "src/components/payments/routes/validateSubscription";
import BillingDataCard from "../billingComponents/billingDataCard";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
);

const PaymentHistoryContainer = styled("div")({
  padding: "1rem",
  paddingTop: "2rem", // Add top padding to the payment history card
});

function Billing({
  totalLocations,
  totalUsers,
  users,
  locations,
  subscriptionDetails,
  cancelSubscription,
  subscriptionValid,
  handleSubscriptionUpdate,
  verifying,
  planDetails,
  refetchSubscriptionDetails,
  fetchBillingHistory,
  billingHistory,
  loadingBilling,
  cardTitle,
  cardSubTitle,
  billingAddress,
  subUserData,
  billingContact,
  fetchUsers,
  fetchLocations,
  paymentStatus,
  failedPaymentError,
}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [open, setOpen] = useState(false);

  const { accountData } = useAccountContext();
  const { getAccessTokenSilently } = useAuth0();

  console.log("Billing History:", billingHistory);

  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: "#FFFFFF",
            height: "42.5px",
            letterSpacing: "-1%",
            lineHeight: "-1%",
            minHeight: "65px",
          },
        },
      },
    },
  });

  const StyledHeader = styled(TableCell)({
    color: "#447c92",
    borderBottom: "none",
    letterSpacing: "-2%",
    lineHeight: "-2%",
    minHeight: "65px",
  });

  const StyledRow = styled(TableCell)({
    color: "#48768C",
    fontWeight: 500,
    letterSpacing: "-2%",
    lineHeight: "-2%",
    height: "50px",
  });

  const StyledHeaderText = styled(Typography)({
    fontWeight: 500,
    fontSize: 13.5,
    align: "center",
    margin: "auto",
    letterSpacing: "-2%",
    lineHeight: "-2%",
    height: "40px",
    color: "#48768C",
  });

  const onClose = () => {
    setOpen(false);
  };

  const customerId = accountData ? accountData.customer_id : null;
  const subscriptionId = subscriptionDetails
    ? subscriptionDetails.lastSubscriptionId
    : null;
  const priceId = planDetails ? planDetails.plan_price_id : null;

  const handleCancelClick = () => {
    if (customerId && subscriptionId) {
      cancelSubscription(customerId, subscriptionId);
    }
  };

  const deleteUser = async (auth0UserId) => {
    try {
      const response = await fetch(`${apiUrl}/api/users/${auth0UserId}`, {
        method: "DELETE",
      });

      const data = await response.json();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const Header = styled(Typography)({
    width: "100%",
    textAlign: "left",
    letterSpacing: "-.02em",
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Header
        sx={{
          color: "#48768C",
          fontWeight: 700,
          fontSize: 17.5,
        }}
      >
        Billing & Subscription
      </Header>
      <Header
        sx={{ color: "#48768C", fontSize: ".9rem", letterSpacing: "-.01em" }}
      >
        Manage your subscription and payment information.
      </Header>
      <Box
        sx={{
          width: "auto",
          height: "74vh",

          mt: 1.5,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            fontSize: ".9rem",
            fontWeight: 600,
            letterSpacing: "0.02em",
            lineHeight: 1.5,
            color: "#48768C",
            width: "100%",
            flex: 1,
            margin: 0,
          }}
        >
          {subscriptionValid && subscriptionDetails ? (
            <>
              <Stack spacing={2} direction="row" sx={{ mt: 0.5, flex: 1 }}>
                <ActivePlanCard
                  name={
                    subscriptionDetails
                      ? subscriptionDetails.subscriptionName
                      : ""
                  }
                  subscriptionValid={subscriptionValid}
                  status={
                    subscriptionDetails.subscriptionStatus
                      ? subscriptionDetails.subscriptionStatus
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.subscriptionStatus.slice(1)
                      : ""
                  }
                  cost={
                    subscriptionDetails ? "$" + subscriptionDetails.cost : ""
                  }
                  reoccurrence={
                    subscriptionDetails &&
                    subscriptionDetails.paymentReoccurrence
                      ? subscriptionDetails.paymentReoccurrence
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.paymentReoccurrence.slice(1) +
                        "ly"
                      : ""
                  }
                  paymentMethod={
                    subscriptionDetails &&
                    subscriptionDetails.last4 !== "No card found"
                      ? "**** **** **** " + subscriptionDetails.last4
                      : "No Active Payment Method"
                  }
                  subscriptionId={subscriptionId}
                  customerId={customerId}
                  onSubscriptionUpdate={handleSubscriptionUpdate}
                  planDetails={planDetails}
                  stripePromise={stripePromise}
                  priceId={priceId}
                  totalLocations={totalLocations}
                  totalUsers={totalUsers}
                  users={users}
                  locations={locations}
                  accountData={accountData}
                  handleSubscriptionUpdate={handleSubscriptionUpdate}
                  fetchUsers={fetchUsers}
                  fetchLocations={fetchLocations}
                />
                <BillingDataCard
                  title={"Payment Method"}
                  name={
                    subscriptionDetails
                      ? subscriptionDetails.subscriptionName
                      : ""
                  }
                  subscriptionValid={subscriptionValid}
                  status={
                    subscriptionDetails.subscriptionStatus
                      ? subscriptionDetails.subscriptionStatus
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.subscriptionStatus.slice(1)
                      : ""
                  }
                  cost={
                    subscriptionDetails ? "$" + subscriptionDetails.cost : ""
                  }
                  reoccurrence={
                    subscriptionDetails &&
                    subscriptionDetails.paymentReoccurrence
                      ? subscriptionDetails.paymentReoccurrence
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.paymentReoccurrence.slice(1) +
                        "ly"
                      : ""
                  }
                  paymentMethod={
                    subscriptionDetails &&
                    subscriptionDetails.last4 !== "No card found"
                      ? "**** **** **** " + subscriptionDetails.last4
                      : "No Active Payment Method"
                  }
                  subscriptionId={subscriptionId}
                  description={"Change how you pay for your subscription."}
                  customerId={customerId}
                  onSubscriptionUpdate={handleSubscriptionUpdate}
                  planDetails={planDetails}
                  stripePromise={stripePromise}
                  priceId={priceId}
                  subscriptionDetails={subscriptionDetails}
                  refetchSubscriptionDetails={refetchSubscriptionDetails}
                  billingAddress={billingAddress}
                  subUserData={subUserData}
                  billingHistory={billingHistory}
                  billingContact={billingContact}
                  fetchBillingHistory={fetchBillingHistory}
                  paymentStatus={paymentStatus}
                  failedPaymentError={failedPaymentError}
                />
              </Stack>
            </>
          ) : (
            <>
              {/* If there is no active subscription, show the plan selection card */}
              <Stack spacing={2} direction="row" sx={{ mt: 1.5 }}>
                <ActivePlanCard
                  name={planDetails ? planDetails.plan_name : ""}
                  status={
                    subscriptionDetails
                      ? subscriptionDetails.subscriptionStatus
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.subscriptionStatus.slice(1)
                      : ""
                  }
                  cost={
                    planDetails
                      ? "$" + (planDetails.plan_cost / 100).toFixed(2)
                      : ""
                  }
                  subscriptionValid={subscriptionValid}
                  subscriptionId={subscriptionId}
                  customerId={customerId}
                  priceId={planDetails ? planDetails.plan_price_id : ""}
                  onSubscriptionUpdate={handleSubscriptionUpdate}
                  planDetails={planDetails}
                  stripePromise={stripePromise}
                  subscriptionDetails={subscriptionDetails}
                  totalLocations={totalLocations}
                  totalUsers={totalUsers}
                  users={users}
                  locations={locations}
                  accountData={accountData}
                  handleSubscriptionUpdate={handleSubscriptionUpdate}
                  fetchUsers={fetchUsers}
                  fetchLocations={fetchLocations}
                />
                <BillingDataCard
                  title={"Activate Your Subscription"}
                  name={planDetails ? planDetails.plan_name : ""}
                  status={
                    subscriptionDetails
                      ? subscriptionDetails.subscriptionStatus
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.subscriptionStatus.slice(1)
                      : ""
                  }
                  cost={
                    planDetails
                      ? "$" + (planDetails.plan_cost / 100).toFixed(2)
                      : ""
                  }
                  subscriptionValid={subscriptionValid}
                  subscriptionId={subscriptionId}
                  customerId={customerId}
                  priceId={planDetails ? planDetails.plan_price_id : ""}
                  onSubscriptionUpdate={handleSubscriptionUpdate}
                  planDetails={planDetails}
                  stripePromise={stripePromise}
                  subscriptionDetails={subscriptionDetails}
                  description={
                    "Add a payment method to activate your subscription."
                  }
                  billingAddress={billingAddress}
                  subUserData={subUserData}
                  billingHistory={billingHistory}
                  billingContact={billingContact}
                  fetchBillingHistory={fetchBillingHistory}
                  paymentStatus={paymentStatus}
                  failedPaymentError={failedPaymentError}
                  refetchSubscriptionDetails={refetchSubscriptionDetails}
                />
              </Stack>
            </>
          )}
        </Box>

        <PaymentHistoryContainer sx={{ margin: 0, padding: 0, mt: 2 }}>
          <TableContainer
            component={Paper}
            sx={{
              margin: 0,
              padding: 0,
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              border: "1.25px solid rgba(173, 216, 230, 0.5)",
              borderRadius: "6px",
              minHeight: "49vh",
              flexGrow: 1, // Allow the table to take up remaining space

              overflowY: "auto",
              maxHeight: "34vh",
            }}
          >
            <Typography
              sx={{
                mb: 0.5,
                mt: 2,
                color: "#48768C",
                fontSize: 17.5,
                fontWeight: 600,
                ml: 1.65,
                letterSpacing: -0.2,
              }}
              gutterBottom
            >
              Recent Payment History
            </Typography>
            <Typography
              sx={{
                mb: 1.5,
                color: "#48768C",
                fontSize: 15.5,
                fontWeight: 500,
                ml: 1.65,
              }}
              gutterBottom
            >
              View or download previous subscription invoices.
            </Typography>
            <Table size="small" sx={{}} stickyHeader>
              <TableHead>
                <TableRow></TableRow>
                <TableRow sx={{ color: "#48768C" }}>
                  <TableCell sx={{ color: "#48768C" }}>
                    <strong>Date</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#48768C" }}>
                    <strong>Description</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Amount Paid</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Status</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Invoice Link</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billingHistory &&
                  billingHistory.length > 0 &&
                  billingHistory
                    .filter(
                      (invoice) => invoice.status.toLowerCase() === "paid",
                    )
                    .map((invoice) => (
                      <Fade key={invoice.invoice_id} in={true} timeout={200}>
                        <TableRow key={invoice.invoice_id}>
                          <StyledRow sx={{ fontWeight: "bold" }}>
                            {invoice.date}
                          </StyledRow>
                          <StyledRow>{invoice.description}</StyledRow>
                          <StyledRow>${invoice.amount_paid}</StyledRow>
                          <StyledRow>
                            {invoice.status.charAt(0).toUpperCase() +
                              invoice.status.slice(1)}
                          </StyledRow>
                          <TableCell>
                            <a
                              href={invoice.hosted_invoice_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Invoice
                            </a>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))}
                {billingHistory &&
                  billingHistory.length === 0 &&
                  !loadingBilling && (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{
                          backgroundColor: "rgba(191, 218, 231, 0.1)",
                          overflowY: "hidden",
                        }}
                      >
                        <Typography
                          align="center"
                          sx={{
                            mt: 5,
                            width: "100%",
                            padding: "20px",
                            color: "#48768C",
                            fontSize: 16,
                            ml: 2,
                            justifyContent: "center",
                            height: "30vh",
                            borderLeft: "none",
                            borderRight: "none",
                            fontWeight: 600,
                            alignSelf: "center",
                          }}
                        >
                          No Recent Payment History...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                {billingHistory &&
                  billingHistory.length === 0 &&
                  loadingBilling && (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{ backgroundColor: "rgba(191, 218, 231, 0.1)" }}
                      >
                        <Typography
                          align="center"
                          sx={{
                            mt: 15,
                            width: "100%",
                            padding: "20px",
                            color: "#48768C",
                            fontSize: "18px",
                            ml: 2,
                            justifyContent: "center",
                            height: "30vh",
                            borderLeft: "none",
                            borderRight: "none",
                            fontWeight: 600,
                            fontStyle: "italic",
                          }}
                        >
                          Loading Payment History...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </PaymentHistoryContainer>
      </Box>
    </Box>
  );
}

export default Billing;
