import { Typography, TextField, InputAdornment } from "@mui/material";

const StepsToReproduceForm = ({ bugDetails, handleChange }) => {
  return (
    <>
     <Typography
                   

                   sx={{
                     fontSize: "1.15rem",
                     fontWeight: 600,
                     pb: "5px",
                 
                     width: "100%", // Makes sure it takes up full width
                     letterSpacing: "-0.029em", // Adds letter spacing
                     color: "#48768c",
                 
                   }}
                 >       
                 How can we reproduce the issue?
                       </Typography>
     
                       <Typography 
         color="textSecondary"
         sx={{ fontSize: ".93rem", fontWeight: 600, pl: "2px", pb: "10px", mt: "15px", pt:'5px' }}>
        Tell us step by step how we can make the problem happen again so we can insure that the issue is resolved.  
      </Typography>

      <TextField
        sx={{
          marginBottom: 3.5,
          border:'none',
          "& textarea": {
            minHeight: "50px", // Set the minimum height of the textarea
          },
          "& .MuiOutlinedInput-root": {
            border:'none',  
            "&:hover": {
              borderColor: "none", // Remove the border on hover
            },
          },
        
        }}
        placeholder="How can we make the problem happen again? (Tell us step by step)"
        name="stepsToReproduce"
        value={bugDetails.stepsToReproduce}
        onChange={handleChange}
        multiline
        rows={1}

        fullWidth
        required
        InputProps={{
          endAdornment: bugDetails.stepsToReproduce.length > 0 && bugDetails.stepsToReproduce.length < 20 ? (
            <InputAdornment position="end" sx={{ fontSize: "0.75rem", color: "#d32f2f", position: "absolute", bottom: "15px", right: "10px" }}>
           
           
           <Typography 
         color="textSecondary"
      sx={{ fontSize: ".93rem", fontWeight: 600, }}>
            Your response needs to be least 20 characters.
            </Typography>
            </InputAdornment>
          ) : null,
        }}
      />
    </>
  );
};

export default StepsToReproduceForm;