import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Button,
  IconButton,
  TextField,
  Box,
  Stack,
  Chip,
  Popover,
  Paper,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  LinearProgress,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import { useAccountContext } from "src/context/AccountContext";
import { useAppContext } from "../../../../context/AppContext";
import { useAuth } from "../../../../context/AuthContext";
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext";
import icons from "../../../../public/assets/icons/messageModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//import EditIcon from '../../../../public/assets/icons/icons/help-center/edit.png';
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import EditIcon from "../../../../public/assets/icons/icons/help-center/edit.png";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import StarTwoToneIcon from "@mui/icons-material/StarTwoTone";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import SquareIcon from "@mui/icons-material/Square";
import IconGrid from "./Components/IconGrid.js";
import AddIcon from "@mui/icons-material/Add";
import "./styles.css";
import FieldSelector from "./Components/FieldSelector";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import {
  updateFieldData,
  addNewField,
  deleteField,
  updateConfigs,
  handleDisableTwoWayNotifications,
  enableTwoWayNotifications,
  deleteNotification,
} from "./api/api";
import ConfirmedNotificationButton from "src/components/NewUserChecklist/confirmedNotificationButton";
import AddNewButton from "./Components/AddNewNotification/AddNewButton";
import NewNotificationType from "./Components/AddNewNotification/NewNotificationType";
import { set } from "date-fns";
import ConfirmChangesDialog from "./Components/ConfirmChangesDialog";

function UserNotifications({ cardTitle, cardSubTitle }) {
  const { username, subUserData, setSubUserData } = useAuth();
  const { accountData, onboardingData, fetchOnboardingRecord } =
    useAccountContext(); // Use the accountData from the context
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const { selectedNotifications, businessHours, loadingNotifications } =
    useUserSelectedNotifications();
  const [cardAnchorEl, setCardAnchorEl] = useState(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingContextMessage, setIsEditingContextMessage] = useState(false);
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [isEditingFieldName, setIsEditingFieldName] = useState(false);
  const [isEditingContextLabel, setIsEditingContextLabel] = useState(false);
  const [isEditingIcon, setIsEditingIcon] = useState(false);
  const [isEditingFieldType, setIsEditingFieldType] = useState(false);
  const [showFieldTypeSaveIcon, setShowFieldTypeSaveIcon] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [isAddingNewField, setIsAddingNewField] = useState(false);
  const [isAddedNewField, setIsAddedNewField] = useState(false);
  const [notificationStates, setNotificationStates] = useState({});
  const [isAddingIcon, setIsAddingIcon] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [newFieldTypeSelected, setNewFieldTypeSelected] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isFieldButtonDisabled, setIsFieldButtonDisabled] = useState("");
  const [fieldAnchorEl, setFieldAnchorEl] = useState(null);
  const [newFieldAnchorEl, setNewFieldAnchorEl] = useState(null);
  const [defaultSelectedFieldType, setDefaultSelectedFieldType] = useState("");
  const [selectedFieldType, setSelectedFieldType] = useState("");
  const [changedFieldType, setChangedFieldType] = useState(false);
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [isDeletingField, setIsDeletingField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isColorMenuHidden, setIsColorMenuHidden] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [createNew, setCreateNew] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dialogSubText, setDialogSubText] = useState("");
  const [enableTwoWay, setEnableTwoWay] = useState(false);

  const handleResetCardPopover = () => {
    setIsEditingColor(false);
    setIsEditingContextMessage(false);
    setIsEditingTitle(false);
    setIsEditingIcon(false);
    setConfirmDelete(false);
  };

  const handleConfirmNotification = async () => {
    setLoading(true);
    if (!isAuthenticated || !user) {
      throw new Error("User is not authenticated");
      setLoading(false);
    }

    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/confirm_notifications`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ auth0_user: user.sub }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Failed to fetch onboarding data: ${errorData.error || "Unknown error"}`,
        );
      }

      const result = await response.json();
      await fetchOnboardingRecord();
      showSnackBar("Notification setup complete!", true);
      setLoading(false);

      // Or use a state variable to display a message
    } catch (error) {
      showSnackBar("Unable to confirm notifications. Please try again.", true);
      setLoading(false);
    }
  };

  const handleDelete = async (notificationType) => {
    try {
      setIsLoading(true);
      await deleteNotification(
        userId,
        notificationType,
        getAccessTokenSilently,
        handleReloadComponents,
        apiUrl,
        showSnackBar,
        handleCardPopoverClose,
      );
    } catch (error) {
      console.error("Error deleting notification:", error);
      // Optionally handle the error, e.g., show an error message
    } finally {
      setIsLoading(false); // This runs whether deleteNotification succeeds or fails
      setConfirmDelete(false); // Ensure this is always called
    }
  };

  const notificationsConfirmed = onboardingData?.notifications_confirmed; // Safe access with optional chaining

  const showDialog = (message, onConfirmCallback) => {
    setCardOpen(false);
    setDialogMessage(message);
    setOnConfirm(() => onConfirmCallback);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    handleCardPopoverClose();
  };

  const handleDialogConfirm = () => {
    onConfirm();
    handleDialogClose();
    handleCardPopoverClose();
  };

  const userId = user.sub; // Get the user ID from Auth0

  const handleDisableClick = () => {
    setEnableTwoWay(false);
    setDialogSubText(
      "Your customers can still respond to any two-way notifications that are pending approval.",
    );
    showDialog(
      "Are you sure you want to disable two-way notifications?",
      async () => {
        try {
          // Proceed to disable the two-way notifications
          await handleDisableTwoWayNotifications({
            userId,
            setIsLoading,
            setApiError,
            apiUrl,
            getAccessTokenSilently,
            handleReloadComponents,
            showSnackBar,
            handleCardPopoverClose,
          });
        } catch (error) {
          console.error("Error disabling two-way notifications:", error);
        }
      },
    );
  };

  const handleEnableClick = async () => {
    if (
      !selectedNotifications ||
      !Array.isArray(selectedNotifications) ||
      selectedNotifications.length === 0
    ) {
      console.error(
        "selectedNotifications is not properly defined or is empty",
      );
      return;
    }

    // Flatten the selectedNotifications array to easily search all fields
    const allNotifications = selectedNotifications.flatMap((notification) => {
      const fields = notification.selected_data || {};
      return Object.keys(fields).map((notificationType) => ({
        notificationType,
        ...fields[notificationType].Configs,
        Fields: fields[notificationType].Fields,
      }));
    });

    // Find if there are any fields with twoWayEnabled: "true"
    const twoWayEnabledNotifications = allNotifications.filter((notification) =>
      Object.values(notification.Fields || {}).some(
        (field) => field.twoWayEnabled === "true",
      ),
    );

    // If there are zero two-way enabled notifications
    if (twoWayEnabledNotifications.length === 0) {
      setEnableTwoWay(true);
      setDialogSubText(
        "Are you sure you want to enable two-way notifications?'",
      );
      showDialog(
        'Two-way notifications allow you to request approval from your customers.\nYour customers can respond "yes" or "no" to this notification, and we will alert you when they respond.',
        async () => {
          try {
            // Enable the new two-way notification
            await enableTwoWayNotifications({
              userId,
              setIsLoading,
              setApiError,
              apiUrl,
              getAccessTokenSilently,
              handleReloadComponents,
              handleCardPopoverClose,
              showSnackBar,
              enabledTwoWay: editData.notificationType,
            });
          } catch (error) {
            console.error("Error enabling two-way notifications:", error);
          }
        },
      );
      return;
    }

    // If there's already one two-way enabled notification, handle that
    const existingNotification = twoWayEnabledNotifications[0]; // Only one can be enabled

    if (existingNotification) {
      const existingNotificationName = existingNotification.notificationType;
      setEnableTwoWay(true);
      setDialogSubText(
        `Would you like to disable two-way notifications on "${existingNotificationName}" and enable it on "${editData.notificationType}"?`,
      );
      showDialog(
        `Only one notification can have two-way notifications enabled at a time.`,
        async () => {
          try {
            // Disable the existing two-way notification
            await handleDisableTwoWayNotifications({
              userId,
              setIsLoading,
              setApiError,
              apiUrl,
              getAccessTokenSilently,
              handleReloadComponents,
              handleCardPopoverClose,
              showSnackBar,
            });

            // Enable the new two-way notification
            await enableTwoWayNotifications({
              userId,
              setIsLoading,
              setApiError,
              apiUrl,
              getAccessTokenSilently,
              handleReloadComponents,
              handleCardPopoverClose,
              showSnackBar,
              enabledTwoWay: editData.notificationType,
              disableNotificationType: existingNotificationName,
            });
          } catch (error) {
            console.error("Error handling two-way notifications:", error);
          }
        },
      );
    }
  };

  const [editData, setEditData] = useState({
    notificationType: null, // Initialize as needed
    newNotificationType: null,
    fieldName: null, // Initialize as needed
    updatedFieldName: null,
    fieldData: {
      tag: null, // Parameterized tag value
      icon: null,
      value: null,
      fieldType: null,
      valueType: null, // Initialize as needed
      messagePrefix: null,
    },
    configs: {},
  });

  const [newFieldData, setNewFieldData] = useState({
    notificationType: null,
    fieldName: null,
    fieldData: {
      tag: null,
      icon: null,
      value: null,
      fieldType: null,
      valueType: null,
      isRequired: null,
    },
    configs: {},
  });

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const { value } = target;

    // Check if the pressed key is not a letter, not a special key, and not a space
    if (
      (!(key === " ") && // Allow space key
        !/^[A-Za-z]$/.test(key)) || // Disallow other keys that are not letters
      value.length >= 30 // Restrict to 20 letters
    ) {
      !(key === "Backspace") && // Allow Backspace key
        !(e.ctrlKey && key === "a") && // Allow Ctrl + A (Windows/Linux)
        !(e.metaKey && key === "a") && // Allow Command + A (macOS)
        !(key === "ArrowLeft") && // Allow Left arrow key
        !(key === "ArrowRight") && // Allow Right arrow key
        // Prevent the default action (typing the character)
        e.preventDefault();
    }
  };

  const handleKeyDownMessage = (e) => {
    const { key, target } = e;
    const { value } = target;

    // Allow Backspace key, Ctrl + A (Windows/Linux), Command + A (macOS),
    // Left arrow key, Right arrow key, space key, and periods/exclamation points
    if (
      !(key === "Backspace") &&
      !(e.ctrlKey && key === "a") &&
      !(e.metaKey && key === "a") &&
      !(key === "ArrowLeft") &&
      !(key === "ArrowRight") &&
      !(key === " ") &&
      !(key === ".") &&
      !(key === "!") &&
      !(key === ":") &&
      !(key === ";") && // Block semicolon (;)
      value.length >= 100 // Restrict to 20 characters
    ) {
      !(key === "Backspace") && e.preventDefault(); // Prevent the default action (typing the character)
    }
  };

  const handleFieldNameChange = (e) => {
    let value = e.target.value;
    // Capitalize the first letter of the input value
    value = value.charAt(0).toUpperCase() + value.slice(1);
    value = value.replace(/[;:]+$/g, ""); // Regex to match colon or semi-colon at the end of the string and replace with empty string

    setEditData((prevState) => ({
      ...prevState,
      updatedFieldName: value, // Update the fieldName in editData with the input value
    }));
  };

  const handleEditNotificationType = (e) => {
    let value = e.target.value;
    // Capitalize the first letter of the input value
    value = value.charAt(0).toUpperCase() + value.slice(1);
    setEditData((prevState) => ({
      ...prevState,
      newNotificationType: value,
    }));
  };

  const handleNewFieldName = (e) => {
    const { name, value } = e.target;
    // Capitalize the first letter of the input value
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    setNewFieldData((prevData) => ({
      ...prevData,
      [name]: capitalizedValue,
    }));
  };

  const handleEditContextLabel = (e) => {
    let value = e.target.value;
    // Capitalize the first letter of the input value
    value = value.charAt(0).toUpperCase() + value.slice(1);

    // Remove special characters from the end of the string
    value = value.replace(/[^\w\s]+$/, "");

    setEditData((prevData) => ({
      ...prevData,
      fieldData: {
        ...prevData.fieldData,
        messagePrefix: value,
      },
    }));
  };

  const handleEditContextMessage = (e) => {
    let value = e.target.value;
    // Capitalize the first letter of the input value
    value = value.charAt(0).toUpperCase() + value.slice(1);

    setEditData((prevData) => ({
      ...prevData,
      configs: {
        ...prevData.configs, // Preserve other config properties
        "Context Message": value, // Update the Context Message
      },
    }));
  };

  const handleSetAsDefault = () => {
    // Update editData first
    setEditData((prevData) => {
      const updatedData = {
        ...prevData,
        configs: {
          ...prevData.configs,
          new_is_default: "true",
        },
      };

      // Call updateConfigs after updating editData
      updateConfigs(
        updatedData,
        user,
        apiUrl,
        getAccessTokenSilently,
        handleCardPopoverClose,
        handleFieldPopoverClose,
        handleReloadComponents,
        handleClearNewFieldData,
        showSnackBar,
        setIsLoading,
      );

      return updatedData; // Return updatedData from setEditData
    });
  };

  const [cardOpen, setCardOpen] = useState(false);

  const fieldOpen = Boolean(fieldAnchorEl);

  const newFieldOpen = Boolean(newFieldAnchorEl);

  const handleSelectFieldType = (selectedValue, valueType) => {
    setChangedFieldType(true);

    // Update the fieldType in editData.fieldData
    setEditData((prevData) => ({
      ...prevData,
      fieldData: {
        ...prevData.fieldData,
        fieldType: selectedValue,
        notificationType: prevData.notificationType,
      },
    }));

    // Call setNewFieldData with the updated data
    setNewFieldData((prevData) => ({
      ...prevData,
      fieldType: selectedValue,
      notificationType: prevData.notificationType,
      isRequired: false,
      valueType: valueType,
      value: "",
      icon: selectedIcon,
      messagePrefix: "",
    }));

    handleNewFieldPopoverClose();
  };

  const handleFieldPopoverOpen = (
    event,
    fieldName,
    fieldData,
    notificationType,
    fieldType,
  ) => {
    const configs = cardConfigs(notificationType);
    setEditData({ fieldName, fieldData, notificationType, fieldType, configs });
    // Open the popover
    setFieldAnchorEl(event.currentTarget);
  };

  const handleNewFieldPopoverOpen = (
    event,
    fieldName,
    fieldData,
    notificationType,
  ) => {
    const configs = cardConfigs(notificationType);
    setNewFieldData({
      fieldName: newFieldData.fieldName,
      fieldType: selectedValue,
      notificationType: newFieldData.notificationType,
    });
    // Open the popover
    setNewFieldAnchorEl(event.currentTarget);
  };

  // Function to handle card popover open
  const handleCardPopoverOpen = (event, notificationType, index) => {
    // Retrieve the Configs and FieldData for the notificationType
    const configs = cardConfigs(notificationType);

    // Assuming `selectedNotifications` is available in your component
    const fieldData =
      selectedNotifications[0].selected_data[notificationType]?.Fields || {};

    // Set editData to include all necessary data
    setEditData({ notificationType, index, configs, fieldData });
    setIsEditingTitle(false);
    setIsEditingContextMessage(false);
    setIsEditingColor(false);
    setConfirmDelete(false);
    setIsLoading(false);
    setIsEditingColor(false);

    // Open the popover
    setCardAnchorEl(event.currentTarget);
    setCardOpen(true);
  };

  const handleSelectedIcon = (iconName) => {
    setSelectedIcon(iconName);
    setIsButtonDisabled(false);
    setNewFieldData((prevData) => ({
      ...prevData,
      fieldData: {
        ...prevData.fieldData,
        icon: iconName,
      },
    }));

    // Update editData for fieldIcon
    setEditData((prevData) => ({
      ...prevData,
      fieldData: {
        ...prevData.fieldData,
        notificationType: prevData.notificationType,
        icon: iconName,
      },
    }));
  };

  const handleUpdateIcon = (iconName) => {
    setSelectedIcon(iconName);
    setIsButtonDisabled(false);
    setEditData((prevData) => ({
      ...prevData,
      fieldData: {
        ...prevData.fieldData,
        icon: iconName,
      },
    }));
  };

  useEffect(() => {
    if (defaultSelectedFieldType) {
      handleFieldPopoverClose();
      handleNewFieldPopoverClose();
    }
  }, [defaultSelectedFieldType]);

  useEffect(() => {
    if (selectedFieldType) {
      handleFieldPopoverClose();
      handleNewFieldPopoverClose();
    }
  }, [selectedFieldType]);

  // Function to toggle the add new field state for a specific notification type
  const toggleAddingNewField = (notificationType, value) => {
    setIsFieldButtonDisabled(true);

    setNotificationStates((prevState) => ({
      ...prevState,
      [notificationType]: {
        ...prevState[notificationType],
        isAddingNewField: value,
      },
    }));
  };

  const toggleIsAddedNewField = (notificationType, value) => {
    setIsFieldButtonDisabled(true);
    setNotificationStates((prevState) => ({
      ...prevState,
      [notificationType]: {
        ...prevState[notificationType],
        isAddedNewField: value,
      },
    }));
  };

  // Function to handle card popover close
  const handleCardPopoverClose = () => {
    setCardOpen(false);
  };

  // Function to handle field popover close
  const handleFieldPopoverClose = () => {
    setFieldAnchorEl(null);
    setIsEditingFieldName(false);
    setIsEditingContextLabel(false);
    setIsEditingIcon(false);
    setIsEditingFieldType(false);
    setIsDeletingField(false);
  };

  const handleNewFieldPopoverClose = () => {
    setFieldAnchorEl(null);
    setNewFieldAnchorEl(null);
    setIsEditingFieldName(false);
    setIsEditingContextLabel(false);
    setIsEditingIcon(false);
    setIsEditingFieldType(false);
  };

  const handleClearNewFieldData = (notificationType) => {
    setSelectedIcon(null);
    setIsButtonDisabled(true);
    setNewFieldTypeSelected(null);
    setIsAddedNewField(false);
    setIsAddingNewField(false);
    setNotificationStates({});
    setEditData("");
    setSelectedValue("");
    setIsAddingIcon(false);
    setIsFieldButtonDisabled(false);
    setIsAddingIcon(false);
    setIsEditingIcon(false);
    setNewFieldData("");
    setChangedFieldType(false);
  };

  const renderIcon = (iconName) => {
    if (iconName) {
      const iconPath = icons[iconName];
      return (
        <img src={iconPath} alt={iconName} className="default_field_icon" />
      );
    }
    return null;
  };

  const defaultNotification =
    selectedNotifications &&
    selectedNotifications[0] &&
    selectedNotifications[0].selected_data
      ? Object.keys(selectedNotifications[0].selected_data).find(
          (notificationType) =>
            selectedNotifications[0].selected_data[notificationType]?.Configs
              ?.is_default === "true",
        )
      : null;

  // Filter out the default notification type
  const otherNotifications =
    selectedNotifications &&
    selectedNotifications[0] &&
    selectedNotifications[0].selected_data
      ? Object.keys(selectedNotifications[0].selected_data).filter(
          (notificationType) => notificationType !== defaultNotification,
        )
      : [];

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              "--TextField-brandBorderColor": "#E0E3E7",
              "--TextField-brandBorderHoverColor": "#B2BAC2",
              "--TextField-brandBorderFocusedColor": "#6F7E8C",
              "& label.Mui-focused": {
                color: "#48768C",
                borderBottom: "none",
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: "var(--TextField-brandBorderColor)",
            },
            root: {
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderBottom: "none",
              },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                borderBottom: "none",
                color: "#48768C",
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              "&::before, &::after": {
                borderBottom: "none",
                color: "#48768C",
              },
              "&:hover:not(.Mui-disabled, .Mui-error):before": {
                borderBottom: "none",
              },
              "&.Mui-focused:after": {
                borderBottom: "none",
                color: "#48768C",
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              "&::before": {
                borderBottom: "none",
                color: "#48768C",
              },
              "&:hover:not(.Mui-disabled, .Mui-error):before": {
                borderBottom:
                  "2px solid var(--TextField-brandBorderHoverColor)",
              },
              "&.Mui-focused:after": {
                borderBottom:
                  "2px solid var(--TextField-brandBorderFocusedColor)",
                color: "#48768C",
              },
            },
          },
        },
      },
    });

  const outerTheme = useTheme();

  const generateColor = (notificationType) => {
    // Get the notification data for the specified type
    const notificationData =
      selectedNotifications[0]?.selected_data[notificationType];

    // Check if notificationData exists and has the Configs object
    if (
      notificationData &&
      notificationData.Configs &&
      notificationData.Configs.Color
    ) {
      const color = notificationData.Configs.Color;

      // Generate additional colors based on the provided color
      let cardBackgroundColor,
        borderColor,
        fieldFontTextColor,
        iconBackgroundColor,
        titleColor,
        containerBackgroundColor;

      switch (color) {
        case "#2BBB9E":
          titleColor = "#2BBB9E";
          cardBackgroundColor = "#DFF5F0";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#2BBB9E";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#BFEBE2";
          break;
        //parts arrived
        case "#7589A2":
          titleColor = "#7589A2";
          cardBackgroundColor = "#EAEDF1";
          containerBackgroundColor = "#FFFFFF";

          borderColor = "#7589A2";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#7589A2";
          break;
        //pending approval
        case "#a1848f":
          titleColor = "#a1848f";
          cardBackgroundColor = "#F1EDEE";
          containerBackgroundColor = "#FFFFFF";

          borderColor = "#8C4350";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#FFFFFF";
          break;
        //action needed
        case "#8C4350":
          titleColor = "#8C4350";
          cardBackgroundColor = "#EEE3E5";
          containerBackgroundColor = "#FFFFFF";

          borderColor = "#8C4350";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#FFFFFF";
          break;

        // Palette 1
        case "#7D7371":
          titleColor = "#7D7371";
          cardBackgroundColor = "#F0E7E6";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#7D7371";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#7D7371";
          break;
        // Palette 2
        case "#A9A877":
          titleColor = "#A9A877";
          cardBackgroundColor = "#F2F2E5";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#A9A877";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#A9A877";
          break;
        // Palette 3
        case "#7F8487":
          titleColor = "#7F8487";
          cardBackgroundColor = "#E9EAEA";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#7F8487";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#FFFFFF";
          break;
        // Palette 4
        case "#B5AD8E":
          titleColor = "#B5AD8E";
          cardBackgroundColor = "#F3F2E5";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#B5AD8E";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#B5AD8E";
          break;
        // Palette 5
        case "#827B76":
          titleColor = "#827B76";
          cardBackgroundColor = "#EAE7E5";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#827B76";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#FFFFFF";
          break;
        // Palette 6
        case "#A3847D":
          titleColor = "#A3847D";
          cardBackgroundColor = "#F1E8E7";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#A3847D";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#A3847D";
          break;
        // Palette 7
        case "#858BA9":
          titleColor = "#858BA9";
          cardBackgroundColor = "#EAEAF1";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#858BA9";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#FFFFFF";
          break;
        // Palette 8
        case "#A89A8C":
          titleColor = "#A89A8C";
          cardBackgroundColor = "#F0E9E3";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#A89A8C";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#A89A8C";
          break;
        // Palette 9
        case "#808A7C":
          titleColor = "#808A7C";
          cardBackgroundColor = "#E7ECE5";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#808A7C";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#FFFFFF";
          break;
        // Palette 10
        case "#9F7F75":
          titleColor = "#9F7F75";
          cardBackgroundColor = "#EEE5E3";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#9F7F75";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#9F7F75";
          break;
        // Palette 11
        case "#827D89":
          titleColor = "#827D89";
          cardBackgroundColor = "#E7E6EB";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#827D89";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#FFFFFF";
          break;
        // Palette 13
        case "#7D8585":
          titleColor = "#7D8585";
          cardBackgroundColor = "#E6EAEA";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#7D8585";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#FFFFFF";
          break;
        // Palette 14
        case "#A97D75":
          titleColor = "#A97D75";
          cardBackgroundColor = "#F2E7E5";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#A97D75";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#A97D75";
          break;
        // Palette 15
        case "#828CA0":
          titleColor = "#828CA0";
          cardBackgroundColor = "#E6E9F0";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#828CA0";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#FFFFFF";
          break;
        // Palette 16
        case "#A38D8A":
          titleColor = "#A38D8A";
          cardBackgroundColor = "#EDE6E5";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#A38D8A";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#A38D8A";
          break;
        // Palette 17
        case "#7F8285":
          titleColor = "#7F8285";
          cardBackgroundColor = "#E9E9EA";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#7F8285";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#FFFFFF";
          break;
        // Palette 18
        case "#A6A077":
          titleColor = "#A6A077";
          cardBackgroundColor = "#EDEDE5";
          containerBackgroundColor = "#FFFFFF";
          borderColor = "#A6A077";
          fieldFontTextColor = "#747C86";
          iconBackgroundColor = "#A6A077";
          break;
        default:
          // Default colors if color doesn't match any case
          cardBackgroundColor = "#FFFFFF";
          borderColor = "#000000";
          fieldFontTextColor = "#000000";
          iconBackgroundColor = "#FFFFFF";
      }
      return {
        cardBackgroundColor,
        borderColor,
        fieldFontTextColor,
        iconBackgroundColor,
        titleColor,
        containerBackgroundColor,
        LightTooltip,
      };
    }
  };

  const renderNewField = (
    fieldType,
    notificationType,
    includeCustomMessageBox,
  ) => {
    switch (fieldType) {
      case "DATE":
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
                whiteSpace: "noWrap",
              }}
            >
              MM/DD/YYYY
            </Typography>
            <CalendarMonthTwoToneIcon className="change_field_icon" />
          </Box>
        );
      case "TIME":
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
                whiteSpace: "noWrap",
              }}
            >
              HH:MM AM/PM
            </Typography>
            <QueryBuilderTwoToneIcon className="change_field_icon" />
          </Box>
        );
      case "DATE/TIME":
        return (
          <Stack direction="row" spacing={1} style={{ marginRight: "auto" }}>
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
                whiteSpace: "noWrap",
              }}
            >
              MM/DD/YYYY
            </Typography>
            <CalendarMonthTwoToneIcon className="change_field_icon" />
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
                whiteSpace: "noWrap",
              }}
            >
              HH:MM AM/PM
            </Typography>
            <QueryBuilderTwoToneIcon className="change_field_icon" />
          </Stack>
        );
      case "CUR":
        return (
          <Typography
            sx={{
              marginRight: "auto",
              mt: 0.35,
              fontWeight: 600,
              fontSize: "15px",
              color: "#747C86",
              ml: 1,
              mr: 1,
            }}
          >
            $0.00
          </Typography>
        );
      case "STR":
        return (
          <Box>
            <CalendarMonthTwoToneIcon className="change_field_icon" />
          </Box>
        );
      case "TMR":
        return (
          <Stack
            direction="row"
            spacing={1}
            style={{
              marginLeft: "auto",
              flexGrow: 1,
              justifyContent: "flex-end",
              marginLeft: "auto",
              marginRight: "10px",
            }}
          >
            <Chip
              label="Days"
              sx={{ fontWeight: 600, fontSize: "12px", color: "#747C86" }}
            />
            <Chip
              label="Hours"
              sx={{ fontWeight: 600, fontSize: "12px", color: "#747C86" }}
            />
            <Chip
              label="Minutes"
              sx={{ fontWeight: 600, fontSize: "12px", color: "#747C86" }}
            />
          </Stack>
        );
      default:
        return null;
    }
  };

  const renderField = (
    fieldType,
    notificationType,
    includeCustomMessageBox,
  ) => {
    switch (fieldType) {
      case "DATE":
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
                whiteSpace: "noWrap",
              }}
            >
              MM/DD/YYYY
            </Typography>
            <CalendarMonthTwoToneIcon className="change_field_icon" />
          </Box>
        );
      case "TIME":
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
                whiteSpace: "noWrap",
              }}
            >
              HH:MM AM/PM
            </Typography>
            <QueryBuilderTwoToneIcon className="change_field_icon" />
          </Box>
        );
      case "DATE/TIME":
        return (
          <Stack direction="row" spacing={1} style={{ marginRight: "auto" }}>
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
                whiteSpace: "noWrap",
              }}
            >
              MM/DD/YYYY
            </Typography>
            <CalendarMonthTwoToneIcon className="change_field_icon" />
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
                whiteSpace: "noWrap",
              }}
            >
              HH:MM AM/PM
            </Typography>
            <QueryBuilderTwoToneIcon className="change_field_icon" />
          </Stack>
        );
      case "CUR":
        return (
          <Typography
            sx={{
              marginRight: "auto",
              mt: 0.35,
              fontWeight: 600,
              fontSize: "15px",
              color: "#747C86",
              mr: 1,
            }}
          >
            $0.00
          </Typography>
        );
      case "STR":
        return (
          <Typography
            sx={{
              fontSize: "12px",
              display: "flex",
              fontWeight: "bold",
              mt: 3,
              marginLeft: "auto",
              flexGrow: 1,
              justifyContent: "flex-end",
              marginLeft: "auto",
              marginRight: "10px",
              color: "#747C86",
            }}
          >
            225 / 225
          </Typography>
        );
      case "TMR":
        return (
          <Stack
            direction="row"
            spacing={1}
            style={{
              marginLeft: "auto",
              flexGrow: 1,
              justifyContent: "flex-end",
              marginLeft: "auto",
              marginRight: "10px",
            }}
          >
            <Chip
              label="Days"
              sx={{
                fontWeight: 600,
                fontSize: "12px",
                color: "#747C86",
                backgroundColor: `${generateColor(notificationType).cardBackgroundColor}`,
              }}
            />
            <Chip
              label="Hours"
              sx={{
                fontWeight: 600,
                fontSize: "12px",
                color: "#747C86",
                backgroundColor: `${generateColor(notificationType).cardBackgroundColor}`,
              }}
            />
            <Chip
              label="Minutes"
              sx={{
                fontWeight: 600,
                fontSize: "12px",
                color: "#747C86",
                backgroundColor: `${generateColor(notificationType).cardBackgroundColor}`,
              }}
            />
          </Stack>
        );
      default:
        return null;
    }
  };

  const cardConfigs = (notificationType) => {
    // Get the notification data for the specified type
    const notificationData =
      selectedNotifications[0]?.selected_data[notificationType];

    // Check if notificationData exists and has the Configs object
    if (notificationData && notificationData.Configs) {
      const configs = notificationData.Configs;
      return configs;
    }
  };

  const LightTooltip = styled(({ className, notificationType, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme, notificationType }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F2F9FF",
      color: "#48768C",
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  }));

  const Header = styled(Typography)({
    width: "100%",
    textAlign: "left",
    letterSpacing: "-.02em",
  });

  return (
    <Box sx={{ width: "100%", pb: 2 }}>
      <Header
        sx={{
          color: "#48768C",
          fontWeight: 700,
          fontSize: 17.5,
        }}
      >
        Manage Your Notifications
      </Header>
      <Header sx={{ color: "#48768C", fontSize: ".9rem" }}>
        Create & Manage Your Notifications Sent to Customers
      </Header>
      {!loadingNotifications ? (
        <Box sx={{ width: "100%", overflow: "auto", borderRadius: "20px" }}>
          <Box
            sx={{
              overflow: "auto",
              maxHeight: "80vh",
              pb: 1,
              mt: 2,
              width: "100%",
            }}
          >
            {/* Default Notification */}
            {defaultNotification && (
              <Paper
                elevation={1}
                square={false}
                className={`notification_card_default`}
                sx={{}}
              >
                <Box
                  sx={{
                    mt: -1.5,
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {subUserData && subUserData.is_admin && (
                    <IconButton
                      disabled={Boolean(isFieldButtonDisabled)}
                      onClick={(event) =>
                        handleCardPopoverOpen(event, defaultNotification)
                      }
                      sx={{
                        mr: -1.5,
                        justifyContent: "right",
                        flexDirection: "flex-end",
                      }}
                    >
                      <img
                        src={EditIcon}
                        alt="detailsIcon"
                        style={{
                          border: "1px solid #B0BEC5",
                          width: "auto",
                          height: "24px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "6px",
                          padding: "1px",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    </IconButton>
                  )}

                  {subUserData && !subUserData.is_admin && (
                    <Box
                      sx={{
                        mr: -1.5,
                        justifyContent: "right",
                        flexDirection: "flex-end",
                        width: "auto",
                        height: "38px",
                      }}
                    >
                      {" "}
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "auto",
                    paddingTop: 1,
                    paddingBottom: 0.25,
                    marginTop: "-35px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    noWrap
                    sx={{
                      color: `${generateColor(defaultNotification).titleColor}`,
                      borderRadius: "8px",
                      fontSize: 15.5,
                      fontWeight: 600,
                      textAlign: "left",
                      position: "relative", // Required for absolute positioning of animation
                      letterSpacing: "-2%",
                      lineheight: "-2%",
                    }}
                  >
                    {defaultNotification}
                  </Typography>

                  <Stack direction="row">
                    <Box>
                      <LightTooltip
                        title="Your Default Notification"
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [10, -5], // Adjust the offset to ensure it's positioned correctly
                              },
                            },
                            {
                              name: "preventOverflow",
                              options: {
                                boundariesElement: "viewport", // Ensure it does not overflow the viewport
                              },
                            },
                          ],
                        }}
                      >
                        <StarTwoToneIcon
                          sx={{
                            marginLeft: "7px",
                            alignItems: "center",
                            display: "flex",
                            border: `1px solid ${generateColor(defaultNotification).titleColor}`,
                            width: "20px",
                            height: "18px",
                            color:
                              generateColor(defaultNotification).titleColor,
                            backgroundColor:
                              generateColor(defaultNotification)
                                .cardBackgroundColor,
                            borderRadius: "4px",
                            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                            position: "relative", // Ensure the tooltip anchors properly
                            zIndex: 1, // Make sure it renders on top of other elements
                          }}
                        />
                      </LightTooltip>
                    </Box>
                    {Object.entries(
                      selectedNotifications[0]?.selected_data?.[
                        defaultNotification
                      ]?.Fields || {},
                    )
                      .filter(
                        ([, fieldData]) =>
                          fieldData.isRequired &&
                          fieldData.twoWayEnabled === "true",
                      )
                      .map(([fieldName, fieldData]) => (
                        <Box key={fieldName}>
                          <LightTooltip title="Two Way Notifications Enabled">
                            <ForumTwoToneIcon
                              key={fieldName}
                              sx={{
                                marginLeft: "10px",
                                alignItems: "center",
                                display: "flex",
                                border: `1px solid ${generateColor(defaultNotification).titleColor}`,
                                width: "18.5px",
                                height: "18.5px",
                                color:
                                  generateColor(defaultNotification).titleColor,
                                backgroundColor:
                                  generateColor(defaultNotification)
                                    .cardBackgroundColor,
                                borderRadius: "4px",
                                padding: "1px",
                                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                              }}
                            />
                          </LightTooltip>
                        </Box>
                      ))}
                  </Stack>
                </Box>
                {/* Default Notification Configurable Fields */}
                <Box>
                  {Object.entries(
                    selectedNotifications[0].selected_data[defaultNotification]
                      .Fields,
                  )
                    .filter(([, fieldData]) => fieldData.isRequired) // Required fields
                    .map(([fieldName, fieldData]) => (
                      <Box key={fieldName}>
                        <Box
                          className="field_name_container"
                          style={{
                            border: `1px solid ${generateColor(defaultNotification).titleColor}`,
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {editData &&
                          editData.notificationType === defaultNotification &&
                          editData.fieldName === fieldName
                            ? renderIcon(
                                editData.fieldData.icon,
                                defaultNotification,
                              )
                            : renderIcon(fieldData.icon, defaultNotification)}

                          <Typography
                            className="field_title"
                            style={{
                              color: `${generateColor(defaultNotification).fieldFontTextColor}`,
                            }}
                          >
                            {fieldName}:
                          </Typography>
                          <Typography
                            className="field_name"
                            sx={{ marginRight: 2 }}
                          >
                            *{" "}
                          </Typography>

                          {editData &&
                          editData.notificationType === defaultNotification &&
                          editData.fieldName === fieldName
                            ? renderField(
                                editData.fieldData.fieldType,
                                defaultNotification,
                              )
                            : renderField(
                                fieldData.fieldType,
                                defaultNotification,
                              )}

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "auto",
                            }}
                          >
                            {selectedIcon &&
                              editData &&
                              editData.notificationType ===
                                defaultNotification &&
                              editData.fieldName === fieldName && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: "auto",
                                  }}
                                >
                                  <span>
                                    <IconButton
                                      className="icon_save_button"
                                      onClick={() =>
                                        updateFieldData(
                                          editData,
                                          user,
                                          apiUrl,
                                          getAccessTokenSilently,
                                          handleFieldPopoverClose,
                                          handleReloadComponents,
                                          handleClearNewFieldData,
                                          showSnackBar,
                                          setIsLoading,
                                        )
                                      }
                                    >
                                      <CheckCircleTwoToneIcon
                                        sx={{
                                          ml: 0.5,
                                          fontSize: "21px",
                                          border: "1px solid #747C86",
                                          borderRadius: "5px",
                                          color: isLoading
                                            ? "#BEBEBE"
                                            : "#48B09B",
                                        }}
                                      />
                                    </IconButton>
                                  </span>
                                  <IconButton
                                    onClick={() =>
                                      handleClearNewFieldData(
                                        defaultNotification,
                                      )
                                    }
                                    sx={{ ml: -1 }}
                                  >
                                    <HighlightOffTwoToneIcon
                                      className="field_cancel_button"
                                      sx={{
                                        fontSize: "21px",
                                        border: "1px solid #747C86",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              )}

                            {editData &&
                              editData.notificationType ===
                                defaultNotification &&
                              editData.fieldName === fieldName &&
                              changedFieldType && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: "auto",
                                  }}
                                >
                                  <span>
                                    <IconButton
                                      className="icon_save_button"
                                      onClick={() =>
                                        updateFieldData(
                                          editData,
                                          user,
                                          apiUrl,
                                          getAccessTokenSilently,
                                          handleFieldPopoverClose,
                                          handleReloadComponents,
                                          handleClearNewFieldData,
                                          showSnackBar,
                                          setIsLoading,
                                        )
                                      }
                                    >
                                      <CheckCircleTwoToneIcon
                                        sx={{
                                          ml: 0.5,
                                          fontSize: "21px",
                                          border: "1px solid #747C86",
                                          borderRadius: "5px",
                                          color: isLoading
                                            ? "#BEBEBE"
                                            : "#48B09B",
                                        }}
                                      />
                                    </IconButton>
                                  </span>
                                  <IconButton
                                    onClick={() =>
                                      handleClearNewFieldData(
                                        defaultNotification,
                                      )
                                    }
                                    sx={{ ml: -1 }}
                                  >
                                    <HighlightOffTwoToneIcon
                                      className="field_cancel_button"
                                      sx={{
                                        fontSize: "21px",
                                        border: "1px solid #747C86",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              )}

                            {subUserData && subUserData.is_admin && (
                              <IconButton
                                disabled={Boolean(isFieldButtonDisabled)}
                                onClick={(event) =>
                                  handleFieldPopoverOpen(
                                    event,
                                    fieldName,
                                    fieldData,
                                    defaultNotification,
                                    fieldData.fieldType,
                                  )
                                }
                                sx={{ mr: -1.5 }}
                              >
                                <SettingsTwoToneIcon
                                  style={{
                                    border: "1px solid #B0BEC5",
                                    width: "auto",
                                    height: "22px",
                                    color:
                                      generateColor(defaultNotification)
                                        .titleColor,
                                    backgroundColor:
                                      generateColor(defaultNotification)
                                        .cardBackgroundColor,
                                    borderRadius: "6px",
                                    padding: "1px",
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  <Box>
                    {/* Optional  default fields */}
                    {Object.entries(
                      selectedNotifications[0].selected_data[
                        defaultNotification
                      ].Fields,
                    )
                      .filter(([, fieldData]) => !fieldData.isRequired)
                      .map(([fieldName, fieldData]) => (
                        <Box key={fieldName}>
                          <Box
                            className="field_name_container"
                            style={{
                              border: `1px solid ${generateColor(defaultNotification).borderColor}`,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {editData &&
                            editData.notificationType === defaultNotification &&
                            editData.fieldName === fieldName
                              ? renderIcon(
                                  editData.fieldData.icon,
                                  defaultNotification,
                                )
                              : renderIcon(fieldData.icon, defaultNotification)}

                            <Typography
                              className="field_title"
                              style={{
                                color: `${generateColor(defaultNotification).fieldFontTextColor}`,
                                marginRight: "10px",
                              }}
                            >
                              {fieldName}:
                            </Typography>

                            {editData &&
                            editData.notificationType === defaultNotification &&
                            editData.fieldName === fieldName
                              ? renderField(
                                  editData.fieldData.fieldType,
                                  defaultNotification,
                                )
                              : renderField(
                                  fieldData.fieldType,
                                  defaultNotification,
                                )}

                            <Box sx={{ display: "flex", marginLeft: "auto" }}>
                              {selectedIcon &&
                                editData &&
                                editData.notificationType ===
                                  defaultNotification &&
                                editData.fieldName === fieldName && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <span>
                                      <IconButton
                                        className="icon_save_button"
                                        onClick={() =>
                                          updateFieldData(
                                            editData,
                                            user,
                                            apiUrl,
                                            getAccessTokenSilently,
                                            handleFieldPopoverClose,
                                            handleReloadComponents,
                                            handleClearNewFieldData,
                                            showSnackBar,
                                            setIsLoading,
                                          )
                                        }
                                      >
                                        <CheckCircleTwoToneIcon
                                          sx={{
                                            ml: 0.5,
                                            fontSize: "21px",
                                            border: "1px solid #747C86",
                                            borderRadius: "5px",
                                            color: isLoading
                                              ? "#BEBEBE"
                                              : "#48B09B",
                                          }}
                                        />
                                      </IconButton>
                                    </span>
                                    <IconButton
                                      onClick={() =>
                                        handleClearNewFieldData(
                                          defaultNotification,
                                        )
                                      }
                                      sx={{ ml: -1 }}
                                    >
                                      <HighlightOffTwoToneIcon
                                        className="field_cancel_button"
                                        sx={{
                                          fontSize: "21px",
                                          border: "1px solid #747C86",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </IconButton>
                                  </Box>
                                )}
                              {editData &&
                                editData.notificationType ===
                                  defaultNotification &&
                                editData.fieldName === fieldName &&
                                changedFieldType && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <span>
                                      <IconButton
                                        className="icon_save_button"
                                        onClick={() =>
                                          updateFieldData(
                                            editData,
                                            user,
                                            apiUrl,
                                            getAccessTokenSilently,
                                            handleFieldPopoverClose,
                                            handleReloadComponents,
                                            handleClearNewFieldData,
                                            showSnackBar,
                                            setIsLoading,
                                          )
                                        }
                                      >
                                        <CheckCircleTwoToneIcon
                                          sx={{
                                            ml: 0.5,
                                            fontSize: "21px",
                                            border: "1px solid #747C86",
                                            borderRadius: "5px",
                                            color: isLoading
                                              ? "#BEBEBE"
                                              : "#48B09B",
                                          }}
                                        />
                                      </IconButton>
                                    </span>
                                    <IconButton
                                      onClick={() =>
                                        handleClearNewFieldData(
                                          defaultNotification,
                                        )
                                      }
                                      sx={{ ml: -1 }}
                                    >
                                      <HighlightOffTwoToneIcon
                                        className="field_cancel_button"
                                        sx={{
                                          fontSize: "21px",
                                          border: "1px solid #747C86",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </IconButton>
                                  </Box>
                                )}
                              {subUserData && subUserData.is_admin && (
                                <IconButton
                                  disabled={Boolean(isFieldButtonDisabled)}
                                  onClick={(event) =>
                                    handleFieldPopoverOpen(
                                      event,
                                      fieldName,
                                      fieldData,
                                      defaultNotification,
                                      fieldData.fieldType,
                                    )
                                  }
                                  sx={{ mr: -1.5 }}
                                >
                                  <SettingsTwoToneIcon
                                    style={{
                                      border: "1px solid #B0BEC5",
                                      width: "auto",
                                      height: "22px",
                                      color:
                                        generateColor(defaultNotification)
                                          .titleColor,
                                      backgroundColor:
                                        generateColor(defaultNotification)
                                          .cardBackgroundColor,
                                      borderRadius: "6px",
                                      padding: "1px",
                                      boxShadow:
                                        "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    {/* Conditionally render when there are no optional fields */}
                    {Object.entries(
                      selectedNotifications[0].selected_data[
                        defaultNotification
                      ].Fields,
                    ).filter(([, fieldData]) => !fieldData.isRequired)
                      .length === 0 && (
                      <Box>
                        {!isAddingNewField && (
                          <Button
                            onClick={() => {
                              setIsAddingNewField(true);
                              setIsFieldButtonDisabled(true);
                              setNewFieldData({
                                notificationType: defaultNotification,
                              });
                            }}
                            disabled={Boolean(
                              isFieldButtonDisabled || !subUserData.is_admin,
                            )}
                            className="field_name_container"
                            sx={{
                              color: `${generateColor(defaultNotification).fieldFontTextColor}`,
                              cursor: "pointer",
                              border: `1.75px solid ${generateColor(defaultNotification).cardBackgroundColor}`,

                              "&:hover": {
                                backgroundColor: "#F5FAFE",
                                cursor: "pointer",
                              },
                            }}
                          >
                            <AddCircleOutlineIcon
                              className="optional_field_icon"
                              sx={{
                                color: "#7589A2",
                                fontSize: "11px",
                              }}
                            />

                            <Typography
                              className="field_title"
                              style={{
                                color: `${generateColor(defaultNotification).fieldFontTextColor}`,
                                fontSize: "14px",
                                cursor: "pointer",
                                marginRight: "auto",
                              }}
                            >
                              Add an optional field
                            </Typography>
                          </Button>
                        )}
                        {isAddingNewField && !isAddedNewField && (
                          <Box
                            className="add_optional_container"
                            style={{
                              color: `${generateColor(defaultNotification).fieldFontTextColor}`,
                              backgroundColor: "#F2F9FF",
                            }}
                          >
                            {!selectedIcon && (
                              <AddIcon
                                onClick={(event) => {
                                  handleFieldPopoverOpen(event);
                                  setIsEditingIcon(true);
                                }}
                                className="new_field_icon"
                                sx={{
                                  color: "#7589A2",
                                  fontSize: "11px",
                                  backgroundColor: "#FFFFFF",
                                  ml: 0.55,
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
                                  transition:
                                    "background-color 0.3s, color 0.3s, box-shadow 0.3s", // Add transition for smooth effect

                                  "&:hover": {
                                    backgroundColor: "#F5FAFE",
                                    cursor: "pointer",
                                    color: "#526071",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Increase shadow on hover
                                  },
                                }}
                              />
                            )}

                            {selectedIcon && (
                              <Box
                                sx={{ display: "flex", ml: 0.55 }}
                                onClick={(event) => {
                                  handleFieldPopoverOpen(
                                    event,
                                    defaultNotification,
                                  );
                                  setIsEditingIcon(true);
                                }}
                              >
                                {" "}
                                {renderIcon(selectedIcon)}{" "}
                              </Box>
                            )}
                            <ThemeProvider theme={customTheme(outerTheme)}>
                              <TextField
                                hiddenLabel
                                id="filled-hidden-label-small"
                                placeholder="New Field Name"
                                variant="filled"
                                size="small"
                                name="fieldName"
                                value={newFieldData.fieldName || ""}
                                onChange={handleNewFieldName}
                                onKeyDown={handleKeyDown}
                                sx={{
                                  alignContent: "center",
                                  ml: 1,
                                  width: 411,
                                  mr: 0.5,
                                  mt: "3px",
                                  input: {
                                    color: "#48768C",
                                    fontWeight: 600,
                                    fontSize: "13.5px",
                                    padding: 0,
                                    alignItems: "center",
                                    backgroundColor: "#F2F9FF",
                                    fontFamily: "Source Sans Pro, sans-serif",
                                  },
                                }}
                              />

                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginLeft: "auto",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <LightTooltip
                                  title="Select an icon and enter a field name to continue"
                                  disableHoverListener={!isButtonDisabled}
                                >
                                  <span>
                                    <IconButton
                                      disabled={
                                        !newFieldData.fieldName || !selectedIcon
                                      }
                                      onClick={() => setIsAddedNewField(true)}
                                      className="field_save_button"
                                    >
                                      <CheckCircleTwoToneIcon
                                        sx={{
                                          ml: 0.5,
                                          fontSize: "21px",
                                          border: "1px solid #747C86",
                                          borderRadius: "5px",
                                          color:
                                            isLoading ||
                                            !newFieldData.fieldName ||
                                            !selectedIcon
                                              ? "#BEBEBE"
                                              : "#48B09B",
                                        }}
                                      />
                                    </IconButton>
                                  </span>
                                </LightTooltip>
                                <IconButton
                                  onClick={() =>
                                    handleClearNewFieldData(defaultNotification)
                                  }
                                  sx={{ ml: -1 }}
                                >
                                  <HighlightOffTwoToneIcon
                                    className="field_cancel_button"
                                    sx={{
                                      fontSize: "21px",
                                      border: "1px solid #747C86",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </IconButton>
                              </Stack>
                            </ThemeProvider>
                          </Box>
                        )}
                        {isAddedNewField && (
                          <Box
                            className="field_name_container"
                            style={{
                              color: `${generateColor(defaultNotification).fieldFontTextColor}`,
                              backgroundColor: "#F2F9FF",
                              alignItems: "center",

                              display: "flex",
                            }}
                          >
                            {/* Render icon */}
                            {renderIcon(selectedIcon)}

                            <Typography sx={{ mr: 2 }} className="field_title">
                              {newFieldData.fieldName}:{" "}
                            </Typography>

                            {editData.fieldData &&
                              editData.fieldData.fieldType &&
                              renderNewField(editData.fieldData.fieldType)}

                            <Box
                              sx={{
                                marginLeft: "auto",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* Conditionally render "Select a field type" */}
                              {!editData.fieldData ||
                              !editData.fieldData.fieldType ? (
                                <Typography
                                  onClick={(event) =>
                                    handleNewFieldPopoverOpen(
                                      event,
                                      defaultNotification,
                                    )
                                  }
                                  sx={{
                                    border: "1.5px solid #48768C",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    fontSize: "14px",
                                    borderRadius: "8px",
                                    fontWeight: 700,
                                    flexShrink: 0,
                                    cursor: "pointer",
                                    color: "#48768C",
                                    mr: 2,
                                    transition:
                                      "box-shadow 0.3s, background-color 0.3s",
                                    "&:hover": {
                                      backgroundColor: "#E6EDF2", // Change background color on hover
                                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Add box shadow on hover
                                    },
                                  }}
                                >
                                  Select a field type
                                </Typography>
                              ) : null}
                              {/* Render save button */}
                              {editData ? (
                                editData.fieldData &&
                                editData.fieldData.fieldType ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <IconButton
                                      sx={{ ml: -0.5 }}
                                      onClick={(event) =>
                                        setNewFieldAnchorEl(event.currentTarget)
                                      }
                                    >
                                      <SettingsTwoToneIcon className="popover_edit_button" />
                                    </IconButton>

                                    <IconButton
                                      onClick={() =>
                                        addNewField(
                                          newFieldData,
                                          user,
                                          apiUrl,
                                          getAccessTokenSilently,
                                          handleFieldPopoverClose,
                                          handleReloadComponents,
                                          handleClearNewFieldData,
                                          showSnackBar,
                                          setIsLoading,
                                        )
                                      }
                                    >
                                      <CheckCircleTwoToneIcon
                                        sx={{
                                          ml: -0.5,
                                          color: isLoading
                                            ? "#BEBEBE"
                                            : "#48B09B",
                                        }}
                                        className="popover_save_button"
                                      />
                                    </IconButton>
                                  </Box>
                                ) : null
                              ) : null}
                              {/* Render cancel button */}
                              <IconButton
                                onClick={() =>
                                  handleClearNewFieldData(defaultNotification)
                                }
                                sx={{ ml: -1 }}
                              >
                                <HighlightOffTwoToneIcon
                                  className="popover_cancel_button"
                                  sx={{ marginRight: -1, ml: 0.5 }}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Paper>
            )}

            {/* All other notification types */}
            {otherNotifications.map((notificationType, index) => (
              <Paper
                key={notificationType}
                elevation={1}
                square={false}
                className={`notification_card`}
                sx={{
                  backgroundColor: "#FFFFFFF",
                }}
              >
                <Box
                  sx={{
                    mt: -1.5,
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "auto",
                    alignItems: "center",
                  }}
                >
                  {/* Render EditIcon */}
                  {subUserData && subUserData.is_admin && (
                    <IconButton
                      disabled={Boolean(isFieldButtonDisabled)}
                      onClick={(event) =>
                        handleCardPopoverOpen(
                          event,
                          notificationType,
                          index,
                          cardConfigs(notificationType),
                        )
                      }
                      sx={{
                        mr: -1.5,
                        justifyContent: "right",
                        flexDirection: "flex-end",
                      }}
                    >
                      <img
                        src={EditIcon}
                        alt="detailsIcon"
                        style={{
                          border: "1px solid #B0BEC5",
                          width: "auto",
                          height: "24px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "6px",
                          padding: "1px",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    </IconButton>
                  )}
                  {subUserData && !subUserData.is_admin && (
                    <Box
                      sx={{
                        mr: -1.5,
                        justifyContent: "right",
                        flexDirection: "flex-end",
                        width: "auto",
                        height: "38px",
                      }}
                    >
                      {" "}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "auto",
                    paddingTop: 1,
                    paddingBottom: 0.25,
                    marginTop: "-35px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      alignItems: "center",
                      display: "flex",
                      color: generateColor(notificationType).titleColor,
                      borderRadius: "8px",
                      fontSize: 15.5,
                      fontWeight: 600,
                      textAlign: "left",
                      marginLeft: "-5px",
                      letterSpacing: "-2%",
                      lineheight: "-2%",
                    }}
                  >
                    {notificationType}
                  </Typography>
                  {/* Render ForumTwoToneIcon */}
                  {Object.entries(
                    selectedNotifications[0]?.selected_data?.[notificationType]
                      ?.Fields || {},
                  )
                    .filter(
                      ([, fieldData]) =>
                        fieldData.isRequired &&
                        fieldData.twoWayEnabled === "true",
                    )
                    .map(([fieldName, fieldData]) => (
                      <Box key={fieldName}>
                        <LightTooltip
                          notificationType={notificationType}
                          title="Two Way Notifications Enabled"
                        >
                          <ForumTwoToneIcon
                            key={fieldName}
                            sx={{
                              marginLeft: "10px",
                              alignItems: "center",
                              display: "flex",
                              border: `1px solid ${generateColor(notificationType).titleColor}`,
                              width: "18.5px",
                              height: "18.5px",
                              color: generateColor(notificationType).titleColor,
                              backgroundColor:
                                generateColor(notificationType)
                                  .cardBackgroundColor,
                              borderRadius: "4px",
                              padding: "1px",
                              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                            }}
                          />
                        </LightTooltip>
                      </Box>
                    ))}
                </Box>
                {/* Configurable Fields */}
                <Box>
                  {Object.entries(
                    selectedNotifications[0].selected_data[notificationType]
                      .Fields,
                  )
                    .filter(([, fieldData]) => fieldData.isRequired) // Required other notification fields
                    .map(([fieldName, fieldData, contextMessage]) => (
                      <Box key={fieldName}>
                        <Box
                          className="field_name_container"
                          style={{
                            border: `1px solid ${generateColor(notificationType).borderColor}`,
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {editData &&
                          editData.notificationType === notificationType &&
                          editData.fieldName === fieldName
                            ? renderIcon(
                                editData.fieldData.icon,
                                notificationType,
                              )
                            : renderIcon(fieldData.icon, notificationType)}

                          <Typography
                            className="field_title"
                            style={{
                              color: `${generateColor(notificationType).fieldFontTextColor}`,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {" "}
                            {fieldName}:
                          </Typography>
                          <Typography className="field_name" sx={{ mr: 1 }}>
                            *{" "}
                          </Typography>

                          {editData &&
                          editData.notificationType === notificationType &&
                          editData.fieldName === fieldName
                            ? renderField(
                                editData.fieldData.fieldType,
                                notificationType,
                              )
                            : renderField(
                                fieldData.fieldType,
                                notificationType,
                              )}

                          <Box sx={{ display: "flex", marginLeft: "auto" }}>
                            {selectedIcon &&
                              editData &&
                              editData.notificationType === notificationType &&
                              editData.fieldName === fieldName && (
                                <Stack
                                  direction="row"
                                  sx={{ marginLeft: "auto" }}
                                >
                                  <span>
                                    <IconButton
                                      className="icon_save_button"
                                      onClick={() =>
                                        updateFieldData(
                                          editData,
                                          user,
                                          apiUrl,
                                          getAccessTokenSilently,
                                          handleFieldPopoverClose,
                                          handleReloadComponents,
                                          handleClearNewFieldData,
                                          showSnackBar,
                                          setIsLoading,
                                        )
                                      }
                                    >
                                      <CheckCircleTwoToneIcon
                                        sx={{
                                          fontSize: "21px",
                                          border: "1px solid #747C86",
                                          borderRadius: "5px",
                                          color: isLoading
                                            ? "#BEBEBE"
                                            : "#48B09B",
                                        }}
                                      />
                                    </IconButton>
                                  </span>
                                  <IconButton
                                    onClick={() =>
                                      handleClearNewFieldData(notificationType)
                                    }
                                    sx={{ ml: -1 }}
                                  >
                                    <HighlightOffTwoToneIcon
                                      className="field_cancel_button"
                                      sx={{
                                        fontSize: "21px",
                                        border: "1px solid #747C86",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </IconButton>
                                </Stack>
                              )}
                            {editData &&
                              editData.notificationType === notificationType &&
                              editData.fieldName === fieldName &&
                              changedFieldType && (
                                <Stack
                                  direction="row"
                                  sx={{ marginLeft: "auto" }}
                                >
                                  <LightTooltip title="Save and update field type">
                                    <span>
                                      <IconButton
                                        className="icon_save_button"
                                        onClick={() =>
                                          updateFieldData(
                                            editData,
                                            user,
                                            apiUrl,
                                            getAccessTokenSilently,
                                            handleFieldPopoverClose,
                                            handleReloadComponents,
                                            handleClearNewFieldData,
                                            showSnackBar,
                                            setIsLoading,
                                          )
                                        }
                                      >
                                        <CheckCircleTwoToneIcon
                                          sx={{
                                            fontSize: "21px",
                                            border: "1px solid #747C86",
                                            borderRadius: "5px",
                                            color: isLoading
                                              ? "#BEBEBE"
                                              : "#48B09B",
                                          }}
                                        />
                                      </IconButton>
                                    </span>
                                  </LightTooltip>
                                  <IconButton
                                    onClick={() =>
                                      handleClearNewFieldData(notificationType)
                                    }
                                    sx={{ ml: -1 }}
                                  >
                                    <HighlightOffTwoToneIcon
                                      className="field_cancel_button"
                                      sx={{
                                        fontSize: "21px",
                                        border: "1px solid #747C86",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </IconButton>
                                </Stack>
                              )}
                            {subUserData && subUserData.is_admin && (
                              <IconButton
                                disabled={Boolean(isFieldButtonDisabled)}
                                onClick={(event) =>
                                  handleFieldPopoverOpen(
                                    event,
                                    fieldName,
                                    fieldData,
                                    notificationType,
                                  )
                                }
                                sx={{ mr: -1.5 }}
                              >
                                <SettingsTwoToneIcon
                                  style={{
                                    border: "1px solid #B0BEC5",
                                    width: "auto",
                                    height: "22px",
                                    color:
                                      generateColor(notificationType)
                                        .titleColor,
                                    backgroundColor:
                                      generateColor(notificationType)
                                        .cardBackgroundColor,
                                    borderRadius: "6px",
                                    padding: "1px",
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  {Object.entries(
                    selectedNotifications[0].selected_data[notificationType]
                      .Fields,
                  )
                    .filter(([, fieldData]) => !fieldData.isRequired) // Optional fields
                    .map(([fieldName, fieldData]) => (
                      <Box key={fieldName}>
                        <Box
                          className="field_name_container"
                          style={{
                            backgroundColor: `${generateColor(notificationType).containerBackgroundColor}`,
                            display: "flex",
                            alignItems: "center",
                            border: `1px solid ${generateColor(notificationType).borderColor}`,
                          }}
                        >
                          {editData &&
                          editData.notificationType === notificationType &&
                          editData.fieldName === fieldName
                            ? renderIcon(
                                editData.fieldData.icon,
                                notificationType,
                              )
                            : renderIcon(fieldData.icon, notificationType)}

                          <Typography
                            className="field_title"
                            style={{
                              color: `${generateColor(notificationType).fieldFontTextColor}`,
                              marginRight: "10px",
                            }}
                          >
                            {" "}
                            {fieldName} :
                          </Typography>
                          {editData &&
                          editData.notificationType === notificationType &&
                          editData.fieldName === fieldName
                            ? renderField(
                                editData.fieldData.fieldType,
                                notificationType,
                              )
                            : renderField(
                                fieldData.fieldType,
                                notificationType,
                              )}

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "auto",
                            }}
                          >
                            {selectedIcon &&
                              editData &&
                              editData.notificationType === notificationType &&
                              editData.fieldName === fieldName && (
                                <Stack
                                  direction="row"
                                  sx={{ marginLeft: "auto" }}
                                >
                                  <LightTooltip title="Save and update field type">
                                    <span>
                                      <IconButton
                                        className="icon_save_button"
                                        onClick={() =>
                                          updateFieldData(
                                            editData,
                                            user,
                                            apiUrl,
                                            getAccessTokenSilently,
                                            handleFieldPopoverClose,
                                            handleReloadComponents,
                                            handleClearNewFieldData,
                                            showSnackBar,
                                            setIsLoading,
                                          )
                                        }
                                      >
                                        <CheckCircleTwoToneIcon
                                          sx={{
                                            fontSize: "21px",
                                            border: "1px solid #747C86",
                                            borderRadius: "5px",
                                            color: isLoading
                                              ? "#BEBEBE"
                                              : "#48B09B",
                                          }}
                                        />
                                      </IconButton>
                                    </span>
                                  </LightTooltip>
                                  <IconButton
                                    onClick={() =>
                                      handleClearNewFieldData(notificationType)
                                    }
                                    sx={{ ml: -1 }}
                                  >
                                    <HighlightOffTwoToneIcon
                                      className="field_cancel_button"
                                      sx={{
                                        fontSize: "21px",
                                        border: "1px solid #747C86",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </IconButton>
                                </Stack>
                              )}

                            {editData &&
                              editData.notificationType === notificationType &&
                              editData.fieldName === fieldName &&
                              changedFieldType && (
                                <Stack
                                  direction="row"
                                  sx={{ marginLeft: "auto" }}
                                >
                                  <LightTooltip title="Save and update field type">
                                    <span>
                                      <IconButton
                                        className="icon_save_button"
                                        onClick={() =>
                                          updateFieldData(
                                            editData,
                                            user,
                                            apiUrl,
                                            getAccessTokenSilently,
                                            handleFieldPopoverClose,
                                            handleReloadComponents,
                                            handleClearNewFieldData,
                                            showSnackBar,
                                            setIsLoading,
                                          )
                                        }
                                      >
                                        <CheckCircleTwoToneIcon
                                          sx={{
                                            fontSize: "21px",
                                            border: "1px solid #747C86",
                                            borderRadius: "5px",
                                            color: isLoading
                                              ? "#BEBEBE"
                                              : "#48B09B",
                                          }}
                                        />
                                      </IconButton>
                                    </span>
                                  </LightTooltip>
                                  <IconButton
                                    onClick={() =>
                                      handleClearNewFieldData(notificationType)
                                    }
                                    sx={{ ml: -1 }}
                                  >
                                    <HighlightOffTwoToneIcon
                                      className="field_cancel_button"
                                      sx={{
                                        fontSize: "21px",
                                        border: "1px solid #747C86",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </IconButton>
                                </Stack>
                              )}

                            {subUserData && subUserData.is_admin && (
                              <IconButton
                                disabled={Boolean(isFieldButtonDisabled)}
                                onClick={(event) =>
                                  handleFieldPopoverOpen(
                                    event,
                                    fieldName,
                                    fieldData,
                                    notificationType,
                                  )
                                }
                                sx={{ mr: -1.5 }}
                              >
                                <SettingsTwoToneIcon
                                  style={{
                                    border: "1px solid #B0BEC5",
                                    width: "auto",
                                    height: "22px",
                                    color:
                                      generateColor(notificationType)
                                        .titleColor,
                                    backgroundColor:
                                      generateColor(notificationType)
                                        .cardBackgroundColor,
                                    borderRadius: "6px",
                                    padding: "1px",
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  {/* Conditionally render when there are no optional fields */}
                  {Object.entries(
                    selectedNotifications[0].selected_data[notificationType]
                      .Fields,
                  ).filter(([, fieldData]) => !fieldData.isRequired).length ===
                    0 && (
                    <Box>
                      {Object.entries(
                        selectedNotifications[0].selected_data[notificationType]
                          .Fields,
                      ).filter(([, fieldData]) => !fieldData.isRequired)
                        .length === 0 && (
                        <Box>
                          {!notificationStates[notificationType] && (
                            <Button
                              onClick={() => {
                                toggleAddingNewField(notificationType, true);
                                setIsFieldButtonDisabled(true);
                                setNewFieldData({
                                  notificationType: notificationType,
                                });
                              }}
                              disabled={Boolean(
                                isFieldButtonDisabled || !subUserData.is_admin,
                              )}
                              className="field_name_container"
                              sx={{
                                color: `${generateColor(defaultNotification).fieldFontTextColor}`,
                                cursor: "pointer",
                                border: `1.75px solid ${generateColor(notificationType).cardBackgroundColor}`,

                                "&:hover": {
                                  backgroundColor: "#F5FAFE",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              <AddCircleOutlineIcon
                                className="optional_field_icon"
                                sx={{
                                  color: "#7589A2",
                                  fontSize: "11px",
                                  backgroundColor: "#FFFFFF",
                                }}
                              />
                              <Typography
                                className="field_title"
                                style={{
                                  color: `${generateColor(defaultNotification).fieldFontTextColor}`,
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  marginRight: "auto",
                                }}
                              >
                                Add an optional field
                              </Typography>
                            </Button>
                          )}
                          {notificationStates[notificationType] && (
                            <>
                              {notificationStates[notificationType]
                                .isAddingNewField &&
                                !notificationStates[notificationType]
                                  .isAddedNewField && (
                                  <Box
                                    className="add_optional_container"
                                    style={{
                                      color: `${generateColor(defaultNotification).fieldFontTextColor}`,
                                      height: "40px",
                                      backgroundColor: "#F2F9FF",
                                    }}
                                  >
                                    {!selectedIcon && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <AddIcon
                                          onClick={(event) => {
                                            handleFieldPopoverOpen(event);
                                            setIsEditingIcon(true);
                                          }}
                                          className="new_field_icon"
                                          sx={{
                                            ml: 0.55,

                                            color: "#7589A2",
                                            fontSize: "11px",
                                            backgroundColor: "#FFFFFF",
                                            boxShadow:
                                              "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
                                            transition:
                                              "background-color 0.3s, color 0.3s, box-shadow 0.3s", // Add transition for smooth effect

                                            "&:hover": {
                                              backgroundColor: "#F5FAFE",
                                              cursor: "pointer",
                                              color: "#526071",
                                              boxShadow:
                                                "0px 4px 8px rgba(0, 0, 0, 0.2)", // Increase shadow on hover
                                            },
                                          }}
                                        />
                                      </Box>
                                    )}
                                    {selectedIcon && (
                                      <Box
                                        sx={{
                                          color: "#7589A2",
                                          fontSize: "11px",
                                          backgroundColor: "#FFFFFF",
                                          ml: 0.55,
                                        }}
                                        onClick={(event) => {
                                          handleFieldPopoverOpen(
                                            event,
                                            notificationType,
                                          );
                                          setIsEditingIcon(true);
                                        }}
                                      >
                                        {renderIcon(selectedIcon)}
                                      </Box>
                                    )}

                                    <ThemeProvider
                                      theme={customTheme(outerTheme)}
                                    >
                                      <TextField
                                        hiddenLabel
                                        id="filled-hidden-label-small"
                                        placeholder="New Field Name"
                                        variant="filled"
                                        size="small"
                                        name="fieldName"
                                        value={newFieldData.fieldName || ""}
                                        onChange={handleNewFieldName}
                                        sx={{
                                          alignContent: "center",
                                          ml: 1,
                                          width: 411,
                                          mr: 0.5,
                                          mt: "3px",
                                          input: {
                                            color: "#48768C",
                                            fontWeight: 600,
                                            fontSize: "13.5px",
                                            padding: 0,
                                            alignItems: "center",
                                            backgroundColor: "#F2F9FF",
                                            fontFamily:
                                              "Source Sans Pro, sans-serif",
                                          },
                                        }}
                                      />
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-end",
                                          marginLeft: "auto",
                                        }}
                                      >
                                        <LightTooltip
                                          title="Select an icon and enter a field name to continue"
                                          disableHoverListener={
                                            !isButtonDisabled
                                          }
                                        >
                                          <span>
                                            <IconButton
                                              disabled={
                                                !newFieldData.fieldName ||
                                                !selectedIcon
                                              }
                                              onClick={() => {
                                                toggleIsAddedNewField(
                                                  notificationType,
                                                  true,
                                                );
                                              }}
                                              className="field_save_button"
                                            >
                                              <CheckCircleTwoToneIcon
                                                sx={{
                                                  ml: 0.5,
                                                  fontSize: "21px",
                                                  border: "1px solid #747C86",
                                                  borderRadius: "5px",
                                                  color:
                                                    isLoading ||
                                                    !newFieldData.fieldName ||
                                                    !selectedIcon
                                                      ? "#BEBEBE"
                                                      : "#48B09B",
                                                }}
                                              />
                                            </IconButton>
                                          </span>
                                        </LightTooltip>
                                        <IconButton
                                          onClick={() =>
                                            handleClearNewFieldData(
                                              notificationType,
                                            )
                                          }
                                          sx={{ ml: -1 }}
                                        >
                                          <HighlightOffTwoToneIcon
                                            className="field_cancel_button"
                                            sx={{
                                              fontSize: "21px",
                                              border: "1px solid #747C86",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </IconButton>
                                      </Stack>
                                    </ThemeProvider>
                                  </Box>
                                )}
                              {notificationStates[notificationType]
                                .isAddedNewField && (
                                <Box
                                  className="field_name_container"
                                  style={{
                                    color: `${generateColor(defaultNotification).fieldFontTextColor}`,
                                    backgroundColor: "#F2F9FF",
                                    alignItems: "center",

                                    display: "flex",
                                  }}
                                >
                                  {/* Render icon */}
                                  {renderIcon(selectedIcon)}

                                  <Typography
                                    sx={{ mr: 2 }}
                                    className="field_title"
                                  >
                                    {newFieldData.fieldName}:{" "}
                                  </Typography>

                                  {editData.fieldData &&
                                    editData.fieldData.fieldType &&
                                    renderNewField(
                                      editData.fieldData.fieldType,
                                    )}

                                  <Box
                                    sx={{
                                      marginLeft: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* Conditionally render "Select a field type" */}
                                    {!editData.fieldData ||
                                    !editData.fieldData.fieldType ? (
                                      <Typography
                                        onClick={(event) =>
                                          handleNewFieldPopoverOpen(
                                            event,
                                            notificationType,
                                            newFieldData.fieldName,
                                            newFieldData.fieldData,
                                          )
                                        }
                                        sx={{
                                          border: "1.5px solid #48768C",
                                          paddingLeft: "10px",
                                          paddingRight: "10px",
                                          fontSize: "14px",
                                          borderRadius: "8px",
                                          fontWeight: 700,
                                          flexShrink: 0,
                                          cursor: "pointer",
                                          color: "#48768C",
                                          mr: 2,
                                          transition:
                                            "box-shadow 0.3s, background-color 0.3s",
                                          "&:hover": {
                                            backgroundColor: "#E6EDF2", // Change background color on hover
                                            boxShadow:
                                              "0 2px 4px rgba(0,0,0,0.1)", // Add box shadow on hover
                                          },
                                        }}
                                      >
                                        Select a field type
                                      </Typography>
                                    ) : null}
                                    {/* Render save button */}
                                    {editData ? (
                                      editData.fieldData &&
                                      editData.fieldData.fieldType ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "auto",
                                          }}
                                        >
                                          <IconButton
                                            sx={{ ml: -0.5 }}
                                            onClick={(event) =>
                                              setNewFieldAnchorEl(
                                                event.currentTarget,
                                              )
                                            }
                                          >
                                            <SettingsTwoToneIcon className="popover_edit_button" />
                                          </IconButton>

                                          <IconButton
                                            onClick={() =>
                                              addNewField(
                                                newFieldData,
                                                user,
                                                apiUrl,
                                                getAccessTokenSilently,
                                                handleFieldPopoverClose,
                                                handleReloadComponents,
                                                handleClearNewFieldData,
                                                showSnackBar,
                                                setIsLoading,
                                              )
                                            }
                                          >
                                            <CheckCircleTwoToneIcon
                                              sx={{
                                                ml: -0.5,
                                                color: isLoading
                                                  ? "#BEBEBE"
                                                  : "#48B09B",
                                              }}
                                              className="popover_save_button"
                                            />
                                          </IconButton>
                                        </Box>
                                      ) : null
                                    ) : null}
                                    {/* Render cancel button */}
                                    <IconButton
                                      onClick={() =>
                                        handleClearNewFieldData(
                                          notificationType,
                                        )
                                      }
                                      sx={{ ml: -1 }}
                                    >
                                      <HighlightOffTwoToneIcon
                                        className="popover_cancel_button"
                                        sx={{ marginRight: -1, ml: 0.5 }}
                                      />
                                    </IconButton>
                                  </Box>
                                </Box>
                              )}
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Paper>
            ))}

            {otherNotifications.length < 3 && (
              <Box sx={{ pt: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    mx: "auto",
                    border: "1px solid rgba(173, 216, 230, 0.7)",
                    borderRadius: "10px",
                    backgroundColor: "rgba(191, 218, 231, 0.1)",
                  }}
                >
                  {!createNew && (
                    <AddNewButton
                      createNew={createNew}
                      setCreateNew={setCreateNew}
                    />
                  )}

                  {createNew && (
                    <NewNotificationType
                      createNew={createNew}
                      setCreateNew={setCreateNew}
                    />
                  )}
                </Box>
              </Box>
            )}

            {/* Add New Notification End*/}
          </Box>

          <Box
            sx={{
              marginBottom: 2,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {notificationsConfirmed == false &&
              accountData &&
              accountData.onboarding_complete == false && (
                <Button
                  className="confirmNotificationButton"
                  onClick={handleConfirmNotification}
                >
                  {" "}
                  {loading ? "Saving..." : "Save And Confirm"}
                </Button>
              )}
          </Box>

          <Popover
            id="popover"
            open={cardOpen}
            anchorEl={cardAnchorEl}
            onClose={handleCardPopoverClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 0,
                mt: "auto",
                ml: "auto",
                width: "auto",
                backgroundColor: "#F2F9FF",
                border: "1px solid rgba(173, 216, 230, 0.5)",

                "& .MuiMenuItem-root": {
                  typography: "body2",
                  borderRadius: 0.75,
                  width: "auto",
                },
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
              {!isEditingColor && !isEditingContextMessage && !isLoading && (
                <Button
                  disabled={isEditingContextMessage}
                  sx={{
                    padding: 0,
                    marginRight: "auto",

                    "&:disabled": {
                      padding: 0,
                      pointerEvents: "none",
                    },
                  }}
                >
                  <Typography
                    title="Edit Notification Name"
                    onClick={() => setIsEditingTitle(true)}
                    variant="subtitle2"
                    noWrap
                    sx={{
                      color: "#48768C",
                      "&:hover": {
                        backgroundColor: "#E3F2FD",
                        cursor: "pointer",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    Edit Notification Name
                  </Typography>
                </Button>
              )}
              {isEditingTitle && (
                <Box
                  sx={{
                    maxWidth: "100%",
                    color: "#48768C",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <ThemeProvider theme={customTheme(outerTheme)}>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-small"
                      defaultValue={
                        editData.notificationType || defaultNotification
                      }
                      onChange={handleEditNotificationType}
                      onKeyDown={handleKeyDown}
                      variant="filled"
                      size="small"
                      sx={{
                        width: 225,
                        maxWidth: "100%",
                        input: {
                          color: "#48768C",
                          fontWeight: 600,
                          fontSize: "15px",
                          padding: 0,
                          fontFamily: "Source Sans Pro, sans-serif",
                          letterSpacing: "-1.5%",
                          lineHeight: "-2%",
                        },
                      }}
                    />
                  </ThemeProvider>
                  <IconButton
                    disabled={!editData.newNotificationType || isLoading}
                    onClick={() =>
                      updateConfigs(
                        editData,
                        user,
                        apiUrl,
                        getAccessTokenSilently,
                        handleCardPopoverClose,
                        handleFieldPopoverClose,
                        handleReloadComponents,
                        handleClearNewFieldData,
                        showSnackBar,
                        setIsLoading,
                      )
                    }
                  >
                    <CheckCircleTwoToneIcon
                      className="popover_save_button"
                      sx={{
                        color:
                          isLoading || !editData.newNotificationType
                            ? "#BEBEBE"
                            : "#48B09B",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => setIsEditingTitle(false)}
                    sx={{ ml: -1 }}
                  >
                    <HighlightOffTwoToneIcon className="popover_cancel_button" />
                  </IconButton>
                </Box>
              )}
              {!isEditingColor &&
                !isEditingTitle &&
                editData &&
                editData.configs &&
                editData.configs["Context Message"] &&
                !isLoading && (
                  <Button
                    disabled={isEditingTitle}
                    sx={{
                      padding: 0,
                      justifyContent: "left",
                      "&:disabled": {
                        padding: 0,
                        pointerEvents: "none",
                        justifyContent: "left",
                      },
                    }}
                  >
                    <Typography
                      onClick={() => setIsEditingContextMessage(true)}
                      variant="subtitle2"
                      noWrap
                      sx={{
                        color: "#48768C",
                        padding: 0,
                        "&:hover": {
                          backgroundColor: "#E3F2FD",
                          cursor: "pointer",
                          borderRadius: "4px",
                          padding: 0,
                        },
                      }}
                    >
                      Edit Context Message
                    </Typography>
                  </Button>
                )}
              {!isEditingColor &&
                !isEditingTitle &&
                editData &&
                editData.configs &&
                !editData.configs["Context Message"] &&
                !isLoading &&
                !confirmDelete && (
                  <Button
                    disabled={isEditingTitle}
                    sx={{
                      padding: 0,
                      justifyContent: "left",
                      "&:disabled": {
                        padding: 0,
                        pointerEvents: "none",
                        justifyContent: "left",
                      },
                    }}
                  >
                    <Typography
                      onClick={() => setIsEditingContextMessage(true)}
                      variant="subtitle2"
                      noWrap
                      sx={{
                        color: "#48768C",
                        padding: 0,
                        "&:hover": {
                          backgroundColor: "#E3F2FD",
                          cursor: "pointer",
                          borderRadius: "4px",
                          padding: 0,
                        },
                      }}
                    >
                      Add A Context Message
                    </Typography>
                  </Button>
                )}

              {isEditingContextMessage && !isLoading && !confirmDelete && (
                <Box
                  sx={{
                    color: "#48768C",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <ThemeProvider theme={customTheme(outerTheme)}>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-small"
                      defaultValue={
                        editData && editData.configs["Context Message"]
                      }
                      onChange={handleEditContextMessage}
                      onKeyDown={handleKeyDownMessage}
                      variant="filled"
                      size="small"
                      sx={{
                        width: 450,
                        maxWidth: "100%",
                        input: {
                          color: "#48768C",
                          fontWeight: 600,
                          fontSize: "15px",
                          padding: 0,
                          alignItems: "center",
                          mr: 1,
                          fontFamily: "Source Sans Pro, sans-serif",
                          letterSpacing: "-1.5%",
                          lineHeight: "-2%",
                        },
                      }}
                    />
                  </ThemeProvider>
                  <IconButton
                    className="icon_save_button"
                    onClick={() =>
                      updateConfigs(
                        editData,
                        user,
                        apiUrl,
                        getAccessTokenSilently,
                        handleCardPopoverClose,
                        handleFieldPopoverClose,
                        handleReloadComponents,
                        handleClearNewFieldData,
                        showSnackBar,
                        setIsLoading,
                      )
                    }
                  >
                    <CheckCircleTwoToneIcon
                      className="popover_save_button"
                      sx={{ color: isLoading ? "#BEBEBE" : "#48B09B" }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => setIsEditingContextMessage(false)}
                    sx={{ ml: -1 }}
                  >
                    <HighlightOffTwoToneIcon className="popover_cancel_button" />
                  </IconButton>
                </Box>
              )}

              {!isEditingTitle &&
                !isEditingContextMessage &&
                !isColorMenuHidden &&
                !isLoading && (
                  <Typography
                    title="Change Color"
                    onClick={() => setIsEditingColor(true)}
                    variant="subtitle2"
                    noWrap
                    sx={{
                      color: "#48768C",
                      "&:hover": {
                        backgroundColor: "#E3F2FD",
                        cursor: "pointer",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    Change Color
                  </Typography>
                )}
              {isEditingColor && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                    mt: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      padding: 0,
                      gap: "5px",
                      mb: "5px",
                    }}
                  >
                    <SquareIcon className="_2BBB9E" />
                    <SquareIcon className="_7589A2" />
                    <SquareIcon className="_a1848f" />
                    <SquareIcon className="_8C4350" />
                    <SquareIcon className="_b5ad8e" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      padding: 0,
                      gap: "5px",
                      mb: "5px",
                    }}
                  >
                    <SquareIcon className="_827b76" />
                    <SquareIcon className="_a3847d" />
                    <SquareIcon className="_858ba9" />
                    <SquareIcon className="_a89a8c" />
                    <SquareIcon className="_808a7c" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      padding: 0,
                      gap: "5px",
                      mb: "5px",
                    }}
                  >
                    <SquareIcon className="_9f7f75" />
                    <SquareIcon className="_827d89" />
                    <SquareIcon className="_7d8585" />
                    <SquareIcon className="_a97d75" />
                    <SquareIcon className="_828ca0" />
                  </Box>
                </Box>
              )}

              <>
                {!isEditingColor &&
                  !isEditingTitle &&
                  !isEditingContextMessage &&
                  editData &&
                  editData.fieldData &&
                  typeof editData.fieldData === "object" &&
                  !isLoading &&
                  Object.values(editData.fieldData).some(
                    (field) =>
                      field &&
                      typeof field === "object" &&
                      field.twoWayEnabled === "true",
                  ) && (
                    <Typography
                      variant="subtitle2"
                      noWrap
                      sx={{
                        color: "#48768C",
                        "&:hover": {
                          backgroundColor: "#E3F2FD",
                          cursor: "pointer",
                          borderRadius: "4px",
                        },
                      }}
                      onClick={handleDisableClick} // Call the function on click
                    >
                      Disable Two Way Notifications
                    </Typography>
                  )}

                {/* Show error message if the API request fails */}
                {apiError && (
                  <Typography variant="body2" color="error">
                    {apiError}
                  </Typography>
                )}
              </>
              {!isEditingColor &&
                !isEditingTitle &&
                !isEditingContextMessage &&
                editData &&
                editData.fieldData &&
                typeof editData.fieldData === "object" &&
                !isLoading &&
                !Object.values(editData.fieldData).some(
                  (field) =>
                    field &&
                    typeof field === "object" &&
                    field.twoWayEnabled === "true",
                ) && (
                  <Typography
                    variant="subtitle2"
                    noWrap
                    sx={{
                      color: "#48768C",
                      "&:hover": {
                        backgroundColor: "#E3F2FD",
                        cursor: "pointer",
                        borderRadius: "4px",
                      },
                    }}
                    onClick={handleEnableClick}
                  >
                    Enable Two Way Notifications
                  </Typography>
                )}

              {!isEditingColor &&
                !isEditingTitle &&
                !isEditingContextMessage &&
                editData &&
                editData.configs &&
                editData.configs.is_default !== "true" &&
                !isLoading && (
                  <Typography
                    title="Change Color"
                    onClick={handleSetAsDefault}
                    variant="subtitle2"
                    noWrap
                    sx={{
                      color: "#48768C",
                      "&:hover": {
                        backgroundColor: "#E3F2FD",
                        cursor: "pointer",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    Set As Default
                  </Typography>
                )}

              <>
                {editData.configs &&
                  editData.configs.is_default !== "true" &&
                  otherNotifications.length > 0 &&
                  !confirmDelete &&
                  !isLoading &&
                  !isEditingTitle &&
                  !isEditingContextMessage && (
                    <Button
                      onClick={() => setConfirmDelete(true)}
                      disabled={isEditingTitle}
                      sx={{
                        padding: 0,
                        justifyContent: "left",
                        "&:disabled": {
                          padding: 0,
                          pointerEvents: "none",
                          justifyContent: "left",
                        },
                      }}
                    >
                      <Typography
                        onClick={() => setIsEditingContextMessage(true)}
                        variant="subtitle2"
                        noWrap
                        sx={{
                          color: "#8C4350",
                          padding: 0,
                          "&:hover": {
                            backgroundColor: "#E3F2FD",
                            cursor: "pointer",
                            borderRadius: "4px",
                            padding: 0,
                          },
                        }}
                      >
                        Delete Notification
                      </Typography>
                    </Button>
                  )}
              </>
              <>
                {editData.configs &&
                  editData.configs.is_default !== "true" &&
                  otherNotifications.length > 0 &&
                  confirmDelete && (
                    <Stack direction="row">
                      <Typography
                        onClick={() => setIsEditingContextMessage(true)}
                        variant="subtitle2"
                        noWrap
                        sx={{
                          color: "#48768C",
                          padding: 0,
                          alignItems: "center",
                          my: "auto",
                          mr: 1,
                        }}
                      >
                        Are you sure? This action cannot be undone.
                      </Typography>
                      <IconButton
                        className="icon_save_button"
                        onClick={() => handleDelete(editData.notificationType)}
                      >
                        <CheckCircleTwoToneIcon
                          className="popover_save_button"
                          sx={{
                            color: isLoading ? "#BEBEBE" : "#48B09B",
                            mr: 0.5,
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={handleResetCardPopover}
                        sx={{ ml: -1 }}
                      >
                        <HighlightOffTwoToneIcon className="popover_cancel_button" />
                      </IconButton>
                    </Stack>
                  )}
              </>

              {!isEditingColor &&
                !isEditingTitle &&
                !isEditingContextMessage &&
                editData &&
                editData.configs &&
                editData.configs.is_default !== "true" &&
                isLoading && (
                  <Typography
                    title="Change Color"
                    onClick={handleSetAsDefault}
                    variant="subtitle2"
                    noWrap
                    sx={{
                      color: "#7698A9",
                    }}
                  >
                    Saving Changes...
                  </Typography>
                )}
            </Box>
          </Popover>
          <ConfirmChangesDialog
            open={dialogOpen}
            onClose={handleDialogClose}
            dialogMessage={dialogMessage}
            dialogSubText={dialogSubText}
            buttonAction={handleDialogConfirm}
            isLoading={isLoading}
            enableTwoWay={enableTwoWay}
          />
          <Popover
            open={fieldOpen}
            anchorEl={fieldAnchorEl}
            onClose={handleFieldPopoverClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 0,
                mt: "auto",
                ml: "auto",
                width: "auto",
                backgroundColor: "#F2F9FF",
                border: "1px solid rgba(173, 216, 230, 0.5)",

                "& .MuiMenuItem-root": {
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
              {!isEditingContextLabel &&
                !isEditingFieldType &&
                !isEditingIcon &&
                !isAddingIcon &&
                !isDeletingField && (
                  <Button
                    disabled={
                      isEditingContextLabel ||
                      isEditingIcon ||
                      isEditingFieldType ||
                      showFieldTypeSaveIcon ||
                      isEditingFieldName
                    }
                    sx={{ padding: 0, justifyContent: "left" }}
                  >
                    <Typography
                      title="Edit Name"
                      onClick={() => setIsEditingFieldName(true)}
                      variant="subtitle2"
                      noWrap
                      sx={{
                        color: "#48768C",
                        "&:hover": {
                          backgroundColor: "#E3F2FD",
                          cursor: "pointer",
                          borderRadius: "4px",
                        },
                      }}
                    >
                      Edit Field Name
                    </Typography>
                  </Button>
                )}
              {isEditingFieldName && (
                <Box>
                  <Typography
                    sx={{
                      color: "#48768C",

                      fontSize: "13px",

                      mb: 0.5, // Add margin bottom to space out the context label from the fields below
                    }}
                  >
                    Your field name is the title of the field that will be
                    displayed in your notification.
                  </Typography>

                  <Box
                    sx={{
                      maxWidth: "100%",
                      color: "#48768C",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <ThemeProvider theme={customTheme(outerTheme)}>
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        defaultValue={editData.fieldName}
                        onChange={handleFieldNameChange}
                        onKeyDown={handleKeyDown}
                        variant="filled"
                        size="small"
                        sx={{
                          width: "93%",
                          maxWidth: "93%",
                          input: {
                            color: "#48768C",
                            fontWeight: 600,
                            fontSize: "14.5px",
                            padding: 0,
                            fontFamily: "Source Sans Pro, sans-serif",
                            letterSpacing: "-1.5%",
                            lineHeight: "-2%",
                          },
                        }}
                      />
                    </ThemeProvider>
                    <IconButton
                      disabled={!editData.updatedFieldName || isLoading}
                      onClick={() =>
                        updateFieldData(
                          editData,
                          user,
                          apiUrl,
                          getAccessTokenSilently,
                          handleFieldPopoverClose,
                          handleReloadComponents,
                          handleClearNewFieldData,
                          showSnackBar,
                          setIsLoading,
                        )
                      }
                    >
                      <CheckCircleTwoToneIcon
                        className="popover_save_button"
                        sx={{
                          color:
                            !editData.updatedFieldName || isLoading
                              ? "#BEBEBE"
                              : "#48B09B",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => setIsEditingFieldName(false)}
                      sx={{ ml: -1 }}
                    >
                      <HighlightOffTwoToneIcon className="popover_cancel_button" />
                    </IconButton>
                  </Box>
                </Box>
              )}
              {!isEditingFieldType &&
                !isEditingIcon &&
                !isAddingIcon &&
                !isEditingFieldName &&
                !isDeletingField &&
                editData &&
                editData.fieldData &&
                editData.fieldData.messagePrefix && (
                  <Button
                    disabled={
                      isEditingFieldName ||
                      isEditingIcon ||
                      isEditingFieldType ||
                      showFieldTypeSaveIcon ||
                      isEditingContextLabel
                    }
                    sx={{ padding: 0, justifyContent: "left" }}
                  >
                    <Typography
                      onClick={() => setIsEditingContextLabel(true)}
                      variant="subtitle2"
                      noWrap
                      sx={{
                        color: "#48768C",
                        "&:hover": {
                          backgroundColor: "#E3F2FD",
                          cursor: "pointer",
                          borderRadius: "4px",
                        },
                      }}
                    >
                      Edit Context Label
                    </Typography>
                  </Button>
                )}

              {!isEditingFieldType &&
                !isEditingIcon &&
                !isAddingIcon &&
                !isEditingFieldName &&
                !isDeletingField &&
                editData &&
                editData.fieldData &&
                !editData.fieldData.messagePrefix && (
                  <Button
                    disabled={
                      isEditingFieldName ||
                      isEditingIcon ||
                      isEditingFieldType ||
                      showFieldTypeSaveIcon ||
                      isEditingContextLabel
                    }
                    sx={{ padding: 0, justifyContent: "left" }}
                  >
                    <Typography
                      onClick={() => setIsEditingContextLabel(true)}
                      variant="subtitle2"
                      noWrap
                      sx={{
                        color: "#48768C",
                        "&:hover": {
                          backgroundColor: "#E3F2FD",
                          cursor: "pointer",
                          borderRadius: "4px",
                        },
                      }}
                    >
                      Add Context Label
                    </Typography>
                  </Button>
                )}

              {isEditingContextLabel && (
                <Box
                  sx={{
                    color: "#48768C",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ThemeProvider theme={customTheme(outerTheme)}>
                    <Typography
                      sx={{
                        color: "#48768C",

                        fontSize: "13px",

                        mb: 0.5, // Add margin bottom to space out the context label from the fields below
                      }}
                    >
                      A context label is a short message that is automatically
                      added to the beginning of the field value to provide
                      context to the value in your notification.
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        defaultValue={editData.fieldData.messagePrefix}
                        onChange={handleEditContextLabel}
                        onKeyDown={handleKeyDownMessage}
                        variant="filled"
                        size="small"
                        sx={{
                          minWidth: "92%",
                          width: `${editData.fieldData.messagePrefix.length * 8}px`,
                          maxWidth: "100%",
                          input: {
                            color: "#48768C",
                            fontWeight: 600,
                            fontSize: "14.5px",
                            padding: 0,
                            alignItems: "center",
                            mr: 1,
                            flexGrow: 1,
                            fontFamily: "Source Sans Pro, sans-serif",
                            letterSpacing: "-1.5%",
                            lineHeight: "-2%",
                          },
                        }}
                      />
                      <IconButton
                        onClick={() =>
                          updateFieldData(
                            editData,
                            user,
                            apiUrl,
                            getAccessTokenSilently,
                            handleFieldPopoverClose,
                            handleReloadComponents,
                            handleClearNewFieldData,
                            showSnackBar,
                            setIsLoading,
                          )
                        }
                      >
                        <CheckCircleTwoToneIcon
                          className="popover_save_button"
                          sx={{ color: isLoading ? "#BEBEBE" : "#48B09B" }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => setIsEditingContextLabel(false)}
                        sx={{ ml: -1 }}
                      >
                        <HighlightOffTwoToneIcon className="popover_cancel_button" />
                      </IconButton>
                    </Box>
                  </ThemeProvider>
                </Box>
              )}
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {!isEditingFieldName &&
                  !isEditingFieldType &&
                  !isEditingIcon &&
                  !isAddingIcon &&
                  !isEditingContextLabel &&
                  editData &&
                  editData.fieldData &&
                  editData.fieldData.valueType !== "STR" &&
                  !isDeletingField && (
                    <Button
                      disabled={
                        isEditingFieldName ||
                        isEditingIcon ||
                        isEditingContextLabel
                      }
                      sx={{ padding: 0, justifyContent: "left" }}
                    >
                      <Typography
                        onClick={() => {
                          setIsEditingFieldType(true);
                          setShowFieldTypeSaveIcon(false);
                        }}
                        variant="subtitle2"
                        noWrap
                        sx={{
                          color: "#48768C",
                          "&:hover": {
                            backgroundColor: "#E3F2FD",
                            cursor: "pointer",
                            borderRadius: "4px",
                          },
                        }}
                      >
                        Change Field Type
                      </Typography>
                    </Button>
                  )}
                {showFieldTypeSaveIcon && (
                  <Box>
                    <IconButton
                      onClick={() =>
                        updateFieldData(
                          editData,
                          user,
                          apiUrl,
                          getAccessTokenSilently,
                          handleFieldPopoverClose,
                          handleReloadComponents,
                          handleClearNewFieldData,
                          showSnackBar,
                          setIsLoading,
                        )
                      }
                    >
                      <CheckCircleTwoToneIcon
                        className="popover_save_button"
                        sx={{ color: isLoading ? "#BEBEBE" : "#48B09B" }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => setShowFieldTypeSaveIcon(false)}
                      sx={{ ml: -1 }}
                    >
                      <HighlightOffTwoToneIcon className="popover_cancel_button" />
                    </IconButton>
                  </Box>
                )}
              </Box>

              {isEditingFieldType && (
                <FieldSelector
                  onSelectField={handleSelectFieldType}
                  includeCustomMessageBox={false}
                  editData={editData}
                  closePopover={handleFieldPopoverClose}
                />
              )}

              {!isEditingFieldName &&
                !isEditingContextLabel &&
                !isEditingFieldType &&
                !isAddingIcon &&
                editData.fieldData &&
                editData.fieldData.valueType !== "STR" &&
                !isEditingIcon &&
                !isDeletingField && (
                  <Typography
                    title="Change Icon"
                    onClick={() => setIsEditingIcon(true)}
                    variant="subtitle2"
                    noWrap
                    sx={{
                      color: "#48768C",
                      "&:hover": {
                        backgroundColor: "#E3F2FD",
                        cursor: "pointer",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    Change Icon
                  </Typography>
                )}
              {isEditingIcon && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                    gap: "5px",
                    mb: "5px",
                    mt: "5px",
                  }}
                >
                  <IconGrid
                    handleSetSelectedIcon={handleSelectedIcon}
                    closePopover={handleFieldPopoverClose}
                    setIsButtonDisabled={setIsButtonDisabled}
                  />
                </Box>
              )}

              {!isEditingFieldType &&
                !isAddingIcon &&
                editData.fieldData &&
                !isDeletingField &&
                !isEditingIcon &&
                editData.fieldData.isRequired !== true &&
                !isEditingContextLabel &&
                !isEditingFieldName && (
                  <Typography
                    onClick={() => setIsDeletingField(true)}
                    variant="subtitle2"
                    noWrap
                    sx={{
                      color: "#8C4350",
                      "&:hover": {
                        backgroundColor: "#E3F2FD",
                        cursor: "pointer",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    Delete Field
                  </Typography>
                )}
              {isDeletingField && (
                <>
                  <Box sx={{ mt: "5px" }}>
                    <Typography
                      variant="subtitle2"
                      noWrap
                      sx={{ color: "#8C4350" }}
                    >
                      {" "}
                      You are about to delete the {editData.fieldName} field
                      from the {editData.notificationType} notification.{" "}
                    </Typography>
                  </Box>

                  <Stack
                    direction="row"
                    sx={{ marginLeft: "auto", alignItems: "center", mt: "5px" }}
                  >
                    <Typography
                      variant="subtitle2"
                      noWrap
                      sx={{ color: "#48768C" }}
                    >
                      This cannot be undone. Are you sure?{" "}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        deleteField(
                          editData,
                          user,
                          apiUrl,
                          getAccessTokenSilently,
                          handleFieldPopoverClose,
                          handleReloadComponents,
                          handleClearNewFieldData,
                          showSnackBar,
                          setIsLoading,
                        )
                      }
                    >
                      <CheckCircleTwoToneIcon
                        className="popover_save_button"
                        sx={{ color: isLoading ? "#BEBEBE" : "#48B09B" }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => setIsDeletingField(false)}
                      sx={{ ml: -1 }}
                    >
                      <HighlightOffTwoToneIcon className="popover_cancel_button" />
                    </IconButton>
                  </Stack>
                </>
              )}
            </Box>
          </Popover>
          <Popover
            open={newFieldOpen}
            anchorEl={newFieldAnchorEl}
            onClose={handleNewFieldPopoverClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                mt: "auto",
                ml: "auto",
                width: "auto",
                backgroundColor: "#F2F9FF",
                "& .MuiMenuItem-root": {
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <FieldSelector
              onSelectField={handleSelectFieldType}
              closePopover={handleNewFieldPopoverClose}
              includeCustomMessageBox={false}
            />{" "}
            {/* Render the FieldSelector component */}
          </Popover>
        </Box>
      ) : (
        <Fade in={loadingNotifications || !selectedNotifications} timeout={400}>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "40vh",
              background: "#f4fbfd",
              borderRadius: "10px",
              border: "1px solid rgba(173, 216, 230, 0.7)",
            }}
          >
            <Typography
              align="center"
              sx={{
                width: "100%",
                color: "#447c92",
                fontSize: "1.1rem",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Center text vertically within the Typography
                letterSpacing: "-2%",
              }}
            >
              Loading Your Notifications...
            </Typography>

            <LinearProgress
              color="secondary"
              sx={{
                height: 3,
                width: "20%",
                mt: 2, // Add some space between Typography and LinearProgress
                mb: 8,
                "& .MuiLinearProgress-barColorSecondary": {
                  backgroundColor: "#2BBB9E",
                },
              }}
            />
          </Box>
        </Fade>
      )}
    </Box>
  );
}

export default UserNotifications;
