import { useState, useEffect } from "react";

import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
// utils
import { bgBlur } from "../../utils/cssStyles";
// components
import Iconify from "../../components/iconify";
//
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import { useAuth0 } from "@auth0/auth0-react";

import { useAuth } from "../../context/AuthContext"; // Import the authentication context
import { useAccountContext } from "../../context/AccountContext"; // Import AppProvider from AccountContext

import { useAppContext } from "src/context/AppContext";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 70;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 5}px)`,
  },
  backgroundColor: "#FFFFFF",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  boxShadow: "none",

  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(4, 1.5),
    margin: theme.spacing(0, 1),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState({});
  const { user, isAuthenticated } = useAuth0();
  const { username, subUserData, activeLocationName } = useAuth();
  const [location, setLocation] = useState("");
  const { accountData } = useAccountContext(); // Use the accountData from the context
  const { activeLocation } = useAppContext(); // Use the activeLocation from the context

  return (
    <StyledRoot sx={{ height: "10px" }}>
      <StyledToolbar sx={{ height: "10px" }}>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 0,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          sx={{
            pb: 2,
            ml: "auto",
            mb: -5,
            transition: "box-shadow 0.3s",
            "&:hover": {
              boxShadow: "none !important",
            },
            "&:active": {
              boxShadow: "none !important",
            },
          }}
        >
          {!accountData?.single_location && subUserData?.location_name && (
            <Typography
              sx={{
                pr: 1,
                fontSize: 17,
                fontWeight: 600,
                color: "#48768C",
              }}
            >
              {subUserData.location_name}
            </Typography>
          )}

          {accountData?.single_location && accountData?.company_name && (
            <Typography
              color="textSecondary"
              sx={{
                pr: 1,
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              {accountData.company_name}
            </Typography>
          )}

          <NotificationsPopover />
          <AccountPopover accountData={accountData} />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
