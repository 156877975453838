import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";

function DeleteCustomerConfirmation({ open, onClose, onConfirm, itemName }) {
  return (
    <Dialog
      PaperProps={{ sx: { borderRadius: "15px", backgroundColor: "#FFFFFF" } }}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          color: "#48768C",
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: "#FFFFFF",
            alignItems: "center",
            textAlign: "center",
            width: "auto",
          }}
        >
          <Typography
            sx={{
              fontSize: 16.5,
              marginTop: 1,
              mb: 0.25,
              fontWeight: 500,
              color: "#48768C",
            }}
          >
            {`Are you sure you want to delete ${itemName}?`}
          </Typography>
          <Typography
            sx={{
              fontSize: 13.75,
              marginTop: 1,
              mb: -1,
              fontWeight: 600,
              color: "#48768C",
              width: "auto",
              wordWrap: "break-word",
            }}
          >
            This will not remove any notifications you sent to this customer
            from your notification history.
          </Typography>
        </DialogContent>

        <DialogActions
          sx={{ display: "flex", justifyContent: "center", gap: 3, mb: 1 }}
        >
          <Button
            onClick={onClose}
            sx={{
              color: "#48768C",
              borderRadius: "8px",
              border: "1px solid #B0BEC5",
              fontSize: ".9rem",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            sx={{
              fontSize: ".9rem",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
              width: "15%",
              backgroundColor: "#48768C",
              color: "rgba(233, 242, 255, 0.99)",

              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: "#3B6B7D",
              },
              "&:active": {
                backgroundColor: "#2E7D32", // Adjust active state color if needed
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default DeleteCustomerConfirmation;
