import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Card,
  Typography,
  Paper,
  TableFooter,
  Button,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardMedia,
  Skeleton,
  Fade,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "../../context/AppContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { useChart } from "../../components/chart";
import LinearProgress from "@mui/material/LinearProgress";
import { useAccountContext } from "../../context/AccountContext"; // Import AppProvider from AccountContext
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import fetchGlobalNotifications from "../../utils/notificationUtils";
import { styled } from "@mui/system";
import noMessage from "../../public/assets/illustrations/historyEmpty.png";
import ApproveIcon from "../../public/assets/icons/icons/approve.png";
import DeclineIcon from "../../public/assets/icons/icons/decline.png";
import { set } from "lodash";
import PendIcon from "../../public/assets/icons/icons/pend.png";
import PendLiveIcon from "../../public/assets/icons/icons/pendLive.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MessageDetails from "../../../src/components/modal/MessageDetails.js";
import DetailsIcon from "../../public/assets/icons/icons/details.png";
import { useWidgetContext } from "src/context/WidgetContext";
import PendingApproval from "../../public/assets/illustrations/pendingApproval.png";
import StatusTag from "src/components/color-utils/statusTags";
import CreateCustomerModal from "src/components/modal/CreateCustomerModal";
import saveCustomerData from "../../components/customers/saveCustomerData";
import { useAuth } from "src/context/AuthContext";
import UpdateDialog from "src/components/modal/UpdateDialog";
import TooltipWrapper from "src/components/Tooltip/LightTooltip";
import ErrorIcon from "@mui/icons-material/Error";
import PendingRoundedIcon from "@mui/icons-material/PendingRounded";

const StatusIcon = ({ pendingApproval }) => {
  let icon;
  let color;

  // Check if pendingApproval is null or contains a phone numbe
  if (pendingApproval === "PENDING_APPROVAL") {
    // Render PendIcon when pendingApproval is null
    icon = (
      <img
        src={PendIcon}
        alt="bell"
        style={{
          display: "flex", // Add flex display
          justifyContent: "center", // Center content horizontally
          width: "auto",
          height: "32px",
          backgroundColor: "transparent",
          padding: "1px",
          marginTop: "2px",
        }}
      />
    );
    color = "#A1848F"; // Custom color for pending approval
  } else {
    // Render PendLiveIcon when pendingApproval is not null (contains a phone number)
    icon = (
      <img
        src={PendLiveIcon}
        alt="bell"
        style={{
          display: "flex", // Add flex display
          justifyContent: "center", // Center content horizontally
          width: "auto",
          height: "32px",
          backgroundColor: "transparent",
          padding: "1px",
          marginTop: "2px",
        }}
      />
    );
    color = "#A1848F"; // Custom color for pending approval
  }

  return icon ? <span style={{ color }}>{icon}</span> : null;
};

export default function CustomerFeed({
  pendingApprovalData,
  loadingPendingApproval,
  pauseAutoRefresh,
  resumeAutoRefresh,
  fetchAllWidgetTypes,
  showPendingApproval,
}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { reloadComponents, showSnackBar } = useAppContext();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { accountData } = useAccountContext(); // Use the accountData from the context

  const [loading, setLoading] = useState(false);
  const isSingleLocation = accountData?.single_location; // Check if single_user is true in accountData
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { handleReloadComponents } = useAppContext();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [headerAction, setHeaderAction] = useState("");

  const [isMessageDetailsOpen, setIsMessageDetailsOpen] = useState(false);

  const [imageLoaded, setImageLoaded] = useState(false);

  const [visibleRows, setVisibleRows] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [emptyRowCount, setEmptyRowCount] = useState(0);

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [customerFeedData, setCustomerFeedData] = useState([]);
  const { subUserData } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      const filteredRows = pendingApprovalData?.filter(
        (activity) => activity?.location_id === subUserData?.active_location,
      );

      const screenWidth = window.innerWidth;

      let visibleRowCount = 0;

      if (pendingApprovalData) {
        if (screenWidth <= 1440) {
          visibleRowCount = 9;
          setTotalRowCount(9); // Maximum of 9 rows for smaller screens
        } else if (screenWidth <= 1599) {
          visibleRowCount = 12; // Maximum of 12 rows for screens between 1400px and 1599px
          setTotalRowCount(12); // Maximum of 12 rows
        } else {
          visibleRowCount = pendingApprovalData.length; // For larger screens, show all available rows
          setTotalRowCount(12); // Update the max rows for larger screens
        }

        setVisibleRows(filteredRows.slice(0, visibleRowCount)); // Slice the data according to the row count
        setEmptyRowCount(totalRowCount - visibleRows.length);
      }
    };

    handleResize(); // Call initially to set rows on load

    window.addEventListener("resize", handleResize); // Handle resizing dynamically

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, [
    pendingApprovalData,
    totalRowCount,
    visibleRows.length,
    showPendingApproval,
    subUserData,
  ]);

  useEffect(() => {
    const img = new Image();
    img.src = PendingApproval;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);

  useEffect(() => {
    // Access userData and subUserData wherever you need in your component
  }, [accountData]);

  const buttonText = loading
    ? "Loading..."
    : headerAction === "Approve"
      ? "Approve"
      : "Decline";

  // Handle saving customer
  const handleSaveCustomer = async (firstName, lastName, location_name) => {
    const success = await saveCustomerData(
      selectedPhoneNumber,
      firstName,
      lastName,
      user.sub,
      location_name,
    );

    if (success) {
      handleCloseModal();
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenPopover = (event, rowData) => {
    setAnchorEl(event.currentTarget); // Set anchor element for the clicked row
    pauseAutoRefresh(); // Pause auto refresh when Popover is open
    setSelectedRow(rowData);
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
    setSelectedRow(null);
    resumeAutoRefresh();
  };

  const handleAction = async (action) => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently(); // Get access token
      const response = await fetch(`${apiUrl}/api/communications/${action}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include access token in headers
        },
        body: JSON.stringify({
          notificationId: selectedRow.ticket_number,
          sub: user.sub,
        }),
      });
      if (response.ok) {
        // Handle success
        showSnackBar(`Notification updated successfully`, true);
        fetchAllWidgetTypes(); // Fetch all widget types after successful update
        handleReloadComponents(); // Reload components after successful update
        setLoading(true);
      } else {
        // Handle error
        console.error(
          `Failed to ${action} notification: ${response.statusText}`,
          showSnackBar(
            `Failed to update notification. Please try again`,
            false,
          ),
        );
        setLoading(false);
      }
    } catch (error) {
      console.error(`Failed to ${action} notification:`, error);
      setLoading(false);
      showSnackBar(`Failed to update notification. Please try again`, false);
    } finally {
      setPopoverOpen(false);
      setLoading(false);
      resumeAutoRefresh();
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (timeStr) => {
    const time = new Date(timeStr);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return time.toLocaleTimeString(undefined, options);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
  };

  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: "#FFFFFF",
            height: "42.5px",
            letterSpacing: "-1%",
            lineHeight: "-1%",
            minHeight: "65px",
          },
        },
      },
    },
  });

  const StyledHeader = styled(TableCell)({
    color: "#447c92",
    borderBottom: "none",
    letterSpacing: "-2%",
    lineHeight: "-2%",
    minHeight: "65px",
  });

  const StyledRow = styled(TableCell)({
    color: "#5e9fbc",
    fontWeight: 600,
    letterSpacing: "-2%",
    lineHeight: "-2%",
  });

  const StyledHeaderText = styled(Typography)({
    fontWeight: 500,
    fontSize: 13.5,
    align: "center",
    margin: "auto",
    letterSpacing: "-2%",
    lineHeight: "-2%",
    minHeight: "20px",
  });

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    setSelectedRow(null);
  };

  const handleOpenMessageDetails = (row) => {
    setSelectedRow(row);
    setIsMessageDetailsOpen(true);
  };

  const handleOpenDialog = (row) => {
    setConfirmationOpen(true);
    setSelectedRow(row);
  };

  const handleCloseMessageDetails = () => {
    setIsMessageDetailsOpen(false);
    resumeAutoRefresh();
  };

  const HeaderTypography = styled(Typography)({
    backgroundColor: "#48768C",
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: 13.5,
  });

  const RowTypography = styled(Typography)({
    color: "#48768C",
    fontSize: 15,
    height: "32px", // Adjust the height as needed
    display: "flex",
    alignItems: "center", // Center the content vertically
    justifyContent: "center", // Center the content horizontally
  });

  const message = `Are you sure you want to manually update this notification to ${actionType === "approve" ? "approved" : "declined"}?`;

  const handleUpdate = async () => {
    handleAction(actionType);
    resumeAutoRefresh();
    setConfirmationOpen(false);
  };

  const handleCancel = () => {
    setSelectedRow(null);
    setConfirmationOpen(false);
    setPopoverOpen(false);
    resumeAutoRefresh();
  };

  return (
    <Box sx={{ background: "#f4fbfd", height: "100%" }}>
      {(loadingPendingApproval || !pendingApprovalData) && (
        <Fade in={loadingPendingApproval || !pendingApprovalData} timeout={400}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", // Full height of the viewport
              width: "100%",
            }}
          >
            <Typography
              align="center"
              sx={{
                width: "100%",
                color: "#447c92",
                fontSize: "1.1rem",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Center text vertically within the Typography
                letterSpacing: "-2%",
              }}
            >
              Loading Your Pending Approval Notifications...
            </Typography>

            <LinearProgress
              color="secondary"
              sx={{
                height: 3,
                width: "33%",
                mt: 2, // Add some space between Typography and LinearProgress
                mb: 8,
                "& .MuiLinearProgress-barColorSecondary": {
                  backgroundColor: "#2BBB9E",
                },
              }}
            />
          </Box>
        </Fade>
      )}

      {!loadingPendingApproval &&
        pendingApprovalData &&
        visibleRows.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignContent: "center",
              background: "radial-gradient(circle, #EEF6F9, #D4E9F2)",
              alignItems: "center",
              height: "100%",
              borderRadius: "10px",
              padding: "20px",
              overflow: "hidden",
              flex: 1, // Ensures it takes up all available space
              minHeight: 0, // Prevents content from expanding beyond limits
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                mt: -9,
                mb: -1,
                position: "relative",
                width: {
                  xs: "60vw",
                  sm: "40vw",
                  md: "30vw",
                  lg: "25.05vw", // 10% increase from 22.77vw
                },
                height: {
                  xs: "60vw",
                  sm: "40vw",
                  md: "30vw",
                  lg: "26.44vw", // 10% increase from 24.04vw
                },
              }}
            >
              {/* Skeleton loader */}
              {!imageLoaded && (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "75%",
                    height: {
                      xs: "70vw",
                      sm: "50vw",
                      md: "35vw",
                      lg: "41.25vw",
                    }, // 10% increase from 37.5vw
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                    objectFit: "contain",
                    transition: "opacity 0.3s ease-in-out",
                    opacity: imageLoaded ? 0 : 1,
                  }}
                />
              )}

              {/* Image with fade-in effect */}
              <CardMedia
                component="img"
                src={PendingApproval}
                sx={{
                  width: { xs: "70vw", sm: "50vw", md: "35vw", lg: "41.25vw" }, // 10% increase from 37.5vw
                  height: "auto",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                  transition: "opacity 0.3s ease-in-out",
                  opacity: imageLoaded ? 1 : 0,
                }}
                onLoad={() => setImageLoaded(true)}
              />
            </Box>
            <Typography
              align="center"
              sx={{
                mt: -9,
                fontSize: { xs: 16, sm: 18 },
                color: "#447c92",
                mb: "15px",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              No Notifications Pending Approval
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: { xs: 14, sm: 16 },
                color: "#447c92",
                mb: "15px",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Notifications that are pending approval from your customers will
              automatically be displayed here.
            </Typography>
          </Box>
        )}

      {!loadingPendingApproval &&
        pendingApprovalData &&
        visibleRows.length > 0 && (
          <Fade in={true} timeout={500}>
            <TableContainer
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflowY: "auto",
                background: "#f4fbfd",
              }}
            >
              <Table
                size="small"
                sx={{
                  tableLayout: "fixed", // Ensures columns align correctly
                  width: "100%",
                  height: "100%",
                }}
              >
                <ThemeProvider theme={theme}>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TableRow>
                      {[
                        { label: "Date Sent", width: "12%" },
                        { label: "Time Sent", width: "14%" },
                        { label: "Sent To", width: "14%" },
                        { label: "Ticket Number", width: "14%" },
                        { label: "Notification", width: "14%" },
                        ...(accountData && !accountData?.single_location
                          ? [{ label: "Location", width: "14%" }]
                          : []),
                        { label: "Status", width: "10%" },
                      ].map((header, index) => (
                        <StyledHeader
                          key={index}
                          align="center"
                          sx={{ width: header.width, textAlign: "center" }}
                        >
                          <StyledHeaderText>{header.label}</StyledHeaderText>
                        </StyledHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                </ThemeProvider>

                <TableBody
                  sx={{
                    height: "100%",
                    width: "100%",
                    pb: 1,
                  }}
                >
                  {visibleRows.map((activity, index) => {
                    const rowData = [
                      formatDate(activity.created_at),
                      formatTime(activity.created_at),
                      activity.customer_name ||
                        formatPhoneNumber(activity.phone_number),
                      activity.ticket_number,
                      <Box
                        height="32px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <StatusTag status={activity.status} />
                      </Box>,
                      accountData && !accountData?.single_location
                        ? activity.location_name
                        : null,
                      <Box
                        height="32px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <TooltipWrapper status={activity.pending_approval}>
                          <StatusIcon
                            pendingApproval={activity.pending_approval}
                          />
                        </TooltipWrapper>
                      </Box>,
                    ].filter(Boolean);

                    return (
                      <TableRow
                        key={index}
                        onClick={(event) => handleOpenPopover(event, activity)}
                        sx={{
                          height: `calc(85% / ${totalRowCount})`, // Use totalRowCount to ensure consistent height
                          borderBottom: "none",
                          boxShadow: "none",
                          cursor: "pointer",

                          "&:last-child td, &:last-child th": {
                            border: "none",
                            pb: 1.5,
                          },
                          "&:hover": {
                            backgroundColor: "rgba(173, 216, 230, 0.1)",
                            cursor: "pointer",
                          },
                        }}
                      >
                        {rowData.map((cell, idx) => (
                          <StyledRow
                            key={idx}
                            align="center"
                            sx={{
                              textAlign: "center",
                              borderBottom: "none",
                              boxShadow: "none",
                            }}
                          >
                            {cell}
                          </StyledRow>
                        ))}
                      </TableRow>
                    );
                  })}

                  {visibleRows.length < totalRowCount &&
                    Array.from({ length: emptyRowCount }).map((_, i) => (
                      <TableRow
                        key={`empty-${i}`}
                        sx={{
                          height: `calc(85% / ${totalRowCount})`, // Ensures empty rows match data rows
                          backgroundColor: "#FFFFFF",
                          borderBottom: "none",
                          boxShadow: "none",
                        }}
                      >
                        <TableCell
                          colSpan={accountData?.single_location ? 6 : 7}
                          sx={{
                            borderBottom: "none",
                          }}
                        />
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Fade>
        )}

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl} // Use the anchor element state variable
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom", // Render Popover below the anchor element
          horizontal: "center", // Center horizontally
        }}
        transformOrigin={{
          vertical: "top", // Position Popover to the top of the anchor element
          horizontal: "center", // Center horizontally
        }}
        PaperProps={{
          sx: {
            border: "1.25px solid rgba(173, 216, 230, 0.6)",

            borderRadius: "8px", // Adjust the value to control the roundness
          },
        }}
      >
        <Box sx={{ backgroundColor: "#F2F9FF" }}>
          <Paper sx={{ backgroundColor: "#F2F9FF" }}>
            <Box sx={{ backgroundColor: "#F2F9FF" }}>
              {selectedRow && selectedRow.pending_approval !== null ? (
                <div>
                  <Box
                    onClick={() => {
                      handleOpenMessageDetails(selectedRow);
                      setPopoverOpen(false);
                    }} // Open message details on row click
                    sx={{
                      pt: "5px",
                      px: 1,
                      flexDirection: "row",
                      display: "flex",
                      backgroundColor: "#F2F9FF",
                      color: "#8A5D6E",
                      "&:hover": {
                        backgroundColor: "#E6F1FF", // Adjusted hover color to a lighter shade
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#E6F1FF", // Adjusted selected color to a darker shade
                      },
                      alignItems: "center", // Center vertically
                      cursor: "pointer", // Change cursor to pointer on hover
                    }}
                  >
                    <img
                      src={DetailsIcon}
                      alt="bell"
                      style={{
                        width: "auto",
                        height: "30px",
                        borderRadius: "10px",
                        padding: "3px",
                        borderRadius: "6px",
                      }}
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        color: "#5e9fbc",
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: "center", // Fix typo here: 'textAlign' instead of 'alignText'
                      }}
                    >
                      View Notification Details
                    </Typography>
                  </Box>
                  {/* Approve button */}
                  <Box
                    onClick={(event, selectedRow) => {
                      event.stopPropagation(); // Stop event propagation
                      setPopoverOpen(false);
                      setConfirmationOpen(true);
                      setActionType("approve");
                      setHeaderAction("Approve");
                    }}
                    sx={{
                      px: 1,

                      paddingBottom: 1,
                      mb: -1,
                      flexDirection: "row",
                      display: "flex",
                      backgroundColor: "#F2F9FF",
                      color: "#8A5D6E",
                      "&:hover": {
                        backgroundColor: "#E6F1FF", // Adjusted hover color to a lighter shade
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#E6F1FF", // Adjusted selected color to a darker shade
                      },
                      alignItems: "center", // Center vertically
                      cursor: "pointer", // Change cursor to pointer on hover
                    }}
                  >
                    <img
                      src={ApproveIcon}
                      alt="bell"
                      style={{
                        width: "auto",
                        height: "30px",
                        borderRadius: "10px",
                        padding: "3px",
                        borderRadius: "6px",
                      }}
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        color: "#5e9fbc",
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: "center", // Fix typo here: 'textAlign' instead of 'alignText'
                      }}
                    >
                      Customer Approved
                    </Typography>
                  </Box>
                  {/* Deny button */}
                  <Box
                    onClick={(event) => {
                      event.stopPropagation(); // Stop event propagation
                      setPopoverOpen(false);
                      setConfirmationOpen(true);
                      setActionType("deny");
                      setHeaderAction("Decline");
                    }}
                    sx={{
                      px: 1,
                      pb: "5px",
                      flexDirection: "row",
                      display: "flex",
                      backgroundColor: "#F2F9FF",
                      color: "#8A5D6E",
                      "&:hover": {
                        backgroundColor: "#E6F1FF", // Adjusted hover color to a lighter shade
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#E6F1FF", // Adjusted selected color to a darker shade
                      },
                      alignItems: "center", // Center vertically
                      cursor: "pointer", // Change cursor to pointer on hover
                    }}
                  >
                    <img
                      src={DeclineIcon}
                      alt="bell"
                      style={{
                        width: "auto",
                        height: "30px",
                        borderRadius: "10px",
                        padding: "3px",
                        borderRadius: "6px",
                      }}
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        color: "#5e9fbc",
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: "center", // Fix typo here: 'textAlign' instead of 'alignText'
                      }}
                    >
                      Customer Declined
                    </Typography>
                  </Box>
                </div>
              ) : (
                <div>
                  <Box
                    onClick={() => {
                      handleOpenMessageDetails(selectedRow);
                      setPopoverOpen(false);
                    }} // Open message details on row click
                    sx={{
                      pt: "5px",
                      px: 1,
                      flexDirection: "row",
                      display: "flex",
                      backgroundColor: "#F2F9FF",
                      color: "#8A5D6E",
                      "&:hover": {
                        backgroundColor: "#E6F1FF", // Adjusted hover color to a lighter shade
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#E6F1FF", // Adjusted selected color to a darker shade
                      },
                      alignItems: "center", // Center vertically
                      cursor: "pointer", // Change cursor to pointer on hover
                    }}
                  >
                    <img
                      src={DetailsIcon}
                      alt="bell"
                      style={{
                        width: "auto",
                        height: "30px",
                        borderRadius: "10px",
                        padding: "3px",
                        borderRadius: "6px",
                      }}
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        color: "#5e9fbc",
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center", // Fix typo here: 'textAlign' instead of 'alignText'
                      }}
                    >
                      View Notification Details
                    </Typography>
                  </Box>
                  {/* Approve button */}
                  <Box
                    onClick={(event) => {
                      event.stopPropagation(); // Stop event propagation
                      setActionType("approve");
                      handleAction("approve");
                    }}
                    sx={{
                      px: 1,

                      paddingBottom: 1,
                      mb: -1,
                      flexDirection: "row",
                      display: "flex",
                      backgroundColor: "#F2F9FF",
                      color: "#8A5D6E",
                      "&:hover": {
                        backgroundColor: "#E6F1FF", // Adjusted hover color to a lighter shade
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#E6F1FF", // Adjusted selected color to a darker shade
                      },
                      alignItems: "center", // Center vertically
                      cursor: "pointer", // Change cursor to pointer on hover
                    }}
                  >
                    <img
                      src={ApproveIcon}
                      alt="bell"
                      style={{
                        width: "auto",
                        height: "30px",
                        borderRadius: "10px",
                        padding: "3px",
                        borderRadius: "6px",
                      }}
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        color: "#5e9fbc",
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: "center", // Fix typo here: 'textAlign' instead of 'alignText'
                      }}
                    >
                      Customer Approved
                    </Typography>
                  </Box>
                  {/* Deny button */}
                  <Box
                    onClick={(event) => {
                      event.stopPropagation(); // Stop event propagation
                      setActionType("deny");
                      handleAction("deny");
                    }}
                    sx={{
                      px: 1,
                      pb: "5px",
                      flexDirection: "row",
                      display: "flex",
                      backgroundColor: "#F2F9FF",
                      color: "#8A5D6E",
                      "&:hover": {
                        backgroundColor: "#E6F1FF", // Adjusted hover color to a lighter shade
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#E6F1FF", // Adjusted selected color to a darker shade
                      },
                      alignItems: "center", // Center vertically
                      cursor: "pointer", // Change cursor to pointer on hover
                    }}
                  >
                    <img
                      src={DeclineIcon}
                      alt="bell"
                      style={{
                        width: "auto",
                        height: "30px",
                        borderRadius: "10px",
                        padding: "3px",
                        borderRadius: "6px",
                      }}
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        color: "#5e9fbc",
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: "center", // Fix typo here: 'textAlign' instead of 'alignText'
                      }}
                    >
                      Customer Declined
                    </Typography>
                  </Box>
                </div>
              )}
            </Box>
          </Paper>
        </Box>
      </Popover>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Notification updated successfully!
        </MuiAlert>
      </Snackbar>
      <UpdateDialog
        open={
          confirmationOpen &&
          selectedRow &&
          selectedRow.pending_approval !== null
        }
        onClose={() => {
          setConfirmationOpen(false);
          setSelectedRow(null);
        }}
        header={`Manually ${headerAction} Notification`}
        message={message}
        subText="Your customer will no longer be able to update this notification by responding to it."
        headerAction={headerAction}
        buttonText={buttonText}
        isLoading={loading}
        buttonLoadingText="Updating..."
        onConfirm={handleUpdate}
        onCancel={handleCancel}
      />

      <MessageDetails
        open={isMessageDetailsOpen}
        selectedRow={selectedRow}
        onClose={handleCloseMessageDetails}
        handleOpenModal={handleOpenModal}
        setSelectedPhoneNumber={setSelectedPhoneNumber}
      />

      <CreateCustomerModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSaveCustomer={handleSaveCustomer}
        selectedPhoneNumber={selectedPhoneNumber}
        location_name={subUserData?.location_name || ""} // Check if subUserData exists before accessing location_name
      />
    </Box>
  );
}
