import React from "react";
import { Button, Box, Paper } from "@mui/material";
import addNewNotificationIcon from "src/public/assets/icons/icons/addNewNotification.png";

const AddNewButton = ({ createNew, setCreateNew }) => {
  const handleAddNewButtonClick = () => {
    setCreateNew(true);
  };

  return (
    <Paper
      elevation={4}
      sx={{ backgroundColor: "rgba(191, 218, 231, 0.075)", my: 4 }}
    >
      <Button
        onClick={handleAddNewButtonClick}
        variant="contained"
        sx={{
          padding: 1,
          backgroundColor: "white",
          border: "1.55px solid #b9c8d5",
          boxShadow: "none",
          color: "#48768C",
          fontSize: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          letterSpacing: 0.05,
          lineHeight: 2,
          "&:hover": {
            backgroundColor: "rgba(173, 216, 230, 0.1)", // Light cyan on hover
            border: "1px solid #b9c8d5", // Darker border on hover
            color: "#7589A2", // White text on hover
            boxShadow: "0px 4px 12px rgba(173, 216, 230, 0.4)", // Subtle shadow on hover
          },
        }}
      >
        <img
          src={addNewNotificationIcon}
          alt="Add New Notification Icon"
          style={{ width: "31px", height: "31px", marginRight: "12px" }} // Adjust size and spacing
        />
        New Notification Type
      </Button>
    </Paper>
  );
};

export default AddNewButton;
