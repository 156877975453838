import React, { createContext, useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useWidgetContext } from "src/context/WidgetContext";
import { useAuth } from "./AuthContext";

// Create a context for global notifications
export const GlobalNotificationsContext = createContext();

export const GlobalNotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const { isLoggedIn } = useAuth();

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchGlobalNotifications = async () => {
    try {
      // Null check for user and isAuthenticated
      if (isAuthenticated && user && user.sub) {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(
          `${apiUrl}/api/global-notifications?userSub=${user.sub}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (response.ok) {
          const data = await response.json();
          setNotifications(data.notifications);
        } else {
          throw new Error("Failed to fetch global notifications");
        }
      }
    } catch (error) {
      console.error("Error fetching global notifications:", error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchGlobalNotifications();
  }, [apiUrl, isAuthenticated, user, isLoggedIn]);

  return (
    <GlobalNotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
        fetchGlobalNotifications,
        error,
      }}
    >
      {children}
    </GlobalNotificationsContext.Provider>
  );
};

// Custom hook to use global notifications context
export const useGlobalNotifications = () =>
  useContext(GlobalNotificationsContext);
