
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Modal,
  Grid,
  Typography,
  Fade,
  Stack,
  Skeleton,
  CardMedia,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import moment from "moment-timezone"; // Import moment-timezone
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import companyImage from "src/public/assets/companyImage.png";
import RestartSetupDialog from "src/components/modal/NewUserSignUp/Refresh/Components/RestartSetupDialog";


const theme = createTheme({
  palette: {
    primary: {
      main: "#48768C", // Set the main color to white
    },
  },
});



const CreateUserStep = ({newFirstName, setNewFirstName, newLastName, setNewLastName, newUsername, setNewUsername, loading, newPin, setNewPin, confirmNewPin, setConfirmNewPin, pinError, isFormValid, isSingleUser, setIsSingleUser, showCreateUser, isSingleLocation  }) => {


  const handleUserSelectChange = (event) => {
    setIsSingleUser(event.target.value === "single_user");
  };




  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = null;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);


return (
  !showCreateUser && isSingleLocation ? (
    <Fade in={true} timeout={400}>
    <Box
      sx={{
        height:'auto',
        width: "auto",
        justifyContent: "center",
        borderRadius: "40px",
        fontSize: 17,
        fontWeight: 600,
        backgroundColor: "#FFFFFF",
        color: "#48768C",
        padding:1,
        alignItems: "center",
        mt:3
      }}
    >
    
    <Typography
        sx={{
          fontSize: '1.28rem',
          textAlign: "left",
          fontWeight: 600,
          px:.25,
          letterSpacing:'-0.02em',
          lineHeight: '-0.02em',
          alignSelf: 'center',
          justifyContent: 'center'
    
        }}
      >
        Lets get some information about your team
        </Typography>
    
      <Typography
        sx={{
          mt: 1.75,
          fontSize: '1.1rem',
          textAlign: "left",
          fontWeight: 500,
          px:.25,
                letterSpacing:'-0.01em',
          lineHeight: '-0.04em',
          color:'#64697D'
        }}
      >
        Does your location have multiple employees?
    
        </Typography>
    
    
    <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
    <FormControl component="fieldset">
      <RadioGroup name="userType" onChange={handleUserSelectChange}>
        <FormControlLabel
          value="single_user"
          control={<Radio sx={{ color: "#48768c", '&.Mui-checked': { color: "#6595ab" } }} />}
          label="Just a single employee"
          sx={{
            "& .MuiTypography-root": { fontSize: '1.07rem', letterSpacing: '-0.01em', fontWeight: 500, color: '#64697D' }
          }}
        />
        <FormControlLabel
          value="multiple_users"
          control={<Radio sx={{ color: "#48768c", '&.Mui-checked': { color: "#6595ab" } }} />}
          label="We have multiple employees"
          sx={{
            "& .MuiTypography-root": { fontSize: '1.07rem', letterSpacing: '-0.01em', fontWeight: 500, color: '#64697D' }
          }}
        />
      </RadioGroup>
    </FormControl>
    </Box>
    
    </Box>
    </Fade>
  ) : (

<Fade in={true} timeout={400}>
<Box
  sx={{
    outline: "none", // Remove the default focus outline
    alignContent: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "28%",
    padding: 3,

    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Added box shadow
    borderRadius: "60px",
    fontSize: 17,
    fontWeight: 600,
    backgroundColor: "#FFFFFF",
    color: "#48768C",
  }}
>
  <Typography
    sx={{
      mt: 1,
      fontWeight: 600,
      fontSize: 21,
      textAlign: "center",
      mb: 1,
    }}
  >
    Create Your Account Administrator
  </Typography>

  {!imageLoaded && (
    <Skeleton
      variant="rectangular"
      sx={{
        height: "auto",
        maxWidth: "55%",
        maxHeight: "55%",
        objectFit: "contain",
        borderRadius: "10px",
        backgroundColor: "#EEF6F9", // Adjust as needed to match background
        transition: "opacity 0.3s ease-in-out",
        opacity: imageLoaded ? 0 : 1,
      }}
    />
  )}
  <CardMedia
    component="img"
    image={null}
    sx={{
      display: "block",
      margin: "auto",
      marginBottom: "20px",
      maxWidth: "55%",
      maxHeight: "55%",
      objectFit: "contain",
      transition: "opacity 0.3s ease-in-out",
      opacity: imageLoaded ? 1 : 0,
    }}
    onLoad={() => setImageLoaded(true)}
  />

  <Typography
    sx={{
      fontSize: 14,
      textAlign: "center",
      mb: 4,
      fontWeight: 600,
    }}
  >
    An account administrator can make important changes to your account,
    such as adding or removing users, and managing locations
  </Typography>

  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      mt: 1,
    }}
  >
    <ThemeProvider theme={theme}>
      <TextField
        label="First Name"
        variant="outlined"
        size="small"
        fullWidth
        value={newFirstName}
        onChange={(e) => setNewFirstName(e.target.value)}
        InputProps={{
          style: { color: "#48768C", borderRadius: "8px" },
        }}
        InputLabelProps={{ style: { color: "#48768C", fontSize: 14 } }}
        sx={{
          mb: 2,
          mr: 2.5,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#48768C",
          },
          color: "#48768C",
        }}
      />
    </ThemeProvider>
    <ThemeProvider theme={theme}>
      <TextField
        label="Last Name"
        variant="outlined"
        size="small"
        fullWidth
        value={newLastName}
        onChange={(e) => setNewLastName(e.target.value)}
        InputProps={{
          style: { color: "#48768C", borderRadius: "8px" },
        }}
        InputLabelProps={{
          style: {
            color: "#48768C",
            borderRadius: "8px",
            fontSize: 14,
          },
        }}
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#48768C",
          },
          color: "#48768C",
        }}
      />
    </ThemeProvider>
  </Box>

  <ThemeProvider theme={theme}>
    <TextField
      label="Username"
      variant="outlined"
      size="small"
      fullWidth
      value={newUsername}
      onChange={(e) => setNewUsername(e.target.value)}
      InputProps={{
        style: { color: "#48768C", borderRadius: "8px", fontSize: 14 },
      }}
      InputLabelProps={{ style: { color: "#48768C", fontSize: 14 } }}
      sx={{
        mb: 2,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#48768C",
        },
        color: "#48768C",
      }}
    />{" "}
  </ThemeProvider>

  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
    }}
  >
    <ThemeProvider theme={theme}>
      <TextField
        label="New PIN"
        size="small"
        variant="outlined"
        type="password"
        fullWidth
        value={newPin}
        onChange={(e) =>
          setNewPin(e.target.value.replace(/\D/g, "").slice(0, 6))
        }
        InputProps={{
          style: { color: "#48768C", borderRadius: "8px" },
        }}
        InputLabelProps={{ style: { color: "#48768C", fontSize: 14 } }}
        sx={{
          mb: 2,
          mr: 2.5,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#48768C",
          },
          color: "#48768C",
        }}
        required
      />{" "}
    </ThemeProvider>

    <ThemeProvider theme={theme}>
      <TextField
        label="Confirm New PIN"
        size="small"
        variant="outlined"
        type="password"
        fullWidth
        value={confirmNewPin}
        onChange={(e) =>
          setConfirmNewPin(
            e.target.value.replace(/\D/g, "").slice(0, 6),
          )
        }
        InputProps={{
          style: { color: "#48768C", borderRadius: "8px" },
        }}
        InputLabelProps={{ style: { color: "#48768C", fontSize: 14 } }}
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#48768C",
          },
          color: "#48768C",
        }}
        required
        error={!!pinError}
        helperText={pinError}
      />{" "}
    </ThemeProvider>
  </Box>
  <Box
    sx={{
      marginTop: 1.5,
      display: "flex",
      width: "100%",
      justifyContent: "center",
      mb: 2,
    }}
  >
    <Button
      disabled={!isFormValid || loading}
      variant="contained"
      sx={{
        justifyContent: "center",
        backgroundColor: "rgba(72, 118, 140, 0.8)", // Adjusted color with transparency
        padding: 1,
        borderRadius: "15px",
        mb: 1,
        width: "400px",
        fontSize: 17,
        fontWeight: 600,
        "&:hover": {
          backgroundColor: "rgba(72, 118, 140, 0.7)", // Adjusted hover color with transparency
        },
        "&:active": {
          backgroundColor: "rgba(72, 118, 140, 0.5)", // Adjusted active color with transparency
        },
      }}
    >
      {loading ? "Saving...." : "Continue"}
    </Button>
  </Box>

</Box>
</Fade>
  )
);
}

export default CreateUserStep;