import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Grid,
  Typography,
  Stack,
  Divider,
  IconButton,
  Radio,
  Paper,
  Avatar,
} from "@mui/material";
import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";
import AddUserIcon from "src/public/assets/icons/icons/addUser.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function TransferList({
  users,
  subUserData,
  name,
  handleCheckedLeft,
  handleToggle,
  checked,
  left,
  right,
  handleCheckedRight,
  setLeft,
  handleCreateNewEmployeeClick,
  accountData,
  isNewUserSaved,
  isAssignEmployeeVisible,
  enableEditButtons,
  hideCreateUserButton,
}) {
  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0) : "";
    const lastInitial = lastName ? lastName.charAt(0) : "";
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const customList = (title, items) => (
    <Box
      sx={{
        flex: 1,
        minHeight: "20vh",
        maxHeight: "40vh",
        border: "1.25px solid rgba(173, 216, 230, 0.5)",
        borderRadius: "6px",
        mb: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <List
        dense
        component="div"
        role="list"
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: 0,
        }}
      >
        {/* Header Row */}
        <ListItem
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: 2,
            paddingRight: 2,
            border: "1.25px solid rgba(173, 216, 230, 0.5)",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            mb: 1,
          }}
        >
          <Box sx={{ width: "11.5%" }} />{" "}
          {/* Placeholder for Checkbox column */}
          <Typography
            sx={{
              fontSize: "14.5px",
              fontWeight: 600,
              color: "#4A6B75",
              width: "35%",
              textAlign: "left",
            }}
          >
            Name
          </Typography>
          <Typography
            sx={{
              fontSize: "14.5px",
              fontWeight: 600,
              color: "#4A6B75",
              width: "25%",
              textAlign: "left",
              ml: 3,
            }}
          >
            Title
          </Typography>
        </ListItem>

        {/* User Items */}
        {Array.isArray(items) && items.length > 0 ? (
          items.map((user, index) => {
            const labelId = `transfer-list-item-${user.user_id || index}-label`;

            return (
              <ListItem
                key={user.user_id || index}
                role="listitem"
                onClick={handleToggle(user)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 1,
                  paddingRight: 2,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  mb: 1,
                  minHeight: "28px", // Consistent height for rows
                }}
              >
                <ListItemIcon
                  sx={{
                    width: "13%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      color: "#48768C",
                      fontSize: "15px",
                      width: 35,
                      height: 35,
                      background: "radial-gradient(circle, #EEF6F9, #FFFFFF)",
                      border: "2px solid rgba(173, 216, 230, 0.9)",
                      borderRadius: "20px",
                      padding: "4px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.01)",
                      marginRight: 1,
                      fontWeight: 600,
                    }}
                  >
                    {getInitials(user.first_name, user.last_name)}
                  </Avatar>
                </ListItemIcon>
                <Typography
                  sx={{
                    fontSize: "14.5px",
                    fontWeight: 600,
                    color: "#48768C",
                    whiteSpace: "nowrap",
                    width: "35%",
                    textAlign: "left",
                    letterSpacing: "-.25px",
                  }}
                  id={labelId}
                >
                  {`${user.first_name} ${user.last_name}`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14.5px",
                    fontWeight: 500,
                    color: "#4A6B75",
                    whiteSpace: "nowrap",
                    width: "25%",
                    textAlign: "left",
                    ml: 3,
                  }}
                >
                  {user.title}
                </Typography>
              </ListItem>
            );
          })
        ) : (
          // Placeholder for no employees
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
              height: "100%",
              testAlign: "center",
              alignSelf: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "15.5px",
                fontWeight: 500,
                color: "#7A7A7A",
                mb: 5,
              }}
            >
              No employees currently assigned to {name}
            </Typography>
          </Box>
        )}
      </List>
    </Box>
  );

  return (
    <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{ width: "100%", display: "flex", flexGrow: 1, m: 0 }}
      >
        {/* Left List Container */}
        <Box sx={{ flexGrow: 1, minWidth: 0, display: "flex", width: "100%" }}>
          <Box
            sx={{ flexGrow: 1, minWidth: 0, display: "flex", width: "100%" }}
          >
            {customList("Active Employees", right)}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}

export default TransferList;
