import React from "react";
import {
  Typography,
  Button,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Modal,
  TextField,
  Box,
  TableFooter,
  Divider,
  Paper,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  InputAdornment,
  Stack,
  Fade,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

//Import One Line Snippet:
//import { PrimaryButton, CancelButton, SecondaryButton } from 'src/components/Buttons/ModalButtons';

// Styled Primary Button Used For Adding/Editing/Deleting Users, Locations, Customers & Whatever Else
const AddButton = ({ onClick, text, icon }) => {
  return (
    <Paper
      elevation={4}
      sx={{
        borderRadius: "9px",
        marginRight: 0.25,
        height: "34px",
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "#f2f7f9",
          opacity: 0.9,
          boxShadow: (theme) => theme.shadows[2], // Increase elevation on hover
        },
      }}
    >
      <IconButton
        variant="contained"
        onClick={onClick}
        sx={{
          minWidth: 85,
          height: "34px",
          borderRadius: "9px",
          backgroundColor: "#FFFFFF",
          border: "2px solid #c2d7df",
          padding: 1,
          "&:hover": {
            backgroundColor: "#e9f1f5",
          },
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 0.25,
          }}
        >
          <AddRoundedIcon
            sx={{
              mt: "1px",
              width: "20px", // Adjusted to fit inside the box with padding
              height: "20px",
              color: "#48768C",
            }}
          />{" "}
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 13.5,
              fontWeight: 600,
              color: "#48768C",
              whiteSpace: "nowrap",
              letterSpacing: "-.01em",
              mx: "3px",
            }}
          >
            {text}
          </Typography>
        </Box>
      </IconButton>
    </Paper>
  );
};

const EditButton = ({ onClick, text, icon }) => {
  return (
    <Paper
      elevation={4}
      sx={{
        mt: "1px",
        borderRadius: "9px",
        marginRight: 0.25,
        "&:hover": {
          backgroundColor: "#f2f7f9",
          opacity: 0.9,
          boxShadow: (theme) => theme.shadows[2], // Increase elevation on hover
        },
      }}
    >
      <IconButton
        variant="contained"
        onClick={onClick}
        sx={{
          minWidth: 85,
          height: "34px",
          borderRadius: "9px",
          backgroundColor: "#FFFFFF",
          border: "2px solid #c2d7df",
          padding: 1,
          "&:hover": {
            backgroundColor: "#e9f1f5",
          },
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 0.25,
          }}
        >
          <AddRoundedIcon
            sx={{
              mt: "1px",
              width: "20px", // Adjusted to fit inside the box with padding
              height: "20px",
              color: "#48768C",
            }}
          />{" "}
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 13.5,
              fontWeight: 600,
              color: "#48768C",
              whiteSpace: "nowrap",
              letterSpacing: "-.01em",
              mx: "3px",
            }}
          >
            {text}
          </Typography>
        </Box>
      </IconButton>
    </Paper>
  );
};

const DeleteButton = ({ onClick, text, icon }) => {
  return (
    <Paper
      elevation={4}
      sx={{
        mt: "1px",
        borderRadius: "9px",
        marginRight: 0.25,
        "&:hover": {
          backgroundColor: "#f2f7f9",
          opacity: 0.9,
          boxShadow: (theme) => theme.shadows[2], // Increase elevation on hover
        },
      }}
    >
      <IconButton
        variant="contained"
        onClick={onClick}
        sx={{
          minWidth: 85,
          height: "34px",
          borderRadius: "9px",
          backgroundColor: "#FFFFFF",
          border: "2px solid #c2d7df",
          padding: 1,
          "&:hover": {
            backgroundColor: "#e9f1f5",
          },
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 0.25,
          }}
        >
          <AddRoundedIcon
            sx={{
              mt: "1px",
              width: "20px", // Adjusted to fit inside the box with padding
              height: "20px",
              color: "#48768C",
            }}
          />{" "}
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 13.5,
              fontWeight: 600,
              color: "#48768C",
              whiteSpace: "nowrap",
              letterSpacing: "-.01em",
              mx: "3px",
            }}
          >
            {text}
          </Typography>
        </Box>
      </IconButton>
    </Paper>
  );
};

// Usage in a Component
function AddDeleteEditButtons() {
  return (
    <div>
      <AddButton />
      <EditButton />
      <DeleteButton />
    </div>
  );
}

export { AddButton, EditButton, DeleteButton };
export default AddDeleteEditButtons;
