import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Avatar,
  Badge,
  Box,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  Popover,
  Typography,
  Card,
  CardContent,
  Stack,
  Paper,
} from "@mui/material";
import Iconify from "../../components/iconify";
import Scrollbar from "../../components/scrollbar";
import { useAuth0 } from "@auth0/auth0-react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PestControlIcon from "@mui/icons-material/PestControl";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"; // Import the new icon
import DoneIcon from "../../public/assets/icons/icons/done.png";
import { NavLink } from "react-router-dom";
import WelcomeIcon from "../../public/assets/icons/icons/welcome.png";
import UpgradeIcon from "../../public/assets/icons/icons/upgrade.png";
import SentIcon from "../../public/assets/icons/icons/sent.png";
import FailIcon from "../../public/assets/icons/icons/fail.png";
import ApprovedIcon from "../../public/assets/icons/icons/updateApprovedLight.png";
import DeclinedIcon from "../../public/assets/icons/icons/updateDeclinedLight.png";
import ScheduledIcon from "../../public/assets/icons/icons/scheduledNotification.png";

import { useAuth } from "../../context/AuthContext"; // Import the authentication context
import { useAccountContext } from "../../context/AccountContext"; // Import AppProvider from AccountContext
import EmailIcon from "@mui/icons-material/Email";
import EditHoursModal from "src/components/modal/EditHoursModal";
import SingleUserEditHoursModal from "src/components/modal/SingleUserEditHoursModal";
import { useGlobalNotifications } from "../../context/GlobalNotificationsContext";
import { set } from "lodash";

export default function NotificationsPopover() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [setSelectedNotificationRect] = useState(null);
  const [showEditHoursModal, setShowEditHoursModal] = useState(false);
  const [showSingleUserEditHoursModal, setShowSingleUserEditHoursModal] =
    useState(false);
  const { fetchGlobalNotifications, notifications, setNotifications } =
    useGlobalNotifications();
  const { isLoggedIn } = useAuth(); // Use the isLoggedIn state from the context
  const { accountData } = useAccountContext(); // Use the accountData from the context
  const [notificationOpen, setNotificationOpen] = useState(true);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setSelectedNotification(null);
  };

  const handleNotificationClick = async (event, notification) => {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/api/mark-notification-as-read/${notification.id}?userSub=${user.sub}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.ok) {
        // Mark the notification as read locally
        markAsRead(notification.id);

        // Refetch notifications to update the list
        fetchGlobalNotifications();
      } else {
        console.error("Failed to mark notification as read");
      }
    } catch (error) {
      console.error("Error marking notification as read", error);
    }

    // Check if the target is present before getting its bounding rect
    const target = event.currentTarget;
    if (target) {
      setSelectedNotificationRect(target.getBoundingClientRect());
    }
    setNotificationOpen(true);
    setSelectedNotification(notification);
  };

  const handleMarkAsUnread = async (notification) => {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/api/mark-notification-as-unread/${notification.id}?userSub=${user.sub}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.ok) {
        // Mark the notification as read locally
        markAsUnread(notification.id);

        // Refetch notifications to update the list
        fetchGlobalNotifications();
      } else {
        console.error("Failed to mark notification as read");
      }
    } catch (error) {
      console.error("Error marking notification as read", error);
    }

    handleNotificationClose();

    setSelectedNotification(notification);
  };

  const handleMarkAllRead = async () => {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/api/mark-all-notifications-as-read?userSub=` + user.sub,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.ok) {
        // Refetch notifications to update the list
        fetchGlobalNotifications();
      } else {
        console.error("Failed to mark notification as read");
      }
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };

  const markAsRead = (notificationId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === notificationId
          ? { ...notification, isUnRead: false }
          : notification,
      ),
    );
  };

  const markAsUnread = (notificationId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === notificationId
          ? { ...notification, isUnRead: true }
          : notification,
      ),
    );
  };

  const handleDelete = async (notificationId) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${apiUrl}/api/global-notifications/${notificationId}?userSub=${user.sub}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.ok) {
        setNotifications((prevNotifications) =>
          prevNotifications.filter(
            (notification) => notification.id !== notificationId,
          ),
        );
      } else {
        console.error("Failed to delete notification");
      }
    } catch (error) {
      console.error("Error deleting notification", error);
    }
  };

  const customColors = {
    Bugfix: "#8c4350",
    "Feature Update": "#48768C",
    "Minor Update": "#647D9E",
    "Developer Message": "#0277BD",
    "Version Upgrade": "#2BBB9E",
    "New User": "#48768C",
    Help: "#4FD4B9",
    scheduled: "#FFFFFF",
    failure: "#FFFFFF",
    APPROVED: "#FFFFFF",
    DECLINED: "#FFFFFF",
  };

  const categoryIcons = {
    Bugfix: (
      <PestControlIcon
        style={{
          alignSelf: "center",
          alignContent: "center",
          width: "auto",
          height: "20px",
        }}
      />
    ),
    "Feature Update": (
      <img
        src={UpgradeIcon}
        alt="DoneIcon"
        style={{
          alignSelf: "center",
          alignContent: "center",
          width: "auto",
          height: "20px",
        }}
      />
    ),
    "Minor Update": (
      <SettingsSuggestIcon
        style={{
          alignSelf: "center",
          padding: 1,

          alignContent: "center",
          width: "auto",
          height: "20px",
        }}
      />
    ),
    "Developer Message": (
      <EmailIcon
        style={{
          alignSelf: "center",
          padding: 2,
          alignContent: "center",
          width: "auto",
          height: "20px",
        }}
      />
    ),
    "Version Upgrade": (
      <AutoAwesomeIcon
        style={{
          alignSelf: "center",
          alignContent: "center",
          padding: 2,
          width: "auto",
          height: "20px",
        }}
      />
    ),
    "New User": (
      <img
        src={DoneIcon}
        alt="DoneIcon"
        style={{
          alignSelf: "center",
          alignContent: "center",
          width: "auto",
          height: "20px",
        }}
      />
    ),
    Help: (
      <img
        src={WelcomeIcon}
        alt="WelcomeIcon"
        style={{
          alignSelf: "center",
          alignContent: "center",
          width: "auto",
          height: "20px",
        }}
      />
    ),
    scheduled: (
      <img
        src={ScheduledIcon}
        alt="WelcomeIcon"
        style={{
          width: "30px",
          height: "30px",
          padding: "2px",
          borderRadius: "50%",
        }}
      />
    ),
    failure: (
      <img
        src={FailIcon}
        alt="WelcomeIcon"
        style={{
          width: "30px",
          height: "30px",
          padding: "1px",
          borderRadius: "50%",
        }}
      />
    ),
    APPROVED: (
      <img
        src={ApprovedIcon}
        alt="WelcomeIcon"
        style={{
          width: "30px",
          height: "30px",
          padding: "1px",
          borderRadius: "50%",
        }}
      />
    ),
    DECLINED: (
      <img
        src={DeclinedIcon}
        alt="WelcomeIcon"
        style={{
          width: "30px",
          height: "30px",
          padding: "1px",
          borderRadius: "50%",
        }}
      />
    ),
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (timeStr) => {
    const time = new Date(timeStr);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return time.toLocaleTimeString(undefined, options);
  };

  return (
    <>
      <IconButton
        color={open ? "primary" : "default"}
        disabled={!isLoggedIn}
        onClick={handleOpen}
        sx={{ width: 38, height: 38 }}
      >
        <Badge
          badgeContent={notifications.filter((item) => item.is_unread).length}
          color="error"
        >
          <Iconify icon="eva:bell-fill" sx={{ color: "#48768C" }} />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 0.25,
            ml: 0.75,
            width: 360,
            backgroundColor: "#F2F9FF",
            border: "1.25px solid rgba(173, 216, 230, 0.5)",
            borderRadius: "14px", // Adjust the value to control the roundness
          },
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", pt: 2, pb: 1, px: 2.5 }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{ fontSize: 15, fontWeight: 600, color: "#48768C" }}
            >
              Notifications
            </Typography>
            <Typography sx={{ fontSize: 13, color: "#628197" }}>
              You have {notifications.filter((item) => item.is_unread).length}{" "}
              New Notification
              {notifications.filter((item) => item.is_unread).length !== 1 &&
                "s"}
            </Typography>
          </Box>
        </Box>

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                markAsRead={markAsRead}
                markAsUnread={markAsUnread}
                handleNotificationClick={handleNotificationClick}
                handleDelete={handleDelete}
                isUnRead={notification.is_unread}
                setShowEditHoursModal={setShowEditHoursModal} // Pass setShowEditHoursModal to NotificationItem
              />
            ))}
          </List>
        </Scrollbar>

        <Box sx={{ p: 1 }}>
          <Button
            variant="text"
            sx={{ color: "#48768C", fontSize: 12 }}
            onClick={() => {
              handleMarkAllRead();
            }}
          >
            Mark all as read
          </Button>
        </Box>
      </Popover>

      {selectedNotification && (
        <Popover
          open={notificationOpen}
          anchorEl={open}
          onClose={() => {
            handleNotificationClose();
            handleClose();
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              ml: 0.75,
              width: 360,
              borderRadius: "14px",
            },
          }}
        >
          <Card
            sx={{
              minWidth: 300,
              backgroundColor: "#F2F9FF",
              border: "2px solid #D3E3F1",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "14px",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mb: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Paper
                    elevation={4}
                    sx={{
                      borderRadius: "50%",
                      border: "2.25px solid rgba(173, 216, 230, 0.9)",
                      alignSelf: "center",
                      mt: "0px",
                      alignContent: "center",
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: customColors[selectedNotification.tag],
                        color: "primary.contrastText",
                        width: "auto",
                        height: "auto",
                        alignItems: "center",
                      }}
                    >
                      {categoryIcons[selectedNotification.tag]}
                    </Avatar>
                  </Paper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      ml: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 600,
                        color: "#48768C",
                        marginLeft: "8px",
                        alignItems: "center",
                        lineHeight: "24px", // Matches Avatar height
                        letterSpacing: -0.1,
                      }}
                    >
                      {selectedNotification.title}
                    </Typography>
                    {selectedNotification &&
                      ["APPROVED", "DECLINED"].some((tag) =>
                        selectedNotification.tag.includes(tag),
                      ) && (
                        <>
                          <Stack
                            direction="row"
                            sx={{ marginLeft: "8px", mt: "-3px" }}
                          >
                            {/* Description */}
                            <Typography
                              color="text.secondary"
                              sx={{
                                fontSize: 13.75,
                                fontWeight: 600,
                                color: "#48768C",
                                opacity: 0.87,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {selectedNotification.description}
                            </Typography>

                            {/* Created At Timestamp */}
                            <Typography
                              color="text.secondary"
                              sx={{
                                fontSize: 13.75,
                                ml: 0.5,
                                fontWeight: 600,
                                color: "#48768C",
                                opacity: 0.87,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {formatDate(selectedNotification.created_at)}{" "}
                              {formatTime(selectedNotification.created_at)}
                            </Typography>
                          </Stack>
                        </>
                      )}
                    {selectedNotification &&
                      selectedNotification.tag.includes("scheduled") && (
                        <>
                          <Stack
                            direction="row"
                            sx={{ marginLeft: "8px", mt: "-3px" }}
                          >
                            {/* Description */}
                            <Typography
                              color="text.secondary"
                              sx={{
                                fontSize: 13.75,
                                fontWeight: 600,
                                color: "#48768C",
                                opacity: 0.87,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {selectedNotification.description}
                            </Typography>

                            {/* Created At Timestamp */}
                            <Typography
                              color="text.secondary"
                              sx={{
                                fontSize: 13.75,
                                ml: 0.5,
                                fontWeight: 600,
                                color: "#48768C",
                                opacity: 0.87,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {formatDate(selectedNotification.created_at)}{" "}
                              {formatTime(selectedNotification.created_at)}
                            </Typography>
                          </Stack>
                        </>
                      )}
                  </Box>
                </Box>

                {selectedNotification.content && (
                  <Typography
                    sx={{
                      fontSize: 14,
                      borderRadius: "10px",
                      color: "#48768C",
                      mt: 2,
                      ml: 1,
                    }}
                  >
                    {selectedNotification.content
                      .split("\n")
                      .map((paragraph, index) => (
                        <React.Fragment key={index}>
                          {paragraph.split(" ").map((word, index) => (
                            <React.Fragment key={index}>{word} </React.Fragment>
                          ))}
                          <br />
                        </React.Fragment>
                      ))}
                  </Typography>
                )}
                <Box sx={{ width: "100%" }}>
                  {selectedNotification &&
                    ["scheduled", "APPROVED", "DECLINED"].some((tag) =>
                      selectedNotification.tag.includes(tag),
                    ) && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                          mb: -1,
                          justifyContent: "flex-end",
                          mt: -0.5,
                        }}
                      >
                        <Button
                          variant="text"
                          sx={{ color: "#48768C", fontSize: 13.5, mt: "5px" }}
                          onClick={() => {
                            handleMarkAsUnread(selectedNotification);
                          }}
                        >
                          Mark as unread
                        </Button>
                      </Box>
                    )}
                </Box>
              </Box>

              {selectedNotification &&
                selectedNotification.tag.includes("New User") && (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                  >
                    {accountData.single_location ? (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "10px",
                          color: "#F0F0F0",
                          backgroundColor: "#46A793",
                          mt: 2,
                          mb: 0.5,
                          fontSize: 13,
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s ease",
                          width: "55%",
                          "&:hover": {
                            backgroundColor: "#079E80",
                          },
                          "&:active": {
                            backgroundColor: "#2E7D32",
                          },
                        }}
                        onClick={() => {
                          setShowSingleUserEditHoursModal(true);
                          handleClose();
                        }}
                      >
                        Setup Location Hours
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "10px",
                          color: "#F0F0F0",
                          backgroundColor: "#46A793",
                          mt: 2,
                          mb: 0.5,
                          fontSize: 13,
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s ease",
                          width: "55%",
                          "&:hover": {
                            backgroundColor: "#079E80",
                          },
                          "&:active": {
                            backgroundColor: "#2E7D32",
                          },
                        }}
                        onClick={() => {
                          setShowEditHoursModal(true);
                          handleClose();
                        }}
                      >
                        Setup Location Hours
                      </Button>
                    )}
                  </Box>
                )}

              {selectedNotification &&
                selectedNotification.tag.includes("Help") && (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                  >
                    <Button
                      variant="contained"
                      component={NavLink} // Use NavLink component from react-router-dom
                      to="/dashboard/help-center/" // Specify the path to your specific page
                      sx={{
                        borderRadius: "10px",
                        color: "#F0F0F0",
                        backgroundColor: "#46A793",
                        mt: 2,
                        mb: 0.5,
                        fontSize: 13,
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        transition: "background-color 0.3s ease",
                        width: "55%",
                        "&:hover": {
                          backgroundColor: "#079E80",
                        },
                        "&:active": {
                          backgroundColor: "#2E7D32",
                        },
                      }}
                      onClick={() => {
                        handleNotificationClose(); // Close the notifications panel
                        handleClose(); // Close the notifications panel
                      }}
                    >
                      Get Started
                    </Button>
                  </Box>
                )}

              <IconButton
                aria-label="Close"
                onClick={handleNotificationClose}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <CloseSharpIcon />
              </IconButton>
            </CardContent>
          </Card>
        </Popover>
      )}
      {showSingleUserEditHoursModal && (
        <SingleUserEditHoursModal
          isOpen={showSingleUserEditHoursModal}
          onClose={() => setShowSingleUserEditHoursModal(false)}
        />
      )}

      {showEditHoursModal && (
        <EditHoursModal
          isOpen={showEditHoursModal}
          onClose={() => setShowEditHoursModal(false)}
        />
      )}
    </>
  );
}

NotificationsPopover.propTypes = {
  // Prop types definition
};

function NotificationItem({
  notification,
  markAsRead,
  markAsUnread,
  handleNotificationClick,
  handleDelete,
  isUnRead,
  setShowEditHoursModal,
}) {
  const customColors = {
    Bugfix: "#8c4350",
    "Feature Update": "#48768C",
    "Minor Update": "#647D9E",
    "Developer Message": "#0277BD",
    "Version Upgrade": "#2BBB9E",
    "New User": "#48768C",
    Help: "#4FD4B9",
    scheduled: "#FFFFFF",
    failure: "#FFFFFF",
    APPROVED: "#FFFFFF",
    DECLINED: "#FFFFFF",
  };

  const categoryIcons = {
    Bugfix: (
      <PestControlIcon
        style={{
          width: "auto",
          height: "28px",
          padding: "4px",
        }}
      />
    ),
    "Feature Update": (
      <img
        src={UpgradeIcon}
        alt="DoneIcon"
        style={{
          alignSelf: "center",
          alignContent: "center",
          width: "auto",
          height: "28px",
        }}
      />
    ),
    "Minor Update": (
      <SettingsSuggestIcon
        style={{
          width: "auto",
          height: "28px",
          padding: "4px",
        }}
      />
    ),
    "Developer Message": (
      <EmailIcon
        style={{
          width: "auto",
          height: "28px",
          padding: "4px",
        }}
      />
    ),
    "Version Upgrade": (
      <AutoAwesomeIcon
        style={{
          width: "auto",
          height: "28px",
          padding: "4px",
        }}
      />
    ),
    "New User": (
      <img
        src={DoneIcon}
        alt="DoneIcon"
        style={{
          width: "auto",
          height: "28px",
          padding: "2px",
        }}
      />
    ),
    Help: (
      <img
        src={WelcomeIcon}
        alt="WelcomeIcon"
        style={{
          width: "auto",
          height: "28px",
          padding: "2px",
        }}
      />
    ),
    failure: (
      <img
        src={FailIcon}
        alt="WelcomeIcon"
        style={{
          height: "30px",
          padding: ".5px",
          borderRadius: "50%",
          border: "1px solid #e3bec9",
          alignSelf: "center",
          alignContent: "center",
        }}
      />
    ),
    scheduled: (
      <img
        src={ScheduledIcon}
        alt="WelcomeIcon"
        style={{
          height: "26px",
          padding: "1.5px",
          borderRadius: "50%",
          alignSelf: "center",
          alignContent: "center",
        }}
      />
    ),

    APPROVED: (
      <img
        src={ApprovedIcon}
        alt="WelcomeIcon"
        style={{
          height: "26px",
          padding: "1.5px",
          borderRadius: "50%",
          alignSelf: "center",
          alignContent: "center",
        }}
      />
    ),
    DECLINED: (
      <img
        src={DeclinedIcon}
        alt="WelcomeIcon"
        style={{
          height: "26px",
          padding: "1.5px",
          borderRadius: "50%",
          alignSelf: "center",
          alignContent: "center",
        }}
      />
    ),
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (timeStr) => {
    const time = new Date(timeStr);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return time.toLocaleTimeString(undefined, options);
  };

  return (
    <ListItemButton
      sx={{
        px: 2.5,
        // ... (other styles)
      }}
      onClick={(event) => {
        handleNotificationClick(event, notification);
      }}
    >
      <ListItemAvatar sx={{ alignItems: "center" }}>
        <Badge
          color="error"
          variant="dot"
          invisible={!isUnRead} // Hide the badge if the notification is read
        >
          <Paper
            elevation={2}
            sx={{
              borderRadius: "50%",
              border: "2.25px solid rgba(173, 216, 230, 0.9)",
              alignSelf: "center",
              mt: "4px",
              alignContent: "center",
            }}
          >
            <Avatar
              sx={{
                height: "auto",
                width: "auto",
                bgcolor: customColors[notification.tag],
                color: "primary.contrastText",
              }}
            >
              {categoryIcons[notification.tag]}
            </Avatar>
          </Paper>
        </Badge>
      </ListItemAvatar>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Typography
          sx={{ fontSize: 14, fontWeight: 600, color: "#48768C", mt: "3px" }}
        >
          {notification.title}
        </Typography>

        <Stack direction="row">
          <Typography
            whiteSpace="no-wrap"
            sx={{
              fontSize: 13,
              fontWeight: 500,
              color: "#48768C",
              whiteSpace: "nowrap",
            }}
          >
            {notification.description}
          </Typography>

          {notification &&
            ["scheduled", "APPROVED", "DECLINED"].some((tag) =>
              notification.tag.includes(tag),
            ) && (
              <Typography
                color="text.secondary"
                sx={{
                  fontSize: 13,
                  fontWeight: 500,
                  color: "#48768C",
                  whiteSpace: "nowrap",
                  ml: "2px",
                }}
              >
                {formatDate(notification.created_at)}{" "}
                {formatTime(notification.created_at)}
              </Typography>
            )}
        </Stack>
      </Box>

      <IconButton
        aria-label="Delete"
        onClick={(event) => {
          event.stopPropagation();
          handleDelete(notification.id);
        }}
        sx={{
          justifyContent: "right",
        }}
      >
        <DeleteForeverIcon
          sx={{
            fontSize: "21px",
            color: "#B69AA5",
            display: "flex",
            alignSelf: "center",
            flexDirection: "flex-end",
            justifyContent: "right",
          }}
        />
      </IconButton>
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number, // Change the type to number
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.string,
  }),
  markAsRead: PropTypes.func,
  markAsUnread: PropTypes.func,
  handleNotificationClick: PropTypes.func,
  handleDelete: PropTypes.func,
  setShowEditHoursModal: PropTypes.func, // Add prop type for setShowEditHoursModal
  setShowSingleUserEditHoursModal: PropTypes.func, // Add prop type for setShowEditHoursModal
};
