export const handleProfileSave = async (
  editData,
  getAccessTokenSilently,
  userId,
  username,
) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  try {
    const accessToken = await getAccessTokenSilently();
    const updateUserProfileUrl = `${apiUrl}/api/users/profile/${userId}/${username}`;

    const response = await fetch(updateUserProfileUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(editData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to update profile");
    }

    const updatedUserDataResponse = await fetch(
      `${apiUrl}/api/all-users/${userId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    if (!updatedUserDataResponse.ok) {
      throw new Error("Failed to fetch updated user data");
    }

    const updatedUserData = await updatedUserDataResponse.json();
    return updatedUserData; // Return the updated user data for further processing
  } catch (error) {
    throw new Error(error.message || "Failed to update profile");
  }
};

export const handleLocationSave = async (
  editData,
  getAccessTokenSilently,
  locationId,
  userId,
) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  try {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(`${apiUrl}/api/locations/${locationId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(editData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to update location");
    }

    const updatedUserDataResponse = await fetch(
      `${apiUrl}/api/all-users/${userId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    if (!updatedUserDataResponse.ok) {
      throw new Error("Failed to fetch updated user data");
    }

    const updatedUserData = await updatedUserDataResponse.json();
    return updatedUserData; // Return updated user data for further actions
  } catch (error) {
    throw new Error(error.message || "Error updating location");
  }
};

export const fetchUserData = async (
  userId,
  username,
  getAccessTokenSilently,
  setEditData,
) => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(`${apiUrl}/api/users/${userId}/${username}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }
    const userData = await response.json();
    if (userData.rows && userData.rows.length > 0) {
      setEditData((prevState) => ({
        ...prevState,
        first_name: userData.rows[0].first_name || "",
        last_name: userData.rows[0].last_name || "",
        title: userData.rows[0].title || "",
      }));
    } else {
      console.error("No user data found.");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

export const fetchActiveLocation = async (
  userId,
  username,
  getAccessTokenSilently,
  setActiveLocation,
  setEditData,
) => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(
      `${apiUrl}/api/locations/${userId}/${username}/active`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to fetch active location");
    }

    // Update both activeLocation and editData using the API response data
    setActiveLocation(data);
    setEditData((prevState) => ({
      ...prevState,
      name: data.name || "",
      phone: data.phone || "",
      email: data.email || "",
      location_id: data.location_id || "",
    }));
  } catch (error) {
    console.error("Error fetching active location:", error);
  }
};
