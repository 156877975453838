import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Box,
  CardMedia,
  Typography,
  Stack,
} from "@mui/material";
import checklistDone from "src/public/assets/illustrations/checklistDone1.png";

function SuccessDialog({
  header,
  image,
  caption,
  body,
  loadingText,
  handleButtonAction,
  isLoading,
  successDialogOpen,
  handleCloseSuccessDialog,
  alternateButton,
  closeButtonClick,
  buttonText,
  alternateButtonText,
  handleAlternateButtonAction,
}) {
  const capitalizeWords = (str) => {
    if (!str) return ""; // Check for null or undefined values
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Dialog
      onClose={handleCloseSuccessDialog}
      open={successDialogOpen}
      PaperProps={{ sx: { borderRadius: "12px", overflowX: "hidden" } }}
    >
      <DialogContent sx={{ alignItems: "center", width: "100%", pb: 10 }}>
        {/* Title Section */}
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Typography
            sx={{
              fontSize: 24,
              color: "#48768C",
              fontWeight: 600,
              letterSpacing: -0.25,
            }}
          >
            {capitalizeWords(header)}
          </Typography>
        </Box>

        {/* Image  */}
        <Box sx={{ textAlign: "center", mb: -24 }}>
          <CardMedia
            component="img"
            image={image}
            sx={{
              width: { xs: "60vw", sm: "40vw", md: "30vw", lg: "25vw" },
              height: "auto",
              maxWidth: "485px",
              objectFit: "contain",
            }}
          />
        </Box>

        {/* Caption Section */}
        <Stack
          direction="column"
          sx={{ width: "100%", alignItems: "center", mt: 4 }}
        >
          <Typography
            sx={{
              fontSize: 22,
              mb: 1,
              color: "#8EB5C9",
              fontWeight: 550,
              letterSpacing: -0.25,
            }}
          >
            {caption}
          </Typography>
        </Stack>
      </DialogContent>

      {/* Button and Body Section */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 3,
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{ fontSize: 17, fontWeight: 500, color: "#48768C", mb: 4 }}
        >
          {body}
        </Typography>
        <Stack direction="row" spacing={6}>
          {alternateButton && (
            <Button
              variant="contained"
              onClick={handleAlternateButtonAction}
              sx={{
                width: "auto",
                backgroundColor: "rgba(191, 218, 231, 0.5)",
                border: "1px solid #8EB5C9",
                color: "#647D9E",
                transition: "all 0.3s ease", // Smooth transition for all properties
                boxShadow:
                  "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
                "&:hover": {
                  boxShadow:
                    "0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1)", // Reduced shadow for a "pressed" effect
                  backgroundColor: "rgba(191, 218, 231, 0.3)", // Slightly darken background on hover
                  transform: "translateY(2px)", // Moves the button down to mimic a pressed effect
                },
                "&:active": {
                  boxShadow:
                    "0 1px 2px rgba(0, 0, 0, 0.2), 0 0px 1px rgba(0, 0, 0, 0.1)", // Even less shadow on active
                  transform: "translateY(1px)", // Slight press down when clicked
                },
              }}
            >
              {isLoading ? loadingText : alternateButtonText}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleButtonAction}
            sx={{
              width: "auto",
              backgroundColor: "white",
              border: "1px solid #8EB5C9",
              color: "#647D9E",
              transition: "all 0.3s ease", // Smooth transition for all properties
              boxShadow:
                "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
              "&:hover": {
                boxShadow:
                  "0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1)", // Reduced shadow for a "pressed" effect
                backgroundColor: "rgba(191, 218, 231, 0.3)", // Slightly darken background on hover
                transform: "translateY(2px)", // Moves the button down to mimic a pressed effect
              },
              "&:active": {
                boxShadow:
                  "0 1px 2px rgba(0, 0, 0, 0.2), 0 0px 1px rgba(0, 0, 0, 0.1)", // Even less shadow on active
                transform: "translateY(1px)", // Slight press down when clicked
              },
            }}
          >
            {isLoading ? loadingText : buttonText}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default SuccessDialog;
