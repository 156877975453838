import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Modal,
  Typography,
  Fade,
  Stack,
  Skeleton,
  CardMedia,
} from "@mui/material";
import moment from "moment-timezone"; // Import moment-timezone
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import companyImage from "src/public/assets/companyImage.png";
import RestartSetupDialog from "src/components/modal/NewUserSignUp/Refresh/Components/RestartSetupDialog";
import { add } from "lodash";





  // List of all US states
  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];


  const theme = createTheme({
    palette: {
      primary: {
        main: "#48768C", // Set the main color to white
      },
    },
  });
  


const AccountInfoStep = ({ loading, isNextButtonDisabled, setIsNextButtonDisabled, user, hideWelcome  }) => {

    const [errors, setErrors] = useState({});
    const [warning, setWarning] = useState(false);
  

  const [values, setValues] = React.useState({
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    url: '',
  });


  console.log("values", values);  

    const validateField = (type, value) => {
      const validators = {
        name: (company_name) => company_name.trim().length > 0,
        email: (company_email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(company_email),
        phone: (phone) => /^\d{10}$/.test(phone),
        address: (company_address) => company_address.trim().length > 0,
        city: (company_city) => company_city.trim().length > 0,
        state: (company_state) => company_state.trim().length > 0,
        zip: (zip_code) => /^\d{5}$/.test(zip_code),
      };
  
      return validators[type]?.(value) ?? true; // Return true if no validator is found
    };


    const handleBlur = (field) => {
      setErrors((prev) => {
        const updatedErrors = {
          ...prev,
          [field]: !validateField(field, values[field] || ""),
        };
        setIsNextButtonDisabled(Object.values(updatedErrors).some((error) => error));
        return updatedErrors;
      });
    };
  
    const handleChange = (field, value) => {
      setValues((prev) => ({ ...prev, [field]: value }));
      setErrors((prev) => {
        const updatedErrors = { ...prev, [field]: !validateField(field, value) };
        setIsNextButtonDisabled(Object.values(updatedErrors).some((error) => error));
        return updatedErrors;
      });
      setWarning(false);
     
    };


    const handleStateChange = (e) => {
      const value = e.target.value;
  
      // Update the `values` state with the manually entered value
      setValues((prev) => ({ ...prev, state: value }));
      setErrors((prev) => ({ ...prev, state: false }));
      setWarning(false);
    };
  


    return (
      !hideWelcome ? (
        <Fade in={true} timeout={400}>
          <Box
            sx={{
              height: '10%',
              width: "auto",
              justifyContent: "center",
              borderRadius: "40px",
              fontSize: 17,
              fontWeight: 600,
              backgroundColor: "#FFFFFF",
              color: "#48768C",
              padding: 2,
            }}
          >
            <Typography sx={{ mt: 1, fontSize: 22, textAlign: "left", mb: 1, fontWeight: 600 }}>
              Hey {user?.firstName || "there"}!
            </Typography>
            <Typography sx={{ mt: 0, fontSize: 19, textAlign: "left", mb: 1, fontWeight: 600 }}>
              Welcome to NanoNotify!
            </Typography>
            <Typography sx={{ textAlign: "left", fontSize: 15, fontWeight: 500, mt: 2 }}>
              Let's finish setting up your new account
            </Typography>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <Typography sx={{ display: "flex", justifyContent: "flex-end", whiteSpace: "pre-wrap", textAlign: "flex-start", fontSize: 15, width: "100%", mt: 2 }}>
                We just need to get a few details about your company and how you plan to send notifications to your customers
              </Typography>
            </Box>
          </Box>
        </Fade>
      ) : (
        <Fade in={true} timeout={600}>
          <Box
            sx={{
              height: '100%',
              width: "auto",
              justifyContent: "center",
              borderRadius: "40px",
              fontSize: 17,
              fontWeight: 600,
              backgroundColor: "#FFFFFF",
              color: "#48768C",
              padding: 1,
            }}
          >
            <Typography sx={{ mt: -1, fontSize: '1.28rem', textAlign: "left", fontWeight: 600, px: .25, letterSpacing: '-0.02em', lineHeight: '-0.02em' }}>
              Tell Us A Little About Your Company
            </Typography>
            <Typography color="textSecondary" sx={{ mt: 0, fontSize: '.87rem', textAlign: "left", mb: 1, fontWeight: 500, px: .25, letterSpacing: '-0.01em', lineHeight: '-0.04em' }}>
              Important details like your company’s website, email, and location’s phone number are automatically included in notifications.
            </Typography>
    
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}>
              <ThemeProvider theme={theme}>
                <TextField
                  label="Company Name"
                  variant="outlined"
                  size="small"
                  value={values.name || ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                  onBlur={() => handleBlur("name")}
                  error={errors.name}
                  fullWidth
                  InputProps={{
                    style: { color: "#48768C", borderRadius: "6px" },
                  }}
                  InputLabelProps={{ style: { color: "#82879B" } }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6595ab",
                    },
                    color: "#48768C",
                  }}
                />
                <TextField
                  label="Street Address"
                  variant="outlined"
                  size="small"
                  value={values.address || ""}
                  onChange={(e) => handleChange("address", e.target.value)}
                  onBlur={() => handleBlur("address")}
                  error={errors.address}
                  fullWidth
                  InputProps={{
                    style: { color: "#48768C", borderRadius: "6px" },
                  }}
                  InputLabelProps={{ style: { color: "#82879B" } }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6595ab",
                    },
                    color: "#48768C",
                  }}
                />
              </ThemeProvider>
    
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
                <ThemeProvider theme={theme}>
                  <TextField
                    label="City"
                    variant="outlined"
                    size="small"
                    value={values.city || ""}
                    onChange={(e) => handleChange("city", e.target.value)}
                    onBlur={() => handleBlur("city")}
                    error={errors.city}
                    InputProps={{
                      style: { color: "#48768C", borderRadius: "6px" },
                    }}
                    InputLabelProps={{ style: { color: "#82879B" } }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6595ab",
                      },
                      color: "#48768C",
                    }}
                  />
                </ThemeProvider>
    
                <ThemeProvider theme={theme}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{
                      color: "#82879B",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6595ab",
                      },
                      width: "36%",
                      mr: 2,
                    }}
                  >
                    <InputLabel sx={{ color: "#82879B", mt: -0.35 }}>
                      State
                    </InputLabel>
                    <Select
                      value={values.state || ""}
                      onChange={handleStateChange}
                      sx={{
                        color: "#82879B",
                        borderRadius: "8px",
                        width: "100%",
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    >
                      {usStates.map((state) => (
                        <MenuItem key={state} value={state} sx={{ backgroundColor: "#FFFFFF", color: "#82879B", borderRadius: "8px" }}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ThemeProvider>
    
                <ThemeProvider theme={theme}>
                  <TextField
                    label="Zip Code"
                    variant="outlined"
                    size="small"
                    value={values.zip || ""}
                    onChange={(e) => handleChange("zip", e.target.value)}
                    onBlur={() => handleBlur("zip")}
                    error={errors.zip}
                    InputProps={{
                      style: { color: "#48768C", borderRadius: "6px" },
                    }}
                    InputLabelProps={{ style: { color: "#82879B" } }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6595ab",
                      },
                      color: "#48768C",
                    }}
                  />
                </ThemeProvider>
              </Stack>
    
              <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                <ThemeProvider theme={theme}>
                  <TextField
                    label="Company Email"
                    size="small"
                    variant="outlined"
                    value={values.email || ""}
                    onChange={(e) => handleChange("email", e.target.value)}
                    onBlur={() => handleBlur("email")}
                    error={errors.email}
                    fullWidth
                    InputProps={{
                      style: { color: "#48768C", borderRadius: "6px" },
                    }}
                    InputLabelProps={{ style: { color: "#82879B" } }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6595ab",
                      },
                      color: "#48768C",
                    }}
                  />
                </ThemeProvider>
    
                <ThemeProvider theme={theme}>
                  <TextField
                    label="Company Website"
                    variant="outlined"
                    size="small"
                    value={values.email || ""}
                    onChange={(e) => handleChange("email", e.target.value)}
                    onBlur={() => handleBlur("email")}
                    error={errors.email}
                    fullWidth
                    InputProps={{
                      style: { color: "#48768C", borderRadius: "6px" },
                    }}
                    InputLabelProps={{ style: { color: "#82879B" } }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6595ab",
                      },
                      color: "#48768C",
                    }}
                  />
                </ThemeProvider>
              </Stack>
            </Box>
          </Box>
        </Fade>
      )
    );

  };


export default AccountInfoStep;