import React, { useState } from "react";
import {
  Rating,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
  IconButton,
  Stack,
  Box,
  Divider,
} from "@mui/material";
import reviewIcon from "../../../../public/assets/icons/navbar/reviewSmall.png";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import StarIcon from 'src/public/assets/icons/icons/starIcon2.png';
import './styles.css';
import StarRating from "./Components/StarRating";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 'auto',
    // Ensures it doesn't shrink too much
    minHeight: "410px", // Prevents excessive shrinking
    bgcolor: "background.paper",
    boxShadow: 24,
    pt:0,
    pb: 2,
px:3,
    borderRadius: "12px",
  },
}));

const LeaveFeedbackDialog = ({ isOpen, onClose, onSubmit }) => {
  const [feedbackData, setFeedbackData] = useState({
    rating: 0,
    strength: "",
    improvementAreas: "",
    additionalComments: "",
  });
  const [isLoading, setIsLoading] = useState(false);


  const handleRatingChange = (event, newValue) => {
    setFeedbackData((prevState) => ({
      ...prevState,
      rating: newValue,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  console.log("Feedback Data:", feedbackData);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const { handleReloadComponents, showSnackBar } = useAppContext();

  const handleSubmit = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const userId = user.sub;

    const sendFeedback = async () => {
      setIsLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(`${apiUrl}/api/feedback/${userId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Add this line
          },
          body: JSON.stringify(feedbackData),
        });

        const data = await response.json();

        if (data.error) {
          console.error("Failed to send feedback:", data.error);
          showSnackBar(
            "Failed to submit your feedback. Please try again",
            false,
          );
          handleReloadComponents();
          setIsLoading(false);
          onClose();
        } else {
          showSnackBar(
            "Feedback submitted. Thanks for letting us know your thoughts!",
            true,
          );
          handleReloadComponents();
          setIsLoading(false);
          onClose();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        showSnackBar("Failed to submit your feedback. Please try again", false);
        handleReloadComponents();
        setIsLoading(false);
        onClose();
      }
    };

    sendFeedback();
  };

  return (
    <div>
      <StyledDialog open={isOpen} onClose={onClose}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 6,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            color: "#374247",
            height: "auto",
            width: "75%",
            pt:2.5
          }}
        >
        
        {/* Review Icon- Hiding this for now  */}
          <Stack
            direction="column"
            sx={{
              display: "flex",
              alignItems: "center",
              padding:2 

         
            }}
          >
           
            <Typography
              sx={{ fontSize: '1.39rem', fontWeight: 600, marginRight: "auto", letterSpacing:'-0.034em', lineHeight: '1em', alignSelf: 'center',    color: "#48768C",


              }}
            >
                         Give us your feedback

            </Typography>
            <Typography 
         color="textSecondary"
      sx={{ fontSize: ".94rem", fontWeight: 600, mr:'auto', mt:1.5, mb:.5 }}>
       What do you think of our NanoNotify? We would love to hear your thoughts!
      </Typography>
            
          </Stack>
          
        </DialogTitle>
        <DialogContent
  sx={{
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "100%",
    display: "flex", // Enable flexbox on DialogContent
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    flexDirection: "column", // Stack children vertically 
  }}
>

  
  {/* Feedback Containers */}
  <Box
    sx={{
      width: "100%", // Adjust width if needed
      maxWidth: "600px", // Optional: Max width for the feedback box
      height: "auto", // Auto height based on content
      mb:1,
      display: "flex", // Make sure to use flex for alignment inside Box
      alignItems: "center", 
      justifyContent: "center", 
      mx: "auto", // This can be optional with flexbox, but adds margin to center it
      alignSelf: "center", 
      mt:1, // Optional: Adjust margin top
    }}
  >
<StarRating 
  StarIcon={StarIcon} 
  handleRatingChange={handleRatingChange}
  value={feedbackData.rating} // Pass only the rating value, not the entire object
/>  
  </Box>
  
<Stack spacing={0} direction="column" sx={{width:'100%', display:'flex', flexDirection:'column',height:'100%', padding:1.35, display:'flex', mt:-.5, mt:2 }}> 
<Typography 
         color="textSecondary"
      sx={{ fontSize: ".93rem", fontWeight: 600, px:.5, mb:-1 }}>
       Care to leave us any other comments?
      </Typography>

<TextField
        sx={{
          px:.5,
          marginBottom: 1,
          border:'none',
          "& textarea": {
            minHeight: "110px", // Set the minimum height of the textarea
          },
          "& .MuiOutlinedInput-root": {
            border:'none',  
            "&:hover": {
              borderColor: "none", // Remove the border on hover
            },
          },
        
        }}
        
            name="strength"
            multiline
            fullWidth
            margin="normal"
            size="small"
            variant="outlined"
            value={feedbackData.strength}
            onChange={handleChange}
            rows={1}
          />

</Stack>  
        </DialogContent>
        <DialogActions
  sx={{
    backgroundColor: "#FFFFFF",
    width: "100%", // Use 100% width to ensure it's stretching the entire dialog width
    justifyContent: "flex-end", // Align buttons to the far right
    padding:3,
    px:5
  }}
>
  <Stack
    spacing={3}
    direction="row"
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end", // Ensures the buttons are aligned to the right
      width: "auto", // Let the Stack only take up as much space as needed
      height: "auto",
      mb:2,
    }}
  >
    <CancelButton
      sx={{
        fontSize: "16px",
        mb: 1,
        height: "51px",
        width: "auto", // Allow the width to adjust based on the content
        px:5

        
      }}
      onClick={onClose}
    >
      Cancel
    </CancelButton>
    <PrimaryButton
      sx={{
        height: "51px",
        fontSize: "18px",
        mb: 1,
        width: "auto", // Allow the width to adjust based on the content
        px:5
        
      }}
      onClick={handleSubmit}
      variant="contained"
      disabled={!feedbackData.rating || isLoading} // Disable if rating is empty
    >
      {isLoading ? "Submitting..." : "Submit"}
    </PrimaryButton>
  </Stack>
</DialogActions>
      </StyledDialog>
    </div>
  );
};

export default LeaveFeedbackDialog;
