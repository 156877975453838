import React from "react";
import { Card, Typography } from "@mui/material";
import { styled } from "@mui/system";

const ExportDataHeader = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1.2rem",
  marginBottom: "1rem",
  textAlign: "left",
});

const ExportDataCard = styled(Card)({
  padding: "1rem",
  width: "100%",
  marginBottom: "1rem",
  border: "1px solid lightgray",
});

function ExportData() {
  return (
    <div style={{ width: "100%" }}>
      <ExportDataHeader>Export Data</ExportDataHeader>
      <ExportDataCard>{/* Content will go here */}</ExportDataCard>
    </div>
  );
}

export default ExportData;
