import React, { useState, useEffect } from "react";
import { Typography, Radio, Box, Chip, Stack } from "@mui/material";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";

const FieldSelector = ({
  onSelectField,
  includeCustomMessageBox,
  generateColor,
  closePopover,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [valueType, setValueType] = useState("");

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);

    let newValueType;
    switch (selectedValue) {
      case "STR":
        newValueType = "STR";
        break;
      default:
        newValueType = "INT";
        break;
    }
    setValueType(newValueType);

    onSelectField(selectedValue, newValueType);
    closePopover();
  };

  return (
    <Box
      sx={{
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
        backgroundColor: "transparent",
        borderRadius: "6px",
        margin: -1,
      }}
    >
      {/* Radio Buttons for Field Selection */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid #9AB4C0",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          borderBottomColor: "#C8D6DD",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
        }}
      >
        <Typography className="field_select_title" sx={{ color: "#48768C" }}>
          Include Price / Cost
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "15px",
            color: "#747C86",
            flex: "1",
            textAlign: "right",
          }}
        >
          $0.00
        </Typography>

        <Radio
          checked={selectedValue === "CUR"}
          onChange={handleChange}
          value="CUR"
          name="radio-buttons"
          size="small"
          sx={{ justifyContent: "flex-end", ml: 0.5 }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid #9AB4C0",
          borderBottomColor: "#C8D6DD",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderLeft: "none",
          borderRight: "none",
        }}
      >
        <Typography
          className="field_select_title"
          sx={{ color: "#48768C", fontSize: "11px" }}
        >
          Select a Date{" "}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Typography
          sx={{
            marginLeft: "auto",
            alignContent: "center",
            mt: 0.25,
            fontWeight: 600,
            fontSize: "13.5px",
            color: "#747C86",
            ml: 2,
            mr: 1,
          }}
        >
          MM/DD/YYYY
        </Typography>
        <CalendarMonthTwoToneIcon className="change_field_icon" />

        <Radio
          checked={selectedValue === "DATE"}
          onChange={handleChange}
          value="DATE"
          name="radio-buttons"
          size="small"
          sx={{ justifyContent: "flex-end", ml: 0.5 }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid #9AB4C0",
          borderBottomColor: "#C8D6DD",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
        }}
      >
        <Typography className="field_select_title" sx={{ color: "#48768C" }}>
          Select a Time
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "auto",
            alignItems: "center",
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "13.5px",
              color: "#747C86",
              ml: 1,
              mr: 1,
              whiteSpace: "noWrap",
            }}
          >
            HH:MM AM/PM
          </Typography>
          <QueryBuilderTwoToneIcon className="change_field_icon" />
        </Box>
        <Radio
          checked={selectedValue === "TIME"}
          onChange={handleChange}
          value="TIME"
          name="radio-buttons"
          size="small"
          sx={{ ml: 0.5 }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid #9AB4C0",
          borderBottomColor: "#C8D6DD",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
        }}
      >
        <Typography
          className="field_select_title"
          sx={{ color: "#48768C", mr: 3 }}
        >
          Select a Date & Time{" "}
        </Typography>
        <Typography
          sx={{
            marginRight: "auto",
            alignContent: "center",
            mt: 0.25,
            fontWeight: 600,
            fontSize: "13.5px",
            color: "#747C86",
            ml: 2,
            mr: 1,
          }}
        >
          MM/DD/YYYY
        </Typography>
        <CalendarMonthTwoToneIcon className="change_field_icon" />
        <Typography
          sx={{
            marginRight: "auto",
            alignContent: "center",
            mt: 0.25,
            fontWeight: 600,
            fontSize: "13.5px",
            color: "#747C86",
            ml: 1,
            mr: 1,
            whiteSpace: "noWrap",
          }}
        >
          HH:MM AM/PM
        </Typography>
        <QueryBuilderTwoToneIcon className="change_field_icon" />
        <Radio
          checked={selectedValue === "DATE/TIME"}
          onChange={handleChange}
          value="DATE/TIME"
          name="radio-buttons"
          size="small"
          sx={{
            justifyContent: "flex-end",
            ml: 0.5,
            color: "#747C86",
            "&.Mui-checked": {
              color: "#747C86",
            },
          }}
        />
      </Box>
      {includeCustomMessageBox && (
        <Box
          sx={{ display: "flex", width: "100%", border: "1px solid #9AB4C0" }}
        >
          <Typography className="field_select_title" sx={{ color: "#48768C" }}>
            Custom Message Box
          </Typography>
          <Radio
            checked={selectedValue === "STR"}
            onChange={handleChange}
            value="STR"
            name="radio-buttons"
            size="small"
            sx={{ justifyContent: "flex-end", ml: 0.5 }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid #9AB4C0",
          borderBottomLeftRadius: "6px",
          borderBottomRightRadius: "6px",
          pt: "1px",
          pb: "1px",
          borderBottomColor: "#C8D6DD",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
        }}
      >
        <Typography className="field_select_title" sx={{ color: "#48768C" }}>
          Length of Time{" "}
        </Typography>
        <Stack direction="row" spacing={1} style={{ marginLeft: "auto" }}>
          <Chip
            label="Days"
            sx={{ fontWeight: 600, fontSize: "12px", color: "#747C86" }}
          />
          <Chip
            label="Hours"
            sx={{ fontWeight: 600, fontSize: "12px", color: "#747C86" }}
          />
          <Chip
            label="Minutes"
            sx={{ fontWeight: 600, fontSize: "12px", color: "#747C86" }}
          />
        </Stack>
        <Radio
          checked={selectedValue === "TMR"}
          onChange={handleChange}
          value="TMR"
          name="radio-buttons"
          size="small"
          sx={{ ml: 0.5 }}
        />
      </Box>
    </Box>
  );
};

export default FieldSelector;
