import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  Button,
  Box,
  TableCell,
} from "@mui/material";
import { styled } from "@mui/system";

const InfoCard = styled(Card)({
  padding: "1rem",
  width: "100%",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "space-between",
  justifyContent: "space-between",
  backgroundColor: "rgba(191, 218, 231, 0.1)",
  border: "1px solid rgba(173, 216, 230, 0.7)",
});

function UpgradeAccountCard({ title, message, buttonAction }) {
  return (
    <InfoCard sx={{ mb: 6, mt: 4, width: "100%" }}>
      <Typography
        variant="h6"
        style={{
          marginBottom: "5px",
          color: "#48768C",
          fontWeight: 600,
          fontSize: 16.5,
          marginBottom: 2,
        }}
      >
        {title}
      </Typography>
      <TableContainer style={{ borderCollapse: "collapse" }}>
        <Table size="small" sx={{ borderCollapse: "collapse" }}>
          <TableHead>
            <TableRow sx={{ color: "#7C8795", fontWeight: 600, fontSize: 13 }}>
              <TableCell
                sx={{ backgroundColor: "transparent", borderBottom: "none" }}
              >
                {message}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <Button
          variant="contained"
          onClick={buttonAction}
          sx={{
            fontSize: 13.5,
            justifyContent: "center",
            color: "#48768C",
            mt: 2,
            mr: 0.5,
            p: "7px",
            backgroundColor: "#FFFFFF",
            whiteSpace: "nowrap",
            border: ".5px solid #B0BEC5",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "#DBEAF0",
            },
            "&:active": {
              backgroundColor: "#C0D0D6", // Adjust active state color if needed
            },
          }}
        >
          Upgrade Account
        </Button>
      </Box>
    </InfoCard>
  );
}

export default UpgradeAccountCard;
