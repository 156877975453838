import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Modal,
  TextField,
  Box,
  TableFooter,
  Divider,
  Paper,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  InputAdornment,
  Stack,
  Fade,
} from "@mui/material";
import { styled } from "@mui/system";
import AddUserModal from "../../../modal/AddUserModal.js";
import AddLocationModal from "../../../modal/AddNewLocation/AddLocationModal.js";
import { useAuth0 } from "@auth0/auth0-react";
import EditUsersModal from "../../../modal/EditUsersModal.js";
import EditLocationModal from "../../../modal/EditLocation/EditLocationModal.js";
import ChangePinModal from "../../../modal/ChangePinModal.js";
import DeleteConfirmationDialog from "../../../modal/DeleteConfirmationDialog.js";
import DeleteDialog from "../../../modal/DeleteDialog.js";
import { useAccountContext } from "src/context/AccountContext";
import addIcon from "./../../../../public/assets/icons/icons/add.png";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../../../public/assets/icons/icons/help-center/edit.png";
import DeleteIcon from "../../../../public/assets/icons/icons/delete.png";
import DeleteDisabledIcon from "../../../../public/assets/icons/icons/deleteDisabled.png";
import { useAppContext } from "../../../../context/AppContext.js";
import { useAuth } from "../../../../context/AuthContext.js";
import { first, set } from "lodash";
import ViewUsersModal from "../../../modal/ViewUsersModal.js";
import ViewLocationModal from "../../../modal/ViewLocation/ViewLocationModal.js";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { sub } from "date-fns";
import MultiLocationUpgrade from "../../../Account-Upgrades/components/MultiLocationUpgrade/multiLocationUpgrade.js";
import MultiUserUpgrade from "../../../Account-Upgrades/components/MultiUserUpgrade/multiUserUpgrade.js";
import DowngradeDialog from "./downgradeDialog.js";
import ReviewDialog from "src/components/modal/ReviewDialog/ReviewDialog";
import useAddNewUser from "src/components/payments/hooks/useAddNewUser.js";
import useDeleteUser from "src/components/payments/hooks/useDeleteUser.js";
import useDeleteLocation from "src/components/payments/hooks/useDeleteLocation.js";
import ErrorModal from "src/components/modal/ErrorModal.js";
import { Icon as Iconify } from "@iconify/react";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  AddButton,
  DeleteButton,
  EditButton,
} from "src/components/Buttons/AddDeleteEditButtons.js";
import UpgradeAccountCard from "../../AccountManagementComponents/UpgradeAccountCard.js";

const AccountMgmtHeader = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1.2rem",
  marginBottom: "1rem",
  textAlign: "left",
});

const InfoCard = styled(Card)({
  padding: "1rem",
  width: "100%",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "space-between",
  justifyContent: "space-between",
  backgroundColor: "rgba(191, 218, 231, 0.1)",
  border: "1px solid rgba(173, 216, 230, 0.7)",
});

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

function AccountManagement({
  fetchUsers,
  loadingUsers,
  adminCount,
  users,
  loadingLocations,
  locations,
  setLocations,
  fetchLocations,
  setLoadingLocations,
  totalUsers,
  setTotalUsers,
  totalLocations,
  setTotalLocations,
  handleClearWarnings,
  warnings,
  setIsErrorModalOpen,
  setErrorHeader,
  errorMessage,
  setErrorMessage,
  setMessageSubText,
  locationToDelete,
  setLocationToDelete,
  cardTitle,
  cardSubTitle,
  handleSubscriptionUpdate,
}) {
  const { username, subUserData, setSubUserData } = useAuth();
  const { accountData, handleCloseSuccessDialog } = useAccountContext(); // Use the accountData from the context
  const { user, getAccessTokenSilently } = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [
    isLocationDeleteConfirmationOpen,
    setIsLocationDeleteConfirmationOpen,
  ] = useState(false);
  const [deleteSelfDialogOpen, setDeleteSelfDialogOpen] = useState(false);
  const [selectedUserForView, setSelectedUserForView] = useState(null);
  const [multiUserUpgradeDialogOpen, setMultiUserUpgradeDialogOpen] =
    useState(false);
  const [isDowngradeDialogOpen, setIsDowngradeDialogOpen] = useState(false);
  const [downgradeDialogTitle, setDowngradeDialogTitle] = useState("");
  const [dialogSubText, setDialogSubText] = useState("");
  const [downgradeError, setDowngradeError] = useState("");
  const { deleteUser, deleteUserLoading, deleteUserError } = useDeleteUser();
  const [isLoading, setIsLoading] = useState(false);
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const { deleteLocation, deleteLocationLoading, deleteLocationError } =
    useDeleteLocation();
  const [isDowngradingToMultiUser, setIsDowngradingToMultiUser] =
    useState(false);
  const [deleteHeader, setDeleteHeader] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteSubText, setDeleteSubText] = useState("");
  const [deleteButtonText, setDeleteButtonText] = useState("");
  const [deleteButtonAction, setDeleteButtonAction] = useState(null);
  const [deletedLocation, setDeletedLocation] = useState(false);
  const [deletedUser, setDeletedUser] = useState(false);
  const [addedUser, setAddedUser] = useState(false);
  const [searchedUserValue, setSearchedUserValue] = useState("");
  const [searchedUsers, setSearchedUsers] = useState(users);
  const [searchedLocations, setSearchedLocations] = useState(locations);
  const [searchedLocationValue, setSearchedLocationValue] = useState("");

  const [price, setPrice] = useState(null);

  const handleOpenDeleteDialog = async () => {
    setPrice(null);
    try {
      // Get access token from Auth0
      const accessToken = await getAccessTokenSilently();

      // Make API request to check location users and get price
      const checkResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/subscriptions/plans/checkDeleteLocationUsers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ auth0UserId: user.sub }), // Send user ID to check locations and users
        },
      );

      const checkData = await checkResponse.json();

      // Set the price based on the response
      setPrice(checkData.price);

      // Wait for the price to be set before opening the review dialog
      setIsDeleteConfirmationOpen(true);
    } catch (error) {
      console.error("Error fetching location and user data:", error);
      showSnackBar("Unable to check user/locations. Please try again", false);
    }
  };

  const handleDowngradeDialogOpen = () => {
    setIsDowngradeDialogOpen(true);
  };

  const handleDowngradeDialogClose = () => {
    setIsDowngradeDialogOpen(false);
    setIsDowngradingLocation(false);
  };

  const handleDeleteConfirmationOpen = (
    userId,
    username,
    first_name,
    last_name,
    title,
  ) => {
    setItemToDelete({
      user_id: userId,
      username: username,
      first_name: first_name,
      last_name: last_name,
      title: title,
    });

    // Check if totalUsers equals totalLocations
    if (totalUsers <= 2 && !accountData.single_location) {
      setIsErrorModalOpen(true);
      setErrorMessage("");
      setErrorHeader("Hang on a second...");
      setMessageSubText(
        "Multi-Location accounts must have at least two active users",
      );
      setIsLoading(false);
      return; // Stop further execution
    }

    // If totalUsers >= 3, open the delete confirmation dialog
    if (totalUsers >= 3) {
      setAddedUser(false);
      setDeletedUser(true);
      handleOpenDeleteDialog();
    } else {
      // If fewer than 3 users, show downgrade dialog with a different message
      setDowngradeDialogTitle(
        "Deleting this user will downgrade your account to a single-user account.",
      );
      setDialogSubText("Are you sure you want to continue?");
      setIsDowngradingToMultiUser(true);

      setIsDowngradeDialogOpen(true);
    }
  };

  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false);
    setItemToDelete(null);
  };

  const [locationUpgradeDialogOpen, setLocationUpgradeDialogOpen] =
    useState(false);

  const handleLocationUpgradeDialogOpen = () => {
    setLocationUpgradeDialogOpen(true); // Corrected function call
  };

  const handleLocationUpgradeDialogClose = () => {
    setLocationUpgradeDialogOpen(false); // Corrected function call
  };

  const [userUpgradeDialogOpen, setUserUpgradeDialogOpen] = useState(false);

  const handleUserUpgradeDialogOpen = () => {
    setUserUpgradeDialogOpen(true); // Corrected function call
  };

  const handleUserUpgradeDialogClose = () => {
    setUserUpgradeDialogOpen(false); // Corrected function call
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    assigned_location: "",
    is_admin: "",
    title: "",
    user_id: "",
  });

  const [isViewUserModalOpen, setIsViewUserModalOpen] = useState(false);
  const [viewData, setViewData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    assigned_location: "",
    is_admin: "",
    title: "",
  });

  const handleEditOpen = (user) => {
    if (!user || !user.user_id) {
      console.error("Invalid user data for editing");
      return;
    }

    setSelectedUserForEdit(user);
    setEditData({
      first_name: user.first_name,
      last_name: user.last_name,
      username: user.username,
      assigned_location: user.assigned_location,
      is_admin: user.is_admin,
      title: user.title, // Use the correct property name
      user_id: user.user_id,
    });
    setUserNameTaken(false); // Reset the UI state

    setIsEditModalOpen(true);
  };

  const handleEditClose = () => {
    setIsEditModalOpen(false);
    setSelectedUserForEdit(null);
    setEditData({
      first_name: "",
      last_name: "",
      username: "",
      assigned_location: "",
      is_admin: "",
      title: "",
    });
  };

  const handleViewUserOpen = (user) => {
    if (!user || !user.user_id || totalLocations === 1) {
      console.error("Invalid user data for editing");
      return;
    }

    setSelectedUserForView(user);
    setViewData({
      first_name: user.first_name,
      last_name: user.last_name,
      username: user.username,
      assigned_location: user.assigned_location,
      is_admin: user.is_admin,
      title: user.title, // Use the correct property name
      user_id: user.user_id,
    });

    setIsViewUserModalOpen(true);
  };

  const handleViewClose = () => {
    setIsViewUserModalOpen(false);
    setSelectedUserForView(null);
    setViewData({
      first_name: "",
      last_name: "",
      username: "",
      assigned_location: "",
      is_admin: "",
      title: "",
    });
  };

  const [userNameTaken, setUserNameTaken] = useState(false);
  const [editUserName, setEditUserName] = useState("");

  const handleEditSave = async () => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/api/users/${selectedUserForEdit.user_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            firstName: editData.first_name,
            lastName: editData.last_name,
            newUserName: editData.username,
            assignedLocation: editData.assigned_location,
            isAdmin: editData.is_admin,
            title: editData.title,
            userName: username,
            auth0UserId: user.sub,
            editUserName: editUserName,
            // Add other properties as needed
          }),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.usernameTaken) {
          setUserNameTaken(true); // Set the UI state to show the error
          showSnackBar("Username is already taken", false);
          setIsLoading(false);
          return; // Stop further execution to preserve form state
        } else {
          showSnackBar("Unable to add user. Please try again", false);
          setIsLoading(false);
        }
        throw new Error("Error adding user");
      }

      const responseData = await response.json();

      if (response.ok) {
        fetchUsers(); // Refresh the user list
        handleEditClose(); // Close the edit modal

        // Fetch updated user data
        const updatedUserDataResponse = await fetch(
          `${apiUrl}/api/users/profile/${user.sub}/${username}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Include access token in headers
            },
          },
        );
        const updatedUserData = await updatedUserDataResponse.json();

        showSnackBar("User updated successfully", true);
        setSubUserData(updatedUserData);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.error("Failed to update location");
        showSnackBar("Failed to update user. Please try again later.", false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
      showSnackBar(
        "An unexpected error occurred. Please try again later.",
        false,
      );
    }
  };

  const [isLastUserConfirmationOpen, setIsLastUserConfirmationOpen] =
    useState(false);

  const handleDeleteConfirmation = async () => {
    try {
      setIsLoading(true);

      const { user_id, username } = itemToDelete; // Assuming itemToDelete is defined elsewhere
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      // Fetch the list of users
      const response = await fetch(`${apiUrl}/api/all-users/${user.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      const usersData = Array.isArray(data.users) ? data.users : [];

      setTotalUsers(usersData.length);

      // Check if there are exactly 2 users left
      if (usersData.length === 2) {
        setIsLoading(false);

        setIsDowngradeDialogOpen(true);

        return;
      }

      // Check if there is only one user left (optional: separate logic)
      if (usersData.length <= 1) {
        setIsLoading(false);

        setIsLastUserConfirmationOpen(true);

        return;
      }
      const userToDelete = user_id;
      const deleteResponse = await deleteUser(
        userToDelete,
        totalLocations,
        totalUsers,
      );

      if (deleteResponse.success) {
        handleSubscriptionUpdate();
        showSnackBar("User deleted successfully", true);
        fetchUsers(); // Refresh user list after deletion
        handleSubscriptionUpdate(); // Refresh subscription data after deletion
        setIsDeleteConfirmationOpen(false);
      } else {
        showSnackBar(deleteResponse.error || "Failed to delete user", false);
        setErrorMessage(
          deleteResponse.error ||
            "Failed to delete user. Please try again later.",
        );
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      showSnackBar("An unexpected error occurred", false);
      setErrorMessage(
        error.message ||
          "An unexpected error occurred. Please try again later.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddUser = () => {
    if (warnings.shouldAddUser || warnings.shouldAddUserLocation) {
      handleClearWarnings();
    }
    setIsAddUserModalOpen(true);
  };

  const handleAddUserModalClose = () => {
    setIsAddUserModalOpen(false);
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        if (user && user.sub) {
          const apiUrl = process.env.REACT_APP_API_URL;
          const accessToken = await getAccessTokenSilently();

          const response = await fetch(`${apiUrl}/api/locations/${user.sub}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const data = await response.json();

          const locationsData = Array.isArray(data.rows) ? data.rows : [];
          setLocations(data.rows);
          setLoadingLocations(false);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, [getAccessTokenSilently, user]);

  const [selectedLocationForEdit, setSelectedLocationForEdit] = useState(null);
  const [error, setError] = useState(null);

  const [isViewLocationModalOpen, setIsViewLocationModalOpen] = useState(false);
  const [viewLocationData, setViewLocationData] = useState({
    name: "",
    email: "",
    phone: "",
    location_id: "",
  });

  const handleViewLocationOpen = (location) => {
    setSelectedLocationForEdit(location);
    setEditLocationData({
      name: location.name,
      email: location.email,
      phone: location.phone,
      location_id: location.location_id,
    });
    setIsViewLocationModalOpen(true);
  };

  const handleViewLocationClose = () => {
    setIsViewLocationModalOpen(false);
    setViewLocationData({
      name: "",
      email: "",
      phone: "",
    });
  };

  const handleEditLocationSave = async () => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/api/locations/${selectedLocationForEdit.location_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(editLocationData),
        },
      );

      if (response.ok) {
        showSnackBar("Location updated successfully", true);
        setIsEditLocationModalOpen(false);
        fetchLocations(); // Fetch updated locations
        fetchUsers(); // Fetch updated users
        setIsLoading(false);
      } else {
        console.error("Failed to update location");
        showSnackBar(
          "Failed to update location. Please try again later.",
          false,
        );
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error updating location:", error);
      showSnackBar(
        "An unexpected error occurred. Please try again later.",
        false,
      );
      setIsLoading(false);
    }
  };

  const handleEditLocationOpen = (location) => {
    setSelectedLocationForEdit(location);
    setEditLocationData({
      name: location.name,
      email: location.email,
      phone: location.phone,
      location_id: location.location_id,
    });
    setIsEditLocationModalOpen(true);
  };

  const [isEditLocationModalOpen, setIsEditLocationModalOpen] = useState(false);
  const [editLocationData, setEditLocationData] = useState({
    name: "",
    email: "",
    phone: "",
    location_id: "",
  });

  const handleEditLocationClose = () => {
    setIsEditLocationModalOpen(false);
    setSelectedLocationForEdit(null);
    setEditLocationData({
      name: "",
      email: "",
      phone: "",
    });
  };

  const [isDowngradingLocation, setIsDowngradingLocation] = useState(false);

  const handleDeleteLocation = (location) => {
    if (!location) {
      setIsLocationDeleteConfirmationOpen(false);
      console.error("Invalid location data for deletion");
      return; // Stop further execution if location is invalid
    }

    // Check if totalLocations is 2 first

    // Proceed with location delete confirmation for other cases
    setLocationToDelete(location);
    setIsLocationDeleteConfirmationOpen(true);
  };

  const handleDeleteLocationConfirmation = async (locationToDelete) => {
    try {
      if (!locationToDelete || !locationToDelete.location_id) {
        console.error("Invalid location data for deletion");
        return;
      }

      setLocationToDelete(locationToDelete);
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      // Fetch users assigned to any location
      const response = await fetch(`${apiUrl}/api/all-users/${user.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const userData = await response.json();

      // Check if any user is assigned to the location to be deleted
      const usersInLocation = userData.users.filter(
        (user) => user.active_location === locationToDelete.location_id,
      );
      if (usersInLocation.length > 0 && totalLocations >= 2) {
        setIsErrorModalOpen(true);
        setErrorHeader("Hang on a second...");
        setErrorMessage(
          `${locationToDelete.name} still has employees assigned to it.`,
        );
        setMessageSubText(
          " You'll need to assign those employees to another location before this location can be deleted.",
        );
        setIsLoading(false);
        return;
      }

      // Check if only two locations are left
      if (
        (totalLocations <= 2 && usersInLocation.length === 0) ||
        totalLocations === 1
      ) {
        setIsLocationDeleteConfirmationOpen(false);
        setDowngradeDialogTitle(
          "Deleting this location will downgrade your account to a single-location account.",
        );
        setDialogSubText("Are you sure you want to continue?");
        setDowngradeError(false);
        setIsDowngradingLocation(true);
        setIsDowngradeDialogOpen(true);
        setIsLoading(false);
      } else {
        setDeletedLocation(true);
        setIsLocationDeleteConfirmationOpen(true);

        setIsLoading(false);

        return;
      }
    } catch (error) {
      console.error("Error handling delete confirmation:", error);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      setIsLoading(false);
    }
  };

  const handleConfirmDeleteLocation = async (locationToDelete) => {
    setIsLoading(true);

    try {
      if (!locationToDelete || !locationToDelete.location_id) {
        throw new Error("Invalid location data. Please try again.");
      }

      const deleteResponse = await deleteLocation(locationToDelete.location_id);

      if (deleteResponse.success) {
        handleSubscriptionUpdate();
        showSnackBar("Location deleted successfully", true);
        fetchLocations(); // Refresh location list after deletion
        setLocationToDelete(null);
        setIsLocationDeleteConfirmationOpen(false);
      } else {
        showSnackBar(
          deleteResponse.error || "Failed to delete location",
          false,
        );
        setErrorMessage(
          deleteResponse.error ||
            "Failed to delete location. Please try again later.",
        );
      }
    } catch (error) {
      console.error("Error deleting location:", error);
      showSnackBar("An unexpected error occurred", false);
      setErrorMessage(
        error.message ||
          "An unexpected error occurred. Please try again later.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddLocation = () => {
    if (warnings.shouldAddLocation || warnings.shouldAddUserLocation) {
      handleClearWarnings();
    }
    setIsAddLocationModalOpen(true);
  };

  const handleAddLocationModalClose = () => {
    setIsAddLocationModalOpen(false);
  };

  const LastTableRow = styled(TableRow)({
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
  });

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0) : "";
    const lastInitial = lastName ? lastName.charAt(0) : "";
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const [multiLocationUpgradeDialogOpen, setMultiLocationUpgradeDialogOpen] =
    useState(false);
  const [isDialogClosing, setIsDialogClosing] = useState(false);

  const handleMultiLocationUpgradeDialogOpen = () => {
    setMultiLocationUpgradeDialogOpen(true);
  };

  const handleMultiLocationUpgradeDialogClose = () => {
    setMultiLocationUpgradeDialogOpen(false);
  };

  const handleMultiUserUpgradeDialogOpen = () => {
    setMultiUserUpgradeDialogOpen(true);
  };

  const handleMultiUserUpgradeDialogClose = () => {
    setMultiUserUpgradeDialogOpen(false);
  };

  const Header = styled(Typography)({
    width: "100%",
    textAlign: "left",
    letterSpacing: "-.02em",
  });

  useEffect(() => {
    if (
      isViewLocationModalOpen ||
      isEditLocationModalOpen ||
      isDowngradeDialogOpen ||
      isDeleteConfirmationOpen ||
      isLocationDeleteConfirmationOpen ||
      isAddLocationModalOpen ||
      isAddUserModalOpen ||
      isEditModalOpen ||
      isViewUserModalOpen ||
      isDowngradingLocation ||
      isLastUserConfirmationOpen ||
      multiLocationUpgradeDialogOpen ||
      multiUserUpgradeDialogOpen
    ) {
      setIsErrorModalOpen(false);
    }
  }, [
    isViewLocationModalOpen,
    isEditLocationModalOpen,
    isDowngradeDialogOpen,
    isDeleteConfirmationOpen,
    isLocationDeleteConfirmationOpen,
    isAddLocationModalOpen,
    isAddUserModalOpen,
    isEditModalOpen,
    isViewUserModalOpen,
    isDowngradingLocation,
    isLastUserConfirmationOpen,
    multiLocationUpgradeDialogOpen,
    multiUserUpgradeDialogOpen,
  ]);

  useEffect(() => {
    if (users && users.length > 0) {
      setSearchedUsers(users); // Initialize with all users
    }
  }, [users]);

  const searchByEmployeeName = (e) => {
    const value = e.target.value;
    setSearchedUserValue(value);

    const filteredUsers = users.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`;
      return fullName.toLowerCase().includes(value.toLowerCase());
    });

    setSearchedUsers(filteredUsers);
  };

  useEffect(() => {
    if (locations && locations.length > 0) {
      setSearchedLocations(locations); // Initialize with all locations
    }
  }, [locations]);

  const searchByLocationName = (e) => {
    const value = e.target.value;
    setSearchedLocationValue(value); // Update the input field value

    if (!value.trim()) {
      setSearchedLocations(locations); // Reset to full list if input is cleared
      return;
    }

    const filteredLocations = locations.filter((location) =>
      location.name.toLowerCase().includes(value.toLowerCase()),
    );

    setSearchedLocations(filteredLocations); // Update with filtered locations
  };

  const handleClearSearchedUsers = () => {
    setSearchedUserValue("");
    setSearchedUsers(users);
  };

  const handleClearSearchedLocations = () => {
    setSearchedLocationValue("");
    setSearchedLocations(locations);
  };

  return (
    <Box sx={{ width: "100%", overflowY: "hidden" }}>
      <Header
        sx={{
          color: "#48768C",
          fontWeight: 700,
          fontSize: 18.5,
          letterSpacing: "-.02em",
        }}
      >
        Users and Locations
      </Header>
      <Header sx={{ color: "#48768C", fontSize: ".9rem" }}>
        Manage your team and locations
      </Header>
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          minHeight: "50vh",
          maxHeight: "75vh",
          mt: 1,
          borderRadius: "10px",
        }}
      >
        <Box>
          {accountData.single_user && ( // Hide the InfoCard if single_user is false
            <UpgradeAccountCard
              title="Your Active Employees"
              message="Your account was created as a single user account. Upgrade your plan to add more users."
              buttonAction={handleMultiUserUpgradeDialogOpen}
            />
          )}

          {!accountData.single_user && ( // Add this condition to hide the InfoCard if single_user is true
            <InfoCard sx={{ mt: 1, width: "100%" }}>
              {/* Top row containing "Manage your users" */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                  mt: -1,
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: -1,
                    width: "100%",
                    pl: "2px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#48768C",
                      fontWeight: 600,
                      fontSize: "16.5px",
                      letterSpacing: "-.015em",
                    }}
                  >
                    Your Active Employees
                  </Typography>
                  <Box
                    sx={{
                      padding: "5px",
                      pl: "10px",
                      borderRadius: "26px",
                      mr: 1.5,
                      ml: "auto",
                      mt: "2px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      placeholder="Search by employee name"
                      value={searchedUserValue}
                      onChange={searchByEmployeeName}
                      sx={{
                        width: "100%",
                        color: "#2A3D4F",

                        "& .MuiOutlinedInput-root": {
                          height: "35px",
                          fontSize: "14px",
                          width: "250px",
                          border: "1px solid #c2d7df",
                          borderRadius: "8px", // Maintain rounded corners
                          backgroundColor: "#FFFFFF", // Set the background color
                          "&:hover": {
                            borderColor: "#90A4AE", // Border color on hover
                          },
                          "&.Mui-focused": {
                            borderColor: "#48768C", // Border color on focus
                            borderWidth: "2px",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Remove default MUI fieldset border
                        },
                        "& input::placeholder": {
                          marginLeft: "8px", // Match placeholder's padding
                          color: "#48768C",
                          lineHeight: 0,
                          fontWeight: 500,
                          fontSize: "13.75px",
                          fontFamily: "Source Sans Pro, sans-serif",
                          opacity: 0.88,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "#2A3D4F", fontSize: "14px" }, // Updated label color
                      }}
                      InputProps={{
                        style: { color: "#2A3D4F" }, // Updated text color to white
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon="bi:search"
                              style={{ color: "#90A4AE" }}
                            />{" "}
                            {/* Updated icon color */}
                          </InputAdornment>
                        ),
                        endAdornment: searchedUserValue && (
                          <InputAdornment
                            position="end"
                            sx={{ marginRight: "3px" }}
                          >
                            <Fade in={searchedUserValue} timeout={200}>
                              <IconButton
                                onClick={handleClearSearchedUsers}
                                edge="end"
                                sx={{ color: "#6595ab", padding: 0 }}
                              >
                                <CancelOutlinedIcon
                                  sx={{ fontSize: 20, opacity: 0.85 }}
                                />
                              </IconButton>
                            </Fade>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  {subUserData.is_admin && ( // Show the Add User button only if the user is an admin}
                    <AddButton text="Add Employee" onClick={handleAddUser} />
                  )}
                </Stack>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Paper
                  elevation={3}
                  sx={{
                    backgroundColor: "rgba(191, 218, 231, 0.1)",
                    borderRadius: "10px",
                  }}
                >
                  <TableContainer
                    sx={{
                      padding: 1.5,
                      border: "1px solid rgba(173, 216, 230, 0.7)",
                      borderRadius: "10px",
                      minHeight: "10vh",
                      maxHeight: "30vh",
                    }}
                  >
                    <Table size="small">
                      <TableBody>
                        {Array.isArray(searchedUsers) &&
                        searchedUsers.length > 0 ? (
                          searchedUsers.map((user) => (
                            <TableRow
                              key={user.user_id}
                              sx={{
                                borderRadius: "40px",
                                cursor: "pointer",
                                justifyContent: "space-between",
                                borderBottom: "none",
                                "&:hover": {
                                  backgroundColor: "#EAF6FF",
                                },
                              }}
                            >
                              <TableCell
                                onClick={() => handleViewUserOpen(user)}
                                sx={{
                                  padding: "10px",
                                  justifyContent: "space-between",
                                  borderBottom: "none",
                                  cursor:
                                    totalLocations > 1 ? "pointer" : "default", // Change cursor based on totalLocations

                                  "&:hover": { backgroundColor: "#EAF6FF" },
                                }}
                              >
                                <Avatar
                                  sx={{
                                    color: "#48768C",
                                    fontSize: "15px",
                                    width: 40,
                                    height: 40,
                                    background:
                                      "radial-gradient(circle, #EEF6F9, #FFFFFF)",
                                    border:
                                      "2px solid rgba(173, 216, 230, 0.9)",
                                    borderRadius: "20px",
                                    padding: "4px",
                                    boxShadow:
                                      "0px 1px 1px rgba(0, 0, 0, 0.01)",
                                    marginRight: 1,
                                    fontWeight: 600,
                                  }}
                                >
                                  {getInitials(user.first_name, user.last_name)}
                                </Avatar>
                              </TableCell>
                              <TableCell
                                onClick={() => handleViewUserOpen(user)}
                                sx={{
                                  fontSize: 14,
                                  fontWeight: "bold",
                                  color: "#48768C",
                                  padding: "10px",
                                  justifyContent: "space-between",
                                  paddingRight: 5,
                                  borderBottom: "none",
                                  cursor:
                                    totalLocations > 1 ? "pointer" : "default", // Change cursor based on totalLocations
                                }}
                              >
                                {user.first_name} {user.last_name}
                              </TableCell>

                              <TableCell
                                onClick={() => handleViewUserOpen(user)}
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 500,
                                  color: "#48768C",
                                  padding: "10px",
                                  textAlign: "left",
                                  justifyContent: "space-between",
                                  paddingRight: 5,
                                  borderBottom: "none",
                                  cursor:
                                    totalLocations > 1 ? "pointer" : "default", // Change cursor based on totalLocations
                                }}
                              >
                                {user.title}
                              </TableCell>
                              {!accountData.single_location && (
                                <TableCell
                                  onClick={() => handleViewUserOpen(user)}
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: "#48768C",
                                    padding: "10px",
                                    textAlign: "left",
                                    justifyContent: "space-between",
                                    paddingRight: 5,
                                    borderBottom: "none",
                                    cursor:
                                      totalLocations > 1
                                        ? "pointer"
                                        : "default", // Change cursor based on totalLocations
                                  }}
                                >
                                  {user.location_name}
                                </TableCell>
                              )}
                              <TableCell
                                onClick={() => handleViewUserOpen(user)}
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 500,
                                  color: "#48768C",
                                  padding: "10px",
                                  textAlign: "left",
                                  pl: "20px",
                                  justifyContent: "space-between",
                                  paddingRight: 5,
                                  borderBottom: "none",
                                  cursor:
                                    totalLocations > 1 ? "pointer" : "default", // Change cursor based on totalLocations
                                }}
                              >
                                {user.is_admin ? "Administrator" : "Employee"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100px",
                                  padding: "10px",
                                  textAlign: "left",
                                  borderBottom: "none",
                                }}
                              >
                                {subUserData.is_admin && (
                                  <IconButton
                                    onClick={() => handleEditOpen(user)}
                                    sx={{ ml: 2 }}
                                  >
                                    <img
                                      src={EditIcon}
                                      alt="detailsIcon"
                                      style={{
                                        border: "1px solid #B0BEC5",
                                        width: "auto",
                                        height: "25px",
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: "6px",
                                        boxShadow:
                                          "0px 1px 1px rgba(0, 0, 0, 0.09)",
                                      }}
                                    />
                                  </IconButton>
                                )}

                                {user.username !== username &&
                                  subUserData.is_admin && (
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteConfirmationOpen(
                                          user.user_id,
                                          user.username,
                                          user.first_name,
                                          user.last_name,
                                          user.title,
                                        )
                                      }
                                      sx={{ ml: 2 }}
                                    >
                                      <img
                                        src={DeleteIcon}
                                        alt="detailsIcon"
                                        style={{
                                          border: "1px solid #B0BEC5",
                                          width: "auto",
                                          paddingTop: ".5px",
                                          height: "25px",
                                          backgroundColor: "#FFFFFF",
                                          borderRadius: "6px",
                                          boxShadow:
                                            "0px 1px 1px rgba(0, 0, 0, 0.09)",
                                        }}
                                      />
                                    </IconButton>
                                  )}
                                {user.username === username &&
                                  subUserData.is_admin && (
                                    <IconButton
                                      disabled
                                      sx={{ ml: 2, alignItems: "center" }}
                                    >
                                      <img
                                        src={DeleteIcon}
                                        alt="detailsIcon"
                                        style={{
                                          border: "1px solid #B0BEC5",
                                          width: "auto",
                                          paddingTop: ".5px",
                                          height: "25px",
                                          backgroundColor: "#F0F0F0",
                                          borderRadius: "6px",
                                          boxShadow:
                                            "0px 1px 1px rgba(0, 0, 0, 0.09)",
                                        }}
                                      />
                                    </IconButton>
                                  )}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7}>
                              {loadingUsers || !users ? (
                                <Box>
                                  <Typography
                                    align="center"
                                    sx={{
                                      background: "transparent",
                                      width: "100%",
                                      padding: "20px",
                                      color: "#447c92",
                                      fontSize: ".8rem",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Loading Your Users...
                                  </Typography>
                                  <LinearProgress
                                    color="secondary"
                                    sx={{
                                      height: 2,
                                      "& .MuiLinearProgress-barColorSecondary":
                                        {
                                          backgroundColor: "#2BBB9E",
                                        },
                                    }}
                                  />
                                </Box>
                              ) : (
                                <Fade in={searchedUsers !== ""} timeout={200}>
                                  <Box sx={{ padding: "20px" }}>
                                    <Typography
                                      style={{
                                        color: "#48768C",
                                        fontWeight: 600,
                                        fontSize: 15,
                                        letterSpacing: "-.015em",
                                      }}
                                    >
                                      No users found
                                    </Typography>
                                  </Box>
                                </Fade>
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </InfoCard>
          )}

          <ViewUsersModal
            isOpen={isViewUserModalOpen}
            onClose={() => setIsViewUserModalOpen(false)}
            viewData={viewData}
            setViewData={setViewData}
            locations={locations}
          />

          <EditUsersModal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            onSave={handleEditSave}
            editData={editData}
            setEditData={setEditData}
            locations={locations}
            adminCount={adminCount}
            loading={isLoading}
            userNameTaken={userNameTaken}
            setUserNameTaken={setUserNameTaken}
            users={users}
            setEditUserName={setEditUserName}
            username={username}
          />

          <DeleteDialog
            open={isDeleteConfirmationOpen}
            onClose={handleDeleteConfirmationClose}
            item={itemToDelete}
            onConfirm={handleDeleteConfirmation}
            isLoading={isLoading}
            header="Confirm Delete User"
            message={`Are you sure you want to delete ${itemToDelete?.first_name || ""} ${itemToDelete?.last_name || ""} from your account?`}
            subText="This action cannot be undone."
            buttonText="Delete User"
            addedUserPrice={`- ${price} /month`}
            addedUser={addedUser}
            deletedUser={deletedUser}
            reviewButtonText="Delete User"
            reviewButtonLoadingText={`Deleting ${itemToDelete ? itemToDelete.username : ""}...`}
            buttonLoadingText="Deleting User..."
            hideReviewDialog={false}
            handleCancel={handleDeleteConfirmationClose}
          />
        </Box>
        {!accountData.single_location && (
          <InfoCard sx={{ mb: 3, mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                mt: -0.5,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: -1,
                  width: "100%",
                  pl: "2px",
                }}
              >
                <Typography
                  style={{
                    color: "#48768C",
                    fontWeight: 600,
                    fontSize: "16.5px",
                    letterSpacing: "-.017em",
                  }}
                >
                  Your Active Locations
                </Typography>
                <Box
                  sx={{
                    padding: "5px",
                    pl: "10px",
                    borderRadius: "26px",
                    mr: 1.5,
                    ml: "auto",
                    mt: "2px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Search by location name"
                    value={searchedLocationValue} // Bind input to state
                    onChange={searchByLocationName}
                    sx={{
                      width: "100%",
                      color: "#2A3D4F",

                      "& .MuiOutlinedInput-root": {
                        height: "35px",
                        fontSize: "14px",
                        width: "250px",
                        border: "1px solid #c2d7df",
                        borderRadius: "8px", // Maintain rounded corners
                        backgroundColor: "#FFFFFF", // Set the background color
                        "&:hover": {
                          borderColor: "#90A4AE", // Border color on hover
                        },
                        "&.Mui-focused": {
                          borderColor: "#48768C", // Border color on focus
                          borderWidth: "2px",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Remove default MUI fieldset border
                      },
                      "& input::placeholder": {
                        marginLeft: "8px", // Match placeholder's padding
                        color: "#48768C",
                        lineHeight: 0,
                        fontWeight: 500,
                        fontSize: "13.75px",
                        fontFamily: "Source Sans Pro, sans-serif",
                        opacity: 0.88,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: "#2A3D4F", fontSize: "14px" }, // Updated label color
                    }}
                    InputProps={{
                      style: {
                        color: "#40657a",
                        fontWeight: 500,
                        fontSize: "14.5px",
                        letterSpacing: ".01em",
                      }, // Updated text color to white
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon="bi:search"
                            style={{ color: "#90A4AE" }}
                          />{" "}
                          {/* Updated icon color */}
                        </InputAdornment>
                      ),
                      endAdornment: searchedLocationValue && (
                        <InputAdornment
                          position="end"
                          sx={{ marginRight: "3px" }}
                        >
                          <Fade in={searchedLocationValue} timeout={200}>
                            <IconButton
                              onClick={handleClearSearchedLocations}
                              edge="end"
                              sx={{ color: "#6595ab", padding: 0 }}
                            >
                              <CancelOutlinedIcon
                                sx={{ fontSize: 20, opacity: 0.85 }}
                              />
                            </IconButton>
                          </Fade>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {subUserData.is_admin && (
                  <AddButton text="Add Location" onClick={handleAddLocation} />
                )}
              </Stack>
            </Box>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "rgba(191, 218, 231, 0.08)",
                borderRadius: "10px",
              }}
            >
              <TableContainer
                sx={{
                  padding: 1.5,
                  border: "1px solid rgba(173, 216, 230, 0.7)",
                  borderRadius: "10px",
                  minHeight: "8vh",
                  maxHeight: "22vh",
                }}
              >
                <Table size="small" sx={{ borderCollapse: "collapse" }}>
                  <TableBody>
                    {Array.isArray(searchedLocations) &&
                    searchedLocations.length > 0 ? (
                      searchedLocations.map((location) => (
                        <TableRow
                          key={location.location_id}
                          sx={{
                            borderRadius: "40px",
                            justifyContent: "space-between",

                            "&:hover": {
                              cursor: "pointer",

                              backgroundColor: "#EAF6FF",
                            },
                          }}
                        >
                          <TableCell
                            onClick={() => handleViewLocationOpen(location)}
                            sx={{
                              alignItems: "center",
                              color: "#48768C",
                              borderBottom: "none",
                              fontWeight: 600,
                              textAlign: "left",
                              pr: 2,
                            }}
                          >
                            {location.name}
                          </TableCell>
                          <TableCell
                            onClick={() => handleViewLocationOpen(location)}
                            sx={{
                              color: "#48768C",
                              borderBottom: "none",
                              fontWeight: 500,
                              textAlign: "center",
                              fontSize: 13.5,
                              justifyContent: "space-between",
                            }}
                          >
                            {location.email}
                          </TableCell>
                          <TableCell
                            onClick={() => handleViewLocationOpen(location)}
                            sx={{
                              color: "#48768C",
                              borderBottom: "none",
                              fontWeight: 500,
                              textAlign: "center",
                              fontSize: 13.5,
                              justifyContent: "space-between",
                            }}
                          >
                            {formatPhoneNumber(location.phone)}
                          </TableCell>

                          <TableCell
                            onClick={() => handleViewLocationOpen(location)}
                            sx={{
                              color: "#48768C",
                              borderBottom: "none",
                              fontWeight: 500,
                              textAlign: "left",
                              fontSize: 13.5,
                              justifyContent: "space-between",
                            }}
                          ></TableCell>

                          <TableCell
                            sx={{
                              color: "#48768C",
                              borderBottom: "none",
                              whiteSpace: "no-wrap",
                              fontWeight: 600,
                              alignItems: "center",
                              width: "90px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                gap: "10px",
                                pr: 2,
                              }}
                            >
                              {subUserData.is_admin && (
                                <>
                                  <IconButton
                                    onClick={() =>
                                      handleEditLocationOpen(location)
                                    }
                                    sx={{
                                      width: "35px",
                                      height: "35px",
                                      padding: "3px",
                                      mx: 0.5,
                                      my: 0.25,
                                    }}
                                  >
                                    <img
                                      src={EditIcon}
                                      alt="detailsIcon"
                                      style={{
                                        border: "1px solid #B0BEC5",
                                        width: "auto",
                                        height: "25px",
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: "6px",
                                        padding: "1px",
                                        boxShadow:
                                          "0px 1px 1px rgba(0, 0, 0, 0.09)",
                                        marginTop: 1,
                                      }}
                                    />
                                  </IconButton>

                                  <IconButton
                                    onClick={() =>
                                      handleDeleteLocationConfirmation(location)
                                    }
                                    sx={{
                                      width: "35px",
                                      height: "35px",
                                      padding: "3px",
                                      mx: 0.5,
                                      my: 0.25,
                                    }}
                                  >
                                    <img
                                      src={DeleteIcon}
                                      alt="detailsIcon"
                                      style={{
                                        border: "1px solid #B0BEC5",
                                        width: "auto",
                                        height: "25px",
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: "6px",
                                        padding: "1px",
                                        boxShadow:
                                          "0px 1px 1px rgba(0, 0, 0, 0.09)",
                                        marginTop: 1,
                                      }}
                                    />
                                  </IconButton>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : loadingLocations || !locations ? (
                      <TableRow>
                        <TableCell colSpan={6} sx={{ width: "100%" }}>
                          <Box>
                            <Typography
                              align="center"
                              sx={{
                                background: "transparent",
                                width: "100%",
                                padding: "20px",
                                color: "#447c92",
                                fontSize: ".8rem",
                                fontWeight: 600,
                              }}
                            >
                              Loading Your Locations...
                            </Typography>
                            <LinearProgress
                              color="secondary"
                              sx={{
                                height: 2,
                                "& .MuiLinearProgress-barColorSecondary": {
                                  backgroundColor: "#2BBB9E",
                                },
                              }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} sx={{ width: "100%" }}>
                          <Fade in={searchedLocations !== ""} timeout={200}>
                            <Box sx={{ padding: "20px" }}>
                              <Typography
                                style={{
                                  color: "#48768C",
                                  fontWeight: 600,
                                  fontSize: 15,
                                  letterSpacing: "-.015em",
                                }}
                              >
                                No locations found
                              </Typography>
                            </Box>
                          </Fade>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>

                  <ViewLocationModal
                    open={isViewLocationModalOpen}
                    onClose={handleViewLocationClose}
                    editLocationData={editLocationData}
                    users={users}
                    locations={locations}
                    viewLocationData={editLocationData}
                  />
                  <EditLocationModal
                    open={isEditLocationModalOpen}
                    onClose={handleEditLocationClose}
                    onSave={handleEditLocationSave}
                    editLocationData={editLocationData}
                    setEditLocationData={setEditLocationData}
                    selectedLocationForEdit={selectedLocationForEdit}
                    loading={isLoading}
                    users={users}
                    locations={locations}
                    fetchLocations={fetchLocations}
                    fetchUsers={fetchUsers}
                    locationId={editLocationData.location_id}
                  />
                </Table>
              </TableContainer>
            </Paper>
          </InfoCard>
        )}
        {accountData.single_location && ( // Show the InfoCard if single_location is true
          <UpgradeAccountCard
            title="Manage Your Locations"
            message="Your account was created as a single location account. Upgrade your account to add more locations."
            buttonAction={handleMultiLocationUpgradeDialogOpen}
          />
        )}

        {/* AddUserModal component for adding a new user */}
        <AddUserModal
          open={isAddUserModalOpen}
          onClose={handleAddUserModalClose}
          onAddUser={() => {}} // Pass an empty function here
          locations={locations}
          fetchUsers={fetchUsers}
          users={users}
          handleSubscriptionUpdate={handleSubscriptionUpdate}
        />

        {/* AddLocationModal component for adding a new location */}
        <AddLocationModal
          open={isAddLocationModalOpen}
          onClose={handleAddLocationModalClose}
          fetchLocations={fetchLocations}
          onAddLocation={(locationData) => {
            handleAddLocationModalClose();
          }}
          users={users}
          locations={locations}
          fetchUsers={fetchUsers}
          totalLocations={totalLocations}
          totalUsers={totalUsers}
          handleSubscriptionUpdate={handleSubscriptionUpdate}
        />

        <MultiLocationUpgrade
          multiLocationUpgradeDialogOpen={multiLocationUpgradeDialogOpen}
          handleMultiLocationUpgradeDialogClose={
            handleMultiLocationUpgradeDialogClose
          }
          users={users}
          singleUser={accountData.single_user}
          setMultiLocationUpgradeDialogOpen={setMultiLocationUpgradeDialogOpen}
          isDialogClosing={isDialogClosing}
        />

        <MultiUserUpgrade
          multiUserUpgradeDialogOpen={multiUserUpgradeDialogOpen}
          handleMultiUserUpgradeDialogClose={handleMultiUserUpgradeDialogClose}
          users={users}
          isDialogClosing={isDialogClosing}
        />

        <DowngradeDialog
          open={isDowngradeDialogOpen} // Use the boolean state here
          onClose={handleDowngradeDialogClose} // Function to close the dialog
          itemToDelete={itemToDelete} // Pass the item to be deleted
          fetchUsers={fetchUsers} // Function to fetch users
          downgradeDialogTitle={downgradeDialogTitle} // Pass the dialog title
          isDowngradingLocation={isDowngradingLocation} // Pass the boolean state
          locationToDelete={locationToDelete} // Pass the location to be deleted
          downgradeError={downgradeError} // Pass the downgrade error
          dialogSubText={dialogSubText} // Pass the dialog subtext
          isDowngradingToMultiUser={isDowngradingToMultiUser}
          handleCancel={handleDowngradeDialogClose} // Function to close the dialog
        />

        <DeleteDialog
          open={isLocationDeleteConfirmationOpen}
          onClose={() => {
            setIsLocationDeleteConfirmationOpen(false);
            setLocationToDelete(null);
            setDeletedLocation(false);
          }}
          onConfirm={() => handleConfirmDeleteLocation(locationToDelete)}
          itemName={locationToDelete ? locationToDelete.name : ""}
          item={locationToDelete}
          isLoading={isLoading}
          addedUserPrice="- $10/month"
          header="Confirm Delete Location"
          message={`Are you sure you want to delete ${locationToDelete ? locationToDelete.name : ""}?`}
          subText="This action cannot be undone."
          buttonText="Delete Location"
          buttonAction={handleConfirmDeleteLocation}
          deletedLocation={deletedLocation}
          reviewButtonText="Confirm & Delete Location"
          reviewButtonLoadingText="Deleting Location..."
          hideReviewDialog={false}
          handleCancel={() => {
            setIsLocationDeleteConfirmationOpen(false);
            setLocationToDelete(null);
            setDeletedLocation(false);
          }}
        />
      </Box>
    </Box>
  );
}

export default AccountManagement;
