import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAccountContext } from "src/context/AccountContext";

const useDeleteLocation = () => {
  const { accountData } = useAccountContext();
  const { getAccessTokenSilently } = useAuth0();
  const [deleteLocationLoading, setDeleteLocationLoading] = useState(false);
  const [deleteLocationError, setDeleteLocationError] = useState(null);
  const [success, setSuccess] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [price, setPrice] = useState(null);
  const [productId, setProductId] = useState(null);
  const customerId = accountData?.customer_id;

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${apiUrl}/subscriptions/plans/getSubscription/addAdditionalLocation`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        // Check if the response is OK (status code 200-299)
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setSubscriptionData(data.addAdditionalLocation); // Adjust according to the title
        setPrice(data.addAdditionalLocation.price); // Adjust according to the title
        setProductId(data.addAdditionalLocation.PID); // Adjust according to the title
      } catch (err) {
        setError("Error fetching subscription data: " + err.message);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, []); // Empty dependen

  const deleteLocation = async (locationId) => {
    setDeleteLocationLoading(true);
    setDeleteLocationError(null);

    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/subscriptions/manage/deleteProductFromSubscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            customerId,
            productId,
            locationId,
          }),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        return {
          success: false,
          error: errorData.message || "Failed to delete location",
        };
      }

      const data = await response.json();
      return { success: true, data };
    } catch (err) {
      console.error("Error in deleteLocation:", err);
      return {
        success: false,
        error: err.message || "Unexpected error occurred",
      };
    } finally {
      setDeleteLocationLoading(false);
    }
  };

  return {
    deleteLocation,
    deleteLocationLoading,
    deleteLocationError,
    success,
  };
};

export default useDeleteLocation;
