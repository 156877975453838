import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Stack,
  Divider,
  Dialog
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";
import bugIcon from "../../../../public/assets/icons/navbar/bugSmall.png";
import styled from "@mui/system/styled";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import NewAccountSetupStepper from "./NewAccountSetupStepper";  
import { useAccountContext } from "src/context/AccountContext";


const apiUrl = process.env.REACT_APP_API_URL;



const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#E9EDF2",
  marginBottom: theme.spacing(2),
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -1.5px) scale(0.75)",
    paddingLeft: "7px", // Adjust the translateY value as needed
  },
}));

const SeverityFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 400,
  marginBottom: theme.spacing(2),
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -3.5px) scale(0.79)",
    paddingLeft: "10px",
    paddingTop: "5px", // Adjust the translateY value as needed
  },
}));

const steps = [ "Your Company Info",  "Your Location Info",  "Users & Employees",  "Review "];




const AccountSetupDialog = ({ isOpen, onClose, onSubmit }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { accountData } = useAccountContext();  
  const [activeStep, setActiveStep] = useState(0);
  const [isSingleUser, setIsSingleUser] = useState(null);  
  const [isSingleLocation, setIsSingleLocation] = useState(null);  
  const [isMultiLocation, setIsMultiLocation] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [hideWelcome, setHideWelcome] = useState(false);  
  const [showCreateLocation, setShowCreateLocation] = useState(false);  
  const [showCreateUser, setShowCreateUser] = useState(false);  



  console.log("is single location", isSingleLocation); 
  console.log("is single user", isSingleUser);  
  console.log("Is next button disabled", isNextButtonDisabled); 
  console.log('Is welcome hidden', hideWelcome);  
  console.log("showCreateLocation", showCreateLocation);




  useEffect(() => {
    if ((isSingleLocation == null && activeStep === 2) || (isSingleUser == null && activeStep === 4)) {
      setIsNextButtonDisabled(true);
    } else {
      setIsNextButtonDisabled(false);
    }
  }, [isSingleLocation, isSingleUser, activeStep]);




  const handleNext = () => {
    if (activeStep === 0 && !hideWelcome) {
      setHideWelcome(true);
      return; // Stop further execution
    }
  
    if (activeStep === 1 && !isSingleLocation && !showCreateLocation) {
      setShowCreateLocation(true);
      return; // Stop here to let the user create a location
    }
  
    if (activeStep === 1 && showCreateLocation) {
      setShowCreateLocation(false); // Reset for future use
      setActiveStep(2);
      return;
    }

    if (activeStep === 2 && !isSingleUser && !showCreateUser) {
      setShowCreateUser(true);
      return; // Stop here to let the user create a location
    }
  
    if (activeStep === 2 && showCreateUser) {
      setShowCreateUser(false); // Reset for future use
      setActiveStep(3);
      return;
    }
  
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => {
      let nextStep = prevStep - 1;
  
      if (nextStep === 3 && isSingleLocation) {
        nextStep -= 1; // Skip step 3 when going back
      }
      if (nextStep === 5 && isSingleUser) {
        nextStep -= 1; // Skip step 5 when going back
      }
  
      return nextStep;
    });
  };

  console.log('activeStep', activeStep);



 

  return (
    <Dialog open={isOpen} onClose={onClose}
    PaperProps={{ style: { borderRadius: 12,     maxWidth: '51vw', minWidth:'47vw'
    } }}
    
    >


<Box
  sx={{

    bgcolor: "background.paper",
    boxShadow: 24,
    pt:0,
    pb: 2,
px:3,
    borderRadius: "12px",
  }}
>
      

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 6,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "37px",
            height: "37px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "auto",
            backgroundColor: "#FFFFFF",
            color: "#374247",
            width: "100%",
            mt:3,
          }}
        >
          <Stack
            direction="row"
            sx={{
              width: "30%",
      display: "flex",
      alignItems: "flex-start", // Align to the top
      justifyContent: "flex-start", // Align to the top
      px: 1,

         
       
            }}
          >

            <Typography
              sx={{ fontSize: '1.35rem', fontWeight: 600, marginRight: "auto", letterSpacing:'-0.034em', lineHeight: '1em', alignSelf: 'center',    color: "#48768C",
                width: "100%",  whiteSpace:'noWrap'
              }}
            >
              New Account Setup
            </Typography>
          </Stack>
        </Box>
      
  <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start',height:'auto', overflowY:'auto', width:'100%'}}>
<NewAccountSetupStepper
onClose={onClose}
isLoading={isLoading}
user={user}
activeStep={activeStep}
isNextButtonDisabled={isNextButtonDisabled}
steps={steps}
setIsNextButtonDisabled={setIsNextButtonDisabled}
hideWelcome={hideWelcome}
setIsSingleLocation={setIsSingleLocation}
setIsSingleUser={setIsSingleUser}
isSingleLocation={isSingleLocation}
isSingleUser={isSingleUser} 
showCreateLocation={showCreateLocation}
showCreateUser={showCreateUser}



/>

<Box sx={{ display: "flex",
         justifyContent: activeStep === 0 ? "flex-end" : "flex-end",
          alignItems: "left", gap: 2,       
 backgroundColor: "#FFFFFF", 
 mr:2, mt:0, mb:1
        }}>

{activeStep > 0 && (
          <CancelButton
          sx={{           width: activeStep === 0 ? "150px" : "140px" , 
            height: activeStep === 0 ? "45px" : "39px" ,
            borderRadius: "6px",

          }}
          disabled={activeStep === 0} onClick={handleBack}>
            Back
          </CancelButton>
        )}


          {activeStep < steps.length - 1 ? (
            <PrimaryButton sx={{ 
              width: activeStep === 0 ? "150px" : "140px" , 
              height: activeStep === 0 ? "45px" : "39px" , 
              borderRadius: "6px",


            }}
            disabled={isNextButtonDisabled}
              onClick={handleNext}>
              Continue
            </PrimaryButton>
          ) : (
            <PrimaryButton
            sx={{width: "125px"}}
              variant="contained">

              {isLoading ? "Submitting..." : "Submit"}
            </PrimaryButton>
          )}
        </Box>

  </Box>
     </Box>
      </Dialog>
  );
};

export default AccountSetupDialog;
