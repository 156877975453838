// ChangePinModal.js

import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  TextField,
  Button,
  Box,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ChangePinModal = ({ isOpen, onClose, onSave, userName }) => {
  const [newPin, setNewPin] = useState("");
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const [showNewPin, setShowNewPin] = useState(false);
  const [showConfirmNewPin, setShowConfirmNewPin] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false); // New state for success dialog
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);

  const handleClose = () => {
    setNewPin("");
    setConfirmNewPin("");
    onClose();
  };

  useEffect(() => {
    const isValidInput =
      newPin.length === 4 &&
      confirmNewPin.length === 4 &&
      /^\d+$/.test(newPin) &&
      /^\d+$/.test(confirmNewPin) &&
      newPin === confirmNewPin;

    setSaveButtonEnabled(isValidInput);
  }, [newPin, confirmNewPin]);

  const handleNewPinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "").substr(0, 4);
    setNewPin(inputValue);
  };

  const handleConfirmNewPinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "").substr(0, 4);
    setConfirmNewPin(inputValue);
  };

  const handleSave = async () => {
    onSave({ newPin, confirmNewPin });
    setShowSuccessDialog(true); // Show success dialog when saving
  };

  const toggleShowNewPin = () => {
    setShowNewPin((prev) => !prev);
  };

  const toggleShowConfirmNewPin = () => {
    setShowConfirmNewPin((prev) => !prev);
  };

  const handleSuccessDialogClose = () => {
    setShowSuccessDialog(false);
    setNewPin("");
    setConfirmNewPin("");
    onClose(); // Close the modal after success dialog is closed
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 1,
            width: "17%",
            backgroundColor: "white",
            borderRadius: "15px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 1,
              top: 3,
              color: "#7589A2",
              backgroundColor: "transparent",
              zIndex: 1,
              borderRadius: "12px",
              width: "35px",
              height: "35px",
              padding: "3px",
              mx: 0.5,
              my: 0.25,
            }}
          >
            <CloseIcon sx={{ fontSize: "28px" }} />
          </IconButton>
          <Typography
            gutterBottom
            sx={{
              color: "#48768C",
              mb: 1,
              mt: 3,
              textAlign: "center",
              padding: "15px",
              borderRadius: "8px",
              fontSize: 17,
              fontWeight: 600,
              letterSpacing: "-2%",
            }}
          >
            Change {userName}'s Pin
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              placeholder="New 4 Digit PIN"
              size="small"
              variant="outlined"
              type={showNewPin ? "text" : "password"}
              sx={{
                color: "#48768C",
                "& input": {
                  width: "75%",
                  textAlign: "left",
                  color: "#48768C",
                },
                mb: 2,
              }}
              value={newPin}
              onChange={handleNewPinChange}
              InputProps={{
                style: { color: "#48768C", borderRadius: "10px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowNewPin} edge="end">
                      {showNewPin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              placeholder="Confirm PIN"
              variant="outlined"
              type={showConfirmNewPin ? "text" : "password"}
              size="small"
              value={confirmNewPin}
              onChange={handleConfirmNewPinChange}
              sx={{
                borderRadius: "10px",

                color: "#48768C",
                "& input": {
                  borderRadius: "px",
                  width: "75%",
                  textAlign: "left",
                  color: "#48768C",
                },
                mb: 2,
              }}
              InputProps={{
                style: { color: "#48768C", borderRadius: "10px" },

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowConfirmNewPin} edge="end">
                      {showConfirmNewPin ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                color: "#F0F0F0",
                backgroundColor: "#46A793",
                mt: 1,
                mb: 3,
                fontSize: "1rem",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.)",
                transition: "background-color 0.3s ease",
                width: "50%",
                "&:hover": {
                  backgroundColor: "#079E80",
                },
                "&:active": {
                  backgroundColor: "#2E7D32",
                },
              }}
              onClick={handleSave}
              disabled={!isSaveButtonEnabled}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Dialog
        PaperProps={{ sx: { borderRadius: "15px" } }}
        open={showSuccessDialog}
        onClose={handleSuccessDialogClose}
      >
        <DialogContent>
          <Typography
            sx={{ fontSize: 13.5, mt: 1, fontWeight: 600, color: "#48768C" }}
          >
            {userName}'s pin has been changed successfully!
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", mb: 1, mt: -1 }}
        >
          <Button
            sx={{
              border: "1px solid #B0BEC5",
              color: "rgba(72, 118, 140, 0.8)",
              boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)",
              fontWeight: 600,
              height: "30px",
            }}
            onClick={handleSuccessDialogClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangePinModal;
