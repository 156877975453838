import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Button,
  Box,
  Icon,
  Fade,
  Skeleton,
  CardMedia,
} from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import WelcomeImage from "src/public/assets/WelcomeImage.png";
import { useAuth } from "src/context/AuthContext";
import RestartSetupDialog from "./RestartSetupDialog";

const WelcomeDialog = ({ isOpen, onNext }) => {
  const { setSubUserData } = useAuth();
  const [showRestartDialog, setShowRestartDialog] = useState(false);
  const [confirmRestart, setConfirmRestart] = useState(false);

  const handleBackdropClick = (event) => {
    // Check if the click is on the backdrop by comparing target and currentTarget
    if (event.target === event.currentTarget) {
      setConfirmRestart(false);
      setShowRestartDialog(true); // Open RestartSetupDialog
    }
  };

  const handleCloseRestartDialog = () => {
    setShowRestartDialog(false); // Close RestartSetupDialog
  };

  useEffect(() => {
    // Clear all items from local storage when component mounts
    localStorage.clear();
  }, []);

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = WelcomeImage;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={null} // Prevent the parent modal from closing
      slotProps={{
        backdrop: {
          outline: "none", // Remove the default focus outline

          onClick: handleBackdropClick, // Disable closing the modal by clicking on the backdrop
        },
      }}
      disableEscapeKeyDown // Disable closing the modal by pressing the escape key
    >
      <Fade in={isOpen} timeout={400}>
        <Box
          sx={{
            outline: "none", // Remove the default focus outline
            alignContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",

            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Added box shadow
            borderRadius: "40px",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#FFFFFF",
            color: "#48768C",
          }}
        >
          <Typography
            sx={{
              mt: 3,
              fontSize: 22,
              textAlign: "center",
              mb: 1,
              fontWeight: 600,
            }}
          >
            Welcome to NanoNotify!
          </Typography>
          <div style={{ position: "relative", width: "50%", margin: "auto" }}>
            {/* Skeleton Placeholder */}
            {!imageLoaded && (
              <Skeleton
                variant="rectangular"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#EEF6F9",
                  transition: "opacity 0.3s ease-in-out",
                  opacity: imageLoaded ? 0 : 1,
                }}
              />
            )}

            {/* CardMedia Image */}
            <CardMedia
              component="img"
              image={WelcomeImage}
              sx={{
                display: "block",
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                objectFit: "contain",
                transition: "opacity 0.3s ease-in-out",
                opacity: imageLoaded ? 1 : 0,
              }}
              onLoad={() => setImageLoaded(true)}
            />
          </div>

          <Typography
            sx={{
              textAlign: "center",
              mb: 3,
              fontSize: 15,
              fontWeight: 600,
            }}
          >
            Let's finish setting up your new account
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                whiteSpace: "pre-wrap",

                textAlign: "center",
                mb: 4,
                fontSize: 15,
                width: "80%",
              }}
            >
              We just need to get a few details about your company and how you
              plan to send notifications to your customers
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                backgroundColor: "rgba(72, 118, 140, 0.8)", // Adjusted color with transparency
                padding: 1,
                borderRadius: "15px",
                mb: 3,
                width: "85%",
                fontSize: 17,
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "rgba(72, 118, 140, 0.7)", // Adjusted hover color with transparency
                },
                "&:active": {
                  backgroundColor: "rgba(72, 118, 140, 0.5)", // Adjusted active color with transparency
                },
              }}
              onClick={onNext}
              variant="contained"
              color="primary"
            >
              Get Started
            </Button>
          </Box>
          <RestartSetupDialog
            open={showRestartDialog}
            onClose={handleCloseRestartDialog}
            confirmRestart={confirmRestart}
            setConfirmRestart={setConfirmRestart}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

export default WelcomeDialog;
