import React from "react";
import { styled } from "@mui/system";
import { Button } from "@mui/material";

//Import One Line Snippet:
//import { PrimaryButton, CancelButton, SecondaryButton } from 'src/components/Buttons/ModalButtons';

// Styled Primary Button
export const PrimaryButton = styled(Button)({
  letterSpacing: -0.25,
  fontWeight: "semibold",
  borderRadius: "10px",
  color: "#48768CD9",
  backgroundColor: "#DBEAF075",
  border: "1px solid #48768C54",
  boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)",
  transition: "background-color 0.3s ease",

  "&:hover": {
    backgroundColor: "#DBEAF0C2",
  },
  "&:active": {
    backgroundColor: "#DBEAF0C2",
  },
  "&.Mui-disabled": {
    color: "#A0A0A0D9", // Disabled text color
    backgroundColor: "#48768C21", // Disabled background color
    borderColor: "rgba(128, 128, 128, 0.3)", // Disabled border color
    boxShadow: "none", // Disabled shadow, if you want to remove it
  },
});

export const SecondaryButton = styled(Button)({
  letterSpacing: -0.25,
  fontWeight: "semibold",
  borderRadius: "10px",
  color: "#48768CD9",
  backgroundColor: "#FFFFFFF",
  border: "1px solid #48768C6E",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "#DBEAF0C2",
  },
  "&:active": {
    backgroundColor: "#DBEAF0C2",
  },
  "&.Mui-disabled": {
    color: "#A0A0A0D9", // Disabled text color
    backgroundColor: "#48768C21", // Disabled background color
    borderColor: "rgba(128, 128, 128, 0.3)", // Disabled border color
    boxShadow: "none", // Disabled shadow, if you want to remove it
  },
});

// Styled Cancel Button
export const CancelButton = styled(Button)({
  padding: "8px",
  border: "1px solid #DBEAF0",
  borderRadius: "10px",
  color: "#48768CD9",
  backgroundColor: "#FFFFFF",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "background-color 0.3s ease",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: "#DBEAF0",
  },
  "&:active": {
    backgroundColor: "#DBEAF0",
  },
});

// Usage in a Component
function ModalButtons() {
  return (
    <div>
      <PrimaryButton></PrimaryButton>
      <SecondaryButton></SecondaryButton>
      <CancelButton></CancelButton>
    </div>
  );
}

export default ModalButtons;
