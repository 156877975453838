import React, { useState, useRef } from "react";
import {
  Box,
  IconButton,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import EditIcon from "../../../public/assets/icons/icons/help-center/edit.png";
import DeleteIcon from "../../../public/assets/icons/icons/delete.png";
import UploadIcon from "../../../public/assets/icons/icons/upload.png";

const PopoverButton = ({
  setIsHovered,
  shouldRenderButton,
  handleUploadButtonClick,
  handleDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(buttonRef.current);
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <Box>
      {shouldRenderButton && (
        <IconButton
          ref={buttonRef}
          onClick={handlePopoverOpen}
          sx={{
            position: "absolute",
            top: "-5px",
            right: "-5px",
            opacity: 0.85,
            transition: "opacity 0.3s ease",
          }}
        >
          <img
            src={EditIcon}
            alt="Edit Icon"
            style={{
              border: "1px solid #B0BEC5",
              width: "auto",
              height: "28px",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              padding: "2px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            }}
          />
        </IconButton>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            border: "1.25px solid rgba(173, 216, 230, 0.6)",
            borderRadius: "8px",
            ml: 7,
            mt: -1,
          },
        }}
        disableRestoreFocus
      >
        <Box sx={{ backgroundColor: "#F2F9FF", pb: 0.5, pt: 0.5 }}>
          <List sx={{ padding: 0 }}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleDelete}
                sx={{ padding: 0, ml: 1, mb: 0.25, mt: 0.25 }}
              >
                <img
                  src={DeleteIcon}
                  alt="bell"
                  style={{
                    width: "auto",
                    height: "30px",
                    borderRadius: "10px",
                    padding: "3px",
                    borderRadius: "8px",
                  }}
                />
                <Typography
                  sx={{
                    ml: 0.5,
                    letterSpacing: "-1%",

                    display: "flex",
                    color: "#8C4350",
                    fontSize: 14,
                    fontWeight: 600,
                    textAlign: "center", // Fix typo here: 'textAlign' instead of 'alignText'
                  }}
                >
                  Delete Logo
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={(event) => {
                  event.stopPropagation(); // Ensure popover doesn't close immediately
                  handleUploadButtonClick(); // Trigger the file input
                  handlePopoverClose(); // Optionally close the popover after upload
                }}
                sx={{ padding: 0, ml: 1 }}
              >
                <img
                  src={UploadIcon}
                  alt="bell"
                  style={{
                    width: "auto",
                    height: "30px",
                    borderRadius: "10px",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                />
                <Typography
                  sx={{
                    ml: 0.5,
                    letterSpacing: "-1%",
                    mr: 2,
                    display: "flex",
                    color: "#5e9fbc",
                    fontSize: 14,
                    fontWeight: 600,
                    textAlign: "center", // Fix typo here: 'textAlign' instead of 'alignText'
                  }}
                >
                  Upload New Logo
                </Typography>{" "}
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Popover>
    </Box>
  );
};

export default PopoverButton;
