import React from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Divider,
  Paper,
  Stack,
} from "@mui/material";
import AddLocationCard from "./components/addLocationCard";
import AddUserCard from "./components/addUserCard";
import RemovePlan from "./components/removePlan";
import AddPlan from "./components/addPlan";
import { useAuth } from "src/context/AuthContext";

const Review = ({
  name,
  firstName,
  lastName,
  newUserName,
  assignedUser,
  assignedLocation,
  userName,
  updatedName,
}) => {
  const { subUserData } = useAuth();

  return (
    <Box className="review_text_container">
      <Box
        sx={{
          overflowY: "auto",
          maxHeight: "350px", // Adjust this height as needed
          backgroundColor: "transparent",
        }}
      >
        <Typography className="review_text_header">
          Review Your Plan Changes
        </Typography>
        <Stack
          direction="row"
          sx={{ display: "flex", alignItems: "center" }}
          spacing={1}
        >
          <RemovePlan />
          <AddPlan />
        </Stack>
        <Typography className="review_features_header">
          Review Your Added Users & Locations
        </Typography>
        <Box
          sx={{
            border: "1px solid rgba(191, 218, 231, 0.9)",
            borderRadius: "6px",
            width: "13%",
            height: "14%",
            backgroundColor: "rgba(191, 218, 231, 0.40)",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
            ml: "2px",
          }}
        >
          <Typography
            sx={{
              fontSize: "12.5px",
              fontWeight: 600,
              color: "#4A6B75",
              px: "5px",
              letterSpacing: "-2%",
            }}
          >
            $5/month
          </Typography>
        </Box>
        <AddUserCard
          title="Added New User"
          name={name}
          firstName={firstName}
          lastName={lastName}
          userName={userName}
        />{" "}
        <Box
          sx={{
            border: "1px solid rgba(191, 218, 231, 0.9)",
            borderRadius: "6px",
            width: "13%",
            height: "14%",
            backgroundColor: "rgba(191, 218, 231, 0.40)",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
            ml: "2px",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "12.5px",
              fontWeight: 600,
              color: "#4A6B75",
              px: "5px",
              letterSpacing: "-2%",
            }}
          >
            $5/month
          </Typography>
        </Box>
        <AddUserCard
          title="Updated Exsisting User"
          newUserName={newUserName}
          firstName={subUserData.first_name}
          lastName={subUserData.last_name}
          name={updatedName}
        />
      </Box>
    </Box>
  );
};

export default Review;
