import { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAccountContext } from "src/context/AccountContext";

export const useValidateSubscription = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [subscriptionValid, setSubscriptionValid] = useState(false);
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { accountData } = useAccountContext();

  // Function to check the subscription
  const refreshSubscription = useCallback(async () => {
    if (!user || accountData?.customer_id == null) return false; // Return false if prerequisites are missing

    setVerifying(true); // Set verifying state while fetching
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/subscriptions/check-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ auth0_user: user.sub }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to check subscription");
      }

      const data = await response.json();
      setSubscriptionValid(data.hasSubscription);

      return data.hasSubscription; // Return subscription status
    } catch (error) {
      console.error("Error checking subscription:", error);
      setError(error.message);
      return false; // Assume invalid if there's an error
    } finally {
      setVerifying(false); // Reset verifying state when done
    }
  }, [user, getAccessTokenSilently, apiUrl]);

  // Trigger the subscription check on component mount or when dependencies change
  useEffect(() => {
    refreshSubscription();
  }, [refreshSubscription]);

  return { subscriptionValid, verifying, error, refreshSubscription };
};
