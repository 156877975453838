import { fetchAccountData } from "../../../context/AccountContext";

const AddLocationSubmit = async (
  getAccessTokenSilently,
  name,
  email,
  phone,
  businessHours,
  userId,
  right,
  customerId,
) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const locationData = {
    name,
    email,
    phone,
    businessHours,
    userId,
    users: right.map((user) => ({
      user_id: user.isNewUser ? null : user.user_id,
      isNewUser: user.isNewUser,
      ...user,
    })),
  };

  const requestData = {
    customerId, // Send customerId separately
    locationData, // Send locationData object
  };

  try {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(
      `${apiUrl}/subscriptions/manage/addAdditional`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestData), // Add locationData as the body of the request
      },
    );

    // Check for response status
    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.locationNameTaken) {
        throw new Error("Location name is already taken.");
      } else if (errorData.usernameTaken) {
        throw new Error("Username is already taken.");
      } else {
        throw new Error(
          `Error creating location: ${errorData.error || response.statusText}`,
        );
      }
    }

    // Parse and return the response JSON
    const result = await response.json();
    return result; // Resolve with result on success
  } catch (error) {
    console.error("Error creating location:", error); // Debugging log
    throw error; // Reject with error on failure
  }
};

// Export the function as the default export
export default AddLocationSubmit;
