import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import Typography from "src/theme/overrides/Typography";

export default function ErrorAlert({
  title,
  alertText,
  messageSubText,
  locationToDelete,
  open,
  setOpen,
  handleClose,
}) {
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true); // After 1 second, set open to true to show the alert
    }, 250);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        bottom: 30,
        left: "60%",
        transform: "translateX(-50%)",
        width: "60%",
      }}
    >
      <Fade in={open} timeout={200}>
        <Stack sx={{ width: "100%" }} spacing={1}>
          <Collapse in={open} timeout={500}>
            {" "}
            {/* Adjust the timeout for desired fade duration */}
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{
                mb: 2,
                opacity: open ? 1 : 0, // Control the fade effect
                transition: "opacity 0.5s ease-in-out", // Smooth fade-in transition
                border: ".85px solid #A65160",
                borderRadius: "10px",
                fontSize: ".89rem",
              }}
            >
              <AlertTitle sx={{}}>{title}</AlertTitle>
              <Stack direction="column" sx={{ mt: -0.5 }} spacing={0.25}>
                <div> {alertText} </div>
                <div> {messageSubText} </div>
              </Stack>
            </Alert>
          </Collapse>
        </Stack>
      </Fade>
    </div>
  );
}
