import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Fade,
  Stack,
  Card,
  CardContent,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import ReviewDialog from "../ReviewDialog/ReviewDialog";
import DeleteIcon from "src/public/assets/icons/icons/deleteRed.png";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppContext } from "src/context/AppContext";
import { useAuth } from "src/context/AuthContext";
import { useAccountContext } from "src/context/AccountContext";
import { useUserNotificationsContext } from "src/context/UserNotificationsContext";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext";
import openSign from "src/public/assets/icons/icons/openSign.png";
import closedSign from "src/public/assets/icons/icons/closedSign.png";
import { customTheme } from "./textField";

const LocationHoursCard = ({
  onConfirm,
  hoursItem,
  isLoading,
  header,
  message,
  subText,
  buttonAction,
  addedUserPrice,
  hideReviewDialog,
  businessHours,
  setBusinessHours,
  startTimeValues,
  setStartTimeValues,
  endTimeValues,
  setEndTimeValues,
  handleTimeChange,
  setIsSaveHoursButtonDisabled,
  isSaveHoursButtonDisabled,
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [loadingHours, setLoadingHours] = useState(false);
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const { fetchCurrentHours } = useUserSelectedNotifications();

  const { accountData, fetchOnboardingRecord } = useAccountContext();
  const [isClosed, setIsClosed] = useState(false);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { username, subUserData } = useAuth();

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: "#647D9E", // Unchecked thumb (circle) color
      "&.Mui-checked": {
        color: "#4C5F7D", // Checked thumb color
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "#B0BFD1", // Unchecked track (background) color
        "&.Mui-checked": {
          backgroundColor: "#768AA6", // Checked track color
        },
      },
    },
  }));

  const apiUrl = process.env.REACT_APP_API_URL;

  const WhiteTimePicker = styled(TimePicker)(({ isAdmin }) => ({
    "& input": {
      color: "#48768C",
      fontWeight: 600,
      fontSize: 12.36, // 7.8 * 1.2
      textAlign: "center",
      padding: "1.8px 3.6px", // 4px * 1.2 and 8px * 1.2
      height: "23.04px", // 19.2px * 1.2
    },
    "& .MuiSvgIcon-root, & .MuiIconButton-root, & .MuiInputAdornment-root": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      // Adding focus behavior to clear the field when clicked
      "&:focus": {
        caretColor: "transparent", // Optional: hides the caret to prevent a blinking cursor
      },
      // Clearing the input on focus
      "&:focus-within": {
        value: "",
      },
    },
  }));

  const OpenIconSwitch = styled(Switch)(({ theme }) => ({
    width: 40.71, // 35.4 * 1.15
    height: 22.08, // 19.2 * 1.15
    padding: 4.83, // 4.2 * 1.15
    "& .MuiSwitch-switchBase": {
      margin: 0.69, // 0.6 * 1.15
      padding: 0,
      transform: "translateX(4.14px)", // 3.6 * 1.15
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(15.18px)", // 13.2 * 1.15
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url(${closedSign})`,
          backgroundSize: "contain",
          width: "15.18px", // 13.2 * 1.15
          height: "15.18px", // 13.2 * 1.15
          content: "''",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
      "&.Mui-checked + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#647D9E" : "#647D9E",
        "& .MuiSwitch-thumb:before": {},
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#236381 " : "#236381",
      width: 19.32, // 16.8 * 1.15
      height: 19.32, // 16.8 * 1.15
      "&::before": {
        backgroundImage: `url(${openSign})`,
        backgroundSize: "contain",
        width: "16.56px", // 14.4 * 1.15
        height: "16.56px", // 14.4 * 1.15
        content: "''",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#AAC5E7" : "#AAC5E7",
      borderRadius: 6.9, // 6 * 1.15
    },
  }));

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    // Check if any of the time values are partially entered or null
    const isInvalidTime = Object.values(businessHours).some(
      ({ start_time, end_time }) => {
        // Check if start_time or end_time is null or not in HH:mm format
        return (
          !start_time ||
          !end_time ||
          !isValidTimeFormat(start_time) ||
          !isValidTimeFormat(end_time)
        );
      },
    );
    setIsSaveHoursButtonDisabled(isInvalidTime);
  }, [businessHours]);

  // Function to check if the time is in HH:mm format
  const isValidTimeFormat = (time) => {
    const regex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/; // HH:mm format regex
    return regex.test(time);
  };

  useEffect(() => {
    const logEditedHours = () => {
      let hasNull = false; // Initialize a flag for null values
      Object.entries(businessHours).forEach(([day, data]) => {
        // Check if either start_time or end_time is null or 'Invalid Date'
        if (
          !data.start_time ||
          !data.end_time ||
          data.start_time === "Invalid Date" ||
          data.end_time === "Invalid Date"
        ) {
          hasNull = true; // Set the flag to true if any null or 'Invalid Date' value is found
        }
      });

      // Update the isButtonDisabled state based on whether any null or 'Invalid Date' value is found
      setIsSaveHoursButtonDisabled(hasNull);
    };

    // Call the function when the component renders and whenever editedHours changes
    logEditedHours();
  }, [businessHours]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mb: 2 }}>
        {daysOfWeek.map((dayOfWeek) => (
          <Box
            key={dayOfWeek}
            sx={{
              display: "flex",
              alignItems: "left",
              width: "100%",
              gap: 2,
              mb: 1,
              pl: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: 14.36, // 7.8 * 1.2
                display: "flex",
                color: "#48768C",
                fontWeight: 600, // Increased font weight for emphasis
                width: "130px",
              }}
            >
              {dayOfWeek}
            </Typography>

            <>
              {businessHours[dayOfWeek]?.is_closed ? (
                <Fade in={businessHours[dayOfWeek]?.is_closed} timeout={300}>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{
                      width: "57%",
                      color: "#48768C",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#48768C3D", // Optional background
                      borderRadius: "4px",
                    }}
                  >
                    Closed
                  </Typography>
                </Fade>
              ) : (
                <>
                  <TimePicker
                    isAdmin={subUserData && subUserData.is_admin}
                    value={
                      startTimeValues[dayOfWeek]
                        ? dayjs(`1970-01-01 ${startTimeValues[dayOfWeek]}`)
                        : null
                    }
                    disableOpenPicker={
                      (subUserData && !subUserData.is_admin) ||
                      businessHours[dayOfWeek]?.is_closed
                    }
                    onChange={(time) => {
                      const formattedTime = time ? time.format("HH:mm") : "";
                      handleTimeChange(dayOfWeek, "start_time", formattedTime);
                    }}
                    ampm={true}
                    disabled={
                      (subUserData && !subUserData.is_admin) ||
                      businessHours[dayOfWeek]?.is_closed
                    }
                    sx={{
                      flexGrow: 1,
                      width: "100px",
                      "& input": {
                        color: "#48768C",
                        fontWeight: 600,
                        fontSize: 12.36, // 7.8 * 1.2
                        textAlign: "center",
                        padding: "1.8px 3.6px", // 4px * 1.2 and 8px * 1.2
                        height: "23.04px", // 19.2px * 1.2
                      },
                      "& .MuiSvgIcon-root, & .MuiIconButton-root, & .MuiInputAdornment-root":
                        {
                          display: "none",
                        },
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        inputProps: { inputMode: "numeric" },
                      },
                    }}
                  />

                  <Typography
                    sx={{
                      color: "#B0B0B0",
                      fontWeight: 600,
                      fontSize: 15,
                      flexGrow: 1,
                      width: "50px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    -
                  </Typography>

                  <TimePicker
                    value={
                      endTimeValues[dayOfWeek]
                        ? dayjs(`1970-01-01 ${endTimeValues[dayOfWeek]}`)
                        : null
                    }
                    onChange={(time) =>
                      handleTimeChange(
                        dayOfWeek,
                        "end_time",
                        time && time.format("HH:mm"),
                      )
                    }
                    ampm={true}
                    disabled={
                      (subUserData && !subUserData.is_admin) ||
                      businessHours[dayOfWeek]?.is_closed
                    }
                    disableOpenPicker={
                      (subUserData && !subUserData.is_admin) ||
                      businessHours[dayOfWeek]?.is_closed
                    }
                    sx={{
                      flexGrow: 1,
                      width: "100px",
                      "& input": {
                        color: "#48768C",
                        fontWeight: 600,
                        fontSize: 12.36, // 7.8 * 1.2
                        textAlign: "center",
                        padding: "1.8px 3.6px", // 4px * 1.2 and 8px * 1.2
                        height: "23.04px", // 19.2px * 1.2
                      },
                      "& .MuiSvgIcon-root, & .MuiIconButton-root, & .MuiInputAdornment-root":
                        {
                          display: "none",
                        },
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        width: "90px",
                      },
                    }}
                  />
                </>
              )}
            </>

            <FormControlLabel
              control={
                <OpenIconSwitch
                  checked={businessHours[dayOfWeek]?.is_closed || false}
                  onChange={(e) =>
                    handleTimeChange(dayOfWeek, "is_closed", e.target.checked)
                  }
                  sx={{ ml: 4 }}
                />
              }
            />
          </Box>
        ))}
      </Box>
    </LocalizationProvider>
  );
};

export default LocationHoursCard;
