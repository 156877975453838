import React, { useState } from "react";
import {
  Button,
  Box,
  Paper,
  IconButton,
  TextField,
  Typography,
  Stack,
  Popover,
} from "@mui/material";
import IconGrid from "../../../Components/IconGrid.js";
import { useNotificationState } from "../hooks/useNotificationState";

const IconPopover = ({
  fieldOpen,
  fieldAnchorEl,
  handleIconPopoverClose,
  isEditingIcon,
  handleSelectedIcon,
}) => {
  return (
    <Popover
      open={fieldOpen}
      anchorEl={fieldAnchorEl}
      onClose={handleIconPopoverClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{
        sx: {
          p: 0,
          mt: "auto",
          ml: "auto",
          width: "auto",
          backgroundColor: "#F2F9FF",
          border: "1px solid rgba(173, 216, 230, 0.5)",

          "& .MuiMenuItem-root": { typography: "body2", borderRadius: 0.75 },
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
        {isEditingIcon && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 0,
              gap: "5px",
              mb: "5px",
              mt: "5px",
            }}
          >
            <IconGrid
              handleSetSelectedIcon={handleSelectedIcon}
              closePopover={handleIconPopoverClose}
            />
          </Box>
        )}
      </Box>
    </Popover>
  );
};

export default IconPopover;
