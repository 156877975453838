import { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Custom hook to fetch subscription details
export const useSubscriptionDetails = (apiUrl) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSubscriptionDetails = useCallback(async () => {
    try {
      // Get the access token if your backend API is secured
      const token = await getAccessTokenSilently();

      // Call the backend API to fetch subscription details
      const response = await fetch(
        `${apiUrl}/subscriptions/subscription-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Send the token in the request
          },
          body: JSON.stringify({ auth0_user: user.sub }),
        },
      );

      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Failed to fetch subscription details");
      }

      // Parse the JSON response
      const data = await response.json();
      setSubscriptionDetails(data);
    } catch (error) {
      console.error("Error fetching subscription details:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [user, getAccessTokenSilently, apiUrl]);

  useEffect(() => {
    if (user) {
      fetchSubscriptionDetails();
    }
  }, [user, fetchSubscriptionDetails]);

  return {
    subscriptionDetails,
    loading,
    error,
    refetch: fetchSubscriptionDetails,
  }; // Add refetch
};
