//NotificationDetails.js
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  InputLabel,
  Popover,
  Chip,
  Stack,
  Paper,
  InputAdornment,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import { DateField } from "@mui/x-date-pickers";
import { TimeField } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import { useAccountContext } from "../../context/AccountContext"; // Import useAccountContext from AccountContext
import bellIcon from "../../public/assets/icons/messageModal/bell.png";
import ticketIcon from "../../public/assets/icons/messageModal/ticket.png";
import OutlinedInput from "@mui/material/OutlinedInput";
import ContactsIcon from "../../public/assets/icons/icons/contactList.png";
import ContactListDialog from "./ContactListDialog"; // Import the dialog component
import { useUserSelectedNotifications } from "../../context/UserNotificationsContext";
import icons from "../../public/assets/icons/messageModal";
import "./styles.css";
import PreviewNotification from "./PreviewNotification";
import ScheduleNotificationDialog from "./ScheduleNotificationDialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import dayjs from "dayjs";
import hoursIcon from "../../public/assets/icons/icons/hours.png";
import AfterHoursDialog, { checkIfOpen } from "./AfterHoursDialog"; // Import both
import { useWidgetContext } from "src/context/WidgetContext";
import InactiveSubscriptionDialog from "../payments/components/InactiveSubscriptionDialog";
import { useValidateSubscription } from "src/components/payments/routes/validateSubscription.js";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useSubscriptionDetails } from "src/components/payments/routes/useSubscriptionDetails";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";

export default function NewNotification({
  open,
  onClose,
  phone_number,
  phoneIcon,
  ContactListIcon,
}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const auth0Route = process.env.REACT_APP_AUTH0_ROUTE;
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { subUserData, username } = useAuth();
  const { accountData } = useAccountContext(); // Use the accountData from the context
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [additionalMessage, setAdditionalMessage] = useState("");
  const [previewNotificationDialogOpen, setPreviewNotificationDialogOpen] =
    useState(false);
  const [openContactListDialog, setOpenContactListDialog] = useState(false);
  const [openDateTime, setOpenDateTime] = useState(false);
  const [userTimeZone, setUserTimeZone] = useState("");
  const [timezone, setTimezone] = useState("");
  const [userTimezone, setUserTimezone] = useState("");
  const [convertedTimeZoneAbbr, setConvertedTimeZoneAbbr] = useState("");
  const [loading, setLoading] = useState(false);
  const { selectedNotifications } = useUserSelectedNotifications();
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const notificationTitles =
    selectedNotifications.length > 0
      ? Object.keys(selectedNotifications[0].selected_data)
      : [];
  const firstTitle = notificationTitles.length > 0 ? notificationTitles[0] : "";
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [selectedNotificationData, setSelectedNotificationData] =
    useState(null);
  const [title, setTitle] = useState("");
  const [notificationColor, setNotificationColor] = useState("");
  const [showExtraInfoButton, setShowExtraInfoButton] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(phone_number || "");
  const [ticketNumber, setTicketNumber] = useState("");
  const [contextMessage, setContextMessage] = useState(""); // Initialize messageContext state variable
  const [companyName, setCompanyName] = useState("");
  const [twoWayStatus, setTwoWayStatus] = useState("");
  const [locationPhone, setLocationPhone] = useState("");
  const [locationEmail, setLocationEmail] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [formattedLocationPhone, setFormattedLocationPhone] = useState("");
  const [selectedType, setSelectedType] = useState("minutes");
  const [optionalSelectedType, setOptionalSelectedType] = useState("minutes");
  const [openDialog, setOpenDialog] = useState(false);

  const fieldNameRef = useRef(null);
  const valueRef = useRef(null);
  const fieldDataRef = useRef(null);
  const [timeValue, setTimeValue] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [dateTimeValue, setDateTimeValue] = useState(null);
  const [defaultMessage, setDefaultMessage] = useState("");
  const [defaultMessagePrefix, setDefaultMessagePrefix] = useState("");
  const [openScheduleNotificationDialog, setOpenScheduleNotificationDialog] =
    useState(false);
  const [twoWayEnabled, setTwoWayEnabled] = useState(false);
  const [counterColor, setCounterColor] = useState("#647D9E");
  const [remainingCharacters, setRemainingCharacters] = useState(225); // Assuming initial value is 225
  const { fetchAllWidgetTypes, pauseAutoRefresh, resumeAutoRefresh } =
    useWidgetContext();
  const [inactiveSubscriptionDialogOpen, setInactiveSubscriptionDialogOpen] =
    useState(false);

  const { subscriptionValid, verifying, error, refreshSubscription } =
    useValidateSubscription(apiUrl); // Pass your API URL
  const { subscriptionDetails, refetch: refetchSubscriptionDetails } =
    useSubscriptionDetails(apiUrl); // Assuming useSubscriptionDetails has a refetch function
  const { businessHours } = useUserSelectedNotifications();

  const handleOpenInactiveSubscriptionDialog = () => {
    setInactiveSubscriptionDialogOpen(true);
  };

  const handleCloseInactiveSubscriptionDialog = () => {
    setInactiveSubscriptionDialogOpen(false);
  };

  const CloseInactiveSubscriptionDialog = () => {
    onClose();
    handleCloseInactiveSubscriptionDialog();
    handleCloseDialog();
  };

  const NewNotificationDialogOpen = open;

  //Get Current Business Hours For the Day
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
  });
  const businessHoursForCurrentDay = businessHours[currentDayOfWeek];
  const month = currentDate.getMonth() + 1; // January is 0, so add 1 to get the correct month
  const day = currentDate.getDate();
  // Format the date as "m/d"
  const userCurrentDate = `${month}/${day}`;

  let currentHours = "";
  if (businessHoursForCurrentDay) {
    const { start_time, end_time } = businessHoursForCurrentDay;
    currentHours = `${start_time} - ${end_time}`;
  } else {
    currentHours = "Not available";
  }

  // Create state variables dynamically based on the user's data
  // Create state variables dynamically based on the user's data
  const initialFieldValues =
    selectedNotificationData && selectedNotificationData.Fields
      ? Object.fromEntries(
          Object.entries(selectedNotificationData.Fields)
            .filter(([fieldName, fieldData]) => fieldData.isRequired) // Filter out fields where isRequired is true
            .map(([fieldName, fieldData]) => [fieldName, ""]),
        )
      : {};

  const initialOptionalFieldValues =
    selectedNotificationData && selectedNotificationData.Fields
      ? Object.fromEntries(
          Object.entries(selectedNotificationData.Fields)
            .filter(([fieldName, fieldData]) => !fieldData.isRequired) // Filter out fields where isRequired is false
            .map(([fieldName, fieldData]) => [fieldName, ""]),
        )
      : {};

  useEffect(() => {
    // Update the phoneNumber state when phone_number prop changes
    setPhoneNumber(phone_number || "");
  }, [phone_number]);

  const [requiredFieldValues, setRequiredFieldValues] =
    useState(initialFieldValues);
  const [messagePrefix, setMessagePrefix] = useState("");
  // Initialize messageContext state variable
  const handleFieldChange = (fieldName, value, fieldData) => {
    let formattedValue = value;
    if (!fieldData) {
      console.error("Field data is undefined for field:", fieldName);
      return;
    }
    switch (fieldData.fieldType) {
      case "CUR":
        formattedValue = value.replace(/[^0-9.]/g, "").slice(0, 7);
        break;
      case "TMR":
        // Format timer field
        formattedValue = value.replace(/[^0-9.]/g, "").slice(0, 2);
        if (selectedType) {
          formattedValue += " " + selectedType; // Append selectedType with a space
        }
        break;
      case "TIME":
        // Ensure value is a Date object
        const date = new Date(value);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        formattedValue = `${formattedHours}:${formattedMinutes} ${ampm}`;
        break;
      case "DATE":
        // Ensure value is a Date object and format to 'MM/DD/YYYY'
        const dateForDate = new Date(value);
        const month = (dateForDate.getMonth() + 1).toString().padStart(2, "0");
        const day = dateForDate.getDate().toString().padStart(2, "0");
        const year = dateForDate.getFullYear();
        formattedValue = `${month}/${day}/${year}`;
        break;
      case "DATE/TIME":
        // Ensure value is a Date object and format to 'MM/DD/YYYY at hh:mm AM/PM'
        const dateTime = new Date(value);
        const monthDateTime = (dateTime.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const dayDateTime = dateTime.getDate().toString().padStart(2, "0");
        const yearDateTime = dateTime.getFullYear();
        const hoursDateTime = dateTime.getHours();
        const minutesDateTime = dateTime.getMinutes();
        const ampmDateTime = hoursDateTime >= 12 ? "PM" : "AM";
        const formattedHoursDateTime = hoursDateTime % 12 || 12;
        const formattedMinutesDateTime =
          minutesDateTime < 10 ? "0" + minutesDateTime : minutesDateTime;
        formattedValue = `${monthDateTime}/${dayDateTime}/${yearDateTime} | ${formattedHoursDateTime}:${formattedMinutesDateTime} ${ampmDateTime}`;
        break;
    }

    // Update the field value in state
    setRequiredFieldValues((prevState) => ({
      ...prevState,
      [fieldName]: formattedValue,
    }));

    setMessagePrefix(fieldData.messagePrefix || null);
  };

  const handleIntFieldChange = (fieldName, event, fieldData) => {
    const value = event.target.value; // Extract the value from the event object
    let formattedValue = value; // Initialize formattedValue with the input value

    if (!fieldData) {
      console.error("Field data is undefined for field:", fieldName);
      return;
    }

    // Check if twoWayEnabled is true
    if (fieldData.twoWayEnabled === "true") {
      setTwoWayEnabled(true); // Update twoWayEnabled state
    }

    switch (fieldData.fieldType) {
      case "CUR":
        formattedValue = value.replace(/[^0-9.]/g, "").slice(0, 7);
        break;
      case "TMR":
        // Clean the input to allow numbers and a single dash
        const cleanValue = value.replace(/[^0-9-]/g, "").slice(0, 7);

        // Split the cleaned value into start and end if a dash is present
        const [start, end] = cleanValue.split("-").map((v) => v.trim());

        // Determine the label based on selectedType and numeric value
        const getLabel = (num) => {
          if (num === 1)
            return selectedType === "days"
              ? "day"
              : selectedType === "hours"
                ? "hour"
                : selectedType === "minutes"
                  ? "minute"
                  : "";
          return selectedType === "days"
            ? "days"
            : selectedType === "hours"
              ? "hours"
              : selectedType === "minutes"
                ? "minutes"
                : "";
        };

        if (end !== undefined) {
          // Handle range input
          const startValue = parseInt(start, 10);
          const endValue = parseInt(end, 10);

          const endLabel = getLabel(endValue);

          formattedValue =
            isNaN(startValue) && isNaN(endValue)
              ? ""
              : `${startValue || ""}-${endValue || ""} ${endLabel}`;
        } else {
          // Handle single value input
          const numericValue = parseInt(start, 10);
          const label = getLabel(numericValue);

          formattedValue = isNaN(numericValue)
            ? ""
            : `${numericValue} ${label}`;
        }
        break;
      // Add cases for other field types as needed
    }

    // Update the field value and twoWayEnabled in state
    setRequiredFieldValues((prevState) => ({
      ...prevState,
      [fieldName]: formattedValue,
    }));

    setMessagePrefix(fieldData.messagePrefix || null);
  };

  // Call handleIntFieldChange with current field value whenever selectedType changes
  useEffect(() => {
    if (fieldNameRef.current && valueRef.current) {
      const fieldData = selectedNotificationData.Fields[fieldNameRef.current];
      handleIntFieldChange(
        fieldNameRef.current,
        { target: { value: valueRef.current } },
        fieldData,
      );
    }
  }, [selectedType]);

  const handleBalanceBlur = (fieldName, value) => {
    // Check if the field type is CUR
    if (selectedNotificationData.Fields[fieldName].fieldType === "CUR") {
      // Remove any characters except digits and the decimal point
      let formattedValue = value?.replace(/[^0-9.]/g, "");

      // Limit the number of digits after the decimal point to two
      const [integerPart, decimalPart] = formattedValue.split(".");
      formattedValue =
        integerPart + (decimalPart ? "." + decimalPart.slice(0, 2) : "");

      // Append .00 if decimal is not present
      if (!formattedValue.includes(".")) {
        formattedValue += ".00";
      }

      // Add commas for thousands
      formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      formattedValue = `$${formattedValue}`;

      // Update the field value in state
      setRequiredFieldValues((prevState) => ({
        ...prevState,
        [fieldName]: formattedValue,
      }));
    }
  };

  const handleButtonClicked = (type, fieldName, value) => {
    setSelectedType(type);
    fieldNameRef.current = fieldName; // Update the reference
    valueRef.current = value; // Update the reference
  };

  const [optionalMessagePrefix, setOptionalMessagePrefix] = useState("");
  const [optionalFieldValues, setOptionalFieldValues] = useState(
    initialOptionalFieldValues,
  );

  // Initialize messageContext state variable

  const handleOptionalFieldChange = (fieldName, value, fieldData) => {
    let formattedValue = value;
    if (!fieldData) {
      console.error("Field data is undefined for field:", fieldName);
      return;
    }
    switch (fieldData.fieldType) {
      case "CUR":
        formattedValue = value.replace(/[^0-9.]/g, "").slice(0, 7);
        break;
      case "TMR":
        // Format timer field
        formattedValue = value.replace(/[^0-9.]/g, "").slice(0, 2);
        if (selectedType) {
          formattedValue += " " + selectedType; // Append selectedType with a space
        }
        break;
      case "TIME":
        // Ensure value is a Date object
        const date = new Date(value);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        formattedValue = `${formattedHours}:${formattedMinutes} ${ampm}`;
        break;
      case "DATE":
        // Ensure value is a Date object and format to 'MM/DD/YYYY'
        const dateForDate = new Date(value);
        const month = (dateForDate.getMonth() + 1).toString().padStart(2, "0");
        const day = dateForDate.getDate().toString().padStart(2, "0");
        const year = dateForDate.getFullYear();
        formattedValue = `${month}/${day}/${year}`;
        break;
      case "DATE/TIME":
        // Ensure value is a Date object and format to 'MM/DD/YYYY at hh:mm AM/PM'
        const dateTime = new Date(value);
        const monthDateTime = (dateTime.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const dayDateTime = dateTime.getDate().toString().padStart(2, "0");
        const yearDateTime = dateTime.getFullYear();
        const hoursDateTime = dateTime.getHours();
        const minutesDateTime = dateTime.getMinutes();
        const ampmDateTime = hoursDateTime >= 12 ? "PM" : "AM";
        const formattedHoursDateTime = hoursDateTime % 12 || 12;
        const formattedMinutesDateTime =
          minutesDateTime < 10 ? "0" + minutesDateTime : minutesDateTime;
        formattedValue = `${monthDateTime}/${dayDateTime}/${yearDateTime} | ${formattedHoursDateTime}:${formattedMinutesDateTime} ${ampmDateTime}`;
        break;
      // Add cases for other field types as needed
    }

    // Update the field value in state
    setOptionalFieldValues((prevState) => ({
      ...prevState,
      [fieldName]: formattedValue,
    }));

    setOptionalMessagePrefix(fieldData.messagePrefix || null);
  };

  const handleOptionalIntFieldChange = (fieldName, event, fieldData) => {
    const value = event.target.value; // Extract the value from the event object
    let formattedValue = value; // Initialize formattedValue with the input value
    if (!fieldData) {
      console.error("Field data is undefined for field:", fieldName);
      return;
    }
    switch (fieldData.fieldType) {
      case "CUR":
        formattedValue = value.replace(/[^0-9.]/g, "").slice(0, 7);
        break;
      case "TMR":
        // Clean the input to allow numbers and a single dash
        const cleanValue = value.replace(/[^0-9-]/g, "").slice(0, 7);

        // Split the cleaned value into start and end if a dash is present
        const [start, end] = cleanValue.split("-").map((v) => v.trim());

        // Determine the label based on selectedType and numeric value
        const getLabel = (num) => {
          if (num === 1)
            return optionalSelectedType === "days"
              ? "day"
              : optionalSelectedType === "hours"
                ? "hour"
                : optionalSelectedType === "minutes"
                  ? "minute"
                  : "";
          return optionalSelectedType === "days"
            ? "days"
            : optionalSelectedType === "hours"
              ? "hours"
              : optionalSelectedType === "minutes"
                ? "minutes"
                : "";
        };

        if (end !== undefined) {
          // Handle range input
          const startValue = parseInt(start, 10);
          const endValue = parseInt(end, 10);

          const endLabel = getLabel(endValue);

          formattedValue =
            isNaN(startValue) && isNaN(endValue)
              ? ""
              : `${startValue || ""}-${endValue || ""} ${endLabel}`;
        } else {
          // Handle single value input
          const numericValue = parseInt(start, 10);
          const label = getLabel(numericValue);

          formattedValue = isNaN(numericValue)
            ? ""
            : `${numericValue} ${label}`;
        }
        break;
      // Add cases for other field types as needed
    }

    // Update the field value in state
    setOptionalFieldValues((prevState) => ({
      ...prevState,
      [fieldName]: formattedValue,
    }));

    setOptionalMessagePrefix(fieldData.messagePrefix || null);
  };

  useEffect(() => {
    // Call handleIntFieldChange with current field value whenever selectedType changes
    if (fieldNameRef.current && valueRef.current) {
      // Ensure fieldData is properly defined before calling handleIntFieldChange
      const fieldData = selectedNotificationData.Fields[fieldNameRef.current];
      handleOptionalIntFieldChange(
        fieldNameRef.current,
        { target: { value: valueRef.current } },
        fieldData,
      );
    }
  }, [optionalSelectedType]);

  useEffect(() => {
    // Call handleIntFieldChange with current field value whenever selectedType changes
    if (fieldNameRef.current && valueRef.current) {
      // Ensure fieldData is properly defined before calling handleIntFieldChange
      const fieldData = selectedNotificationData.Fields[fieldNameRef.current];
      handleIntFieldChange(
        fieldNameRef.current,
        { target: { value: valueRef.current } },
        fieldData,
      );
    }
  }, [selectedType]);

  const handleOptionalBalanceBlur = (fieldName, value) => {
    // Check if the field type is CUR
    if (selectedNotificationData.Fields[fieldName].fieldType === "CUR") {
      // Remove any characters except digits and the decimal point
      let formattedValue = value?.replace(/[^0-9.]/g, "");

      // Limit the number of digits after the decimal point to two
      const [integerPart, decimalPart] = formattedValue.split(".");
      formattedValue =
        integerPart + (decimalPart ? "." + decimalPart.slice(0, 2) : "");

      // Append .00 if decimal is not present
      if (!formattedValue.includes(".")) {
        formattedValue += ".00";
      }

      // Add commas for thousands
      formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      formattedValue = `$${formattedValue}`;

      // Update the field value in state
      setOptionalFieldValues((prevState) => ({
        ...prevState,
        [fieldName]: formattedValue,
      }));
    }
  };

  const handleOptionalButtonClicked = (type, fieldName, value) => {
    setOptionalSelectedType(type);
    fieldNameRef.current = fieldName; // Update the reference
    valueRef.current = value; // Update the reference
  };

  const convertToUserTimeZone = (dateTimeString) => {
    // Create a new Date object with the input dateTimeString
    const date = new Date(dateTimeString);

    // Get the user's local time zone offset in minutes
    const userTimeZoneOffset = date.getTimezoneOffset();

    // Adjust the date object to the user's local time zone
    const userLocalTime = new Date(date.getTime() - userTimeZoneOffset * 60000);

    // Return the date in the user's local time zone
    return userLocalTime;
  };

  const timeValueInUserTimeZone = timeValue
    ? convertToUserTimeZone(timeValue)
    : null;
  const dateValueInUserTimeZone = dateValue
    ? convertToUserTimeZone(dateValue)
    : null;
  const dateTimeValueInUserTimeZone = dateTimeValue
    ? convertToUserTimeZone(dateTimeValue)
    : null;

  const handlePhoneNumberChange = (phoneNumber, event) => {
    const rawValue = event.target.value.replace(/[^0-9]/g, "");
    const value = rawValue.slice(0, 10);
    const formattedValue = value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    setPhoneNumber(formattedValue);
  };

  const handleTicketNumberChange = (ticketNumber, event) => {
    const value = event.target.value.slice(0, 10).replace(/[^0-9]/g, "");
    setTicketNumber(value);
  };

  // Initialize fieldValues when selectedNotificationData is not null or undefined
  useEffect(() => {
    if (selectedNotificationData && selectedNotificationData.Fields) {
      const requiredFields = {};
      const optionalFields = {};
      Object.entries(selectedNotificationData.Fields).forEach(
        ([fieldName, fieldData]) => {
          if (fieldData.isRequired) {
            requiredFields[fieldName] = "";
          } else {
            optionalFields[fieldName] = "";
          }
        },
      );

      setRequiredFieldValues(requiredFields);
      setOptionalFieldValues(optionalFields);
    }
  }, [selectedNotificationData, title]);

  const formatPhoneNumber = (locationPhone) => {
    const cleaned = ("" + locationPhone).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  const handleOpenScheduleDialog = () => {
    setOpenScheduleNotificationDialog(true);
  };

  const handleOpenContactListDialog = () => {
    setOpenContactListDialog(true);
  };

  const handleCloseContactListDialog = () => {
    setOpenContactListDialog(false);
  };

  const handlePreviewDialogOpen = () => {
    setPreviewNotificationDialogOpen(true);
  };

  const handleAdditionalMessageChange = (fieldName, fieldData) => (e) => {
    const input = e.target.value;
    const remainingChars = 200 - input.length;
    const color = remainingChars < 10 ? "#8C4350" : "#647D9E";
    setCounterColor(color); // Update the counter color
    setRemainingCharacters(remainingChars); // Update the remaining characters count

    if (input.length <= 200) {
      setRequiredFieldValues((prevState) => ({
        ...prevState,
        [fieldName]: input,
      }));
    }

    setMessagePrefix(fieldData.messagePrefix || null);
  };

  const handleDefaultMessageChange = (e) => {
    const input = e.target.value;
    const remainingChars = 200 - input.length;
    const color = remainingChars < 10 ? "#8C4350" : "#647D9E";
    setCounterColor(color); // Update the counter color
    setRemainingCharacters(remainingChars); // Update the remaining characters count

    if (input.length <= 200) {
      setDefaultMessage(input);
    }
    setDefaultMessagePrefix("Additional notes provided:");
  };

  const [disableScheduleClick, setDisableScheduleClick] = useState(false);

  const handleTitleClick = (title) => {
    const notificationData = selectedNotifications.map(
      (notification) => notification.selected_data[title],
    )[0];
    setTitle(title);
    setNotificationColor(notificationData.Configs.Color);
    setContextMessage(notificationData.Configs["Context Message"] || null);
    setShowExtraInfo(false);
    setSelectedNotificationData(notificationData);
    setShowExtraInfoButton(false);
    setMessagePrefix("");
    setOptionalMessagePrefix("");
    setRequiredFieldValues("");
    setOptionalFieldValues("");
    setAdditionalMessage("");
    setDefaultMessage("");
    setTwoWayEnabled(false);
    setDisableScheduleClick(notificationData.Fields.twoWayEnabled === "true"); // Adjusted to boolean

    // Check if any field has twoWayEnabled set to "true"
    const isTwoWayEnabled = Object.values(notificationData.Fields).some(
      (fieldData) => fieldData.twoWayEnabled === "true",
    );
    setDisableScheduleClick(isTwoWayEnabled);

    // Extract required fields from the selected notification data
    const requiredFields = {};
    Object.entries(notificationData.Fields).forEach(
      ([fieldName, fieldData]) => {
        if (fieldData.isRequired) {
          requiredFields[fieldName] = "";
        }
        if (fieldData.fieldType === "TMR") {
          setSelectedType("minutes");
        }
      },
    );

    // Set the required field values
    setRequiredFieldValues(requiredFields);

    // Set the optional field values to an empty object
    setOptionalFieldValues({});

    setAdditionalMessage("");
    handlePopoverClose(); // Close the popover
  };

  const handlePopoverOpen = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const isSendButtonDisabled = () => {
    return (
      phoneNumber.length < 10 ||
      !ticketNumber ||
      Object.values(requiredFieldValues).some((value) => !value)
    );
  };

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(userTimezone);
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const timeZoneAbbr = currentDate
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];
    setConvertedTimeZoneAbbr(convertedTimeZoneAbbr);
  }, []);

  const getTitleText = (type) => {
    return type;
  };

  const handleCloseDialog = () => {
    setPhoneNumber("");
    setTicketNumber("");
    setMessagePrefix("");
    setContextMessage("");
    setOptionalMessagePrefix("");
    setRequiredFieldValues("");
    setOptionalFieldValues("");
    setAdditionalMessage("");
    setSelectedDateTime(null);
    setShowExtraInfo(false);
    setSelectedDateTime(null);
    setNotificationColor("");
    setAdditionalMessage("");
    setDefaultMessage("");
    setSelectedType("minutes");
    setOptionalSelectedType("minutes");
    setTwoWayEnabled("");

    onClose();
  };

  useEffect(() => {
    if (
      open &&
      accountData &&
      subUserData &&
      selectedNotifications.length > 0
    ) {
      const defaultTitle = Object.keys(
        selectedNotifications[0].selected_data,
      ).find(
        (title) =>
          selectedNotifications[0].selected_data[title]?.Configs?.is_default ===
          "true",
      );
      const defaultNotificationData =
        selectedNotifications[0].selected_data[defaultTitle];
      setTitle(defaultTitle);
      setNotificationColor(defaultNotificationData?.Configs?.Color || "");
      setContextMessage(
        defaultNotificationData?.Configs["Context Message"] || null,
      );
      setSelectedNotificationData(defaultNotificationData);
      setCompanyName(accountData.company_name);
      setLocationPhone(subUserData.location_phone);
      setLocationEmail(subUserData.location_email || subUserData.email);
      setFormattedLocationPhone(formatPhoneNumber(subUserData.location_phone));
      setCompanyWebsite(accountData.company_website);
      setCounterColor("#647D9E");
      setRemainingCharacters(225);
      const isTwoWayEnabled = Object.values(
        defaultNotificationData.Fields || {},
      ).some((fieldData) => fieldData.twoWayEnabled === "true");
      setDisableScheduleClick(isTwoWayEnabled);
    }
    if (selectedNotifications.fieldType === "TMR") {
      setSelectedType("minutes");
    }
  }, [selectedNotifications, accountData, subUserData, open]);

  // Logic to determine whether to show the toggle button
  useEffect(() => {
    const fields = selectedNotificationData?.Fields || {};

    // Check if there are two or more fields, or if there's only one field and its value is not "STR"
    const shouldShowButton =
      Object.keys(fields).length >= 2 ||
      (Object.keys(fields).length === 1 &&
        Object.values(fields)[0].valueType !== "STR");

    setShowExtraInfoButton(shouldShowButton);
  }, [selectedNotificationData]);

  const handleDateTimeSelect = (dateTime) => {
    setSelectedDateTime(dateTime); // Set selected date and time in parent component
    setOpenScheduleNotificationDialog(false); // Close dialog
  };

  const renderNewField = (
    fieldType,
    notificationType,
    includeCustomMessageBox,
  ) => {
    switch (fieldType) {
      case "DATE":
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
              }}
            >
              MM/DD/YYYY
            </Typography>
            <CalendarMonthTwoToneIcon className="change_field_icon" />
          </Box>
        );
      case "TIME":
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
              }}
            >
              HH:MM AM/PM
            </Typography>
            <QueryBuilderTwoToneIcon className="change_field_icon" />
          </Box>
        );
      case "DATE/TIME":
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
              }}
            >
              MM/DD/YYYY
            </Typography>
            <CalendarMonthTwoToneIcon className="change_field_icon" />
            <Typography
              sx={{
                marginRight: "auto",
                alignContent: "center",
                mt: 0.25,
                fontWeight: 600,
                fontSize: "15px",
                color: "#747C86",
                ml: 1,
                mr: 1,
              }}
            >
              HH:MM AM/PM
            </Typography>
            <QueryBuilderTwoToneIcon className="change_field_icon" />
          </Box>
        );
      case "CUR":
        return (
          <Typography
            sx={{
              marginRight: "auto",
              mt: 0.35,
              fontWeight: 600,
              fontSize: "15px",
              color: "#747C86",
              ml: 1,
              mr: 1,
            }}
          >
            $0.00
          </Typography>
        );
      case "STR":
        return includeCustomMessageBox ? (
          <Box className="custom_message_field">Custom Message Box</Box>
        ) : null;
      case "TMR":
        return (
          <Stack direction="row" spacing={1} style={{ marginLeft: "120px" }}>
            <Box sx={{ flexGrow: 1, marginLeft: "auto" }} />

            <Chip
              label="Days"
              sx={{ fontWeight: 600, fontSize: "12px", color: "#747C86" }}
            />
            <Chip
              label="Hours"
              sx={{ fontWeight: 600, fontSize: "12px", color: "#747C86" }}
            />
            <Chip
              label="Minutes"
              sx={{ fontWeight: 600, fontSize: "12px", color: "#747C86" }}
            />
          </Stack>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (open) {
      refetchSubscriptionDetails();
      refreshSubscription();
    }
  }, [subscriptionValid, refetchSubscriptionDetails, open]);

  const handleSubmit = async () => {
    setLoading(true);

    if (subscriptionDetails?.subscriptionStatus === "inactive") {
      setInactiveSubscriptionDialogOpen(true);
      setLoading(false);
      return;
    }

    // Check if open before proceeding
    const isOpen = checkIfOpen(businessHours);

    if (!isOpen && !selectedDateTime) {
      setOpenDialog(true); // Show the After Hours Dialog
      setLoading(false);
      return;
    }

    try {
      pauseAutoRefresh();
      const requiredField = requiredFieldValues
        ? Object.keys(requiredFieldValues)[0]
        : null;
      const RequiredValue = requiredFieldValues
        ? requiredFieldValues[requiredField]
        : null;
      const optionalField = optionalFieldValues
        ? Object.keys(optionalFieldValues)[0]
        : null;
      const OptionalValue = optionalFieldValues
        ? optionalFieldValues[optionalField]
        : null;
      const postData = {};
      let locationName =
        subUserData.location_name || subUserData.assigned_location;
      if (companyName) postData.companyName = companyName;
      if (requiredFieldValues)
        postData.requiredFieldValues = requiredFieldValues;
      if (optionalFieldValues)
        postData.optionalFieldValues = optionalFieldValues;
      if (phoneNumber) postData.phoneNumber = phoneNumber;
      if (ticketNumber) postData.ticketNumber = ticketNumber;
      if (contextMessage) postData.contextMessage = contextMessage;
      if (messagePrefix) postData.messagePrefix = messagePrefix;
      if (additionalMessage) postData.additionalMessage = additionalMessage;
      if (defaultMessage) postData.defaultMessage = defaultMessage;
      if (title) postData.title = title;
      if (optionalMessagePrefix)
        postData.optionalMessagePrefix = optionalMessagePrefix;
      if (currentDayOfWeek) postData.currentDayOfWeek = currentDayOfWeek;
      if (currentHours) postData.currentHours = currentHours;
      if (formattedLocationPhone)
        postData.formattedLocationPhone = formattedLocationPhone;
      if (locationEmail) postData.locationEmail = locationEmail;
      if (companyWebsite) postData.companyWebsite = companyWebsite;
      if (userCurrentDate) postData.userCurrentDate = userCurrentDate;
      if (selectedDateTime) postData.selectedDateTime = selectedDateTime;
      if (timeValueInUserTimeZone)
        postData.timeValueInUserTimeZone = timeValueInUserTimeZone;
      if (dateValueInUserTimeZone)
        postData.dateValueInUserTimeZone = dateValueInUserTimeZone;
      if (dateTimeValueInUserTimeZone)
        postData.dateTimeValueInUserTimeZone = dateTimeValueInUserTimeZone;
      if (defaultMessagePrefix)
        postData.defaultMessagePrefix = defaultMessagePrefix;
      if (subUserData.active_location)
        postData.locationId = subUserData.active_location;
      if (locationName) postData.locationName = locationName;
      if (username) postData.username = username;
      if (notificationColor) postData.notificationColor = notificationColor;
      if (subUserData.username) postData.username = subUserData.username;
      if (username) postData.username = username;
      if (requiredField) postData.requiredField = requiredField;
      if (RequiredValue) postData.RequiredValue = RequiredValue;
      if (optionalField) postData.optionalField = optionalField;
      if (OptionalValue) postData.OptionalValue = OptionalValue;
      if (twoWayEnabled) postData.twoWayEnabled = twoWayEnabled;

      const accessToken = await getAccessTokenSilently();
      if (isAuthenticated && user?.sub) {
        const response = await fetch(
          `${apiUrl}/communications/new-notification/${user.sub}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(postData),
          },
        );

        if (selectedDateTime && response.ok) {
          showSnackBar("Notification scheduled for delivery.", true);
        } else if (response.ok) {
          // Show success snackbar
          showSnackBar("Notification sent successfully!", true);
        } else {
          console.error("Failed to send message");
          // Show error snackbar
          showSnackBar("Failed to send notification.", false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      // Show error snackbar
      showSnackBar("An unexpected error occurred. Please try again.", false);
    } finally {
      fetchAllWidgetTypes();
      handleReloadComponents();
      setLoading(false);
      resumeAutoRefresh();
      handleCloseDialog();
    }
  };

  const renderIcon = (iconName) => {
    const iconPath = icons[iconName];
    return (
      <img
        src={iconPath}
        alt={iconName}
        className="selected_notification_icon"
      />
    );
  };

  const generateColor = (notificationType) => {
    // Check if selectedNotifications is not null and has at least one item
    if (selectedNotifications && selectedNotifications.length > 0) {
      // Get the notification data for the specified type
      const notificationData =
        selectedNotifications[0]?.selected_data[notificationType];

      // Check if notificationData exists and has the Configs object
      if (
        notificationData &&
        notificationData.Configs &&
        notificationData.Configs.Color
      ) {
        const color = notificationData.Configs.Color;

        // Generate additional colors based on the provided color
        let cardBackgroundColor,
          borderColor,
          fieldFontTextColor,
          iconBackgroundColor,
          titleColor,
          containerBackgroundColor,
          hoverColor,
          buttonColor;

        switch (color) {
          case "#2BBB9E":
            titleColor = "#2BBB9E";
            cardBackgroundColor = "#DFF5F0";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#2BBB9E";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#BFEBE2";
            hoverColor = "#CAEEE7";

            break;
          //parts arrived
          case "#7589A2":
            titleColor = "#7589A2";
            cardBackgroundColor = "#d4dae3";
            containerBackgroundColor = "#FFFFFF";

            borderColor = "#7589A2";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#7589A2";
            hoverColor = "#B3BECC";
            buttonColor = "#7589a2";
            break;
          //pending approval
          case "#a1848f":
            titleColor = "#a1848f";
            cardBackgroundColor = "#F1EDEE";
            containerBackgroundColor = "#FFFFFF";

            borderColor = "#8C4350";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#FFFFFF";
            hoverColor = "#E3DADD";

            break;
          //action needed
          case "#8C4350":
            titleColor = "#8C4350";
            cardBackgroundColor = "#F9F5F6";
            containerBackgroundColor = "#FFFFFF";

            borderColor = "#8C4350";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#FFFFFF";
            hoverColor = "#E2D0D3";

            break;

          // Palette 1
          case "#7D7371":
            titleColor = "#7D7371";
            cardBackgroundColor = "#F0E7E6";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#7D7371";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#7D7371";
            break;
          // Palette 2
          case "#A9A877":
            titleColor = "#A9A877";
            cardBackgroundColor = "#F2F2E5";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#A9A877";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#A9A877";
            break;
          // Palette 3
          case "#7F8487":
            titleColor = "#7F8487";
            cardBackgroundColor = "#E9EAEA";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#7F8487";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#FFFFFF";
            break;
          // Palette 4
          case "#B5AD8E":
            titleColor = "#B5AD8E";
            cardBackgroundColor = "#F3F2E5";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#B5AD8E";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#B5AD8E";
            break;
          // Palette 5
          case "#827B76":
            titleColor = "#827B76";
            cardBackgroundColor = "#EAE7E5";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#827B76";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#FFFFFF";
            break;
          // Palette 6
          case "#A3847D":
            titleColor = "#A3847D";
            cardBackgroundColor = "#F1E8E7";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#A3847D";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#A3847D";
            break;
          // Palette 7
          case "#858BA9":
            titleColor = "#858BA9";
            cardBackgroundColor = "#EAEAF1";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#858BA9";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#FFFFFF";
            break;
          // Palette 8
          case "#A89A8C":
            titleColor = "#A89A8C";
            cardBackgroundColor = "#F0E9E3";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#A89A8C";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#A89A8C";
            break;
          // Palette 9
          case "#808A7C":
            titleColor = "#808A7C";
            cardBackgroundColor = "#E7ECE5";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#808A7C";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#FFFFFF";
            break;
          // Palette 10
          case "#9F7F75":
            titleColor = "#9F7F75";
            cardBackgroundColor = "#EEE5E3";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#9F7F75";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#9F7F75";
            break;
          // Palette 11
          case "#827D89":
            titleColor = "#827D89";
            cardBackgroundColor = "#E7E6EB";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#827D89";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#FFFFFF";
            break;
          // Palette 13
          case "#7D8585":
            titleColor = "#7D8585";
            cardBackgroundColor = "#E6EAEA";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#7D8585";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#FFFFFF";
            break;
          // Palette 14
          case "#A97D75":
            titleColor = "#A97D75";
            cardBackgroundColor = "#F2E7E5";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#A97D75";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#A97D75";
            break;
          // Palette 15
          case "#828CA0":
            titleColor = "#828CA0";
            cardBackgroundColor = "#E6E9F0";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#828CA0";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#FFFFFF";
            break;
          // Palette 16
          case "#A38D8A":
            titleColor = "#A38D8A";
            cardBackgroundColor = "#EDE6E5";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#A38D8A";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#A38D8A";
            break;
          // Palette 17
          case "#7F8285":
            titleColor = "#7F8285";
            cardBackgroundColor = "#E9E9EA";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#7F8285";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#FFFFFF";
            break;
          // Palette 18
          case "#A6A077":
            titleColor = "#A6A077";
            cardBackgroundColor = "#EDEDE5";
            containerBackgroundColor = "#FFFFFF";
            borderColor = "#A6A077";
            fieldFontTextColor = "#747C86";
            iconBackgroundColor = "#A6A077";
            break;
          default:
            // Default colors if color doesn't match any case
            cardBackgroundColor = "#FFFFFF";
            borderColor = "#000000";
            fieldFontTextColor = "#000000";
            iconBackgroundColor = "#FFFFFF";
        }
        return {
          cardBackgroundColor,
          borderColor,
          fieldFontTextColor,
          iconBackgroundColor,
          titleColor,
          containerBackgroundColor,
          hoverColor,
          buttonColor,
        };
      }
    }
    return {
      cardBackgroundColor: "#FFFFFF",
      borderColor: "#000000",
      fieldFontTextColor: "#000000",
      iconBackgroundColor: "#FFFFFF",
      titleColor: "#000000",
      containerBackgroundColor: "#FFFFFF",
    };
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F2F9FF",
      color: "#48768C",
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  }));

  const TooltipWrapper = ({ children }) => {
    return disableScheduleClick ? (
      <LightTooltip title="Two way notifications cannot be scheduled">
        <Box display="inline-flex">{children}</Box>
      </LightTooltip>
    ) : (
      children
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        sx={{ backdropFilter: "blur(.5px)" }}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            border: "1.5px solid #8fabb9",
            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <IconButton
          className="icon_button"
          aria-label="close"
          onClick={handleCloseDialog}
        >
          <CloseIcon className="close_icon" />
        </IconButton>
        <DialogTitle>
          <Stack
            className="dialog_title"
            direction="column"
            sx={{ alignItems: "flex-start" }}
          >
            <img src={bellIcon} className="dialog_title_icon" alt="bell" />
            <Typography
              color="textSecondary"
              className="new_notification_header"
            >
              New Notification
            </Typography>
          </Stack>
        </DialogTitle>
        <Box
          sx={{
            maxHeight: "60vh", // Constrains overall height
            overflowY: "auto", // Enables scrolling only when necessary
          }}
        >
          <DialogContent className="dialog_content" sx={{}}>
            <>
              <OutlinedInput
                size="small"
                required
                value={phoneNumber}
                onChange={(event) =>
                  handlePhoneNumberChange(phoneNumber, event)
                } // Pass fieldName and event to handleFieldChange
                className="outlined_input_container"
                sx={{
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2F5C71", // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#48768CBA", // Border color on focus
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#8fabb9", // Default border color
                  },
                }}
                startAdornment={
                  <>
                    <img src={phoneIcon} alt="phone" className="phone_icon" />
                    <InputLabel className="input_label">Send To:</InputLabel>
                  </>
                }
                inputProps={{
                  className: "input_text",
                }}
                endAdornment={
                  <Box className="end_adornment_container">
                    <Paper
                      onClick={handleOpenContactListDialog}
                      sx={{
                        border: "1.5px solid #B0BEC5",
                        padding: "5.5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Ensures the icon is centered
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        borderRadius: "50%",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                        mr: "3.5px",
                        height: "30px", // Ensures a perfect circle
                        width: "30px", // Same as height to maintain a circle
                      }}
                    >
                      <img
                        src={ContactListIcon}
                        alt="Contact List"
                        style={{
                          height: "19px",
                          width: "19px",
                          justifyContent: "center",
                        }} // Slightly smaller to fit inside the circle
                      />
                    </Paper>
                  </Box>
                }
              />
              <Box
                className="notification_select_container"
                sx={{ border: "1px solid #8fabb9", alignItems: "center" }}
              >
                <Box
                  sx={{
                    borderRadius: "20px",
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                  }}
                >
                  <i
                    style={{
                      display: "inline-flex",
                      color: "#647D9E",
                      marginLeft: "20px",
                      marginRight: "5px",
                      fontSize: "17px",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    className="fa-regular fa-bell"
                  ></i>

                  <Typography
                    className="notification_label"
                    sx={{ color: "#647D9E", lineHeight: "-3%" }}
                  >
                    Notification:
                  </Typography>

                  <Typography
                    className="notification_text"
                    sx={{
                      color: `${generateColor(title).titleColor}`,
                      lineHeight: "3%",
                      pt: "2px",
                    }}
                  >
                    {title}
                  </Typography>

                  {notificationTitles.length > 1 && (
                    <Paper
                      elevation={3}
                      onClick={handlePopoverOpen}
                      sx={{
                        border: `1px solid ${generateColor(title).titleColor}`,
                        padding: "5px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#FFFFF",
                        cursor: "pointer",
                        borderRadius: "50%",
                        marginRight: "19px",
                        "&:hover": {
                          backgroundColor: `${generateColor(title).hoverColor}`,
                          transform: "scale(1.1)",
                        },
                      }}
                    >
                      <FormatListBulletedTwoToneIcon
                        onClick={handlePopoverOpen}
                        style={{
                          fontSize: "14px",
                          color: `${generateColor(title).titleColor}`,
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                          boxShadow: "0 0 1px rgba(0, 0, 0, 0.1)",
                          border: "none",
                          borderRadius: "50%",
                        }}
                      />
                    </Paper>
                  )}
                </Box>
                {Boolean(popoverAnchor) && (
                  <Box
                    className="notification_select_popover_container"
                    onClick={handlePopoverClose}
                  />
                )}
                <Popover
                  open={Boolean(popoverAnchor)}
                  anchorEl={popoverAnchor}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right", // Adjust to 'left' to make it appear on the left side
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right", // Adjust to 'right' to make it appear on the left side
                  }}
                  PaperProps={{
                    sx: {
                      borderRadius: "15px",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", // Add a box shadow
                      width: "200px",
                      backgroundColor: "#F2F9FF",
                      marginTop: "5px",
                    },
                  }}
                >
                  <Stack direction="row" spacing={0} sx={{ mt: 0.5 }}></Stack>

                  <Stack
                    direction="column"
                    spacing={0}
                    sx={{ paddingTop: 0.5, paddingBottom: 1 }}
                  >
                    {notificationTitles.map((title, index) => {
                      const notificationData =
                        selectedNotifications[0]?.selected_data[title];
                      const color = notificationData?.Configs?.Color || "none";

                      // Check if the title matches the configuration tag of the selected notification
                      const isActive =
                        selectedNotificationData?.Configs?.Tag === title;

                      // Log values for debugging

                      // Render the title only if it's not active
                      return (
                        !isActive && (
                          <Paper
                            sx={{ backgroundColor: "#F2F9FF", padding: "5px" }}
                            key={index}
                          >
                            <Stack
                              direction="row"
                              spacing={0}
                              sx={{
                                px: 1,
                                py: "5px",
                                backgroundColor: "#F2F9FF",
                                "&:hover": {
                                  backgroundColor: "#E3F2FD",
                                  fontWeight: 600,
                                  borderRadius: "6px",
                                },
                              }}
                            >
                              <RadioButtonUncheckedOutlinedIcon
                                onClick={() => handleTitleClick(title)}
                                sx={{
                                  marginLeft: "auto",
                                  mr: "10px",
                                  color: "#48768C",
                                  fontSize: "24px",
                                  cursor: "pointer",

                                  "&:hover": {
                                    backgroundColor: `${generateColor(title).titleColor}`,
                                    padding: 0,
                                    borderRadius: "55px",
                                    color: "#FFFFFF",
                                  },
                                }}
                              />
                              <Typography
                                className="notification_title_popover"
                                variant="body2"
                                onClick={() => handleTitleClick(title)}
                                sx={{
                                  display: "flex", // Make the container a flex container
                                  alignItems: "center", // Center items vertically
                                  backgroundColor: "transparent",
                                  color: "#48768C",
                                  lineHeight: "-2%",

                                  "&:hover": {
                                    fontWeight: 600,
                                  },
                                }}
                              >
                                {getTitleText(title)}
                              </Typography>
                            </Stack>
                          </Paper>
                        )
                      );
                    })}
                  </Stack>
                </Popover>
              </Box>
              {/* Notification Details Container */}
              <Box className="notification_details_container">
                <Typography
                  variant="subtitle1"
                  className="notification_details_label"
                >
                  Notification Details
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  className="notification_details_stack"
                  sx={{ ml: "30px", mb: 1, mt: 1 }}
                >
                  <img src={ticketIcon} alt="ticket" className="ticket_icon" />
                  <TextField
                    size="small"
                    variant="standard"
                    placeholder="Ticket Number"
                    value={ticketNumber}
                    onChange={(event) =>
                      handleTicketNumberChange(ticketNumber, event)
                    } // Pass fieldName and event to handleFieldChange
                    className="ticket_number_input"
                    InputProps={{
                      style: {
                        color: "#647D9E",
                        fontFamily: "Source Sans Pro, sans-serif",
                        pl: "2px",
                        mt: "5px",
                      },
                    }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#647D9E",
                        marginLeft: "5px",
                        mt: "5px", // Change color of the label font
                      },

                      "& .MuiInputBase-input": {
                        height: "25px",
                        marginLeft: "2px",
                      },
                      "& .MuiInput-underline": {
                        "&:hover:not(.Mui-disabled):before": {
                          borderBottomColor: "#2F5C71", // onHover border color
                        },
                        "&.Mui-focused:before": {
                          borderBottomColor: "#2F5C71", // onFocus border color
                        },
                        "&.Mui-focused:after": {
                          borderBottomColor: "#48768CBA", // Focused border color (with transparency)
                          borderWidth: "2px", // Optional: Adjust border width for focus state
                        },
                      },
                    }}
                  />
                </Stack>
                {/* Required Text Feilds */}
                {selectedNotificationData && (
                  <Box>
                    {Object.entries(selectedNotificationData.Fields)
                      .filter(([fieldName, fieldData]) => fieldData.isRequired) // Filter out fields where isRequired is true
                      .map(
                        (
                          [fieldName, fieldData],
                          index, // Add index parameter to map function
                        ) =>
                          fieldData.valueType !== "STR" &&
                          fieldData.fieldType !== "TIME" &&
                          fieldData.fieldType !== "DATE" &&
                          fieldData.fieldType !== "DATE/TIME" && (
                            <Box
                              key={fieldName + index}
                              className="timer_container"
                            >
                              {" "}
                              {/* Ensure unique key */}
                              <Stack
                                direction="row"
                                sx={{ alignItems: "center", mt: "0px" }}
                              >
                                <Box sx={{ mt: 1 }}>
                                  {renderIcon(fieldData.icon)}
                                </Box>

                                <TextField
                                  placeholder={fieldName}
                                  variant="standard"
                                  value={requiredFieldValues[fieldName] || ""}
                                  onChange={(event) =>
                                    handleIntFieldChange(
                                      fieldName,
                                      event,
                                      fieldData,
                                    )
                                  }
                                  onBlur={() =>
                                    handleBalanceBlur(
                                      fieldName,
                                      requiredFieldValues[fieldName],
                                    )
                                  }
                                  className="timer_text_field"
                                  inputProps={{
                                    className: "selected_notification_input",
                                  }}
                                  sx={{
                                    "& .MuiInput-underline": {
                                      "&:hover:not(.Mui-disabled):before": {
                                        borderBottomColor: "#2F5C71", // onHover border color
                                      },
                                      "&.Mui-focused:before": {
                                        borderBottomColor: "#2F5C71", // onFocus border color
                                      },
                                      "&.Mui-focused:after": {
                                        borderBottomColor: "#48768CBA", // Focused border color (with transparency)
                                        borderWidth: "2px", // Optional: Adjust border width for focus state
                                      },
                                    },
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <>
                                        {fieldData.fieldType === "TMR" && (
                                          <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{ pb: "5px", mt: "8px" }}
                                          >
                                            <Chip
                                              label="Days"
                                              onClick={() =>
                                                handleButtonClicked(
                                                  "days",
                                                  fieldName,
                                                  requiredFieldValues[
                                                    fieldName
                                                  ],
                                                )
                                              }
                                              sx={{
                                                color:
                                                  selectedType === "days"
                                                    ? "#FFFFFF"
                                                    : "#6F89A9",
                                                fontWeight: 600,
                                                fontSize: "12px",
                                                border: "1px solid #c6d4dc",
                                                backgroundColor:
                                                  selectedType === "days"
                                                    ? "#7e9dae"
                                                    : "#d9e2e7",
                                                "&:hover": {
                                                  backgroundColor:
                                                    selectedType === "days"
                                                      ? "#7e9dae"
                                                      : "#c6d4dc",
                                                  color:
                                                    selectedType === "days"
                                                      ? "#FFFFFF"
                                                      : "#6F89A9",
                                                },
                                              }}
                                            />
                                            <Chip
                                              label="Hours"
                                              onClick={() =>
                                                handleButtonClicked(
                                                  "hours",
                                                  fieldName,
                                                  requiredFieldValues[
                                                    fieldName
                                                  ],
                                                )
                                              }
                                              sx={{
                                                color:
                                                  selectedType === "hours"
                                                    ? "#FFFFFF"
                                                    : "#6F89A9",
                                                fontWeight: 600,
                                                fontSize: "12px",
                                                border: "1px solid #c6d4dc",
                                                backgroundColor:
                                                  selectedType === "hours"
                                                    ? "#7e9dae"
                                                    : "#d9e2e7",
                                                "&:hover": {
                                                  backgroundColor:
                                                    selectedType === "hours"
                                                      ? "#7e9dae"
                                                      : "#c6d4dc",
                                                  color:
                                                    selectedType === "hours"
                                                      ? "#FFFFFF"
                                                      : "#6F89A9",
                                                },
                                              }}
                                            />{" "}
                                            <Chip
                                              label="Minutes"
                                              onClick={() =>
                                                handleButtonClicked(
                                                  "minutes",
                                                  fieldName,
                                                  requiredFieldValues[
                                                    fieldName
                                                  ],
                                                )
                                              }
                                              sx={{
                                                color:
                                                  selectedType === "minutes"
                                                    ? "#FFFFFF"
                                                    : "#6F89A9",
                                                fontWeight: 600,
                                                fontSize: "12px",
                                                border: "1px solid #c6d4dc",
                                                backgroundColor:
                                                  selectedType === "minutes"
                                                    ? "#7e9dae"
                                                    : "#d9e2e7",
                                                "&:hover": {
                                                  backgroundColor:
                                                    selectedType === "minutes"
                                                      ? "#7e9dae"
                                                      : "#c6d4dc",
                                                  color:
                                                    selectedType === "minutes"
                                                      ? "#FFFFFF"
                                                      : "#6F89A9",
                                                },
                                              }}
                                            />{" "}
                                          </Stack>
                                        )}
                                      </>
                                    ),
                                  }}
                                />
                              </Stack>
                            </Box>
                          ),
                      )}

                    <Box className="time_picker_container">
                      {Object.entries(selectedNotificationData.Fields)
                        .filter(
                          ([fieldName, fieldData]) => fieldData.isRequired,
                        )
                        .map(
                          (
                            [fieldName, fieldData],
                            index, // Add index parameter to map function
                          ) => (
                            <React.Fragment key={fieldName + index}>
                              {" "}
                              {/* Assign a unique key to the fragment */}
                              {fieldData.fieldType === "TIME" && (
                                <Stack
                                  direction="row"
                                  sx={{
                                    marginLeft: "auto",
                                    alignItems: "center",
                                    mt: -1.45,
                                  }}
                                >
                                  <Box sx={{ mb: -1.35 }}>
                                    {renderIcon(fieldData.icon)}
                                  </Box>

                                  <Box
                                    sx={{
                                      border: "none",
                                      width: "100%",
                                      pr: -1,
                                      pl: 1.3,
                                    }}
                                  >
                                    <TimeField
                                      label={fieldName}
                                      onChange={(value) =>
                                        handleFieldChange(
                                          fieldName,
                                          value,
                                          fieldData,
                                        )
                                      }
                                      ampm
                                      views={["hours", "minutes"]}
                                      value={
                                        requiredFieldValues[fieldName] || null
                                      }
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          variant: "standard",
                                          fullWidth: true,
                                        },
                                      }}
                                      sx={{
                                        "& .MuiInputLabel-root": {
                                          color: "#647D9E",
                                          ml: "2px",
                                        },
                                        "& .MuiInputLabel-root.MuiInputLabel-shrink":
                                          {
                                            display: requiredFieldValues[
                                              fieldName
                                            ]
                                              ? "none"
                                              : "none",
                                          },
                                        "& .MuiInputBase-input": {
                                          height: "25px",
                                          mb: "-2px",
                                          marginLeft: "2px",
                                          color: "#647D9E",
                                        },
                                        "& .MuiInput-underline": {
                                          "&:hover:not(.Mui-disabled):before": {
                                            borderBottomColor: "#2F5C71", // onHover border color (green, for example)
                                          },
                                          "&.Mui-focused:before": {
                                            borderBottomColor: "#2F5C71", // onFocus border color (orange, for example)
                                          },
                                          "&.Mui-focused:after": {
                                            borderBottomColor: "#48768CBA", // Focus border color (orange)
                                            borderWidth: "2px", // Optional: Adjust border width for focus state
                                          },
                                        },
                                      }}
                                    />
                                  </Box>
                                </Stack>
                              )}
                            </React.Fragment>
                          ),
                        )}

                      {Object.entries(selectedNotificationData.Fields)
                        .filter(
                          ([fieldName, fieldData]) => fieldData.isRequired,
                        )
                        .map(([fieldName, fieldData]) => (
                          <React.Fragment key={fieldName}>
                            {fieldData.fieldType === "DATE" && (
                              <>
                                <Stack
                                  direction="row"
                                  sx={{
                                    marginLeft: "auto",
                                    alignItems: "center",
                                    mt: "-5px",
                                  }}
                                >
                                  <Box sx={{ mb: -1.35 }}>
                                    {renderIcon(fieldData.icon)}
                                  </Box>
                                  <Box
                                    sx={{
                                      border: "none",
                                      width: "100%",
                                      pr: -1.5,
                                      pl: 1.3,
                                      mt: "-.5px",
                                    }}
                                  >
                                    <DateField
                                      label={fieldName}
                                      onChange={(value) =>
                                        handleFieldChange(
                                          fieldName,
                                          value,
                                          fieldData,
                                        )
                                      } // Pass fieldName, value, and fieldData
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          variant: "standard",
                                          fullWidth: true,
                                        },
                                      }}
                                      sx={{
                                        "& .MuiInputLabel-root": {
                                          color: "#647D9E",
                                          ml: "2px",
                                        },
                                        "& .MuiInputLabel-root.MuiInputLabel-shrink":
                                          {
                                            display: requiredFieldValues[
                                              fieldName
                                            ]
                                              ? "none"
                                              : "none",
                                          },
                                        "& .MuiInputBase-input": {
                                          height: "25px",
                                          mb: "-2px",
                                          marginLeft: "2px",
                                          color: "#647D9E",
                                        },
                                        "& .MuiInput-underline": {
                                          "&:hover:not(.Mui-disabled):before": {
                                            borderBottomColor: "#2F5C71", // onHover border color (green, for example)
                                          },
                                          "&.Mui-focused:before": {
                                            borderBottomColor: "#2F5C71", // onFocus border color (orange, for example)
                                          },
                                          "&.Mui-focused:after": {
                                            borderBottomColor: "#48768CBA", // Focus border color (orange)
                                            borderWidth: "2px", // Optional: Adjust border width for focus state
                                          },
                                        },
                                      }}
                                    />
                                  </Box>
                                </Stack>
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      {Object.entries(selectedNotificationData.Fields)
                        .filter(
                          ([fieldName, fieldData]) => fieldData.isRequired,
                        )
                        .map(
                          (
                            [fieldName, fieldData],
                            index, // Add index parameter to map function
                          ) => (
                            <React.Fragment key={fieldName + index}>
                              {fieldData.fieldType === "DATE/TIME" && (
                                <>
                                  <Stack
                                    direction="row"
                                    sx={{
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      mt: "-5px",
                                    }}
                                  >
                                    <Box sx={{ mb: -1.35 }}>
                                      {renderIcon(fieldData.icon)}
                                    </Box>
                                    <Box
                                      sx={{
                                        border: "none",
                                        width: "100%",
                                        pr: -1,
                                        pl: 1.3,
                                      }}
                                    >
                                      <DateTimeField
                                        label={fieldName}
                                        onChange={(value) =>
                                          handleFieldChange(
                                            fieldName,
                                            value,
                                            fieldData,
                                          )
                                        } // Pass fieldName, value, and fieldData
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            variant: "standard",
                                            fullWidth: true,
                                          },
                                        }}
                                        sx={{
                                          "& .MuiInputLabel-root": {
                                            color: "#647D9E",
                                            ml: "2px",
                                          },
                                          "& .MuiInputLabel-root.MuiInputLabel-shrink":
                                            {
                                              display: requiredFieldValues[
                                                fieldName
                                              ]
                                                ? "none"
                                                : "none",
                                            },
                                          "& .MuiInputBase-input": {
                                            height: "25px",
                                            mb: "-2px",
                                            marginLeft: "2px",
                                            color: "#647D9E",
                                          },
                                          "& .MuiInput-underline": {
                                            "&:hover:not(.Mui-disabled):before":
                                              {
                                                borderBottomColor: "#2F5C71", // onHover border color (green, for example)
                                              },
                                            "&.Mui-focused:before": {
                                              borderBottomColor: "#2F5C71", // onFocus border color (orange, for example)
                                            },
                                            "&.Mui-focused:after": {
                                              borderBottomColor: "#48768CBA", // Focus border color (orange)
                                              borderWidth: "2px", // Optional: Adjust border width for focus state
                                            },
                                          },
                                        }}
                                      />
                                    </Box>
                                  </Stack>
                                </>
                              )}
                            </React.Fragment>
                          ),
                        )}
                    </Box>
                  </Box>
                )}

                {/* Optional Text Feilds */}
                {selectedNotificationData && showExtraInfo && (
                  <Box sx={{ mt: 1.5 }}>
                    {Object.entries(selectedNotificationData.Fields)
                      .filter(([fieldName, fieldData]) => !fieldData.isRequired)
                      .map(
                        ([fieldName, fieldData], index) =>
                          fieldData.valueType !== "STR" &&
                          fieldData.fieldType !== "TIME" &&
                          fieldData.fieldType !== "DATE" &&
                          fieldData.fieldType !== "DATE/TIME" && (
                            <Box
                              key={fieldName + index}
                              className="timer_container"
                            >
                              {" "}
                              {/* Ensure unique key */}
                              <Stack
                                direction="row"
                                sx={{ alignItems: "center", mt: "-3px" }}
                              >
                                <Box sx={{ mt: 1 }}>
                                  {renderIcon(fieldData.icon)}
                                </Box>

                                <TextField
                                  placeholder={fieldName}
                                  variant="standard"
                                  value={optionalFieldValues[fieldName]}
                                  onChange={(event) =>
                                    handleOptionalIntFieldChange(
                                      fieldName,
                                      event,
                                      fieldData,
                                    )
                                  }
                                  onBlur={() =>
                                    handleOptionalBalanceBlur(
                                      fieldName,
                                      optionalFieldValues[fieldName],
                                    )
                                  }
                                  className="timer_text_field"
                                  inputProps={{
                                    className: "selected_notification_input",
                                  }}
                                  sx={{
                                    "& .MuiInput-underline": {
                                      "&:hover:not(.Mui-disabled):before": {
                                        borderBottomColor: "#2F5C71", // onHover border color
                                      },
                                      "&.Mui-focused:before": {
                                        borderBottomColor: "#2F5C71", // onFocus border color
                                      },
                                      "&.Mui-focused:after": {
                                        borderBottomColor: "#48768CBA", // Focused border color (with transparency)
                                        borderWidth: "2px", // Optional: Adjust border width for focus state
                                      },
                                    },
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <>
                                        {fieldData.fieldType === "TMR" && (
                                          <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{ pb: "5px", mt: 0.75 }}
                                          >
                                            <Chip
                                              label="Days"
                                              onClick={() =>
                                                handleOptionalButtonClicked(
                                                  "days",
                                                  fieldName,
                                                  optionalFieldValues[
                                                    fieldName
                                                  ],
                                                )
                                              }
                                              sx={{
                                                color:
                                                  optionalSelectedType ===
                                                  "days"
                                                    ? "#FFFFFF"
                                                    : "#6F89A9",
                                                fontWeight: 600,
                                                fontSize: "12px",
                                                backgroundColor:
                                                  optionalSelectedType ===
                                                  "days"
                                                    ? "#7e9dae"
                                                    : "#d9e2e7",
                                                "&:hover": {
                                                  backgroundColor:
                                                    optionalSelectedType ===
                                                    "days"
                                                      ? "#7e9dae"
                                                      : "#c6d4dc",
                                                  color:
                                                    optionalSelectedType ===
                                                    "days"
                                                      ? "#FFFFFF"
                                                      : "#6F89A9",
                                                },
                                              }}
                                            />
                                            <Chip
                                              label="Hours"
                                              onClick={() =>
                                                handleOptionalButtonClicked(
                                                  "hours",
                                                  fieldName,
                                                  optionalFieldValues[
                                                    fieldName
                                                  ],
                                                )
                                              }
                                              sx={{
                                                color:
                                                  optionalSelectedType ===
                                                  "hours"
                                                    ? "#FFFFFF"
                                                    : "#6F89A9",
                                                fontWeight: 600,
                                                fontSize: "12px",
                                                border: "1px solid #c6d4dc",
                                                backgroundColor:
                                                  optionalSelectedType ===
                                                  "hours"
                                                    ? "#7e9dae"
                                                    : "#d9e2e7",
                                                "&:hover": {
                                                  backgroundColor:
                                                    optionalSelectedType ===
                                                    "hours"
                                                      ? "#7e9dae"
                                                      : "#c6d4dc",
                                                  color:
                                                    optionalSelectedType ===
                                                    "hours"
                                                      ? "#FFFFFF"
                                                      : "#6F89A9",
                                                },
                                              }}
                                            />{" "}
                                            <Chip
                                              label="Minutes"
                                              onClick={() =>
                                                handleOptionalButtonClicked(
                                                  "minutes",
                                                  fieldName,
                                                  optionalFieldValues[
                                                    fieldName
                                                  ],
                                                )
                                              }
                                              sx={{
                                                color:
                                                  optionalSelectedType ===
                                                  "minutes"
                                                    ? "#FFFFFF"
                                                    : "#6F89A9",
                                                fontWeight: 600,
                                                fontSize: "12px",
                                                border: "1px solid #c6d4dc",
                                                backgroundColor:
                                                  optionalSelectedType ===
                                                  "minutes"
                                                    ? "#7e9dae"
                                                    : "#d9e2e7",
                                                "&:hover": {
                                                  backgroundColor:
                                                    optionalSelectedType ===
                                                    "minutes"
                                                      ? "#7e9dae"
                                                      : "#c6d4dc",
                                                  color:
                                                    optionalSelectedType ===
                                                    "minutes"
                                                      ? "#FFFFFF"
                                                      : "#6F89A9",
                                                },
                                              }}
                                            />{" "}
                                          </Stack>
                                        )}
                                      </>
                                    ),
                                  }}
                                />
                              </Stack>
                            </Box>
                          ),
                      )}
                    <Box className="time_picker_container">
                      {Object.entries(selectedNotificationData.Fields)
                        .filter(
                          ([fieldName, fieldData]) => !fieldData.isRequired,
                        ) // Filter out fields where isRequired is false
                        .map(([fieldName, fieldData], index) => (
                          <React.Fragment key={fieldName + index}>
                            {fieldData.fieldType === "TIME" && (
                              <>
                                <Stack
                                  direction="row"
                                  sx={{
                                    marginLeft: "auto",
                                    alignItems: "center",
                                    mt: -1.45,
                                  }}
                                >
                                  <Box sx={{ mb: -1.35 }}>
                                    {renderIcon(fieldData.icon)}
                                  </Box>
                                  <Box
                                    sx={{
                                      border: "none",
                                      width: "100%",
                                      pr: -1,
                                      pl: 1.3,
                                    }}
                                  >
                                    <TimeField
                                      label={fieldName}
                                      onChange={(value) =>
                                        handleOptionalFieldChange(
                                          fieldName,
                                          value,
                                          fieldData,
                                        )
                                      }
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          variant: "standard",
                                          fullWidth: true,
                                        },
                                      }}
                                      sx={{
                                        "& .MuiInputLabel-root": {
                                          color: "#647D9E",
                                          ml: "2px",
                                        },
                                        "& .MuiInputLabel-root.MuiInputLabel-shrink":
                                          {
                                            display: optionalFieldValues[
                                              fieldName
                                            ]
                                              ? "none"
                                              : "none",
                                          },
                                        "& .MuiInputBase-input": {
                                          height: "25px",
                                          mb: "-2px",
                                          marginLeft: "2px",
                                          color: "#647D9E",
                                        },
                                        "& .MuiInput-underline": {
                                          "&:hover:not(.Mui-disabled):before": {
                                            borderBottomColor: "#2F5C71", // onHover border color (green, for example)
                                          },
                                          "&.Mui-focused:before": {
                                            borderBottomColor: "#2F5C71", // onFocus border color (orange, for example)
                                          },
                                          "&.Mui-focused:after": {
                                            borderBottomColor: "#48768CBA", // Focus border color (orange)
                                            borderWidth: "2px", // Optional: Adjust border width for focus state
                                          },
                                        },
                                      }}
                                    />
                                  </Box>
                                </Stack>
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      {Object.entries(selectedNotificationData.Fields)
                        .filter(
                          ([fieldName, fieldData]) => !fieldData.isRequired,
                        ) // Filter out fields where isRequired is false
                        .map(([fieldName, fieldData]) => (
                          <React.Fragment key={fieldName}>
                            {fieldData.fieldType === "DATE" && (
                              <>
                                <Stack
                                  direction="row"
                                  sx={{
                                    marginLeft: "auto",
                                    alignItems: "center",
                                    mt: -1,
                                  }}
                                >
                                  <Box sx={{ mb: -1.35 }}>
                                    {renderIcon(fieldData.icon)}
                                  </Box>
                                  <Box
                                    sx={{
                                      border: "none",
                                      width: "100%",
                                      pr: -1.5,
                                      pl: 1.3,
                                    }}
                                  >
                                    <DateField
                                      label={fieldName}
                                      onChange={(value) =>
                                        handleOptionalFieldChange(
                                          fieldName,
                                          value,
                                          fieldData,
                                        )
                                      }
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          variant: "standard",
                                          fullWidth: true,
                                        },
                                      }}
                                      sx={{
                                        "& .MuiInputLabel-root": {
                                          color: "#647D9E",
                                          ml: 0.1, // Change color of the label font
                                        },
                                        "& .MuiInputLabel-root.MuiInputLabel-shrink":
                                          {
                                            display: optionalFieldValues[
                                              fieldName
                                            ]
                                              ? "none"
                                              : "none",
                                            color: "#647D9E",
                                          },
                                        "& .MuiInputBase-input": {
                                          height: "25px",
                                          mb: "-5px",
                                          marginLeft: "2px",
                                          color: "#647D9E",
                                        },
                                        "& .MuiInput-underline": {
                                          "&:hover:not(.Mui-disabled):before": {
                                            borderBottomColor: "#2F5C71", // onHover border color (green, for example)
                                          },
                                          "&.Mui-focused:before": {
                                            borderBottomColor: "#2F5C71", // onFocus border color (orange, for example)
                                          },
                                          "&.Mui-focused:after": {
                                            borderBottomColor: "#48768CBA", // Focus border color (orange)
                                            borderWidth: "2px", // Optional: Adjust border width for focus state
                                          },
                                        },
                                      }}
                                    />
                                  </Box>
                                </Stack>
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      {Object.entries(selectedNotificationData.Fields)
                        .filter(
                          ([fieldName, fieldData]) => !fieldData.isRequired,
                        ) // Filter out fields where isRequired is false
                        .map(([fieldName, fieldData], index) => (
                          <React.Fragment key={fieldName + index}>
                            {fieldData.fieldType === "DATE/TIME" && (
                              <>
                                <Stack
                                  direction="row"
                                  sx={{
                                    marginLeft: "auto",
                                    alignItems: "center",
                                    mt: "-9px",
                                  }}
                                >
                                  <Box sx={{ mb: -1.35 }}>
                                    {renderIcon(fieldData.icon)}
                                  </Box>
                                  <Box
                                    sx={{
                                      border: "none",
                                      width: "100%",
                                      pr: -1,
                                      pl: 1.3,
                                    }}
                                  >
                                    <DateTimeField
                                      label={fieldName}
                                      onChange={(value) =>
                                        handleOptionalFieldChange(
                                          fieldName,
                                          value,
                                          fieldData,
                                        )
                                      }
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          variant: "standard",
                                          fullWidth: true,
                                        },
                                      }}
                                      sx={{
                                        "& .MuiInputLabel-root": {
                                          color: "#647D9E",
                                          ml: "2px",
                                        },
                                        "& .MuiInputLabel-root.MuiInputLabel-shrink":
                                          {
                                            display: optionalFieldValues[
                                              fieldName
                                            ]
                                              ? "none"
                                              : "none",
                                          },
                                        "& .MuiInputBase-input": {
                                          height: "25px",
                                          mb: "-2px",
                                          marginLeft: "2px",
                                          color: "#647D9E",
                                        },
                                        "& .MuiInput-underline": {
                                          "&:hover:not(.Mui-disabled):before": {
                                            borderBottomColor: "#2F5C71", // onHover border color (green, for example)
                                          },
                                          "&.Mui-focused:before": {
                                            borderBottomColor: "#2F5C71", // onFocus border color (orange, for example)
                                          },
                                          "&.Mui-focused:after": {
                                            borderBottomColor: "#48768CBA", // Focus border color (orange)
                                            borderWidth: "2px", // Optional: Adjust border width for focus state
                                          },
                                        },
                                      }}
                                    />
                                  </Box>
                                </Stack>
                              </>
                            )}
                          </React.Fragment>
                        ))}
                    </Box>
                  </Box>
                )}

                {/* Custom Message Field */}
                {selectedNotificationData && (
                  <Box sx={{ px: 3.25, mt: -0.5 }}>
                    {Object.entries(selectedNotificationData.Fields).map(
                      ([fieldName, fieldData]) =>
                        fieldData.valueType === "STR" && (
                          <Box key={fieldName} sx={{ px: 0.25 }}>
                            <TextField
                              fullWidth
                              multiline
                              label={fieldName}
                              value={requiredFieldValues[fieldName]}
                              onChange={handleAdditionalMessageChange(
                                fieldName,
                                fieldData,
                              )} // Pass fieldName to the handler
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <Typography
                                    sx={{
                                      color: counterColor,
                                      textAlign: "right",
                                      fontSize: "12px",
                                      display: "flex",
                                      position: "absolute",
                                      mt: 3,
                                      bottom: "1px",
                                      right: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {200 -
                                      requiredFieldValues[fieldName]?.length ||
                                      0}{" "}
                                    / 200
                                  </Typography>
                                ),
                                inputProps: { style: { color: "#53666F" } }, // Change text color here
                              }}
                              sx={{
                                mt: "10px",

                                "& .MuiInputLabel-outlined": {
                                  fontSize: "15px",
                                  color: "#647D9E",
                                  fontStyle: "italic",
                                  marginRight: 1,
                                  px: 1,
                                  lineHeight: "-2%",
                                  letterSpacing: "-1%",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#48768C", // Adjust border color when focused
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#48768C", // Adjust border color on hover
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#48768C",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "#B2BAC2",
                                  },
                                },
                              }}
                            />
                          </Box>
                        ),
                    )}
                  </Box>
                )}

                {/* Default Message Field */}
                {showExtraInfo && selectedNotificationData && (
                  <>
                    <Box sx={{ px: 3.75, mt: 3 }}>
                      {/* Conditionally render the TextField based on the absence of 'STR' */}
                      {!Object.values(selectedNotificationData.Fields).some(
                        (field) => field.valueType === "STR",
                      ) && (
                        <TextField
                          fullWidth
                          multiline
                          label="Include custom message in your notification"
                          value={defaultMessage}
                          onChange={handleDefaultMessageChange}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <Typography
                                sx={{
                                  color: counterColor,
                                  textAlign: "right",
                                  textAlign: "right",
                                  fontSize: "12px",
                                  display: "flex",
                                  position: "absolute",
                                  mt: 3,
                                  bottom: "1px", // Adjust vertical position as needed
                                  right: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                {200 - defaultMessage.length || 0} / 200
                              </Typography>
                            ),
                            inputProps: { style: { color: "#53666F" } }, // Change text color here
                          }}
                          sx={{
                            justifyContent: "center",
                            mt: "10px",
                            width: "100%",
                            "& .MuiInputLabel-outlined": {
                              fontSize: "15px",
                              color: "#647D9E",
                              fontStyle: "italic",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#48768C", // Adjust border color when focused
                              },
                              "&:hover fieldset": {
                                borderColor: "#48768C", // Adjust border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#48768C",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#B2BAC2",
                              },
                            },
                            "& .MuiInputLabel-root": {
                              color: "#6c90a2", // Default label color
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: "#5a8397", // Label color when focused
                              fontSize: "16px",
                            },
                          }}
                        />
                      )}
                    </Box>
                  </>
                )}

                <Box className="button_container">
                  <Box display="inline-flex">
                    {(showExtraInfo || !showExtraInfoButton) && (
                      <TooltipWrapper>
                        <Button
                          disabled={disableScheduleClick}
                          onClick={handleOpenScheduleDialog}
                          sx={{
                            fontSize: "14.5px",
                            lineHeight: "15px",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginTop: "2.5px",
                            marginRight: "35px",
                            marginBottom: "2px",
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #B0BEC5",
                            borderRadius: "10px",
                            color: "#48768C",
                            textAlign: "center",
                            fontWeight: 600,
                            px: 1,
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            "&:hover": { backgroundColor: "#EAF0FB" },
                          }}
                        >
                          Schedule
                        </Button>
                      </TooltipWrapper>
                    )}
                  </Box>

                  <Typography
                    onClick={
                      isSendButtonDisabled() ? null : handlePreviewDialogOpen
                    }
                    className={`preview_button ${isSendButtonDisabled() ? "disabled" : ""}`}
                  >
                    Preview
                  </Typography>

                  {/* Render toggle button only if there is one field and its value is not "STR" */}
                  {showExtraInfoButton && (
                    <Typography
                      sx={{ "&:hover": { backgroundColor: "#EAF0FB" } }}
                      onClick={() => setShowExtraInfo(!showExtraInfo)}
                      className="toggle_info_button"
                    >
                      {showExtraInfo ? "- Less" : "+ More "}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box></Box>
              <Box sx={{ mt: 1, mb: -1, ml: 1 }}>
                {selectedDateTime && (
                  <Typography className="selected_date_time_text">{`Your notification will be sent on: ${selectedDateTime.format("M/D/YY")} at ${selectedDateTime.format("hh:mm A")}  ${userTimeZone}`}</Typography>
                )}
              </Box>
            </>
          </DialogContent>
        </Box>
        <DialogActions className="dialog_actions">
          {selectedDateTime && (
            <Button
              variant="contained"
              color="primary"
              disabled={loading || isSendButtonDisabled()}
              onClick={() => {
                handleSubmit(
                  phoneNumber,
                  ticketNumber,
                  requiredFieldValues,
                  optionalFieldValues,
                  additionalMessage,
                  title,
                  selectedNotificationData,
                  messagePrefix,
                  contextMessage,
                );
              }}
              className="send_notification_button"
            >
              {loading ? "Scheduling..." : "Schedule Notification"}
            </Button>
          )}

          {!selectedDateTime && (
            <Button
              variant="contained"
              color="primary"
              disabled={loading || isSendButtonDisabled()}
              onClick={() =>
                handleSubmit(
                  phoneNumber,
                  ticketNumber,
                  requiredFieldValues,
                  optionalFieldValues,
                  additionalMessage,
                  title,
                  selectedNotificationData,
                  messagePrefix,
                  contextMessage,
                  selectedDateTime,
                )
              }
              className="send_notification_button"
            >
              {loading ? "Sending..." : "Send Notification"}
            </Button>
          )}
        </DialogActions>

        <ContactListDialog
          open={openContactListDialog}
          handleClose={handleCloseContactListDialog}
          handlePhoneNumberChange={handlePhoneNumberChange}
        />
        <PreviewNotification
          companyName={companyName}
          phoneNumber={phoneNumber}
          ticketNumber={ticketNumber}
          requiredFieldValues={requiredFieldValues}
          additionalMessage={additionalMessage}
          title={title}
          messagePrefix={messagePrefix}
          contextMessage={contextMessage}
          optionalMessagePrefix={optionalMessagePrefix}
          optionalFieldValues={optionalFieldValues}
          currentDayOfWeek={currentDayOfWeek}
          currentHours={currentHours}
          formattedLocationPhone={formattedLocationPhone}
          locationEmail={locationEmail}
          companyWebsite={companyWebsite}
          userCurrentDate={userCurrentDate}
          open={previewNotificationDialogOpen}
          defaultMessage={defaultMessage}
          twoWayEnabled={twoWayEnabled}
          onClose={() => setPreviewNotificationDialogOpen(false)}
          PaperProps={{
            style: {
              backgroundColor: "#747C86",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "20px",
            },
          }}
        />
        <ScheduleNotificationDialog
          open={openScheduleNotificationDialog}
          onClose={() => setOpenScheduleNotificationDialog(false)}
          onSelectDateTime={handleDateTimeSelect}
          businessHours={businessHours}
          PaperProps={{
            style: {
              backgroundColor: "#747C86",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "20px",
            },
          }}
        ></ScheduleNotificationDialog>
      </Dialog>
      <AfterHoursDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleOpenScheduleDialog={handleOpenScheduleDialog}
        selectedDateTime={selectedDateTime}
        setSelectedDateTime={setSelectedDateTime}
        setLoading={setLoading}
        businessHours={businessHours}
        disableScheduleClick={disableScheduleClick}
      />
      <InactiveSubscriptionDialog
        open={inactiveSubscriptionDialogOpen}
        onClose={handleCloseInactiveSubscriptionDialog}
        apiUrl={apiUrl}
        setInactiveSubscriptionDialogOpen={setInactiveSubscriptionDialogOpen}
        subscriptionValid={subscriptionValid}
        verifying={verifying}
        CloseInactiveSubscriptionDialog={CloseInactiveSubscriptionDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </LocalizationProvider>
  );
}
