import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  Typography,
  IconButton,
  Portal,
  Stack,
  CardContent,
  Box,
  Tabs,
  Tab,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "../../../context/AppContext";
import { useAccountContext } from "src/context/AccountContext";
import { dismissItem } from "src/components/NewUserChecklist/dismissItem";
import ReviewDialog from "../ReviewDialog/ReviewDialog";
import useAddLocation from "src/components/payments/hooks/useAddLocation";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import addLocationIcon from "src/public/assets/icons/icons/newLocation.png";
import LocationInfoCard from "./LocationInfoCard";
import LocationHoursCard from "./LocationHoursCard";
import EmployeeSetupCard from "./EmployeeSetupCard";
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext";
import { useAuth } from "src/context/AuthContext.js"; // Assuming subUserData is coming from this context
import CancelCloseDialog from "../CancelCloseDialog";

import dayjs from "dayjs";
import { set } from "lodash";
import { setConfig } from "dompurify";

const apiUrl = process.env.REACT_APP_API_URL;

const ViewLocationModal = ({
  open,
  onClose,
  users,
  locations,
  fetchLocations,
  fetchUsers,
  editLocationData,
  viewLocationData,
}) => {
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { accountData, onboardingData, fetchOnboardingRecord } =
    useAccountContext();
  const [item, setItem] = useState("addLocation");
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const { addNewLocation, addLocationLoading, addLocationError, success } =
    useAddLocation();
  const [hoursItem, setHoursItem] = useState("confirmHours");
  const {
    subUserData,
    username,
    logout: customLogout,
    resetContext,
  } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isCancelCloseOpen, setIsCancelCloseOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  //New Location Info
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [locationNameTaken, setLocationNameTaken] = useState(false);
  //Confirm Hours
  const [confirmHours, setConfirmHours] = useState(false);
  const [defaultHoursChanged, setDefaultHoursChanged] = useState(false);
  const [isSaveHoursButtonDisabled, setIsSaveHoursButtonDisabled] =
    useState(false); // State to manage button disabled state
  //New User
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pin, setPin] = useState("");
  const [showPin, setShowPin] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [isSaveUserButtonEnabled, setIsSaveUserButtonEnabled] = useState(false);
  const [hideCreateUserButton, setHideCreateUserButton] = React.useState(false);
  const [isAssignEmployeeVisible, setIsAssignEmployeeVisible] = useState(false);
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userNameTaken, setUserNameTaken] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);
  //Transfer List
  const [checked, setChecked] = useState([]);
  const [right, setRight] = useState([]);
  const [assignedUser, setAssignedUser] = useState(null);
  const [assignedLocation, setAssignedLocation] = useState(null);
  const availableUsers = users.filter(
    (user) => user.user_id !== subUserData.user_id,
  );
  const [left, setLeft] = useState(availableUsers);
  const [isNewUserSaved, setIsNewUserSaved] = useState(false);
  const [showConfirmNewPin, setShowConfirmNewPin] = useState(false);
  const [newUserId, setNewUserId] = useState(null);
  const [newUserCreated, setNewUserCreated] = useState(false);
  const [enableEditButtons, setEnableEditButtons] = useState(false);
  const [confirmUsers, setConfirmUsers] = useState(false);
  const [hoursWarning, setHoursWarning] = useState(false);
  const [usersWarning, setUsersWarning] = useState(false);

  //Confirm Dialog
  const [confirmHeader, setConfirmHeader] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmSubText, setConfirmSubText] = useState("");

  const handleTabChange = (event, newValue) => {
    setIsAssignEmployeeVisible(false);
    if (newUserCreated === false) {
      setHideCreateUserButton(false);
    }
    if (isAssignEmployeeVisible && newUserCreated === false) {
      setHideCreateUserButton(false);
      setFirstName("");
      setLastName("");
      setPin("");
      setConfirmNewPin("");
      setShowConfirmNewPin(false);
      setShowPin(false);
      setUserName("");
      setNewUserName("");
      setIsAdmin(false);
    }
    setSelectedTab(newValue);
    setChecked([]);
  };

  const handleOpenCancelCloseDialog = () => {
    setIsCancelCloseOpen(true);
  };

  const handleCloseCancelCloseDialog = () => {
    setIsCancelCloseOpen(false);
  };

  const handleCancelClose = () => {
    setIsCancelCloseOpen(false);
    onClose();
  };

  const handleCloseReviewDialog = () => {
    setOpenReviewDialog(false);
  };

  const handleOpenReviewDialog = () => {
    setOpenReviewDialog(true);
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    if (!open) {
      setOpenReviewDialog(false);
    }
  }, [open]);

  const { user, getAccessTokenSilently } = useAuth0();
  const userId = user.sub;

  //Location Info Hooks
  useEffect(() => {
    const validateForm = () => {
      const isValidName = name.trim() !== "";
      const isValidEmail = validateEmail(email);
      const isValidPhone = validatePhoneNumber(phone);

      // Only consider fields as "changed" if they are not the same as the original data
      const isNameChanged = name.trim() !== editLocationData.name.trim();
      const isEmailChanged = email.trim() !== editLocationData.email.trim();
      const isPhoneChanged = phone.trim() !== editLocationData.phone.trim();

      // Form is valid if all fields are valid and at least one has changed
      setIsFormValid(
        isValidName &&
          isValidEmail &&
          isValidPhone &&
          (isNameChanged || isEmailChanged || isPhoneChanged),
      );
    };

    validateForm();
  }, [
    name,
    email,
    phone,
    editLocationData.name,
    editLocationData.email,
    editLocationData.phone,
  ]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneRegex.test(phone);
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return `(${match[1]})${match[2] ? " " + match[2] : ""}${match[3] ? "-" + match[3] : ""}`;
    }
    return value;
  };

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    // Capitalize the first letter and keep the rest of the text unchanged
    const capitalizedValue =
      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setName(capitalizedValue);
    setNameTouched(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailTouched(true);
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "").slice(0, 10);
    setPhone(formatPhoneNumber(value));
    setPhoneTouched(true);
  };

  // Validate Unique Location Name
  useEffect(() => {
    // Check if any location's name matches the input `name`, ignoring case,
    // and exclude `editLocationData.name` from the comparison
    const isLocationNameTaken = locations.some(
      (location) =>
        location.name.toLowerCase() === name.toLowerCase() &&
        location.name.toLowerCase() !== editLocationData.name.toLowerCase(),
    );

    // Set state based on whether the location name is taken
    setLocationNameTaken(isLocationNameTaken);
  }, [name, locations, editLocationData.name]); // Trigger when name, locations, or editLocationData.name change

  //Location Hours Hooks
  const [locationHours, setLocationHours] = useState([]);

  useEffect(() => {
    if (open) {
      const fetchLocationHours = async () => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const accessToken = await getAccessTokenSilently();
          const userId = user.sub;
          const response = await fetch(
            `${apiUrl}/api/view-location-hours/${viewLocationData.location_id}/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          const data = await response.json();
          setLocationHours(data); // Store the object directly
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching location hours:", error);
          setIsLoading(false);
        }
      };

      fetchLocationHours();
    }
  }, [open, viewLocationData.location_id]);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Convert locationHours to an array before sorting, if it's an object
  const locationHoursArray =
    locationHours && typeof locationHours === "object"
      ? Object.values(locationHours)
      : [];

  locationHoursArray.sort(
    (a, b) =>
      daysOfWeek.indexOf(a.day_of_week) - daysOfWeek.indexOf(b.day_of_week),
  );

  //New Employee Hooks
  const handleTogglePinVisibility = () => {
    setShowPin(!showPin);
  };

  const handleConfirmNewPinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "").substr(0, 4);
    setConfirmNewPin(inputValue);
  };

  const toggleShowConfirmNewPin = () => {
    setShowConfirmNewPin((prev) => !prev);
  };

  useEffect(() => {
    // Check if any user's username matches newUserName
    const isUsernameTaken = users.some((user) => user.username === userName);

    // Set state based on whether the username is taken
    setUserNameTaken(isUsernameTaken);
  }, [userName, users]); // Trigger when newUserName or users change

  useEffect(() => {
    const isValidInput =
      pin.length >= 4 &&
      pin.length <= 4 &&
      /^\d+$/.test(pin) &&
      confirmNewPin.length === 4 &&
      /^\d+$/.test(confirmNewPin) &&
      pin === confirmNewPin &&
      userNameTaken === false &&
      userName.trim() !== "";

    setIsSaveUserButtonEnabled(isValidInput);
  }, [pin, confirmNewPin, firstName, lastName, newUserName, userNameTaken]);

  const handleEditUser = () => {
    setIsAssignEmployeeVisible(true);
    setHideCreateUserButton(true);
    setIsEditingUser(true);
  };

  /// Transfer List Logic
  const handleToggle = (value) => () => {
    if (!Array.isArray(checked)) {
      setChecked([]);
      return;
    }

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    // Map over selected users to add `isNewUser: false` to each user object
    const selectedUsers = checked
      .filter((user) => left.includes(user))
      .map((user) => ({ ...user, isNewUser: false }));

    // Move modified users to `right` and remove them from `left`
    setRight(right.concat(selectedUsers));
    setLeft(left.filter((user) => !checked.includes(user)));
    setChecked([]);

    // Set the first user as `assignedUser` if any users are selected
    if (selectedUsers.length > 0) {
      setAssignedUser(selectedUsers[0]);
      setConfirmUsers(false);
    }
  };

  const handleCheckedLeft = () => {
    const selectedUsers = checked.filter(
      (user) => right.includes(user) && !user.isNewUser,
    );
    setLeft(left.concat(selectedUsers));
    setRight(right.filter((user) => !checked.includes(user) || user.isNewUser));
    setChecked([]);

    if (selectedUsers.some((user) => user === assignedUser)) {
      setAssignedUser(null);
      setAssignedLocation(null);
      setConfirmUsers(false);
    }
  };

  // When creating a new employee, reset relevant states
  const handleCreateNewEmployeeClick = async () => {
    setAssignedUser(null);
    setAssignedLocation(null);
    setIsAssignEmployeeVisible(true);
    setHideCreateUserButton(true);
  };

  const handleAssignEmployeeClick = async () => {
    setAssignedUser(null);
    setAssignedLocation(null);
    setRight([]);
    setLeft(availableUsers);
    setIsAssignEmployeeVisible(false);
    setHideCreateUserButton(true);
  };

  // Save new user and add to the right list
  const handleSaveNewUserClick = async () => {
    const newUser = {
      first_name: firstName,
      last_name: lastName,
      user_name: userName, // Use this as the unique identifier if user_id is not present
      pin,
      isNewUser: true,
    };

    setRight((prevRight) => {
      const existingUserIndex = prevRight.findIndex(
        (user) => user.user_name === newUser.user_name,
      );

      if (enableEditButtons && existingUserIndex !== -1) {
        // If editing and user exists, update the existing user
        const updatedRight = [...prevRight];
        updatedRight[existingUserIndex] = newUser;
        setConfirmUsers(false);
        return updatedRight;
      } else if (existingUserIndex === -1) {
        setNewUserCreated(true);
        setConfirmUsers(false);
        return [...prevRight, newUser];
      }
      return prevRight;
    });

    // Reset states and hide form
    setIsNewUserSaved(true);
    setIsAssignEmployeeVisible(false);
    setHideCreateUserButton(true);
    setChecked([]);
  };

  // Clear new user data (delete button)
  const handleClearNewUserClick = () => {
    setNewUserId(null);
    setFirstName("");
    setLastName("");
    setUserName("");
    setPin("");
    setConfirmNewPin("");
    setNewUserName("");
    setHideCreateUserButton(false);
    setIsNewUserSaved(false);
    setShowConfirmNewPin(false);
    setConfirmNewPin("");
    setIsAssignEmployeeVisible(false);
    setEnableEditButtons(false);
    setConfirmUsers(false);
    setLeft(availableUsers);
    setRight((prevRight) => prevRight.filter((user) => !user.isNewUser));
    setChecked([]);
  };

  useEffect(() => {
    right.forEach((user) => {
      setNewUserCreated(true);
    });
  }, [right]);

  useEffect(() => {
    const hasNewUser = Object.values(checked).some(
      (user) => user.isNewUser === true,
    );

    if (hasNewUser && checked.length === 1) {
      setEnableEditButtons(true);
    } else {
      setEnableEditButtons(false);
    }
  }, [checked]);

  const handleUpdateChecklist = async () => {
    const userId = user.sub;
    if (accountData.onboarding_complete === false) {
      await dismissItem(item, getAccessTokenSilently, userId);
      await fetchOnboardingRecord();
      return;
    } else {
      return;
    }
  };

  const handleCancelEdit = () => {
    setIsAssignEmployeeVisible(false);
    setHideCreateUserButton(false);
  };

  const handleCancelAddUser = () => {
    if (enableEditButtons) {
      setIsAssignEmployeeVisible(false);
      setHideCreateUserButton(false);
      setChecked([]);
    } else {
      setFirstName("");
      setLastName("");
      setPin("");
      setUserName("");
      setNewUserName("");
      setIsAssignEmployeeVisible(false);
      setHideCreateUserButton(false);
      setChecked([]);
    }
  };

  const handleConfirmUsers = () => {
    setConfirmUsers(true);
    if (usersWarning === true) {
      setUsersWarning(false);
    }
  };

  const handleResetEmployees = () => {
    setRight([]);
    setLeft(availableUsers);
    setNewUserId(null);
    setFirstName("");
    setLastName("");
    setPin("");
    setUserName("");
    setNewUserName("");
    setHideCreateUserButton(false);
    setIsAssignEmployeeVisible(false);
    setAssignedUser(null);
    setAssignedLocation(null);
    setConfirmNewPin("");
    setShowConfirmNewPin(false);
    setIsNewUserSaved(false);
    setConfirmUsers(false);
    setChecked([]);
  };

  const clearData = () => {
    setName(editLocationData?.name);
    setEmail(editLocationData?.email);
    setPhone(editLocationData?.phone);
    setNameTouched(false);
    setEmailTouched(false);
    setPhoneTouched(false);
    setRight([]);
    setLeft(availableUsers);
    setNewUserId(null);
    setFirstName("");
    setLastName("");
    setPin("");
    setUserName("");
    setNewUserName("");
    setHideCreateUserButton(false);
    setIsAssignEmployeeVisible(false);
    setAssignedUser(null);
    setAssignedLocation(null);
    setConfirmNewPin("");
    setShowConfirmNewPin(false);
    setIsNewUserSaved(false);
    setSelectedTab(0);
    setChecked([]);
    setNewUserCreated(false);
    setHoursWarning(false);
    setUsersWarning(false);
    setConfirmHours(false);
  };

  useEffect(() => {
    if (open) {
      clearData();
    }
  }, [open]);

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const [usersByLocation, setUsersByLocation] = useState({
    matching: [],
    nonMatching: [],
  });

  const locationId = editLocationData.location_id;

  useEffect(() => {
    if (Array.isArray(users) && open) {
      // Parse locationId as a number to match against user.location_id
      const locationIdNum = Number(locationId);

      const matchingUsers = users.filter(
        (user) => Number(user.location_id) === locationIdNum,
      );

      const nonMatchingUsers = users.filter(
        (user) => Number(user.location_id) !== locationIdNum,
      );

      setUsersByLocation({
        matching: matchingUsers,
        nonMatching: nonMatchingUsers,
      });
      setLeft(nonMatchingUsers);
      setRight(matchingUsers);
    }
  }, [users, locationId, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "13px", width: "550px" } }}
    >
      <Box
        sx={{
          padding: "10px",
          backgroundColor: "#FFFFFF",
          minHeight: "20vh",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>
        <Box sx={{ px: "5px" }}>
          <Stack
            direction="column"
            sx={{
              display: "flex",
              width: "100%",
              backgroundColor: "#FFFFFF",

              color: "#374247",
              mt: 1,
            }}
          >
            <img
              src={addLocationIcon}
              alt="addLocationIcon"
              style={{
                border: ".5px solid #B0BEC5",
                width: "28px",
                height: "28px",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                padding: "1px",
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                borderRadius: "6px",
                mb: "15px",
              }}
            />

            <Typography
              sx={{
                color: "#48768C",
                fontWeight: 600,
                lineHeight: "-2%",
                letterSpacing: "-.2px",
                fontSize: "18.5px",
                mb: "15px",
                mt: "5px",
              }}
            >
              Location Details
            </Typography>
          </Stack>
        </Box>

        <DialogContent
          sx={{ padding: 0, backgroundColor: "#FFFFFF", height: "auto" }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              ml: 0.65,
              minHeight: "15px",
              maxHeight: "25px",
              borderRadius: "8px 8px 0 0",
              backgroundColor: "transparent",
              ".MuiTabs-indicator": { backgroundColor: "transparent" },
            }}
          >
            <Tab
              label="Contact Info"
              sx={{
                color: "#48768C",
                fontWeight: 500,
                textTransform: "none",
                borderTop: "1px solid #ADD8E6",
                borderLeft: "1px solid #ADD8E6",
                borderRight: "1px solid #ADD8E6",
                borderRadius: "8px 8px 0 0",
                backgroundColor: "#F2F9FF",
                mb: "-1px",
                fontSize: 14.36,
                minHeight: "25px",
                padding: "4px 32px",
                lineHeight: "-2%",
                pb: 1.25,
                mr: 0.35,
                "&.Mui-selected": {
                  color: "#48768C",
                  fontWeight: 600,
                  backgroundColor: "#E0F3FF", // Slightly different background when selected
                  paddingTop: "6px", // Increase top padding to simulate height increase
                  paddingBottom: "8px", // Increase bottom padding as well
                },
              }}
            />
            <Tab
              label="Location Hours"
              sx={{
                color: "#48768C",
                fontWeight: 500,
                textTransform: "none",
                borderTop: hoursWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderLeft: hoursWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderRight: hoursWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderRadius: "8px 8px 0 0",
                backgroundColor: "#F2F9FF",
                mb: "-1px",
                fontSize: 14.36,
                minHeight: "25px",
                padding: "4px 12px",
                pb: 1.45,
                mr: 0.35,
                "&.Mui-selected": {
                  color: "#48768C",
                  fontWeight: 600,
                  backgroundColor: "#E0F3FF",
                  paddingTop: "6px",
                  paddingBottom: "8px",
                  borderBottom: hoursWarning
                    ? "2px solid #8C4350"
                    : "2px solid #ADD8E6", // Optional: Add a bottom border to emphasize the active state
                  borderTop: hoursWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                  borderLeft: hoursWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                  borderRight: hoursWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                },
              }}
            />
            <Tab
              label="Employees"
              sx={{
                color: "#48768C",
                fontWeight: 500,
                textTransform: "none",
                borderTop: usersWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderLeft: usersWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderRight: usersWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderRadius: "8px 8px 0 0",
                backgroundColor: "#F2F9FF",
                mb: "-1px",
                fontSize: 14.36,
                minHeight: "25px",
                padding: "4px 12px",
                pb: 1.45,
                mr: 0.35,
                "&.Mui-selected": {
                  color: "#48768C",
                  fontWeight: 600,
                  backgroundColor: "#E0F3FF",
                  paddingTop: "6px",
                  paddingBottom: "8px",
                  borderBottom: usersWarning
                    ? "2px solid #8C4350"
                    : "2px solid #ADD8E6", // Optional: Add a bottom border to emphasize the active state
                  borderTop: usersWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                  borderLeft: usersWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                  borderRight: usersWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                },
              }}
            />
          </Tabs>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 0,
              backgroundColor: "#F2F9FF",
              border: "1px solid rgba(173, 216, 230, 0.7)",
              overflowY: "auto",
              px: "10px",
              borderRadius: "8px",
              mb: 2,
            }}
          >
            {/* Tabs at the top of the Dialog */}

            <Box sx={{ mt: 1.5, width: "100%", minHeight: "20vh" }}>
              {/* Tab Panel for Location Info */}
              <Fade in={selectedTab === 0} timeout={600}>
                <Box sx={{ display: selectedTab === 0 ? "block" : "none" }}>
                  <>
                    <Typography
                      sx={{
                        color: "#48768C",
                        fontWeight: 600,
                        fontSize: 16.36,
                        mb: "15px",
                        mt: "2px",
                        letterSpacing: -0.1,
                        lineHeight: -2,
                      }}
                    >
                      Location Info
                    </Typography>
                    <LocationInfoCard
                      name={name}
                      email={email}
                      phone={phone}
                      handleNameChange={handleNameChange}
                      handleEmailChange={handleEmailChange}
                      handlePhoneChange={handlePhoneChange}
                      nameTouched={nameTouched}
                      emailTouched={emailTouched}
                      phoneTouched={phoneTouched}
                      isFormValid={isFormValid}
                      setIsFormValid={setIsFormValid}
                      validateEmail={validateEmail}
                      validatePhoneNumber={validatePhoneNumber}
                      handleOpenCancelCloseDialog={handleOpenCancelCloseDialog}
                      isCancelCloseOpen={isCancelCloseOpen}
                      handleCloseCancelCloseDialog={
                        handleCloseCancelCloseDialog
                      }
                      locationNameTaken={locationNameTaken}
                      isLoading={isLoading}
                      editLocationData={editLocationData}
                    />
                  </>
                </Box>
              </Fade>

              <Fade in={selectedTab === 1} timeout={600}>
                <Box sx={{ display: selectedTab === 1 ? "block" : "none" }}>
                  <>
                    <Typography
                      sx={{
                        color: "#48768C",
                        fontWeight: 600,
                        fontSize: 16.36,
                        mb: "20px",
                        mt: "5px",
                      }}
                    >
                      {(editLocationData?.name
                        ? `${editLocationData.name}'s`
                        : "Edit") + " Hours of Operation"}
                    </Typography>
                    <LocationHoursCard locationHours={locationHours} />
                  </>
                </Box>
              </Fade>

              <Fade in={selectedTab === 2} timeout={600}>
                <Box sx={{ display: selectedTab === 2 ? "block" : "none" }}>
                  {" "}
                  <>
                    <EmployeeSetupCard
                      users={users}
                      subUserData={subUserData}
                      name={name}
                      handleToggle={handleToggle}
                      checked={checked}
                      left={left}
                      right={right}
                      handleCheckedRight={handleCheckedRight}
                      handleCheckedLeft={handleCheckedLeft}
                      setLeft={setLeft}
                      userNameTaken={userNameTaken}
                      hideCreateUserButton={hideCreateUserButton}
                      isAssignEmployeeVisible={isAssignEmployeeVisible}
                      handleCreateNewEmployeeClick={
                        handleCreateNewEmployeeClick
                      }
                      handleSaveNewUserClick={handleSaveNewUserClick}
                      firstName={firstName}
                      setFirstName={setFirstName}
                      lastName={lastName}
                      setLastName={setLastName}
                      pin={pin}
                      setPin={setPin}
                      showPin={showPin}
                      handleTogglePinVisibility={handleTogglePinVisibility}
                      confirmNewPin={confirmNewPin}
                      handleConfirmNewPinChange={handleConfirmNewPinChange}
                      showConfirmNewPin={showConfirmNewPin}
                      toggleShowConfirmNewPin={toggleShowConfirmNewPin}
                      isSaveUserButtonEnabled={isSaveUserButtonEnabled}
                      assignedUser={assignedUser}
                      assignedLocation={assignedLocation}
                      handleUpdateChecklist={handleUpdateChecklist}
                      setHideCreateUserButton={setHideCreateUserButton}
                      handleAssignEmployeeClick={handleAssignEmployeeClick}
                      setUserName={setUserName}
                      newUserId={newUserId}
                      newUserCreated={newUserCreated}
                      enableEditButtons={enableEditButtons}
                      isNewUserSaved={isNewUserSaved}
                      isAdmin={isAdmin}
                      setIsAdmin={setIsAdmin}
                      handleCancelAddUser={handleCancelAddUser}
                      userName={userName}
                      handleClearNewUserClick={handleClearNewUserClick}
                      handleConfirmUsers={handleConfirmUsers}
                      confirmUsers={confirmUsers}
                      isEditingUser={isEditingUser}
                      handleCancelEdit={handleCancelEdit}
                      editLocationData={editLocationData}
                    />
                  </>
                </Box>
              </Fade>
            </Box>
          </Box>
        </DialogContent>
      </Box>
      <CancelCloseDialog
        open={isCancelCloseOpen}
        onClose={handleCloseCancelCloseDialog}
        buttonAction={handleCancelClose}
        onCloseConfirm={onClose}
        CancelCloseMessage="Are you sure you want to close?"
        CancelCloseSubText="Your new location will not be created."
      />
    </Dialog>
  );
};

export default ViewLocationModal;
