import React from "react";
import { Button, Box, Paper, IconButton, Typography } from "@mui/material";
import EditIcon from "src/public/assets/icons/icons/cancel.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import "./styles.css";

const OptionalField = ({ addOptionalField, setAddOptionalField }) => {
  const handleCreateOptionalButtonClick = () => {
    setAddOptionalField(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ml: "-1px",
      }}
    >
      <Button
        onClick={handleCreateOptionalButtonClick}
        className="field_name_container"
        sx={{
          cursor: "pointer",
          border: `1.75px solid lightgrey`,

          "&:hover": {
            backgroundColor: "#F5FAFE",
            cursor: "pointer",
          },
        }}
      >
        <AddCircleOutlineIcon
          className="optional_field_icon"
          sx={{
            color: "#7589A2",
            fontSize: "11px",
            backgroundColor: "#FFFFFF",
          }}
        />
        <Typography
          className="field_title"
          style={{
            fontSize: "14px",
            cursor: "pointer",
            marginRight: "auto",
          }}
        >
          Add an optional field
        </Typography>
      </Button>
    </Box>
  );
};

export default OptionalField;
