import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  Modal,
  Select,
  MenuItem,
  Box,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  InputAdornment,
  Portal,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import editUser from "../../public/assets/icons/icons/editUser.png";
import userDetailsIcon from "../../public/assets/icons/icons/userDetails.png";
import userAccountDetailsIcon from "../../public/assets/icons/icons/userAccountDetails.png";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useAccountContext } from "src/context/AccountContext";
import ChangePinModal from "../modal/ChangePinModal";
import { useAuth0 } from "@auth0/auth0-react";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import { set } from "lodash";

const scaleFactor = 0.75;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42 * scaleFactor,
  height: 27.5 * scaleFactor,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: `translateX(${16 * scaleFactor}px)`,
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2BBB9E" : "#2BBB9E",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: `${6 * scaleFactor}px solid #fff`,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22 * scaleFactor,
    height: 22 * scaleFactor,
  },
  "& .MuiSwitch-track": {
    borderRadius: (26 * scaleFactor) / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const EditUsersModal = ({
  isOpen,
  onClose,
  onSave,
  editData,
  setEditData,
  locations,
  adminCount,
  loading,
  userNameTaken,
  setUserNameTaken,
  users,
  setEditUserName,
  username,
}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const { accountData } = useAccountContext();
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);
  const [selectedUserForPinChange, setSelectedUserForPinChange] =
    useState(null);
  const [newPin, setNewPin] = useState("");
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const { user, getAccessTokenSilently } = useAuth0();
  const [title, setTitle] = useState("");

  const user_id = editData.user_id;

  const handleChangePinOpen = (user) => {
    setSelectedUserForPinChange(user);
    setNewPin("");
    setConfirmNewPin("");
    setIsChangePinModalOpen(true);
  };

  useEffect(() => {
    if (isOpen) {
      setEditUserName(editData.username);
    }
  }, [isOpen]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [admin, setAdmin] = useState(false);
  const [location, setLocation] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (isOpen) {
      // Initialize phone and email independently from editData
      setFirstName(editData.first_name || "");
      setLastName(editData.last_name || "");
      setNewTitle(editData.title || "");
      setAdmin(editData.is_admin || false);
      setLocation(editData.assigned_location || "");
      setNewUsername(editData.username || "");
    }
  }, [isOpen]);

  useEffect(() => {
    // Disable the button if phone and email match the initial editData
    if (
      firstName === editData.first_name &&
      lastName === editData.last_name &&
      newTitle === editData.title &&
      admin === editData.is_admin &&
      location === editData.assigned_location &&
      newUsername === editData.username
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    firstName,
    lastName,
    newTitle,
    admin,
    location,
    newUsername,
    editData.first_name,
    editData.last_name,
    editData.title,
    editData.is_admin,
    editData.assigned_location,
    editData.username,
  ]);

  const handleChangePinSave = async ({ newPin, confirmNewPin }) => {
    try {
      // Validate that PINs match
      if (newPin === confirmNewPin) {
        const apiUrl = process.env.REACT_APP_API_URL;
        const accessToken = await getAccessTokenSilently();

        // Update user PIN
        const response = await fetch(
          `${apiUrl}/api/users/${editData.user_id}/change-pin`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              newPin: newPin,
            }),
          },
        );

        const responseData = await response.json();

        if (response.ok) {
          setIsChangePinModalOpen(false);
          // You might want to refresh the user data or do other actions on success
        } else {
          console.error("Failed to change PIN:", responseData.error);
        }
      } else {
        console.error("Entered PINs do not match");
      }
    } catch (error) {
      console.error("Error changing PIN:", error);
    }
  };

  const handleChangePinClose = () => {
    setIsChangePinModalOpen(false);
    setSelectedUserForPinChange(null);
    setNewPin("");
    setConfirmNewPin("");
  };

  useEffect(() => {
    if (editData && typeof editData.is_admin === "boolean") {
      setIsAdmin(editData.is_admin);
    }
  }, [editData]);

  useEffect(() => {
    if (editData && editData.tite) {
      setTitle(editData.title);
    }
  }, [editData]);

  const handleToggleSwitch = () => {
    setIsAdmin(!isAdmin);
    // Open the dialog when the switch is checked
    if (!isAdmin) {
      setInfoDialogOpen(true);
    }
    // Update editData state
    setEditData({
      ...editData,
      is_admin: !isAdmin, // Toggle the is_admin property in editData
    });
  };

  const handleChangeTitle = (e) => {
    const selectedTitle = e.target.value;
    setTitle(selectedTitle);
    // Update editData state
    setEditData({
      ...editData,
      title: selectedTitle, // Update the title property in editData
    });
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  const handleSave = () => {
    onSave();
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
      <DialogTitle
        sx={{
          padding: 3,

          width: "550px",
          display: "flex",
          alignItems: "center",
          height: "55px",
          backgroundColor: "#FFFFFF",
          pt: 3,
          pb: 2.45,
          color: "#374247",
        }}
      >
        <img
          src={editUser}
          alt="editUser"
          style={{
            border: "1.25px solid rgba(173, 216, 230, 0.7)",
            width: "auto",
            height: "30px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "3px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />
      </DialogTitle>

      <DialogContent
        sx={{
          padding: 2,
          width: "100%",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >
        <Typography
          sx={{
            color: "#48768C",
            fontSize: 19,
            mb: 1.5,
            fontWeight: 600,
            pl: 0.95,
          }}
        >
          Edit User{" "}
        </Typography>
        <Box
          sx={{
            borderRadius: "20px",
            border: "1.25px solid rgba(173, 216, 230, 0.7)",
            py: 2,
            width: "auto",
            borderRadius: "15px",
            mb: 1.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "flex-start",
              width: "100%",
              ml: 1,
              mb: "20px",
            }}
          >
            <img
              src={userDetailsIcon}
              alt="addUserIcon"
              style={{
                border: "1px solid #B0BEC5",
                marginLeft: "1px",
                width: "auto",
                height: "28px",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                padding: "2px",
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                borderRadius: "6px",
              }}
            />{" "}
            <Typography
              sx={{
                ml: 0.5,
                alignSelf: "flex-end",
                color: "#48768C",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Employee Information
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
              pr: 3,
              ml: 1,
              mt: 1,
            }}
          >
            <TextField
              label="First Name"
              variant="outlined"
              size="small"
              fullWidth
              value={editData.first_name}
              onChange={(e) =>
                setEditData({ ...editData, first_name: e.target.value })
              }
              sx={{
                color: "#000",
                pb: 2,
                mr: 2,
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#2F5C71", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#48768CBA", // Focused border color
                  },
                },
                "& input": {
                  width: "100%",
                  textAlign: "left",
                  color: "#2F5C71", // Text color inside the input
                },
                "& .MuiInputLabel-root": {
                  color: "#2F5C71", // Default label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#2F5C71", // Label color when focused
                },
              }}
            />

            <TextField
              label="Last Name"
              variant="outlined"
              size="small"
              fullWidth
              value={editData.last_name}
              onChange={(e) =>
                setEditData({ ...editData, last_name: e.target.value })
              }
              sx={{
                color: "#000",
                pb: 2,
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#2F5C71", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#48768CBA", // Focused border color
                  },
                },
                "& input": {
                  width: "100%",
                  textAlign: "left",
                  color: "#2F5C71", // Text color inside the input
                },
                "& .MuiInputLabel-root": {
                  color: "#2F5C71", // Default label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#2F5C71", // Label color when focused
                },
              }}
            />
          </Box>
          <FormControl
            variant="outlined"
            sx={{
              width: "95.75%",
              mb: 1,
              ml: "7.5px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <InputLabel
              sx={{
                color: "#2F5C71",
                "&.Mui-focused": {
                  color: "#2F5C71", // Keep the same color on focus
                },
                "&.MuiFormLabel-filled": {
                  color: "#2F5C71", // Keep the same color when filled
                },
              }}
              htmlFor="outlined-adornment-role"
            >
              Role
            </InputLabel>
            <Select
              label="Role"
              size="small"
              value={editData.title}
              onChange={handleChangeTitle}
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#576369",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2F5C71", // Hover state border color
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#48768CBA", // Focused border color
                },
                "& .Mui-focused .MuiInputLabel-root": {
                  color: "#2F5C71", // Focused label color
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #2F5C715E",
                    borderTop: "none",
                  },
                },
              }}
            >
              <MenuItem
                value="Employee"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Employee
              </MenuItem>
              <MenuItem
                value="Manager"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Manager
              </MenuItem>
              <MenuItem
                value="Owner"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Owner
              </MenuItem>
              <MenuItem
                value="Regional Manager"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Regional Manager
              </MenuItem>
              <MenuItem
                value="Account Administrator"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Account Administrator
              </MenuItem>
              <MenuItem
                value="Director of Operations"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Director of Operations
              </MenuItem>
              <MenuItem
                value="Assistant Manager"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Assistant Manager
              </MenuItem>
              <MenuItem
                value="Shift Lead"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Shift Lead
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            borderRadius: "20px",
            border: "1.25px solid rgba(173, 216, 230, 0.7)",
            py: 2,
            width: "100%",
            borderRadius: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "flex-start",
              width: "100%",
              ml: 1,
              mb: "20px",
            }}
          >
            <img
              src={userAccountDetailsIcon}
              alt="userAccountDetailsIcon"
              style={{
                border: "1px solid #B0BEC5",
                marginLeft: "1px",
                width: "auto",
                height: "28px",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                padding: "2px",
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                borderRadius: "6px",
              }}
            />
            <Typography
              sx={{
                ml: 0.5,
                alignSelf: "flex-end",
                color: "#48768C",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {" "}
              Account Information
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "97%",
              ml: 1,
              mb: 2,
              mt: 1,
            }}
          >
            <TextField
              label="Username"
              variant="outlined"
              size="small"
              fullWidth
              value={editData.username}
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^A-Za-z]/g, ""); // Sanitize to allow only alphabetic characters

                // Limit the length to 10 characters
                const limitedValue = newValue.slice(0, 10);

                // Check if the username is already taken, excluding the signed-in user
                const isUsernameTaken = users.some(
                  (user) =>
                    user.username === limitedValue &&
                    user.username !== newUsername,
                );

                // Update the editData state with the sanitized and limited value
                setEditData((prevData) => ({
                  ...prevData,
                  username: limitedValue,
                }));

                // Update the username taken state based on the check
                setUserNameTaken(isUsernameTaken);

                // Optionally, clear the userNameTaken state when the username is available
                if (!isUsernameTaken && userNameTaken) {
                  setUserNameTaken(false);
                }
              }}
              sx={{
                mb: 0,
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#2F5C71", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#48768CBA", // Focused border color
                  },
                },
                "& input": {
                  width: "100%",
                  textAlign: "left",
                  color: "#2F5C71", // Text color inside the input
                },
                "& .MuiInputLabel-root": {
                  color: "#2F5C71", // Default label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#2F5C71", // Label color when focused
                },
              }}
              InputProps={{
                endAdornment: userNameTaken && (
                  <InputAdornment position="end">
                    <Typography sx={{ color: "#8C4350", fontSize: 13 }}>
                      Username Already Exists
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
            {!accountData.single_location && (
              <>
                <Typography
                  sx={{
                    fontSize: 15.5,
                    fontWeight: 600,
                    color: "#48768C",
                    mt: 1,
                    ml: 0.5,
                  }}
                >
                  Assigned Location:
                </Typography>

                <Select
                  value={editData.assigned_location}
                  size="small"
                  onChange={(e) => {
                    const selectedLocationName = e.target.value;
                    const selectedLocation = locations.find(
                      (location) => location.name === selectedLocationName,
                    );
                    const selectedLocationId = selectedLocation
                      ? selectedLocation.location_id
                      : null;
                    setEditData({
                      ...editData,
                      assigned_location: selectedLocationName,
                      assigned_location_id: selectedLocationId,
                    });
                  }}
                  fullWidth
                  sx={{
                    backgroundColor: "#FFFFFF",
                    color: "#2F5C71",

                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#2F5C71", // Hover state border color
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#48768CBA", // Focused border color
                    },
                    "& .Mui-focused .MuiInputLabel-root": {
                      color: "#2F5C71", // Focused label color
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #2F5C715E",
                        borderTop: "none",
                      },
                    },
                  }}
                >
                  {locations.map((location) => (
                    <MenuItem
                      sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                      key={location.location_id}
                      value={location.name}
                    >
                      {location.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                ml: 0,
                width: "100%",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "no-wrap",
                  ml: 0.5,
                  align: "center",
                  mr: "-2px",
                  mt: 1,
                  fontSize: 15,
                  color: "#576369",
                  visibility: adminCount > 0 ? "visible" : "hidden",
                }}
              >
                Make this user an administrator:
              </Typography>

              <IOSSwitch
                checked={isAdmin}
                onChange={handleToggleSwitch}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  ml: 2,
                  mt: 1.25,
                  visibility: adminCount > 0 ? "visible" : "hidden",
                }}
                disabled={adminCount === 1 && isAdmin} // Disable if adminCount is 1 and isAdmin is true
              />

              <Button
                variant="contained"
                onClick={handleChangePinOpen}
                sx={{
                  height: "35px",
                  mt: 1,
                  ml: 18,
                  width: "20%",
                  fontSize: 12.5,
                  fontWeight: 600,
                  borderRadius: "10px",
                  border: "1px solid #48768C2B",
                  color: "#48768C",
                  backgroundColor: "#F2F9FF",
                  whiteSpace: "nowrap",
                  paddingLeft: "20px", // Adjust left padding
                  paddingRight: "20px", // Adjust right padding
                  justifyContent: "flex-end",
                  "&:hover": {
                    backgroundColor: "#DBEAF0C2",
                    color: "#48768CD9",
                  },
                }}
              >
                Change PIN
              </Button>
            </Box>
          </Box>
        </Box>

        <DialogActions
          sx={{
            pt: 4,
            backgroundColor: "#FFFFFF",
            width: "100%",
            mx: "auto", // Set left and right margin to auto to center horizontally
            my: "auto",
            display: "flex", // Use flexbox
            justifyContent: "center", // Center content horizontally
          }}
        >
          <CancelButton
            sx={{
              fontSize: "16px",
              mb: 1,

              height: "51px",
              width: "25%",
            }}
            onClick={onClose}
          >
            Cancel
          </CancelButton>
          <PrimaryButton
            disabled={
              !editData.first_name ||
              !editData.last_name ||
              !editData.username ||
              loading ||
              userNameTaken ||
              disabled
            }
            sx={{
              width: "80%", // Adjust width as needed
              height: "51px",
              fontSize: "18px",
              mb: 1,
            }}
            onClick={onSave}
            variant="contained"
          >
            {loading ? "Saving..." : "Submit"}
          </PrimaryButton>
        </DialogActions>
        {infoDialogOpen && (
          <Portal>
            <Dialog
              PaperProps={{
                sx: {
                  borderRadius: "15px",
                  backgroundColor: "rgba(191, 218, 231, 0.1)",
                  border: "1px solid rgba(173, 216, 230, 0.7)",
                },
              }}
              open={infoDialogOpen}
              onClose={handleInfoDialogClose}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(191, 218, 231, 0.1)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Added box shadow
                  fontSize: ".9rem",
                  fontWeight: 600,
                  letterSpacing: "0.02em",
                  lineHeight: 1.5,
                  backgroundColor: "#F0F0F0",
                  color: "#48768C",
                }}
              >
                <DialogContent
                  sx={{ backgroundColor: "rgba(191, 218, 231, 0.1)" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      color: "#48768C",
                      fontWeight: 600,
                      backgroundColor: "rgba(191, 218, 231, 0.1)",
                    }}
                  >
                    An account administrator can make important changes to your
                    NanoNotify account, such as adding or deleting locations,
                    adding users and changing users' pins.
                  </Typography>
                </DialogContent>
                <DialogActions
                  sx={{
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(191, 218, 231, 0.1)",
                  }}
                >
                  <PrimaryButton
                    sx={{
                      transition: "background-color 0.3s ease",
                      width: "30%",
                      mb: 1.5,
                    }}
                    onClick={handleInfoDialogClose}
                  >
                    I Understand
                  </PrimaryButton>
                </DialogActions>
              </Box>
            </Dialog>
          </Portal>
        )}
      </DialogContent>
      <ChangePinModal
        isOpen={isChangePinModalOpen}
        onClose={handleChangePinClose}
        onSave={handleChangePinSave}
        newPin={newPin}
        setNewPin={setNewPin}
        confirmNewPin={confirmNewPin}
        setConfirmNewPin={setConfirmNewPin}
        userName={`${editData?.first_name || ""} ${editData?.last_name || ""}`}
      />
    </Dialog>
  );
};

export default EditUsersModal;
