import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import editUser from "../../public/assets/icons/icons/editUser.png";
import userDetailsIcon from "../../public/assets/icons/icons/userDetails.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../../context/AuthContext";
import addLocationIcon from "../../public/assets/icons/icons/newLocation.png";
import { useAppContext } from "../../context/AppContext";
import { useSingleUser } from "../../context/SingleUserContext";
import { AccountProvider } from "../../context/AccountContext";
import { useAccountContext } from "../../context/AccountContext";
import {
  PrimaryButton,
  CancelButton,
} from "src/components/Buttons/ModalButtons";
import { set } from "lodash";

const EditMultiUserLocation = ({
  isOpen,
  onClose,
  handleSave,
  editData,
  setEditData,
  loading,
  formChanged,
  setFormChanged,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (isOpen) {
      // Initialize phone and email independently from editData
      setPhone(editData.phone || "");
      setEmail(editData.email || "");
    }
  }, [isOpen]);

  useEffect(() => {
    // Disable the button if phone and email match the initial editData
    if (phone === editData.phone && email === editData.email) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [phone, email, editData.phone, editData.email, emailValid, phoneValid]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>
        <DialogTitle
          sx={{
            padding: 3,
            width: "550px",
            display: "flex",
            alignItems: "center",
            height: "55px",
            backgroundColor: "#FFFFFF",
            pt: 3,
            pb: 2.45,
            color: "#374247",
          }}
        >
          <img
            src={addLocationIcon}
            alt="editUser"
            style={{
              border: "1px solid rgba(173, 216, 230, 0.7)",
              width: "auto",
              height: "30px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "2px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              borderRadius: "6px",
            }}
          />
        </DialogTitle>

        <DialogContent
          sx={{
            padding: 2,
            width: "100%",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "left",
            zIndex: 0,
          }}
        >
          <Typography
            sx={{
              color: "#48768C",
              fontSize: 19,
              mb: 2,
              fontWeight: 600,
              pl: 0.95,
            }}
          >
            Edit Location Information
          </Typography>
          <Box
            sx={{
              borderRadius: "20px",
              border: "1px solid rgba(173, 216, 230, 0.7)",
              py: 2,
              width: "auto",
              borderRadius: "15px",
              mb: 1.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "flex-start",
                width: "100%",
                ml: 1,
                mb: "20px",
              }}
            >
              <img
                src={userDetailsIcon}
                alt="addUserIcon"
                style={{
                  border: "1px solid #B0BEC5",
                  marginLeft: "1px",
                  width: "auto",
                  height: "26px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  padding: "2px",
                  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                  borderRadius: "6px",
                }}
              />
              <Typography
                sx={{
                  ml: 0.5,
                  alignSelf: "flex-end",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#48768C",
                }}
              >
                Your Locations Contact Info
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                pr: 3,
                ml: 1,
                mt: 1,
              }}
            >
              <TextField
                label="Phone Number"
                variant="outlined"
                size="small"
                fullWidth
                value={editData.phone}
                onChange={(e) => {
                  const onlyNumbers = e.target.value.replace(/\D/g, "");
                  const formattedValue = onlyNumbers.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "($1) $2-$3",
                  );
                  const truncatedValue = formattedValue.slice(0, 14);
                  setEditData((prevEditData) => ({
                    ...prevEditData,
                    phone: truncatedValue,
                  }));

                  // Check if the entered phone number is valid
                  const isValidPhone = /^\(\d{3}\) \d{3}-\d{4}$/.test(
                    truncatedValue,
                  );
                  setPhoneValid(isValidPhone);
                  setFormChanged(true);
                }}
                inputProps={{ maxLength: 14 }}
                sx={{
                  color: "#000",
                  pb: 2,
                  mr: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#2F5C71", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#48768CBA", // Focused border color
                    },
                  },
                  "& input": {
                    width: "100%",
                    textAlign: "left",
                    color: "#2F5C71", // Text color inside the input
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2F5C71", // Default label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#2F5C71", // Label color when focused
                  },
                }}
              />

              <TextField
                label="Location Email"
                variant="outlined"
                size="small"
                fullWidth
                value={editData.email}
                onChange={(e) => {
                  const emailValue = e.target.value;
                  setEditData((prevEditData) => ({
                    ...prevEditData,
                    email: emailValue,
                  }));
                  setEmailValid(emailRegex.test(emailValue.trim())); // Update emailValid based on whether the email field matches the emailRegex pattern
                  setFormChanged(true);
                }}
                sx={{
                  color: "#000",
                  pb: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#2F5C71", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#48768CBA", // Focused border color
                    },
                  },
                  "& input": {
                    width: "100%",
                    textAlign: "left",
                    color: "#2F5C71", // Text color inside the input
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2F5C71", // Default label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#2F5C71", // Label color when focused
                  },
                }}
              />
            </Box>
          </Box>

          <DialogActions
            sx={{
              pt: 4,
              backgroundColor: "#FFFFFF",
              width: "100%",
              mx: "auto",
              my: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CancelButton
              sx={{
                borderRadius: "10px",
                fontSize: "17px",
                mb: 1,
                height: "51px",
                width: "25%",
              }}
              onClick={onClose}
            >
              Cancel
            </CancelButton>
            <PrimaryButton
              sx={{
                width: "80%", // Adjust width as needed
                height: "51px",
                fontSize: "18px",
                mb: 1,
              }}
              onClick={handleSave}
              variant="contained"
              disabled={
                !(
                  phoneValid &&
                  editData.phone &&
                  editData.phone.length === 14 &&
                  emailValid &&
                  editData.email
                ) ||
                loading ||
                disabled
              }
            >
              {loading ? "Saving..." : "Save Changes"}
            </PrimaryButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default EditMultiUserLocation;
