import React, { createContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";

const LogoContext = createContext();

export const LogoProvider = ({ children }) => {
  const [userLogoUrl, setUserLogoUrl] = useState(null); // Initialize with null
  const [logoUploaded, setLogoUploaded] = useState(false); // Initialize with false
  const [setIsLoading, isLoading] = useState(false);
  const { showSnackBar, handleReloadComponents } = useAppContext();

  const { getAccessTokenSilently, user } = useAuth0();

  const fetchLogoUrl = async () => {
    if (!user || !user.sub) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/api/logo?auth0UserId=${user.sub}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.ok) {
        // No content found, do nothing
        if (response.status === 204) {
          setUserLogoUrl(null);
          return;
        }

        const logo = await response.json();

        if (logo && logo.fileUrl) {
          setUserLogoUrl(logo.fileUrl);
        }
      }
    } catch (error) {
      // Suppress the error
    }
  };

  useEffect(() => {
    const fetchLogoUrl = async () => {
      if (!user || !user.sub) {
        return;
      }

      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${apiUrl}/api/logo?auth0UserId=${user.sub}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (response.ok) {
          // No content found, do nothing
          if (response.status === 204) {
            return;
          }

          const logo = await response.json();

          if (logo && logo.fileUrl) {
            setUserLogoUrl(logo.fileUrl);
          }
        }
      } catch (error) {
        // Suppress the error
      }
    };

    fetchLogoUrl();
  }, [user, getAccessTokenSilently, logoUploaded]);

  const uploadLogo = async (file) => {
    try {
      setUserLogoUrl(null); // Reset the logo URL to null while uploading

      const formData = new FormData();
      formData.append("logo", file);
      formData.append("auth0UserId", user.sub); // Use user.sub to get Auth0 user ID

      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${apiUrl}/api/upload`, {
        method: "POST", // Always use POST for initial upload
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-Auth0-UserId": user.sub, // Custom header to pass the auth0UserId
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        if (result.fileUrl) {
          setUserLogoUrl(result.fileUrl);
          showSnackBar("Logo uploaded successfully!", true);
          setLogoUploaded(true); // Set logoUploaded to true after successful upload
          return true; // Return true for successful upload
        }
      } else {
        const errorMessage = await response.text();
        console.error("Failed to upload logo:", errorMessage);
        showSnackBar("Failed to upload logo. Please try again.", false);
        return false; // Return false for failed upload
      }
    } catch (error) {
      console.error("Error uploading logo:", error);
      showSnackBar("Failed to upload logo. Please try again.", false);
      return false; // Return false for error
    }
  };

  const deleteLogo = async (userLogoUrl) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
      const auth0UserId = user.sub; // Use user.sub to get Auth0 user ID
      // Prepare the request payload
      const payload = {
        auth0UserId,
        userLogoUrl,
      };

      // Make the DELETE request to the API
      const response = await fetch(`${apiUrl}/api/delete-logo`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });

      // Check if the response is successful
      if (response.ok) {
        const data = await response.json();
        showSnackBar("Logo deleted successfully!", true);
        fetchLogoUrl(); // Fetch the logo URL after deletion
        // Handle success, maybe update the UI or state
      } else {
        const errorData = await response.json();
        console.error("Error deleting the file:", errorData);
        showSnackBar("Failed to delete logo. Please try again.", false);

        // Handle error
      }
    } catch (error) {
      console.error("Error in deleteLogo function:", error);
      showSnackBar("Failed to delete logo. Please try again.", false);

      // Handle error
    }
  };

  return (
    <LogoContext.Provider value={{ userLogoUrl, uploadLogo, deleteLogo }}>
      {children}
    </LogoContext.Provider>
  );
};

export const useUserLogo = () => React.useContext(LogoContext);
