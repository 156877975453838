import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Fade,
  Stack,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
  Switch,
  Portal,
} from "@mui/material";
import { useAccountContext } from "src/context/AccountContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TransferList from "./TransferList";
import AddUserIcon from "src/public/assets/icons/icons/addUser.png";
import { styled } from "@mui/material/styles";

const EmployeeSetupCard = ({
  open,
  onClose,
  onConfirm,
  itemName,
  item,
  isLoading,
  header,
  message,
  subText,
  buttonAction,
  addedUserPrice,
  hideReviewDialog,
  firstName,
  lastName,
  setUserName,
  pin,
  userName,
  confirmNewPin,
  showPin,
  showConfirmNewPin,
  isAssignEmployeeVisible,
  setFirstName,
  setLastName,
  setPin,
  handleConfirmNewPinChange,
  handleTogglePinVisibility,
  toggleShowConfirmNewPin,
  setIsAssignEmployeeVisible,
  users,
  subUserData,
  name,
  handleToggle,
  checked,
  left,
  right,
  handleCheckedRight,
  handleCreateNewEmployeeClick,
  handleCheckedLeft,
  setLeft,
  userNameTaken,
  hideCreateUserButton,
  setHideCreateUserButton,
  handleAssignEmployeeClick,
  handleSaveNewUserClick,
  newUser,
  newUserId,
  newUserCreated,
  enableEditButtons,
  isNewUserSaved,
  isAdmin,
  setIsAdmin,
  handleCancelAddUser,
  isSaveUserButtonEnabled,
  handleClearNewUserClick,
  handleConfirmUsers,
  confirmUsers,
  isEditingUser,
  handleCancelEdit,
}) => {
  const { accountData } = useAccountContext();
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  useEffect(() => {
    if (accountData.single_user) setIsAssignEmployeeVisible(true);
  }, [accountData.single_location]);

  const handleToggleSwitch = () => {
    setIsAdmin(!isAdmin);
    // Open the dialog when the switch is checked
    if (!isAdmin) {
      setInfoDialogOpen(true);
    }
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  const scaleFactor = 0.75;

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42 * scaleFactor,
    height: 27.5 * scaleFactor,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: `translateX(${16 * scaleFactor}px)`,
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2BBB9E" : "#2BBB9E",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: `${6 * scaleFactor}px solid #fff`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22 * scaleFactor,
      height: 22 * scaleFactor,
    },
    "& .MuiSwitch-track": {
      borderRadius: (26 * scaleFactor) / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        height: "auto", // Set a fixed height
        width: "100%",
        gap: 1,
      }}
    >
      {isAssignEmployeeVisible ? (
        <>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center", // Center items vertically
              mb: 2,
              mt: 0.5,
            }}
          >
            <Typography
              sx={{
                color: "#48768C",
                fontWeight: 600,
                fontSize: 16.36,
                mt: "0px",
                ml: -0.5,
                letterSpacing: -0.1,
                lineHeight: -2,
              }}
            >
              New Employee
            </Typography>
          </Stack>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <TextField
              placeholder="First Name*"
              variant="outlined"
              size="small"
              value={firstName}
              onChange={(e) => {
                const newValue = e.target.value
                  .replace(/[^A-Za-z]/g, "")
                  .slice(0, 10);
                const capitalizedValue =
                  newValue.charAt(0).toUpperCase() + newValue.slice(1);
                setFirstName(capitalizedValue);
              }}
              sx={{
                flex: 1.5,
                mr: 2,
                color: "#48768C",
                "& input": {
                  height: "18.5px",
                  color: "#48768C",
                  fontSize: 13.36,
                },
              }}
            />
            <TextField
              placeholder="Last Name*"
              variant="outlined"
              size="small"
              value={lastName}
              onChange={(e) => {
                const newValue = e.target.value
                  .replace(/[^A-Za-z]/g, "")
                  .slice(0, 10);
                const capitalizedValue =
                  newValue.charAt(0).toUpperCase() + newValue.slice(1);
                setLastName(capitalizedValue);
              }}
              sx={{
                flex: 1.5,
                color: "#48768C",
                "& input": {
                  height: "18.5px",
                  color: "#48768C",
                  fontSize: 13.36,
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mb: 1,
              mt: 1,
            }}
          >
            <TextField
              error={userNameTaken}
              placeholder="Username*"
              size="small"
              variant="outlined"
              value={userName}
              onChange={(e) => {
                const newValue = e.target.value
                  .replace(/[^A-Za-z]/g, "")
                  .slice(0, 15);
                setUserName(newValue);
              }}
              sx={{
                flex: 2,
                color: "#48768C",
                "& input": {
                  height: "18.5px",
                  color: "#48768C",
                  fontSize: 13.36,
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              width: "100%",
              mt: 1,
              mb: 0,
            }}
          >
            <TextField
              placeholder="Enter PIN*"
              variant="outlined"
              size="small"
              type={showPin ? "text" : "password"}
              value={pin}
              onChange={(e) => {
                let inputValue = e.target.value.replace(/\D/g, "").slice(0, 4);
                setPin(inputValue);
              }}
              sx={{
                flex: 0.8,
                mr: 2,
                color: "#48768C",
                "& input": {
                  height: "18.5px",
                  color: "#48768C",
                  fontSize: 13.36,
                },
              }}
            />
            <TextField
              placeholder="Confirm PIN*"
              variant="outlined"
              size="small"
              type={showConfirmNewPin ? "text" : "password"}
              value={confirmNewPin}
              onChange={handleConfirmNewPinChange}
              sx={{
                flex: 0.8,
                color: "#48768C",
                "& input": {
                  height: "18.5px",
                  color: "#48768C",
                  fontSize: 13.36,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowConfirmNewPin} edge="end">
                      {showConfirmNewPin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              width: "100%",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                whiteSpace: "no-wrap",
                ml: 0.5,
                align: "center",
                mr: "-2px",
                mt: 1,
                color: "#48768C",
                fontSize: 15.36,
              }}
            >
              Make this user an administrator:
            </Typography>
          </Box>
          {isAssignEmployeeVisible && (
            <Stack
              direction="row"
              spacing={2}
              sx={{ mt: 3, mb: 2, width: "50%", ml: "auto" }}
            >
              <Button
                onClick={handleCancelAddUser}
                sx={{
                  border: "1px solid #8C435029",
                  borderRadius: "6px",
                  color: "#8C4350",
                  backgroundColor: "#8C435021",
                  padding: "1px 0", // Same padding as the first button
                  fontSize: 13.76, // Matching font size for consistency
                  width: "200px",
                  height: "35px", // Fixed height to match the first button
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#8C435017",
                  },
                  "&:active": {
                    backgroundColor: "#A4C5D230",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveNewUserClick}
                disabled={!isSaveUserButtonEnabled}
                sx={{
                  border: "1px solid #A4C5D2E8",
                  borderRadius: "6px",
                  padding: "1px 0", // Consistent padding
                  color: "#48768C",
                  backgroundColor: "#FFFFFF",
                  fontSize: 13.76,
                  width: "300px",
                  height: "35px", // Fixed height for consistency
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#DBEAF0",
                  },
                  "&:active": {
                    backgroundColor: "#DBEAF0",
                  },
                }}
              >
                {enableEditButtons ? "Save" : "Add Employee  "}
              </Button>
            </Stack>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              color: "#48768C",
              fontWeight: 600,
              fontSize: 16,
              fontWeight: 600,

              letterSpacing: "-0.10px",
              py: "5px",
              textShadow: "0.05px 0.05px 0px rgba(0, 0, 0, 0.3)",
              mb: 1,
            }}
          >
            {`${name}'s` + " Active Employees"}
          </Typography>
          <TransferList
            users={users}
            subUserData={subUserData}
            name={name}
            handleToggle={handleToggle}
            checked={checked}
            left={left}
            right={right}
            handleCheckedRight={handleCheckedRight}
            handleCheckedLeft={handleCheckedLeft}
            setLeft={setLeft}
            newUser={newUser}
            handleCreateNewEmployeeClick={handleCreateNewEmployeeClick}
            handleSaveNewUserClick={handleSaveNewUserClick}
            accountData={accountData}
            isNewUserSaved={isNewUserSaved}
            isAssignEmployeeVisible={isAssignEmployeeVisible}
            enableEditButtons={enableEditButtons}
            handleAssignEmployeeClick={handleAssignEmployeeClick}
            hideCreateUserButton={hideCreateUserButton}
          />
        </Box>
      )}

      {infoDialogOpen && (
        <Portal>
          <Dialog
            PaperProps={{
              sx: {
                borderRadius: "15px",
                backgroundColor: "rgba(191, 218, 231, 0.1)",
                border: "1px solid rgba(173, 216, 230, 0.7)",
              },
            }}
            open={infoDialogOpen}
            onClose={handleInfoDialogClose}
          >
            <Box
              sx={{
                backgroundColor: "rgba(191, 218, 231, 0.1)",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Added box shadow
                fontSize: ".9rem",
                fontWeight: 600,
                letterSpacing: "0.02em",
                lineHeight: 1.5,
                backgroundColor: "#F0F0F0",
                color: "#48768C",
              }}
            >
              <DialogContent
                sx={{ backgroundColor: "rgba(191, 218, 231, 0.1)" }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: "#48768C",
                    fontWeight: 600,
                    backgroundColor: "rgba(191, 218, 231, 0.1)",
                  }}
                >
                  An account administrator can make important changes to your
                  NanoNotify account, such as adding or deleting locations,
                  adding users and changing users' pins.
                </Typography>
              </DialogContent>
              <DialogActions
                sx={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "rgba(191, 218, 231, 0.1)",
                }}
              >
                <Button
                  sx={{
                    mb: 1,
                    border: "1px solid #A4C5D2E8",
                    borderRadius: "6px",
                    padding: "1px 0", // Consistent padding
                    color: "#48768C",
                    backgroundColor: "#FFFFFF",
                    fontSize: 13.76,
                    width: "300px",
                    height: "35px", // Fixed height for consistency
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      backgroundColor: "#DBEAF0",
                    },
                    "&:active": {
                      backgroundColor: "#DBEAF0",
                    },
                  }}
                  onClick={handleInfoDialogClose}
                >
                  I Understand
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </Portal>
      )}
    </Box>
  );
};

export default EmployeeSetupCard;
