import React, { useState, useEffect } from "react";
import { Card, Typography, Switch, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";
import { useAccountContext } from "src/context/AccountContext";
import "./styles.css";

const scaleFactor = 0.75;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42 * scaleFactor,
  height: 27.5 * scaleFactor,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: `translateX(${16 * scaleFactor}px)`,
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2BBB9E" : "#2BBB9E",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: `${6 * scaleFactor}px solid #fff`,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22 * scaleFactor,
    height: 22 * scaleFactor,
  },
  "& .MuiSwitch-track": {
    borderRadius: (26 * scaleFactor) / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const StyledTypographybody = styled(Typography)({
  fontSize: 17,
  marginBottom: "15px",
  fontWeight: "bold",
});

const CardStyling = {
  padding: "1rem",
  paddingBottom: ".03rem",
  width: "100%",
  marginBottom: "1.5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const AccountNotifications = styled(Card)({
  padding: CardStyling.padding,
  width: CardStyling.width,
  paddingBottom: CardStyling.paddingBottom,
  marginBottom: CardStyling.marginBottom,
  display: CardStyling.display,
  flexDirection: CardStyling.flexDirection,
  justifyContent: CardStyling.justifyContent,
  backgroundColor: "rgba(191, 218, 231, 0.1)",
  border: "1px solid rgba(173, 216, 230, 0.7)",
});

const ActivityNotifications = styled(Card)({
  padding: CardStyling.padding,
  width: CardStyling.width,
  marginBottom: CardStyling.marginBottom,
  paddingBottom: CardStyling.paddingBottom,
  display: CardStyling.display,
  flexDirection: CardStyling.flexDirection,
  justifyContent: CardStyling.justifyContent,
  marginTop: "1rem",
  backgroundColor: "rgba(191, 218, 231, 0.1)",
  border: "1px solid rgba(173, 216, 230, 0.7)",
});

const BillingNotifications = styled(Card)({
  padding: CardStyling.padding,
  width: CardStyling.width,
  marginBottom: CardStyling.marginBottom,
  paddingBottom: CardStyling.paddingBottom,
  display: CardStyling.display,
  flexDirection: CardStyling.flexDirection,
  justifyContent: CardStyling.justifyContent,
  marginTop: "1rem",
  backgroundColor: "rgba(191, 218, 231, 0.1)",
  border: "1px solid rgba(173, 216, 230, 0.7)",
});

// Use this LastToggleOption component for the last ToggleOption in each card.

function Notifications({ cardTitle, cardSubTitle }) {
  const { user, getAccessTokenSilently } = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const { saveSettings, notificationSettings, setNotificationSettings } =
    useAccountContext();

  const Header = styled(Typography)({
    width: "100%",
    textAlign: "left",
    letterSpacing: "-.02em",
  });

  const ToggleSwitch = ({ label, settingKey }) => {
    const handleChange = async () => {
      // Update the local state
      const newSettingValue = !notificationSettings[settingKey];
      setNotificationSettings((prev) => ({
        ...prev,
        [settingKey]: newSettingValue,
      }));

      try {
        // Save the new settings to the backend
        await saveSettings(settingKey, newSettingValue, label);

        // Show success snackBar
        showSnackBar(`${label} Updated Successfully`, true);

        // Optionally reload components or handle any other side effects
        handleReloadComponents();
      } catch (error) {
        // Show error snackBar if the update fails
        showSnackBar("Failed to update notification", false);
        console.error("Error updating notification settings:", error);
      }
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          color="textSecondary"
          sx={{ fontSize: 14, marginRight: "auto" }}
        >
          {label}
        </Typography>
        <IOSSwitch
          size="small"
          checked={notificationSettings[settingKey]}
          onChange={handleChange}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ width: "100%", pb: 2 }}>
      <Header
        sx={{
          color: "#48768C",
          fontWeight: 700,
          fontSize: 17.5,
        }}
      >
        In App Notifications
      </Header>
      <Header
        sx={{ color: "#48768C", fontSize: ".9rem", letterSpacing: "-.01em" }}
      >
        Configure alerts delivered to your team
      </Header>
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          pb: 2,
          height: "75vh",
          mt: 1.5,
          borderRadius: "10px",
        }}
      >
        <AccountNotifications sx={{ mt: 2 }}>
          <StyledTypographybody
            sx={{ fontSize: 15, color: "#48768C", fontWeight: 600 }}
          >
            Account Notifications{" "}
          </StyledTypographybody>
          <Box className="toggle_option_container">
            <ToggleSwitch
              sx={{}}
              label="Message Sent Alerts"
              settingKey="message_sent_alerts"
            />
          </Box>

          <Box className="toggle_option_container">
            <ToggleSwitch
              sx={{}}
              label="Inactivity Timeout"
              settingKey="inactivity_timeout"
            />
          </Box>

          <Box className="last_toggle_option_container">
            <ToggleSwitch
              sx={{}}
              label="Promotions or Offers"
              settingKey="promos"
            />
          </Box>
        </AccountNotifications>

        <ActivityNotifications>
          <StyledTypographybody
            sx={{ fontSize: 15, color: "#48768C", fontWeight: 600 }}
          >
            Activity Notifications{" "}
          </StyledTypographybody>

          <Box className="toggle_option_container">
            <ToggleSwitch
              label="New Customer Added"
              settingKey="new_customer_added"
            />
          </Box>
          <Box className="toggle_option_container">
            <ToggleSwitch
              label="Failed Notification"
              settingKey="failed_notification"
            />
          </Box>
          <Box className="toggle_option_container">
            <ToggleSwitch
              label="Scheduled Notification"
              settingKey="scheduled_notification"
            />
          </Box>

          <Box className="last_toggle_option_container">
            <ToggleSwitch
              label="Two Way Notification Alert"
              settingKey="two_way_notification"
            />
          </Box>
        </ActivityNotifications>

        <BillingNotifications>
          <StyledTypographybody
            sx={{ fontSize: 15, color: "#48768C", fontWeight: 600 }}
          >
            Billing Notifications{" "}
          </StyledTypographybody>

          <Box className="toggle_option_container">
            <ToggleSwitch
              label="Upcoming Payment"
              settingKey="upcoming_payment"
            />
          </Box>

          <Box className="toggle_option_container">
            <ToggleSwitch
              label="Payment Summary"
              settingKey="payment_summary"
            />
          </Box>
          <Box className="toggle_option_container">
            <ToggleSwitch
              label="Daily Summary Report"
              settingKey="daily_summary_report"
            />
          </Box>

          <Box className="last_toggle_option_container">
            <ToggleSwitch
              label="Monthly Activity Report"
              settingKey="monthly_activity_report"
            />
          </Box>
        </BillingNotifications>
      </Box>
    </Box>
  );
}

export default Notifications;
