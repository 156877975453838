import CalendarIcon from "./calendar.png";
import balanceIcon from "./balance.png";
import timeNeededIcon from "./timeNeeded.png";
import bellIcon from "./bell.png";
import clockIcon from "./clock.png";
import timerIcon from "./timer.png";
import valueIcon from "./value.png";
import rocketIcon from "./rocket.png";
import mailIcon from "./mail.png";
import deliverIcon from "./deliver.png";
import cardIcon from "./creditCard.png";
import percentIcon from "./percent.png";
import cashIcon from "./cash.png";
import closeIcon from "./close.png";
import checkIcon from "./check.png";
import refreshIcon from "./refresh.png";

const icons = {
  balance: balanceIcon,
  timeNeeded: timeNeededIcon,
  calendar: CalendarIcon,
  bell: bellIcon,
  clock: clockIcon,
  timer: timerIcon,
  value: valueIcon,
  rocket: rocketIcon,
  mail: mailIcon,
  deliver: deliverIcon,
  card: cardIcon,
  percent: percentIcon,
  cash: cashIcon,
  close: closeIcon,
  check: checkIcon,
  refresh: refreshIcon,
};

export default icons;
