import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";

const ratingLabels = [
  { label: "Not good", value: 1 },
  { label: "So-so", value: 2 },
  { label: "Ok", value: 3 },
  { label: "Good", value: 4 },
  { label: "Great!", value: 5 },
];

const StarRating = ({ StarIcon, handleRatingChange, value }) => {


  
  const [selectedValue, setSelectedValue] = useState(value);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track hovered index

  // Sync local state with the prop whenever value changes
  useEffect(() => {
    setSelectedValue(value); 
  }, [value]);

  const handleStarClick = (newValue) => {
    if (newValue === selectedValue) {
      setSelectedValue(null); // Clear the value if the clicked star is the active one
      handleRatingChange(null, null); // Clear the rating in the parent
    } else {
      setSelectedValue(newValue);
      handleRatingChange(null, newValue); // Update the rating in the parent
    }
  };




  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ px: 9,height:'100%', display:'flex' }}>
      {[...Array(5)].map((_, index) => {
        const value = index + 1;
        const isSelected = value === selectedValue;
        const label = ratingLabels[index].label;

        return (
          <React.Fragment key={index}>
            {/* Wrap Box and Label in a Stack to align them vertically */}
            <Stack alignItems="center" spacing={1} >
              {/* Box */}
              <Box
                onClick={() => handleStarClick(value)}
                onMouseEnter={() => setHoveredIndex(value)} // Set hover on enter
                onMouseLeave={() => setHoveredIndex(null)} // Clear hover on leave
                sx={{
                  position: "relative",
                  backgroundColor: "#dfeaee",
                  width: "56px",
                  height: "55px",
                  borderRadius: "12px",
                  border: "1.5px solid #c2d7df",
                  transition: "transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)",
                  alignContent: "center",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  
                  transform: isSelected ? "scale(1.1)" : "scale(1)",
                  "&:hover": {
                    transform: isSelected ? "scale(1.1)" : "scale(1.09)", // Scale down when selected, increase when not
                  },
                }}
              >
                {/* Filter Box (Overlay) */}
                <Box
                  className="starContainerHigh"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#dfeaee",
                    borderRadius: "12px",
                    p: 1,
                    opacity: isSelected ? 1 : 0.7,
                    transition: "opacity 0.1s ease-in-out",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                      opacity: isSelected ? 0.9 : 1,
                    },
                  }}
                >
                  <img
                    src={StarIcon}
                    alt="star"
                    style={{
                      width: isSelected ? "38px" : "26px", // Increase size when selected
                      height: "auto",
                      display: "block",
                      transition: "transform 0.2s ease-in-out, width 0.2s ease-in-out",
                      transform: isSelected
                        ? "scale(1)"
                        : hoveredIndex === value
                        ? "scale(1.4)"
                        : "scale(1)", // Apply scale only when hovered and not selected
                    }}
                    className={`starIcon ${isSelected ? "selectedStar noHover" : ""}`} // Add the conditional class names
                  />
                </Box>
              </Box>

              {/* Label Below Each Star */}
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  color: "#48768C",
                  textAlign: "center", // Center the label text
                  pt:1,

                }}
              >
                {label}
              </Typography>
            </Stack>

            {/* Line Connector (skip the last one) */}
            {index < 4 && (
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <Box
                sx={{
                    mb:4,
                  height: "2px",
                  background: "linear-gradient(135deg, #1F5A5F, #7A9A92)", // More contrasting and dramatic gradient
                  borderRadius: "19px",
                  opacity: 0.75,
                  width: "16px", // Adjust the width for spacing between the boxes
        
        
                }}
              />
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default StarRating;