import { useAuth0 } from "@auth0/auth0-react";

function ValidateData() {
  const { user, getAccessTokenSilently } = useAuth0();

  // Function to validate the notification title
  const validateNotificationTitle = async (notificationTitle) => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(
        `/api/user-notifications/validate-notification-type/${user.sub}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ notificationType: notificationTitle }),
        },
      );

      const data = await response.json();

      if (response.ok) {
        // Return true if the notification type exists (duplicate), false if it's safe to add
        return data.exists;
      } else {
        console.error("Error:", data.error || "An error occurred");
        return false;
      }
    } catch (err) {
      console.error("Error:", err);
      return false;
    }
  };

  return { validateNotificationTitle }; // You can export the function to be used by the parent component
}

export default ValidateData;
