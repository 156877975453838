import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "../context/AppContext";

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [messagesFeedData, setMessagesFeedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { username, subUserData } = useAuth();
  const { user, getAccessTokenSilently } = useAuth0();
  const { reloadComponents } = useAppContext();

  const fetchData = async (subUserData, getAccessTokenSilently, user) => {
    try {
      setLoading(true);

      if (subUserData && user && user.sub) {
        const apiUrl = process.env.REACT_APP_API_URL;
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${apiUrl}/api/communications/messages/${user.sub}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (response.ok) {
          const data = await response.json();
          setMessagesFeedData(data.messages);
        }
      }
    } catch (error) {
      console.error("Failed to fetch customer feed data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchData(subUserData, getAccessTokenSilently, user);
    }
  }, [subUserData, user, reloadComponents, getAccessTokenSilently]);

  return (
    <MessageContext.Provider value={{ messagesFeedData, loading, fetchData }}>
      {children}
    </MessageContext.Provider>
  );
};

export const useMessageContext = () => useContext(MessageContext);
