import React from "react";
import { useUserSelectedNotifications } from "../../context/UserNotificationsContext.js";
import { Box, Paper, Typography } from "@mui/material";

const StatusText = ({ status, size }) => {
  const { selectedNotifications } = useUserSelectedNotifications();

  // Ensure selectedNotifications is always an array
  const notificationsArray = Array.isArray(selectedNotifications)
    ? selectedNotifications
    : [];

  // Object containing configurations for DECLINED and APPROVED statuses
  const statusConfigs = {
    DECLINED: {
      Color: "#7a4d5e",
      fontSize: "14px",
      fontWeight: "bold",
      letterSpacing: 0.3,
      lineHeight: "-2%",
      textTransform: "capitalize", // Capitalize the first letter of each word
    }, // Red color for DECLINED
    APPROVED: {
      Color: "#5ca595",
      fontSize: "14px",
      fontWeight: "bold",
      letterSpacing: 0.3,
      lineHeight: "-2%",
      textTransform: "capitalize", // Capitalize the first letter of each word
    }, // Green color for APPROVED
  };

  // Default color for unmatched statuses
  const defaultColor = "#DBDBDB"; // Gray color

  // Convert the status to lowercase for case-insensitive comparison
  const lowercaseStatus = status.toLowerCase();

  function capitalizeFirstLetterOfEachWord(str) {
    return str
      .toLowerCase()
      .replace(/(^|\s)\S/g, (match) => match.toUpperCase());
  }

  // Check if the status is DECLINED or APPROVED
  if (lowercaseStatus === "declined" || lowercaseStatus === "approved") {
    const Configs = statusConfigs[status];
    const formattedStatus = capitalizeFirstLetterOfEachWord(status);

    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          maxWidth: "fit-content",
          display: "inline-block",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: Configs?.Color || defaultColor, // Use default color if Configs.Color is undefined

            ml: 0.5,
            mr: "auto",
            textAlign: "center",
            fontWeight: "bold",
            letterSpacing: "-1%",
            lineHeight: "-1%",
            borderRadius: "6.5px",
            textTransform: "capitalize", // Capitalize the first letter of each word
            display: "inline-block",
            justifyContent: "center",
            maxWidth: "fit-content",
          }}
        >
          {formattedStatus}
        </Typography>
      </Box>
    );
  }

  // Check if the status is not DECLINED or APPROVED, and not in any notification type
  const lowercaseNotificationTypes = notificationsArray
    .map((notification) =>
      Object.keys(notification.selected_data).map((notificationType) =>
        notificationType.toLowerCase(),
      ),
    )
    .flat();
  if (!lowercaseNotificationTypes.includes(lowercaseStatus)) {
    // Render a default status tag with default styles

    const formattedStatus = capitalizeFirstLetterOfEachWord(status);

    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          maxWidth: "fit-content",
          display: "inline-block",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            borderRadius: "5px",
            color: "#5B5C5D",

            ml: 0.75,
            mr: 0,
            textAlign: "center",
            fontWeight: "bold",
            letterSpacing: "-1%",
            lineHeight: "-1%",
            borderRadius: "6.5px",
            textTransform: "capitalize", // Capitalize the first letter of each word
            display: "inline-block",
            justifyContent: "center",
            maxWidth: "fit-content",
          }}
        >
          {formattedStatus}
        </Typography>
      </Box>
    );
  }

  // If the status is not DECLINED or APPROVED, continue with the regular logic
  return (
    <div>
      {notificationsArray.map((notification, index) =>
        Object.keys(notification.selected_data).map((notificationType, idx) => {
          const Configs = notification.selected_data[notificationType]?.Configs;

          // Convert the notificationType to lowercase for case-insensitive comparison
          const lowercaseNotificationType = notificationType.toLowerCase();

          // Check if the current notificationType matches the lowercase status
          if (lowercaseNotificationType === lowercaseStatus) {
            return (
              <Box
                key={`${index}-${idx}`}
                sx={{
                  backgroundColor: "transparent",
                  maxWidth: "fit-content",
                  display: "inline-block",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    borderRadius: "5px",
                    color: Configs?.Color || defaultColor,

                    ml: 0.5,
                    mr: 0,
                    textAlign: "center",
                    fontWeight: "bold",
                    letterSpacing: "-1%",
                    borderRadius: "6.5px",
                    textTransform: "capitalize", // Capitalize the first letter of each word
                    justifyContent: "center",
                    maxWidth: "fit-content",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                  }}
                >
                  {notificationType}
                </Typography>
              </Box>
            );
          }
          return null; // If not matched, return null
        }),
      )}
    </div>
  );
};

export default StatusText;
