import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Modal,
  Grid,
  Typography,
  Fade,
  Stack,
  Skeleton,
  CardMedia,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import moment from "moment-timezone"; // Import moment-timezone
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import companyImage from "src/public/assets/companyImage.png";
import RestartSetupDialog from "src/components/modal/NewUserSignUp/Refresh/Components/RestartSetupDialog";



const theme = createTheme({
  palette: {
    primary: {
      main: "#48768C", // Set the main color to white
    },
  },
});






const CreateLocationStep = ({setIsNextButtonDisabled, isNextButtonDisabled, isSingleLocation, setIsSingleLocation, showCreateLocation }) => {

console.log("isSingleLocation", isSingleLocation);


  const handleLocationChange = (event) => {
    setIsSingleLocation(event.target.value === "single_location");
  };




   const [errors, setErrors] = useState({});
      const [warning, setWarning] = useState(false);
    
  
    const [values, setValues] = React.useState({
      name: '',
      phone: '',
      email: '',
    });
  
  
    console.log("values", values);  
  
      const validateField = (type, value) => {
        const validators = {
          name: (company_name) => company_name.trim().length > 0,
          email: (company_email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(company_email),
          phone: (phone) => /^\d{10}$/.test(phone),
        };
    
        return validators[type]?.(value) ?? true; // Return true if no validator is found
      };
  
  
      const handleBlur = (field) => {
        setErrors((prev) => {
          const updatedErrors = {
            ...prev,
            [field]: !validateField(field, values[field] || ""),
          };
          setIsNextButtonDisabled(Object.values(updatedErrors).some((error) => error));
          return updatedErrors;
        });
      };
    
      const handleChange = (field, value) => {
        setValues((prev) => ({ ...prev, [field]: value }));
        setErrors((prev) => {
          const updatedErrors = { ...prev, [field]: !validateField(field, value) };
          setIsNextButtonDisabled(Object.values(updatedErrors).some((error) => error));
          return updatedErrors;
        });
        setWarning(false);
       
      };
  
  
      const handleStateChange = (e) => {
        const value = e.target.value;
    
        // Update the `values` state with the manually entered value
        setValues((prev) => ({ ...prev, state: value }));
        setErrors((prev) => ({ ...prev, state: false }));
        setWarning(false);
      };
    



  return (  
    !showCreateLocation ? (
      <Fade in={true} timeout={400}>
      <Box
        sx={{
          height:'100%',
          width: "auto",
          justifyContent: "center",
          borderRadius: "40px",
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: "#FFFFFF",
          color: "#48768C",
          padding:1,
          alignItems:'center'
        }}
      >
      
      <Typography
          sx={{
            fontSize: '1.28rem',
            textAlign: "left",
            fontWeight: 600,
            px:.25,
            letterSpacing:'-0.02em',
            lineHeight: '-0.02em'
      
          }}
        >
            NanoNotify works great with companies that have multiple
            locations!
          </Typography>
      
        <Typography
          sx={{
            mt: 1.75,
            fontSize: '1.1rem',
            textAlign: "left",
            fontWeight: 500,
            px:.25,
                  letterSpacing:'-0.01em',
            lineHeight: '-0.04em',
            color:'#64697D'
          }}
        >
            Does your company have multiple locations you need to
            manage?
          </Typography>
      
      
      <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
      <FormControl component="fieldset">
        <RadioGroup name="locationType" onChange={handleLocationChange}>
          <FormControlLabel
            value="single_location"
            control={<Radio sx={{ color: "#48768c", '&.Mui-checked': { color: "#6595ab" } }} />}
            label="Just a single location"
            sx={{
              "& .MuiTypography-root": { fontSize: '1.07rem', letterSpacing: '-0.01em', fontWeight: 500, color: '#64697D' }
            }}
          />
          <FormControlLabel
            value="multiple_locations"
            control={<Radio sx={{ color: "#48768c", '&.Mui-checked': { color: "#6595ab" } }} />}
            label="We have multiple locations"
            sx={{
              "& .MuiTypography-root": { fontSize: '1.07rem', letterSpacing: '-0.01em', fontWeight: 500, color: '#64697D' }
            }}
          />
        </RadioGroup>
      </FormControl>
      </Box>
      
      </Box>
      </Fade>
    ) : (
    
<Fade in={true} timeout={400}>
<Box
  sx={{
    height:'100%',
    width: "auto",
    justifyContent: "center",
    borderRadius: "40px",
    fontSize: 17,
    fontWeight: 600,
    backgroundColor: "#FFFFFF",
    color: "#48768C",
    padding:1
  }}
>
<Typography
    sx={{
      mt: -1,
      fontSize: '1.28rem',
      textAlign: "left",
      fontWeight: 600,
      px:.25,
      letterSpacing:'-0.02em',
      lineHeight: '-0.02em'

    }}
  >
    Add Your First Location
  </Typography>


  <Typography
  color="textSecondary"
    sx={{
      mt: 0,
      fontSize: '.87rem',
      textAlign: "left",
      mb: 1,
      fontWeight: 500,
      px:.25,
            letterSpacing:'-0.01em',
      lineHeight: '-0.04em'
    }}
  >
    Important stuff like your company's website, email, and location
    information are automatically added to your notifications.
  </Typography>

        <Stack
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%",}} // Ensure it spans the available width
        >

  <ThemeProvider theme={theme}>
    <TextField
      label="Location Nickname (Example: Main St.)"
      variant="outlined"
      size="small"
      value={values.name || ""}
      onChange={(e) => handleChange("name", e.target.value)}

      fullWidth
      onBlur={() => handleBlur("name")}
      error={errors.name}
      InputProps={{
        style: { color: "#48768C", borderRadius: "6px" },
      }}
      InputLabelProps={{ style: { color: "#82879B" } }}
      sx={{

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6595ab",
        },
        color: "#48768C",
      }}
    />
  </ThemeProvider>
  <ThemeProvider theme={theme}>
    <TextField
      label="Location Email"
      variant="outlined"
      size="small"
      value={values.email || ""}
      onChange={(e) => handleChange("email", e.target.value)}
      fullWidth
      onBlur={() => handleBlur("email")}
      error={errors.email}
      InputProps={{
        style: { color: "#48768C", borderRadius: "6px" },
      }}
      InputLabelProps={{ style: { color: "#82879B" } }}
      sx={{

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6595ab",
        },
        color: "#48768C",
      }}
    />
  </ThemeProvider>
  <ThemeProvider theme={theme}>
    <TextField
      label="Location Phone Number"
      variant="outlined"
      size="small"
      mb={2}
      value={values.phone || ""}
      onChange={(e) => handleChange("phone", e.target.value)}
      onBlur={() => handleBlur("phone")}
      error={errors.phone}
      fullWidth
      InputProps={{
        style: { color: "#48768C", borderRadius: "6px" },
      }}
      InputLabelProps={{ style: { color: "#82879B" } }}
      sx={{

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6595ab",
        },
        color: "#48768C",
      }}
    />
  </ThemeProvider>
 
  </Stack>
</Box>
</Fade>
)  
  );
}

export default CreateLocationStep;  
