import { Typography, InputLabel, MenuItem, Select } from "@mui/material";
import { FormControl } from "@mui/material";
import { styled } from "@mui/system";

const SeverityFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#E9EDF2",
  marginBottom: 3,
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -1.5px) scale(0.75)",
    paddingLeft: "7px", // Adjust the translateY value as needed
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#48768c", // Set the border color here
    },
    "&:hover fieldset": {
      borderColor: "#6fa3b7", // Set the hover border color here (optional)
    },
    "&.Mui-focused fieldset": {
      borderColor: "#48768c", // Set the focused border color here
    },
  },
}));

const SeverityForm = ({ bugDetails, handleChange }) => {
  return (
    <>

      <Typography 
      color="textSecondary"
      sx={{fontSize: ".93rem", fontWeight: 600, pl: "2px", pb: "5px", mt: "5px", pt:'5px' }}>
       Give us an idea on how severe the issue is.
      </Typography>

      <SeverityFormControl sx={{ backgroundColor: "#FFFFFF", mb:'18px' }}>
      {!bugDetails.severity && (
          <InputLabel 
          shrink={false} sx={{ marginTop: "-6px", backgroundColor: "#FFFFFF",
            "&.Mui-focused": { 
              color: "#48768c", fontWeight:600, // Change to your desired active color
              border:'none'
            }
          }}
        >

            Select the severity of the issue
          </InputLabel>
        )}
        <Select
        
          value={bugDetails.severity}
          onChange={handleChange}
          name="severity"
          size="small"
          required
          displayEmpty // Ensures the placeholder is visible when nothing is selected
          fullWidth // Makes sure the Select component spans 100% width
          sx={{    color: "#48768c",backgroundColor: "#FFFFFF", border:'none'
          }}

        >

          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Low">
            Causes issues, but is still functional
          </MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c'  }} value="Medium">
            Requires workaround, but still works
          </MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c'  }} value="High">
            Does not work at all
          </MenuItem>
        </Select>
      </SeverityFormControl>
    </>
  );
};

export default SeverityForm;