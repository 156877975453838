import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  IconButton,
  Stack,
  InputAdornment,
  Fade,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Icon as Iconify } from "@iconify/react";

function SearchBarNormal({
  searchValue,
  handleSearch,
  handleClearSearch,
  placeholderText,
}) {
  return (
    <TextField
      variant="outlined"
      placeholder={placeholderText}
      value={searchValue}
      onChange={handleSearch}
      sx={{
        color: "#2A3D4F",
        ml: "auto",
        "& .MuiOutlinedInput-root": {
          height: "35px",
          fontSize: "14px",
          width: "250px",
          border: "1px solid #c2d7df",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
          "&:hover": {
            borderColor: "#90A4AE",
          },
          "&.Mui-focused": {
            borderColor: "#48768C",
            borderWidth: "2px",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& input::placeholder": {
          marginLeft: "8px",
          color: "#48768C",
          lineHeight: 0,
          fontWeight: 500,
          fontSize: "13.75px",
          fontFamily: "Source Sans Pro, sans-serif",
          opacity: 0.88,
        },
      }}
      InputLabelProps={{
        style: { color: "#2A3D4F", fontSize: "14px" },
      }}
      InputProps={{
        style: { color: "#2A3D4F" },
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="bi:search" style={{ color: "#90A4AE" }} />
          </InputAdornment>
        ),
        endAdornment: searchValue && (
          <InputAdornment position="end" sx={{ marginRight: "3px" }}>
            <Fade in={true} timeout={200}>
              <IconButton
                onClick={handleClearSearch}
                edge="end"
                sx={{ color: "#6595ab", padding: 0 }}
              >
                <CancelOutlinedIcon sx={{ fontSize: 20, opacity: 0.85 }} />
              </IconButton>
            </Fade>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchBarNormal;
