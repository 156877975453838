// customTheme.js
import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput"; // Import any necessary MUI classes

// Define and export the customTheme function
export const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "#48768C",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: "16px", // Adjust font size
            height: "30px", // Adjust height
            padding: "8px 10px", // Adjust padding (top-bottom, left-right)
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
          input: {
            fontSize: "16px", // Adjust font size for input text
            padding: "10px 14px", // Adjust padding for input text
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: "16px", // Adjust font size
            padding: "8px 10px", // Adjust padding
            height: "50px", // Adjust height if needed
          },
        },
      },
    },
  });
