import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "../context/AppContext";
import { useGlobalNotifications } from "../context/GlobalNotificationsContext";
import { useAuth } from "../context/AuthContext";
import { set } from "lodash";

export const WidgetContext = createContext();

export const WidgetProvider = ({ children }) => {
  const { subUserData } = useAuth();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [recentActivityData, setRecentActivityData] = useState(null);
  const [scheduledNotificationsData, setScheduledNotificationsData] =
    useState(null);
  const [pendingApprovalData, setPendingApprovalData] = useState(null);

  const [loadingRecentActivity, setLoadingRecentActivity] = useState(false);
  const [loadingScheduledNotifications, setLoadingScheduledNotifications] =
    useState(false);
  const [loadingPendingApproval, setLoadingPendingApproval] = useState(false);

  const [recentActivityLastTimestamp, setRecentActivityLastTimestamp] =
    useState(0);
  const [scheduledCount, setScheduledCount] = useState(0);
  const [approvalCount, setApprovalCount] = useState(null);
  const [freshScheduledCount, setFreshScheduledCount] = useState(null);
  const [freshApprovalCount, setFreshApprovalCount] = useState(0);
  const { reloadComponents, handleReloadComponents } = useAppContext();
  const [isAutoRefreshPaused, setIsAutoRefreshPaused] = useState(false); // Add paused state

  const apiUrl = process.env.REACT_APP_API_URL;
  const refreshInterval = 60000; // 1 Minute
  const refreshTimeoutRef = useRef(null);

  const isInitialMount = useRef(true);
  const { fetchGlobalNotifications, notifications, setNotifications } =
    useGlobalNotifications();

  const [recentActivityWidgetCount, setRecentActivityWidgetCount] = useState(
    recentActivityData?.filter(
      (activity) => activity?.location_id === subUserData?.active_location,
    ),
  );
  const [
    scheduledNotificationsWidgetCount,
    setScheduledNotificationsWidgetCount,
  ] = useState(
    scheduledNotificationsData?.filter(
      (activity) => activity?.location_id === subUserData?.active_location,
    ),
  );
  const [pendingApprovalWidgetCount, setPendingApprovalWidgetCount] = useState(
    pendingApprovalData?.filter(
      (activity) => activity?.location_id === subUserData?.active_location,
    ),
  );

  useEffect(() => {
    const updateWidgetCounts = () => {
      setRecentActivityWidgetCount(
        recentActivityData?.filter(
          (activity) => activity?.location_id === subUserData?.active_location,
        ).length || 0,
      );

      setScheduledNotificationsWidgetCount(
        scheduledNotificationsData?.filter(
          (activity) => activity?.location_id === subUserData?.active_location,
        ).length || 0,
      );

      setPendingApprovalWidgetCount(
        pendingApprovalData?.filter(
          (activity) => activity?.location_id === subUserData?.active_location,
        ).length || 0,
      );
    };

    updateWidgetCounts();
  }, [
    recentActivityData,
    scheduledNotificationsData,
    pendingApprovalData,
    subUserData?.active_location,
  ]);

  const fetchDataForWidget = async (
    widgetType,
    setData,
    setLastTimestamp,
    setCount,
    setLoading,
  ) => {
    try {
      if (isAuthenticated && user) {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${apiUrl}/api/communications/${widgetType}/${user.sub}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch ${widgetType} data`);
        }

        const { messages, lastTimestamp, count } = await response.json();
        setData(messages);
        setLastTimestamp(lastTimestamp);
        setCount(count);
        fetchGlobalNotifications();
        setLoading(false);
      }
    } catch (error) {
      console.error(`Failed to fetch ${widgetType} data:`, error);
      setLoading(false);
    }
  };

  const fetchAllWidgetTypes = async () => {
    setLoadingRecentActivity(true);
    setLoadingScheduledNotifications(true);
    setLoadingPendingApproval(true);

    try {
      await Promise.all([
        fetchDataForWidget(
          "recent",
          setRecentActivityData,
          setRecentActivityLastTimestamp,
          () => {},
          setLoadingRecentActivity,
        ),
        fetchDataForWidget(
          "scheduled",
          setScheduledNotificationsData,
          () => {},
          setScheduledCount,
          setLoadingScheduledNotifications,
        ),
        fetchDataForWidget(
          "recent/pendingApproval",
          setPendingApprovalData,
          () => {},
          setApprovalCount,
          setLoadingPendingApproval,
        ),
      ]);
    } catch (error) {
      console.error("Failed to fetch all widget types:", error);
    }
  };

  const fetchRecentData = async (setData, setLastTimestamp) => {
    try {
      if (isAuthenticated && user) {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${apiUrl}/api/communications/freshness/recent/${user.sub}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch recent data, status: ${response.status}`,
          );
        }

        const { timestamp } = await response.json();

        if (timestamp > recentActivityLastTimestamp) {
          fetchDataForWidget(
            "recent",
            setData,
            setLastTimestamp,
            () => {},
            () => {},
          );
        }
      }
    } catch (error) {
      console.error("Error fetching recent data:", error);
    }
  };

  const fetchScheduledData = async (
    setScheduledNotificationsData,
    setScheduledCount,
    setLoadingScheduledNotifications,
  ) => {
    try {
      if (isAuthenticated && user) {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${apiUrl}/api/communications/freshness/scheduled/${user.sub}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch scheduled data, status: ${response.status}`,
          );
        }

        const { freshScheduledCount, scheduledNotificationsData } =
          await response.json();

        setFreshScheduledCount(freshScheduledCount);

        if (freshScheduledCount !== scheduledCount) {
          fetchDataForWidget(
            "scheduled",
            setScheduledNotificationsData,
            () => {},
            setScheduledCount,
            setLoadingScheduledNotifications,
          );
          fetchDataForWidget(
            "recent",
            setRecentActivityData,
            setRecentActivityLastTimestamp,
            () => {},
            setLoadingRecentActivity,
          );
          setScheduledCount(freshScheduledCount);
        } else {
          setScheduledNotificationsData(scheduledNotificationsData); // Fixed typo here
          setScheduledCount(freshScheduledCount);
        }
      }
    } catch (error) {
      console.error("Error fetching scheduled data:", error);
    } finally {
      setLoadingScheduledNotifications(false);
    }
  };

  const fetchPendingApprovalData = async (
    setPendingApprovalData,
    setApprovalCount,
    setLoadingPendingApproval,
  ) => {
    try {
      setLoadingPendingApproval(true); // Set loading state to true
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${apiUrl}/api/communications/freshness/pendingApproval/${user.sub}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching pending approval data: ${response.status}`,
        );
      }

      const { freshApprovalCount, pendingApprovalData } = await response.json();
      setFreshApprovalCount(freshApprovalCount);
      if (freshApprovalCount !== approvalCount) {
        // Call fetchDataForWidget to refresh the data
        fetchDataForWidget(
          "recent/pendingApproval",
          setPendingApprovalData,
          () => {},
          setApprovalCount,
          setLoadingPendingApproval,
        );
        fetchDataForWidget(
          "recent",
          setRecentActivityData,
          setRecentActivityLastTimestamp,
          () => {},
          setLoadingRecentActivity,
        );

        setApprovalCount(freshApprovalCount);
      } else {
        // If counts are the same, update the state with the fetched data
        setPendingApprovalData(pendingApprovalData);
        setApprovalCount(freshApprovalCount);
      }
    } catch (error) {
      console.error("Error in fetchPendingApprovalData:", error);
    } finally {
      setLoadingPendingApproval(false); // Set loading state to false when done
    }
  };

  const fetchFreshnessData = async (
    widgetType,
    setData,
    setLastTimestamp,
    setCount,
    setPendingApprovalData,
    setApprovalCount,
    setLoadingPendingApproval,
  ) => {
    if (widgetType === "recent") {
      fetchRecentData(setData, setLastTimestamp);
    } else if (widgetType === "scheduled")
      try {
        await fetchScheduledData(
          setScheduledNotificationsData,
          setScheduledCount,
          setLoadingScheduledNotifications,
        );
      } catch (error) {
        console.error("Error fetching scheduled data:", error);
      }
    else if (widgetType === "recent/pendingApproval") {
      try {
        // Ensure this function awaits the API call
        await fetchPendingApprovalData(
          setPendingApprovalData,
          setApprovalCount,
          setLoadingPendingApproval,
        );
      } catch (error) {
        console.error("Error fetching pending approval data:", error);
      }
    }
  };

  const pauseAutoRefresh = () => {
    setIsAutoRefreshPaused(true);
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }
  };

  const resumeAutoRefresh = () => {
    setIsAutoRefreshPaused(false);
    setAutoRefresh(); // Start the auto-refresh again
  };

  const setAutoRefresh = () => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }

    refreshTimeoutRef.current = setTimeout(() => {
      fetchFreshnessData(
        "recent",
        setRecentActivityData,
        setRecentActivityLastTimestamp,
        null,
        null,
        null,
        null,
      );
      fetchFreshnessData(
        "scheduled",
        setScheduledNotificationsData,
        null,
        setScheduledCount,
        null,
        null,
        null,
      );
      fetchFreshnessData(
        "recent/pendingApproval",
        null,
        null,
        null,
        setPendingApprovalData,
        setApprovalCount,
        setLoadingPendingApproval,
      );

      setAutoRefresh(); // Re-trigger the refresh cycle
    }, refreshInterval);
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      // Fetch data on initial load
      fetchDataForWidget(
        "recent",
        setRecentActivityData,
        setRecentActivityLastTimestamp,
        () => {},
        setLoadingRecentActivity,
      );
      fetchDataForWidget(
        "scheduled",
        setScheduledNotificationsData,
        () => {},
        setScheduledCount,
        setLoadingScheduledNotifications,
      );
      fetchDataForWidget(
        "recent/pendingApproval",
        setPendingApprovalData,
        () => {},
        setApprovalCount,
        setLoadingPendingApproval,
      );

      // Start auto-refreshing the data
      setAutoRefresh();
    }

    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current); // Clear the timeout when component unmounts
      }
    };
  }, [isAuthenticated, user, getAccessTokenSilently]);

  return (
    <WidgetContext.Provider
      value={{
        recentActivityData,
        scheduledNotificationsData,
        pendingApprovalData,
        loadingRecentActivity,
        loadingScheduledNotifications,
        loadingPendingApproval,
        fetchAllWidgetTypes,
        pauseAutoRefresh, // Expose the pause function
        resumeAutoRefresh, // Expose the resume function
        recentActivityWidgetCount,
        scheduledNotificationsWidgetCount,
        pendingApprovalWidgetCount,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};

export const useWidgetContext = () => useContext(WidgetContext);
