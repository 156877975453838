import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAccountContext } from "src/context/AccountContext";

const useDeleteUser = (totalLocations, totalUsers) => {
  const { accountData } = useAccountContext();
  const { getAccessTokenSilently } = useAuth0();
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const [deleteUserError, setDeleteUserError] = useState(null);
  const [success, setSuccess] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [price, setPrice] = useState(null);
  const [productId, setProductId] = useState(null);
  const customerId = accountData?.customer_id;

  useEffect(() => {
    const fetchSubscriptionData = async (totalLocations, totalUsers) => {
      try {
        const accessToken = await getAccessTokenSilently();

        // Determine the API endpoint based on totalUsers and totalLocations
        const endpoint =
          totalUsers > totalLocations
            ? `${apiUrl}/subscriptions/plans/getSubscription/includedAdditionalUser`
            : `${apiUrl}/subscriptions/plans/getSubscription/addAdditionalUser`;

        const response = await fetch(endpoint, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Include the token
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Set state using the response data
        setSubscriptionData(data.addAdditionalUser); // Adjust key if necessary
        setPrice(data.addAdditionalUser.price); // Adjust key if necessary
        setProductId(data.addAdditionalUser.PID); // Adjust key if necessary
      } catch (err) {
        setError("Error fetching subscription data: " + err.message);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, [totalUsers, totalLocations]); // Include dependencies for dynamic comparison

  const deleteUser = async (userToDelete, totalLocations, totalUsers) => {
    setDeleteUserLoading(true);
    setDeleteUserError(null);
    setSuccess(false);

    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/subscriptions/manage/deleteProductFromSubscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            customerId,
            productId, // Ensure you're sending productId here
            userToDelete, // Ensure you're sending userToDelete here
          }),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        return {
          success: false,
          error: errorData.message || "Failed to delete user",
        };
      }

      const data = await response.json();
      return { success: true, data };
    } catch (err) {
      console.error("Error in deleteUser:", err);
      return {
        success: false,
        error: err.message || "Unexpected error occurred",
      };
    } finally {
      setDeleteUserLoading(false);
    }
  };

  return {
    deleteUser,
    deleteUserLoading,
    deleteUserError,
    success,
  };
};

export default useDeleteUser;
