import React from "react";
import { Box, Paper, Typography, Divider, Stack } from "@mui/material";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

const removePlan = () => {
  return (
    <Box
      sx={{
        border: "1px solid rgba(191, 218, 231, 0.5)",
        borderRadius: "16px",
        width: "265px",
        height: "100%",
        backgroundColor: "#FFFFFF",
        padding: 1.5,
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          border: "1.5px solid #BCE4F6",
          borderRadius: "6px",
          width: "83%",
          backgroundColor: "transparent",
          mb: 1.25,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "2px",
            backgroundColor: "transparent",
            color: "#48768C",
            py: "2px",
          }}
        >
          <HighlightOffTwoToneIcon
            sx={{
              padding: "2px",
              color: "#8C4350",
              fontSize: "24px",
              backgroundColor: "transparent",
            }}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#B0B8BC", margin: "0 8px" }}
          />
          <Typography
            sx={{
              fontSize: "13.5px",
              fontWeight: 600,
              color: "#4A6B75",
              letterSpacing: "1%",
              textAlign: "center",
              lineHeight: "-2%",
            }}
          >
            Removed Plan
          </Typography>
        </Paper>
      </Box>

      <Box sx={{ mt: 1.5, height: "auto", display: "flex", mr: 3, mb: 1.5 }}>
        <ChatBubbleOutlineRoundedIcon
          sx={{
            color: "#6E8890",
            fontSize: "25px",
            border: "1.5px solid #D5DEE2",
            borderRadius: "6px",
            backgroundColor: "rgba(191, 218, 231, 0.25)",
            padding: "3px",
          }}
        />
        <Box
          sx={{
            ml: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14.5px",
              fontWeight: 600,
              color: "#4A6B75",
              letterSpacing: "1%",
              textAlign: "left",
              mb: -0.35,
              mt: -0.4,
            }}
          >
            Single User Plan
          </Typography>
        </Box>
      </Box>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{ borderColor: "#B0B8BC", mx: 0.25, my: 0.75 }}
      />
      <Typography
        sx={{
          fontSize: "13.5px",
          fontWeight: 600,
          color: "#4A6B75",
          letterSpacing: "1%",
          textAlign: "left",
          mb: 0.75,
          mt: 0.55,
        }}
      >
        Plan Details
      </Typography>
      <Stack direction="row" sx={{ alignItems: "center", display: "flex" }}>
        <CircleRoundedIcon
          sx={{
            fontSize: "10px",
            color: "#647D9E",
            mr: 1,
            backgroundColor: "rgba(191, 218, 231, 0.75)",
            borderRadius: "50%",
            padding: "1px",
          }}
        />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#628197",
            letterSpacing: "1%",
            textAlign: "left",
            mr: "5px",
          }}
        >
          Unlimited Notifications
        </Typography>
      </Stack>
      <Stack direction="row" sx={{ alignItems: "center", display: "flex" }}>
        <CircleRoundedIcon
          sx={{
            fontSize: "10px",
            color: "#647D9E",
            mr: 1,
            backgroundColor: "rgba(191, 218, 231, 0.75)",
            borderRadius: "50%",
            padding: "1px",
          }}
        />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#628197",
            letterSpacing: "1%",
            textAlign: "left",
            mr: "5px",
          }}
        >
          Two Way Notification Support
        </Typography>
      </Stack>
      <Stack direction="row" sx={{ alignItems: "center", display: "flex" }}>
        <CircleRoundedIcon
          sx={{
            fontSize: "10px",
            color: "#647D9E",
            mr: 1,
            backgroundColor: "rgba(191, 218, 231, 0.75)",
            borderRadius: "50%",
            padding: "1px",
          }}
        />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#628197",
            letterSpacing: "1%",
            textAlign: "left",
            mr: "5px",
          }}
        >
          Scheduled Notifications
        </Typography>
      </Stack>
    </Box>
  );
};

export default removePlan;
