import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Grid,
  Typography,
  Stack,
  Divider,
  IconButton,
  Radio,
} from "@mui/material";
import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";

function TransferList({
  users,
  subUserData,
  name,
  handleCheckedLeft,
  handleToggle,
  checked,
  left,
  right,
  handleCheckedRight,
  setLeft,
}) {
  // Filter out the subUserData.user_id from the initial list

  useEffect(() => {
    if (!left.length && users?.users && subUserData?.user_id) {
      const availableUsers = users.users.filter(
        (user) =>
          user.user_id !== subUserData.user_id &&
          !right.some((rightUser) => rightUser.user_id === user.user_id),
      );

      setLeft(availableUsers);
    }
  }, [users, subUserData, right]);

  const customList = (title, items) => (
    <Box
      sx={{
        width: 216,
        height: 230,
        border: "1.25px solid rgba(173, 216, 230, 0.5)",
        borderRadius: "14px",
        overflow: "auto",
        backgroundColor: "rgba(191, 218, 231, 0.20)",
        mb: 2,
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "15.5px",
          fontWeight: 600,
          color: "#4A6B75",
          px: "5px",
          letterSpacing: "-2%",
          py: "5px",
          border: "1.25px solid rgba(173, 216, 230, 0.5)",
          backgroundColor: "rgba(191, 218, 231, 0.2)",
        }}
      >
        {title}
      </Typography>
      <List
        dense
        component="div"
        role="list"
        sx={{
          "& .MuiListItemIcon-root": {
            minWidth: 0,
            marginRight: 0,
            marginLeft: -1,
          },
        }}
      >
        {Array.isArray(items) &&
          items.map((user) => {
            const labelId = `transfer-list-item-${user.user_id}-label`;

            return (
              <ListItem
                key={user.user_id}
                role="listitem"
                button
                onClick={handleToggle(user)}
              >
                <ListItemIcon>
                  <Checkbox
                    sx={{
                      color: "#4A6B75",
                      borderRadius: "15px",
                      fontSize: 28,
                      borderRadius: "45px",
                      "&.Mui-checked": {
                        color: "#4A6B75",
                        borderRadius: "45px",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 21 },
                    }}
                    checked={checked.indexOf(user) !== -1} // Ensure checked is an array
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: 600,
                    color: "#4A6B75",
                    letterSpacing: "-2%",
                    whiteSpace: "nowrap", // fixed typo here, it should be 'nowrap' instead of 'no-wrap'
                  }}
                  id={labelId}
                >
                  {`${user.first_name} ${user.last_name}`}
                </Typography>
              </ListItem>
            );
          })}
        <ListItem />
      </List>
    </Box>
  );

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Box>{customList("Active Employees", left)}</Box>

      <Stack direction="column" alignItems="center">
        <IconButton
          sx={{
            mb: 3,
            border: "1.25px solid rgba(173, 216, 230, 0.8)",
            borderRadius: "10px",
            width: "40px",
            height: "auto",
          }}
          onClick={handleCheckedRight}
          disabled={checked.length === 0 || left.length === 0}
        >
          <ForwardRoundedIcon sx={{ color: "#4A6B75" }} />
        </IconButton>
        <IconButton
          sx={{
            mb: 2,
            border: "1.25px solid rgba(173, 216, 230, 0.8)",
            borderRadius: "10px",
            width: "40px",
            height: "auto",
          }}
          onClick={handleCheckedLeft}
          disabled={checked.length === 0 || right.length === 0}
        >
          <ForwardRoundedIcon
            sx={{ transform: "rotate(180deg)", color: "#4A6B75" }}
          />
        </IconButton>
      </Stack>

      <Box>{customList(name, right)}</Box>
    </Stack>
  );
}

export default TransferList;
