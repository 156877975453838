import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  Typography,
  IconButton,
  Portal,
  Stack,
  CardContent,
  Box,
  Tabs,
  Tab,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "../../../context/AppContext";
import { useAccountContext } from "src/context/AccountContext";
import { dismissItem } from "src/components/NewUserChecklist/dismissItem";
import ReviewDialog from "../ReviewDialog/ReviewDialog";
import useAddLocation from "src/components/payments/hooks/useAddLocation";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import addLocationIcon from "src/public/assets/icons/icons/newLocation.png";
import LocationInfoCard from "./LocationInfoCard";
import LocationHoursCard from "./LocationHoursCard";
import EmployeeSetupCard from "./EmployeeSetupCard";
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext";
import { useAuth } from "src/context/AuthContext.js"; // Assuming subUserData is coming from this context
import CancelCloseDialog from "../CancelCloseDialog";

import dayjs from "dayjs";
import { set } from "lodash";
import { setConfig } from "dompurify";
import EditLocationSubmit from "./EditLocationSubmit";
import EditHoursSubmit from "./EditHoursSubmit";
import EditUsersSubmit from "./EditUsersSubmit";
import { useWidgetContext } from "src/context/WidgetContext";

const apiUrl = process.env.REACT_APP_API_URL;

const EditLocationModal = ({
  open,
  onClose,
  users,
  locations,
  fetchLocations,
  fetchUsers,
  editLocationData,
  viewLocationData,
  locationId,
}) => {
  const {
    handleReloadComponents,
    showSnackBar,
    updateActiveLocation,
    fetchLatestUserData,
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { accountData, onboardingData, fetchOnboardingRecord } =
    useAccountContext();
  const [item, setItem] = useState("addLocation");
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const { addNewLocation, addLocationLoading, addLocationError, success } =
    useAddLocation();
  const [hoursItem, setHoursItem] = useState("confirmHours");
  const {
    subUserData,
    username,
    logout: customLogout,
    resetContext,
  } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isCancelCloseOpen, setIsCancelCloseOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  //New Location Info
  const [name, setName] = useState("");
  const [newLocationName, setNewLocationName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [locationNameTaken, setLocationNameTaken] = useState(false);
  //Confirm Hours
  const [confirmHours, setConfirmHours] = useState(true);
  const [defaultHoursChanged, setDefaultHoursChanged] = useState(false);
  const [isSaveHoursButtonDisabled, setIsSaveHoursButtonDisabled] =
    useState(false); // State to manage button disabled state
  //New User
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pin, setPin] = useState("");
  const [showPin, setShowPin] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [isSaveUserButtonEnabled, setIsSaveUserButtonEnabled] = useState(false);
  const [hideCreateUserButton, setHideCreateUserButton] = React.useState(false);
  const [isAssignEmployeeVisible, setIsAssignEmployeeVisible] = useState(false);
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userNameTaken, setUserNameTaken] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);
  //Transfer List
  const [checked, setChecked] = useState([]);
  const [right, setRight] = useState([]);
  const [assignedUser, setAssignedUser] = useState(null);
  const [assignedLocation, setAssignedLocation] = useState(null);
  const availableUsers = users.filter(
    (user) => user.user_id !== subUserData.user_id,
  );
  const [left, setLeft] = useState([]);
  const [isNewUserSaved, setIsNewUserSaved] = useState(false);
  const [showConfirmNewPin, setShowConfirmNewPin] = useState(false);
  const [newUserId, setNewUserId] = useState(null);
  const [newUserCreated, setNewUserCreated] = useState(false);
  const [enableEditButtons, setEnableEditButtons] = useState(false);
  const [confirmUsers, setConfirmUsers] = useState(true);
  const [hoursWarning, setHoursWarning] = useState(false);
  const [usersWarning, setUsersWarning] = useState(false);
  const [usersMoved, setUsersMoved] = useState(false);
  const { fetchAllWidgetTypes } = useWidgetContext();

  //Confirm Dialog
  const [confirmHeader, setConfirmHeader] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmSubText, setConfirmSubText] = useState("");

  const handleTabChange = (event, newValue) => {
    setIsAssignEmployeeVisible(false);
    if (newUserCreated === false) {
      setHideCreateUserButton(false);
    }
    if (isAssignEmployeeVisible && newUserCreated === false) {
      setHideCreateUserButton(false);
      setFirstName("");
      setLastName("");
      setPin("");
      setConfirmNewPin("");
      setShowConfirmNewPin(false);
      setShowPin(false);
      setUserName("");
      setNewUserName("");
      setIsAdmin(false);
    }
    setSelectedTab(newValue);
    setChecked([]);
  };

  const [hideWarning, setHideWarning] = useState(false);
  const handleOpenCancelCloseDialog = () => {
    // Check if any condition is true
    if (
      defaultHoursChanged ||
      (!confirmUsers && usersMoved) ||
      nameTouched ||
      emailTouched ||
      phoneTouched
    ) {
      setIsCancelCloseOpen(true); // Always open the cancel close dialog

      // Handle warnings independently
      if (defaultHoursChanged && !confirmHours) {
        setHoursWarning(true); // Show hours warning if needed
      }

      if (!confirmUsers || usersMoved) {
        setUsersWarning(true); // Show users warning if needed
      }
    } else {
      // Close dialog if no conditions are met
      setHideWarning(true);
      onClose();
    }
  };

  const handleCloseCancelCloseDialog = () => {
    setIsCancelCloseOpen(false);
  };

  const handleCancelClose = () => {
    setIsCancelCloseOpen(false);
    onClose();
  };

  const handleCloseReviewDialog = () => {
    setOpenReviewDialog(false);
  };

  const handleOpenReviewDialog = () => {
    setOpenReviewDialog(true);
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    if (!open) {
      setOpenReviewDialog(false);
    }
  }, [open]);

  const { user, getAccessTokenSilently } = useAuth0();
  const userId = user.sub;

  const [locationInfoChanged, setLocationInfoChanged] = useState(false);

  //Location Info Hooks
  useEffect(() => {
    const validateForm = () => {
      const isValidName = newLocationName.trim() !== "";
      const isValidEmail = validateEmail(email);
      const isValidPhone = validatePhoneNumber(phone);

      // Only consider fields as "changed" if they are not the same as the original data
      const isNameChanged =
        newLocationName.trim() !== editLocationData.name.trim();
      const isEmailChanged = email.trim() !== editLocationData.email.trim();
      const isPhoneChanged = phone.trim() !== editLocationData.phone.trim();

      // Form is valid if all fields are valid and at least one has changed
      setIsFormValid(
        isValidName &&
          isValidEmail &&
          isValidPhone &&
          (isNameChanged || isEmailChanged || isPhoneChanged),
      );
      if (isFormValid === false) {
        setIsButtonDisabled(false);
      }

      if (
        isNameChanged === true ||
        isEmailChanged === true ||
        isPhoneChanged === true
      ) {
        setLocationInfoChanged(true);
      } else {
        setLocationInfoChanged(false);
      }
    };

    validateForm();
  }, [
    name,
    email,
    phone,
    editLocationData.name,
    editLocationData.email,
    editLocationData.phone,
    open,
    newLocationName,
  ]);

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return `(${match[1]})${match[2] ? " " + match[2] : ""}${match[3] ? "-" + match[3] : ""}`;
    }
    return value;
  };

  const handleNameChange = (e) => {
    let inputValue = e.target.value;

    // Only allow alphanumeric characters (letters and numbers)
    inputValue = inputValue.replace(/[^a-zA-Z0-9 ]/g, "");

    // Capitalize the first letter and keep the rest of the text unchanged
    const capitalizedValue =
      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);

    setNewLocationName(capitalizedValue);
    setNameTouched(true);
    if (locationInfoChanged === false) {
      setLocationInfoChanged(true);
    }
    setNameTouched(true);
  };

  const handleNameBlur = () => {
    // Trim trailing spaces when the input loses focus
    const trimmedName = newLocationName.trim();
    setNewLocationName(trimmedName);
  };

  useEffect(() => {
    // Normalize names for case-insensitive comparison
    const currentName = editLocationData.name.toLowerCase();
    const newName = newLocationName.toLowerCase();

    // Check if the name is the same as the original or if it's taken by another location
    if (currentName === newName) {
      setLocationNameTaken(false);
    } else {
      const isLocationNameTaken = locations.some(
        (location) => location.name.toLowerCase() === newName,
      );
      setLocationNameTaken(isLocationNameTaken);
    }
  }, [newLocationName, locations, editLocationData.name]); // Trigger when name or locations change

  const validateName = (newLocationName) => {
    const nameRegex = /^[a-zA-Z0-9 ]+$/;
    return nameRegex.test(newLocationName);
  };

  const handleEmailChange = (e) => {
    let inputValue = e.target.value;

    // Allow only alphanumeric characters, '@', and '.'
    const validEmail = inputValue.replace(/[^a-zA-Z0-9@.]/g, "");

    setEmail(validEmail);
    if (locationInfoChanged === false) {
      setLocationInfoChanged(true);
    }
  };

  const handleEmailBlur = () => {
    // Trim trailing spaces and special characters like periods at the end of the email
    let trimmedEmail = email.trim();
    trimmedEmail = trimmedEmail.replace(/[^\w@.]+$/, "");

    // Only update the email state if it has changed
    if (email !== trimmedEmail) {
      setEmail(trimmedEmail);
    }

    // Mark email as touched only after the blur event
    setEmailTouched(true);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "").slice(0, 10); // Allow only digits, max length 10
    setPhone(formatPhoneNumber(value));
    if (locationInfoChanged === false) {
      setLocationInfoChanged(true);
    }
  };

  const handlePhoneBlur = () => {
    // Mark phone as touched only when the user leaves the field
    setPhoneTouched(true);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/; // Example: (123) 456-7890
    return phoneRegex.test(phone);
  };

  // Validate Unique Location Name
  useEffect(() => {
    setLocationNameTaken(false); // Reset the state to false
    const isLocationNameTaken = locations.some(
      (location) =>
        location.name.toLowerCase() === name.toLowerCase() &&
        location.name.toLowerCase() !== editLocationData.name.toLowerCase(),
    );

    // Set state based on whether the location name is taken
    setLocationNameTaken(isLocationNameTaken);
  }, [name, locations, editLocationData.name]); // Trigger when name, locations, or editLocationData.name change

  //Location Hours Hooks

  const [businessHours, setBusinessHours] = useState({
    Sunday: { start_time: null, end_time: null, is_closed: false },
    Monday: { start_time: null, end_time: null, is_closed: false },
    Tuesday: { start_time: null, end_time: null, is_closed: false },
    Wednesday: { start_time: null, end_time: null, is_closed: false },
    Thursday: { start_time: null, end_time: null, is_closed: false },
    Friday: { start_time: null, end_time: null, is_closed: false },
    Saturday: { start_time: null, end_time: null, is_closed: false },
  });
  const [startTimeValues, setStartTimeValues] = useState({
    Sunday: null,
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
  });
  const [endTimeValues, setEndTimeValues] = useState({
    Sunday: null,
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
  });

  const [defaultBusinessHours, setDefaultBusinessHours] = useState({
    Sunday: { start_time: null, end_time: null, is_closed: false },
    Monday: { start_time: null, end_time: null, is_closed: false },
    Tuesday: { start_time: null, end_time: null, is_closed: false },
    Wednesday: { start_time: null, end_time: null, is_closed: false },
    Thursday: { start_time: null, end_time: null, is_closed: false },
    Friday: { start_time: null, end_time: null, is_closed: false },
    Saturday: { start_time: null, end_time: null, is_closed: false },
  });
  const [defaultStartTimeValues, setDefaultStartTimeValues] = useState({
    Sunday: null,
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
  });
  const [defaultEndTimeValues, setDefualtEndTimeValues] = useState({
    Sunday: null,
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
  });

  const [defaultHours, setDefaultHours] = useState(true);

  useEffect(() => {
    const fetchLocationHours = async () => {
      if (open && defaultHours === true) {
        // Check if the modal is open
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const accessToken = await getAccessTokenSilently();

          const response = await fetch(
            `${apiUrl}/api/view-location-hours/${editLocationData.location_id}/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          const locationData = await response.json();

          if (response.ok) {
            const updatedEditedHours = { ...businessHours };

            Object.keys(businessHours).forEach((dayOfWeek) => {
              const receivedData = locationData[dayOfWeek];
              if (receivedData) {
                const start_time = receivedData.start_time || null;
                const end_time = receivedData.end_time || null;

                updatedEditedHours[dayOfWeek] = {
                  start_time: start_time,
                  end_time: end_time,
                  is_closed: receivedData.is_closed || false,
                };

                const startTime = dayjs(`2022-04-17T${start_time}`);
                const endTime = dayjs(`2022-04-17T${end_time}`);

                setStartTimeValues((prev) => ({
                  ...prev,
                  [dayOfWeek]: startTime,
                }));
                setEndTimeValues((prev) => ({
                  ...prev,
                  [dayOfWeek]: endTime,
                }));
              }
            });

            setBusinessHours(updatedEditedHours);
            setDefaultHours(false);
          }
        } catch (error) {
          console.error("Error fetching user saved times:", error);
        }
      }
    };

    fetchLocationHours();
  }, [open, defaultHours]); // Trigger when the modal is opened

  const handleResetHours = () => {
    setDefaultHours(true);
    setDefaultHoursChanged(false);
    setHoursWarning(false);
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleConfirmHours = async () => {
    if (hoursWarning === true) {
      setHoursWarning(false);
    }
    setConfirmHours(true);
    setDefaultHoursChanged(true);
    setIsButtonDisabled(false);
  };

  const [focusedField, setFocusedField] = useState(null);

  const handleFocus = (dayOfWeek, field) => {
    setFocusedField({ dayOfWeek, field });
  };

  const handleBlur = () => {
    setFocusedField(null);
  };

  const handleTimeChange = (dayOfWeek, field, value) => {
    if (confirmHours) {
      setConfirmHours(false);
    }
    setDefaultHoursChanged(true);

    // Update state with the dayjs object for the picker or handle the closed switch
    if (field === "start_time") {
      setStartTimeValues((prev) => ({
        ...prev,
        [dayOfWeek]: value,
      }));
    } else if (field === "end_time") {
      setEndTimeValues((prev) => ({
        ...prev,
        [dayOfWeek]: value,
      }));
    } else if (field === "is_closed") {
      // Update the closed status directly in businessHours without formatting
      setBusinessHours((prev) => ({
        ...prev,
        [dayOfWeek]: {
          ...prev[dayOfWeek],
          is_closed: value,
        },
      }));
      return; // Skip formatting since `is_closed` is a boolean
    }

    // Format time if value is a dayjs object, otherwise use null
    const formattedTime = value && value.format ? value.format("HH:mm") : null;
    setBusinessHours((prev) => ({
      ...prev,
      [dayOfWeek]: {
        ...prev[dayOfWeek],
        [field]: formattedTime,
      },
    }));
  };

  //New Employee Hooks
  const handleTogglePinVisibility = () => {
    setShowPin(!showPin);
  };

  const handleConfirmNewPinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "").substr(0, 4);
    setConfirmNewPin(inputValue);
  };

  const toggleShowConfirmNewPin = () => {
    setShowConfirmNewPin((prev) => !prev);
  };

  useEffect(() => {
    // Check if any user's username matches newUserName
    const isUsernameTaken = users.some((user) => user.username === userName);

    // Set state based on whether the username is taken
    setUserNameTaken(isUsernameTaken);
  }, [userName, users]); // Trigger when newUserName or users change

  useEffect(() => {
    const isValidInput =
      pin.length >= 4 &&
      pin.length <= 4 &&
      /^\d+$/.test(pin) &&
      confirmNewPin.length === 4 &&
      /^\d+$/.test(confirmNewPin) &&
      pin === confirmNewPin &&
      userNameTaken === false &&
      userName.trim() !== "";

    setIsSaveUserButtonEnabled(isValidInput);
  }, [pin, confirmNewPin, firstName, lastName, newUserName, userNameTaken]);

  const handleEditUser = () => {
    setIsAssignEmployeeVisible(true);
    setHideCreateUserButton(true);
    setIsEditingUser(true);
  };

  /// Transfer List Logic
  const handleToggle = (value) => () => {
    // Prevent toggling if the user's location_id matches the current locationId
    if (value.location_id === locationId) {
      return;
    }

    if (!Array.isArray(checked)) {
      setChecked([]);
      return;
    }

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    // Map over selected users to add `isNewUser: false` to each user object
    const selectedUsers = checked
      .filter((user) => left.includes(user))
      .map((user) => ({ ...user, isNewUser: false }));

    // Move modified users to `right` and remove them from `left`
    setRight(right.concat(selectedUsers));
    setLeft(left.filter((user) => !checked.includes(user)));
    setConfirmUsers(false);

    setUsersMoved(true);

    setChecked([]);

    // Set the first user as `assignedUser` if any users are selected
    if (selectedUsers.length > 0) {
      setAssignedUser(selectedUsers[0]);
      setConfirmUsers(false);
    }
  };

  const handleCheckedLeft = () => {
    const selectedUsers = checked.filter(
      (user) => right.includes(user) && !user.isNewUser,
    );
    setLeft(left.concat(selectedUsers));
    setRight(right.filter((user) => !checked.includes(user) || user.isNewUser));

    setChecked([]);

    setUsersMoved(true);
    setConfirmUsers(false);

    if (selectedUsers.some((user) => user === assignedUser)) {
      setAssignedUser(null);
    }
  };

  // Save new user and add to the right list
  const handleSaveNewUserClick = async () => {
    const newUser = {
      first_name: firstName,
      last_name: lastName,
      user_name: userName, // Use this as the unique identifier if user_id is not present
      pin,
      isNewUser: true,
    };

    setRight((prevRight) => {
      const existingUserIndex = prevRight.findIndex(
        (user) => user.user_name === newUser.user_name,
      );

      if (enableEditButtons && existingUserIndex !== -1) {
        // If editing and user exists, update the existing user
        const updatedRight = [...prevRight];
        updatedRight[existingUserIndex] = newUser;
        setConfirmUsers(false);
        return updatedRight;
      } else if (existingUserIndex === -1) {
        setNewUserCreated(true);
        setConfirmUsers(false);
        return [...prevRight, newUser];
      }
      return prevRight;
    });

    // Reset states and hide form
    setIsNewUserSaved(true);
    setIsAssignEmployeeVisible(false);
    setHideCreateUserButton(true);
    setChecked([]);
  };

  // Clear new user data (delete button)
  const handleClearNewUserClick = () => {
    setNewUserId(null);
    setFirstName("");
    setLastName("");
    setUserName("");
    setPin("");
    setConfirmNewPin("");
    setNewUserName("");
    setHideCreateUserButton(false);
    setIsNewUserSaved(false);
    setShowConfirmNewPin(false);
    setConfirmNewPin("");
    setIsAssignEmployeeVisible(false);
    setEnableEditButtons(false);
    setConfirmUsers(false);
    setLeft(availableUsers);
    setRight((prevRight) => prevRight.filter((user) => !user.isNewUser));
    setChecked([]);
  };

  useEffect(() => {
    right.forEach((user) => {
      setNewUserCreated(true);
    });
  }, [right]);

  useEffect(() => {
    const hasNewUser = Object.values(checked).some(
      (user) => user.isNewUser === true,
    );

    if (hasNewUser && checked.length === 1) {
      setEnableEditButtons(true);
    } else {
      setEnableEditButtons(false);
    }
  }, [checked]);

  const handleUpdateChecklist = async () => {
    const userId = user.sub;
    if (accountData.onboarding_complete === false) {
      await dismissItem(item, getAccessTokenSilently, userId);
      await fetchOnboardingRecord();
      return;
    } else {
      return;
    }
  };

  const handleCancelEdit = () => {
    setIsAssignEmployeeVisible(false);
    setHideCreateUserButton(false);
  };

  const handleCancelAddUser = () => {
    if (enableEditButtons) {
      setIsAssignEmployeeVisible(false);
      setHideCreateUserButton(false);
      setChecked([]);
    } else {
      setFirstName("");
      setLastName("");
      setPin("");
      setUserName("");
      setNewUserName("");
      setIsAssignEmployeeVisible(false);
      setHideCreateUserButton(false);
      setChecked([]);
    }
  };

  const handleConfirmUsers = () => {
    setConfirmUsers(true);
    setIsButtonDisabled(false);
    if (usersWarning === true) {
      setUsersWarning(false);
      setIsButtonDisabled(false);
    }
  };

  const [defaultLeft, setDefaultLeft] = useState([]);
  const [defaultRight, setDefaultRight] = useState([]);

  const handleResetEmployees = () => {
    setRight(defaultRight);
    setLeft(defaultLeft);
    setNewUserId(null);
    setFirstName("");
    setLastName("");
    setPin("");
    setUserName("");
    setNewUserName("");
    setHideCreateUserButton(false);
    setIsAssignEmployeeVisible(false);
    setAssignedUser(null);
    setAssignedLocation(null);
    setConfirmNewPin("");
    setShowConfirmNewPin(false);
    setIsNewUserSaved(false);
    setConfirmUsers(false);
    setChecked([]);
    setUsersMoved(false);
    setUsersWarning(false);
  };

  const clearData = () => {
    setName(editLocationData?.name);
    setNewLocationName(editLocationData?.name);
    setEmail(editLocationData?.email);
    setPhone(editLocationData?.phone);
    setNameTouched(false);
    setEmailTouched(false);
    setPhoneTouched(false);
    setNewUserId(null);
    setFirstName("");
    setLastName("");
    setPin("");
    setUserName("");
    setNewUserName("");
    setHideCreateUserButton(false);
    setIsAssignEmployeeVisible(false);
    setAssignedUser(null);
    setAssignedLocation(null);
    setConfirmNewPin("");
    setShowConfirmNewPin(false);
    setIsNewUserSaved(false);
    setSelectedTab(0);
    setChecked([]);
    setNewUserCreated(false);
    setHoursWarning(false);
    setUsersWarning(false);
    setConfirmHours(true);
    setDefaultHoursChanged(false);
    setUsersMoved(false);
    setConfirmUsers(true);
    setIsButtonDisabled(true);
    setDefaultHours(true);
    setHideWarning(false);
  };

  useEffect(() => {
    if (open) {
      clearData();
    }
  }, [open]);

  const handleEditLocationClick = async () => {
    // Trigger warnings if applicable
    if (defaultHoursChanged && !confirmHours) {
      setHoursWarning(true);
      return;
    }
    if (!confirmUsers) {
      setUsersWarning(true);
      return;
    } else {
      handleEditLocationSubmit();
    }
  };

  const handleEditLocationSubmit = async () => {
    if (openConfirmDialog) {
      setOpenConfirmDialog(false);
    }

    try {
      setIsLoading(true); // Start the loading state

      // Call the function to submit updated location details
      const result = await EditLocationSubmit(
        getAccessTokenSilently,
        name,
        newLocationName,
        email,
        phone,
        businessHours,
        userId,
        locationId,
        right,
      );

      // Fetch updated data after a successful submission
      fetchLocations();
      fetchUsers();
      await fetchLatestUserData();
      await fetchAllWidgetTypes();
      handleUpdateChecklist();

      // Close dialog and show a success message
      onClose();
      showSnackBar("Location Updated", true);
    } catch (error) {
      console.error("Failed to create location:", error);

      // Check if error message matches 'Location name is already taken.'
      if (error.message === "Location name is already taken.") {
        setOpenConfirmDialog(false);
        setOpenReviewDialog(false);
        showSnackBar("Location name is already in use", false);
        setLocationNameTaken(true);
      } else {
        showSnackBar("Failed to create location. Please try again.", false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const [usersByLocation, setUsersByLocation] = useState({
    matching: [],
    nonMatching: [],
  });

  useEffect(() => {
    if (Array.isArray(users) && open) {
      // Parse locationId as a number to match against user.location_id
      const locationIdNum = Number(locationId);

      const matchingUsers = users.filter(
        (user) => Number(user.location_id) === locationIdNum,
      );

      const nonMatchingUsers = users.filter(
        (user) => Number(user.location_id) !== locationIdNum,
      );

      setUsersByLocation({
        matching: matchingUsers,
        nonMatching: nonMatchingUsers,
      });
      setLeft(nonMatchingUsers);
      setRight(matchingUsers);
      setDefaultLeft(nonMatchingUsers);
      setDefaultRight(matchingUsers);
    }
  }, [users, locationId, open]);

  const handleUpdateHoursSubmit = async () => {
    try {
      setIsLoading(true); // Set loading state before making request

      const result = await EditHoursSubmit(
        getAccessTokenSilently,
        userId,
        businessHours,
        locationId,
      );

      // Handle successful response, e.g., redirect or display success message
      fetchLocations();
      fetchUsers();
      showSnackBar("Hours Updated", true);
      setConfirmHours(true);
      setDefaultHoursChanged(false);
      setHoursWarning(false);
    } catch (error) {
      // Handle error response
      console.error("Failed to update location:", error);
    } finally {
      setIsLoading(false); // Ensure loading state is reset
    }
  };

  const handleUpdateUsersSubmit = async () => {
    try {
      setIsLoading(true); // Set loading state before making request

      const result = await EditUsersSubmit(
        getAccessTokenSilently,
        userId,
        locationId,
        right,
      );

      // Handle successful response, e.g., redirect or display success message
      fetchLocations();
      fetchUsers();
      showSnackBar("Active Employees Updated", true);
      setConfirmUsers(true);
      setUsersMoved(false);
      setUsersWarning(false);
    } catch (error) {
      // Handle error response
      console.error("Failed to update location:", error);
    } finally {
      setIsLoading(false); // Ensure loading state is reset
    }
  };

  useEffect(() => {
    // Extract usernames from both arrays
    const defaultLeftUsernames = defaultLeft.map((user) => user.username);
    const leftUsernames = left.map((user) => user.username);

    // Check if both arrays of usernames are the same (sorted order matters here)
    const areUsernamesEqual =
      defaultLeftUsernames.length === leftUsernames.length &&
      defaultLeftUsernames.every(
        (username, index) => username === leftUsernames[index],
      );

    if (areUsernamesEqual) {
      setUsersMoved(false);
      setConfirmUsers(true);
      setUsersWarning(false);
    }
  }, [left, defaultLeft, right, defaultRight]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "13px", width: "550px" } }}
    >
      <Box
        sx={{
          padding: "10px",
          backgroundColor: "#FFFFFF",
          minHeight: "20vh",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleOpenCancelCloseDialog}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>
        <Box sx={{ px: "5px" }}>
          <Stack
            direction="column"
            sx={{
              display: "flex",
              width: "100%",
              backgroundColor: "#FFFFFF",

              color: "#374247",
              mt: 1,
            }}
          >
            <img
              src={addLocationIcon}
              alt="addLocationIcon"
              style={{
                border: ".5px solid #B0BEC5",
                width: "28px",
                height: "28px",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                padding: "1px",
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                borderRadius: "6px",
                mb: "15px",
              }}
            />
            <Typography
              sx={{
                color: "#48768C",
                fontWeight: 600,
                lineHeight: "-2%",
                letterSpacing: "-.2px",
                fontSize: "18.5px",
                mb: "15px",
                mt: "5px",
              }}
            >
              Edit Location{" "}
            </Typography>
          </Stack>
        </Box>

        <DialogContent sx={{ padding: 0, backgroundColor: "#FFFFFF" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              ml: 0.65,
              minHeight: "15px",
              maxHeight: "25px",
              borderRadius: "8px 8px 0 0",
              backgroundColor: "transparent",
              ".MuiTabs-indicator": { backgroundColor: "transparent" },
            }}
          >
            <Tab
              label="Contact Info"
              sx={{
                color: "#48768C",
                fontWeight: 500,
                textTransform: "none",
                borderTop: "1px solid #ADD8E6",
                borderLeft: "1px solid #ADD8E6",
                borderRight: "1px solid #ADD8E6",
                borderRadius: "8px 8px 0 0",
                backgroundColor: "#F2F9FF",
                mb: "-1px",
                fontSize: 14.36,
                minHeight: "25px",
                padding: "4px 32px",
                lineHeight: "-2%",
                pb: 1.25,
                mr: 0.35,
                "&.Mui-selected": {
                  color: "#48768C",
                  fontWeight: 600,
                  backgroundColor: "#E0F3FF", // Slightly different background when selected
                  paddingTop: "6px", // Increase top padding to simulate height increase
                  paddingBottom: "8px", // Increase bottom padding as well
                },
              }}
            />
            <Tab
              label="Location Hours"
              sx={{
                color: "#48768C",
                fontWeight: 500,
                textTransform: "none",
                borderTop: hoursWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderLeft: hoursWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderRight: hoursWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderRadius: "8px 8px 0 0",
                backgroundColor: "#F2F9FF",
                mb: "-1px",
                fontSize: 14.36,
                minHeight: "25px",
                padding: "4px 12px",
                pb: 1.45,
                mr: 0.35,
                "&.Mui-selected": {
                  color: "#48768C",
                  fontWeight: 600,
                  backgroundColor: "#E0F3FF",
                  paddingTop: "6px",
                  paddingBottom: "8px",
                  borderBottom: hoursWarning
                    ? "2px solid #8C4350"
                    : "2px solid #ADD8E6", // Optional: Add a bottom border to emphasize the active state
                  borderTop: hoursWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                  borderLeft: hoursWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                  borderRight: hoursWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                },
              }}
            />
            <Tab
              label="Manage Employees"
              sx={{
                color: "#48768C",
                fontWeight: 500,
                textTransform: "none",
                borderTop: usersWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderLeft: usersWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderRight: usersWarning
                  ? "1.5px solid #8C4350"
                  : "1px solid #ADD8E6",
                borderRadius: "8px 8px 0 0",
                backgroundColor: "#F2F9FF",
                mb: "-1px",
                fontSize: 14.36,
                minHeight: "25px",
                padding: "4px 12px",
                pb: 1.45,
                mr: 0.35,
                "&.Mui-selected": {
                  color: "#48768C",
                  fontWeight: 600,
                  backgroundColor: "#E0F3FF",
                  paddingTop: "6px",
                  paddingBottom: "8px",
                  borderBottom: usersWarning
                    ? "2px solid #8C4350"
                    : "2px solid #ADD8E6", // Optional: Add a bottom border to emphasize the active state
                  borderTop: usersWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                  borderLeft: usersWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                  borderRight: usersWarning
                    ? "1.5px solid #8C4350"
                    : "1px solid #ADD8E6",
                },
              }}
            />
          </Tabs>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 0,
              backgroundColor: "#F2F9FF",
              border: "1px solid rgba(173, 216, 230, 0.7)",
              overflowY: "auto",
              px: "10px",
              borderRadius: "8px",
              mb: 2,
            }}
          >
            {/* Tabs at the top of the Dialog */}

            <Box sx={{ mt: 1.5, width: "100%", minHeight: "20vh" }}>
              {/* Tab Panel for Location Info */}
              <Fade in={selectedTab === 0} timeout={600}>
                <Box sx={{ display: selectedTab === 0 ? "block" : "none" }}>
                  <>
                    <Typography
                      sx={{
                        color: "#48768C",
                        fontWeight: 600,
                        fontSize: 16.36,
                        mb: "15px",
                        mt: "2px",
                        letterSpacing: -0.1,
                        lineHeight: -2,
                      }}
                    >
                      Location Info
                    </Typography>
                    <LocationInfoCard
                      name={name}
                      newLocationName={newLocationName}
                      handleNameBlur={handleNameBlur}
                      email={email}
                      phone={phone}
                      handleNameChange={handleNameChange}
                      handleEmailChange={handleEmailChange}
                      handlePhoneChange={handlePhoneChange}
                      nameTouched={nameTouched}
                      emailTouched={emailTouched}
                      phoneTouched={phoneTouched}
                      isFormValid={isFormValid}
                      setIsFormValid={setIsFormValid}
                      validateEmail={validateEmail}
                      handleEmailBlur={handleEmailBlur}
                      handlePhoneBlur={handlePhoneBlur}
                      validatePhoneNumber={validatePhoneNumber}
                      handleOpenCancelCloseDialog={handleOpenCancelCloseDialog}
                      isCancelCloseOpen={isCancelCloseOpen}
                      handleCloseCancelCloseDialog={
                        handleCloseCancelCloseDialog
                      }
                      handleEditLocationSubmit={handleEditLocationSubmit}
                      locationNameTaken={locationNameTaken}
                      isLoading={isLoading}
                      editLocationData={editLocationData}
                      handleEditLocationClick={handleEditLocationClick}
                      confirmHours={confirmHours}
                      defaultHoursChanged={defaultHoursChanged}
                      confirmUsers={confirmUsers}
                      usersMoved={usersMoved}
                      isButtonDisabled={isButtonDisabled}
                      locationInfoChanged={locationInfoChanged}
                      validateName={validateName}
                      hideWarning={hideWarning}
                    />
                  </>
                </Box>
              </Fade>

              <Fade in={selectedTab === 1} timeout={600}>
                <Box sx={{ display: selectedTab === 1 ? "block" : "none" }}>
                  <>
                    <Typography
                      sx={{
                        color: "#48768C",
                        fontWeight: 600,
                        fontSize: 16.36,
                        mb: "20px",
                        mt: "5px",
                      }}
                    >
                      {`${editLocationData?.name}'s` + " Hours of Operation"}
                    </Typography>
                    <LocationHoursCard
                      handleUpdateChecklist={handleUpdateChecklist}
                      handleOpenReviewDialog={handleOpenReviewDialog}
                      hoursItem={hoursItem}
                      setHoursItem={setHoursItem}
                      businessHours={businessHours}
                      setBusinessHours={setBusinessHours}
                      startTimeValues={startTimeValues}
                      setStartTimeValues={setStartTimeValues}
                      endTimeValues={endTimeValues}
                      setEndTimeValues={setEndTimeValues}
                      handleTimeChange={handleTimeChange}
                      isSaveHoursButtonDisabled={isSaveHoursButtonDisabled}
                      setIsSaveHoursButtonDisabled={
                        setIsSaveHoursButtonDisabled
                      }
                      editLocationData={editLocationData}
                      handleBlur={handleBlur}
                      handleFocus={handleFocus}
                    />
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ mt: 5, mb: 2, width: "60%", ml: "auto" }}
                    >
                      <Button
                        onClick={handleResetHours}
                        disabled={!defaultHoursChanged}
                        sx={{
                          border: "1px solid #8C435029",
                          borderRadius: "6px",
                          color: "#8C4350",
                          backgroundColor: "#8C435021",
                          padding: "1px 0", // Same padding as the first button
                          fontSize: 13.76, // Matching font size for consistency
                          width: "415px",
                          height: "35px", // Fixed height to match the first button
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          "&:hover": {
                            backgroundColor: "#8C435017",
                          },
                          "&:active": {
                            backgroundColor: "#A4C5D230",
                          },
                        }}
                      >
                        Reset Changes
                      </Button>
                      <Button
                        onClick={handleUpdateHoursSubmit}
                        disabled={
                          confirmHours ||
                          !defaultHoursChanged ||
                          isSaveHoursButtonDisabled ||
                          isLoading
                        }
                        sx={{
                          whiteSpace: "pre-wrap",
                          border: "1px solid #A4C5D2E8",
                          borderRadius: "6px",
                          padding: "1px 0", // Consistent padding
                          color: "#48768C",
                          backgroundColor: "#FFFFFF",
                          fontSize: 13.76,
                          width: "490px",
                          height: "35px", // Fixed height to match the first button
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          "&:hover": {
                            backgroundColor: "#DBEAF0",
                          },
                          "&:active": {
                            backgroundColor: "#DBEAF0",
                          },
                        }}
                      >
                        {isLoading ? "Updating Hours" : "Update Hours"}
                      </Button>
                    </Stack>
                  </>
                </Box>
              </Fade>

              <Fade in={selectedTab === 2} timeout={600}>
                <Box sx={{ display: selectedTab === 2 ? "block" : "none" }}>
                  {" "}
                  <>
                    <EmployeeSetupCard
                      users={users}
                      subUserData={subUserData}
                      name={name}
                      handleToggle={handleToggle}
                      checked={checked}
                      left={left}
                      right={right}
                      handleCheckedRight={handleCheckedRight}
                      handleCheckedLeft={handleCheckedLeft}
                      setLeft={setLeft}
                      userNameTaken={userNameTaken}
                      hideCreateUserButton={hideCreateUserButton}
                      isAssignEmployeeVisible={isAssignEmployeeVisible}
                      handleSaveNewUserClick={handleSaveNewUserClick}
                      firstName={firstName}
                      setFirstName={setFirstName}
                      lastName={lastName}
                      setLastName={setLastName}
                      pin={pin}
                      setPin={setPin}
                      showPin={showPin}
                      handleTogglePinVisibility={handleTogglePinVisibility}
                      confirmNewPin={confirmNewPin}
                      handleConfirmNewPinChange={handleConfirmNewPinChange}
                      showConfirmNewPin={showConfirmNewPin}
                      toggleShowConfirmNewPin={toggleShowConfirmNewPin}
                      isSaveUserButtonEnabled={isSaveUserButtonEnabled}
                      assignedUser={assignedUser}
                      assignedLocation={assignedLocation}
                      handleUpdateChecklist={handleUpdateChecklist}
                      setHideCreateUserButton={setHideCreateUserButton}
                      setUserName={setUserName}
                      newUserId={newUserId}
                      newUserCreated={newUserCreated}
                      enableEditButtons={enableEditButtons}
                      isNewUserSaved={isNewUserSaved}
                      isAdmin={isAdmin}
                      setIsAdmin={setIsAdmin}
                      handleCancelAddUser={handleCancelAddUser}
                      userName={userName}
                      handleClearNewUserClick={handleClearNewUserClick}
                      handleConfirmUsers={handleConfirmUsers}
                      confirmUsers={confirmUsers}
                      isEditingUser={isEditingUser}
                      handleCancelEdit={handleCancelEdit}
                      locationId={locationId}
                      setRight={setRight}
                    />
                    {!isAssignEmployeeVisible &&
                      isNewUserSaved &&
                      enableEditButtons && (
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ mt: 2, mb: 2, width: "60%", ml: "auto" }}
                        >
                          <Button
                            onClick={handleClearNewUserClick}
                            disabled={!enableEditButtons}
                            sx={{
                              border: "1px solid #8C435029",
                              borderRadius: "6px",
                              color: "#8C4350",
                              backgroundColor: "#8C435021",
                              padding: "1px 0", // Same padding as the first button
                              fontSize: 13.76, // Matching font size for consistency
                              width: "415px",
                              height: "35px", // Fixed height to match the first button
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              "&:hover": {
                                backgroundColor: "#8C435017",
                              },
                              "&:active": {
                                backgroundColor: "#A4C5D230",
                              },
                            }}
                          >
                            Delete
                          </Button>
                          <Button
                            onClick={handleEditUser}
                            disabled={!enableEditButtons}
                            sx={{
                              whiteSpace: "pre-wrap",
                              border: "1px solid #A4C5D2E8",
                              borderRadius: "6px",
                              padding: "1px 0", // Consistent padding
                              color: "#48768C",
                              backgroundColor: "#FFFFFF",
                              fontSize: 13.76,
                              width: "490px",
                              height: "35px", // Fixed height to match the first button
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              "&:hover": {
                                backgroundColor: "#DBEAF0",
                              },
                              "&:active": {
                                backgroundColor: "#DBEAF0",
                              },
                            }}
                          >
                            Edit
                          </Button>
                        </Stack>
                      )}
                    {!isAssignEmployeeVisible && !enableEditButtons && (
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{ mt: 2, mb: 2, width: "60%", ml: "auto" }}
                      >
                        <Button
                          onClick={handleResetEmployees}
                          disabled={!usersMoved}
                          sx={{
                            border: "1px solid #8C435029",
                            borderRadius: "6px",
                            color: "#8C4350",
                            backgroundColor: "#8C435021",
                            padding: "1px 0", // Same padding as the first button
                            fontSize: 13.76, // Matching font size for consistency
                            width: "415px",
                            height: "35px", // Fixed height to match the first button
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            "&:hover": {
                              backgroundColor: "#8C435017",
                            },
                            "&:active": {
                              backgroundColor: "#A4C5D230",
                            },
                          }}
                        >
                          Reset
                        </Button>
                        <Button
                          onClick={handleUpdateUsersSubmit}
                          disabled={!usersMoved || confirmUsers || isLoading}
                          sx={{
                            whiteSpace: "pre-wrap",
                            border: "1px solid #A4C5D2E8",
                            borderRadius: "6px",
                            padding: "1px 0", // Consistent padding
                            color: "#48768C",
                            backgroundColor: "#FFFFFF",
                            fontSize: 13.76,
                            width: "490px",
                            height: "35px", // Fixed height to match the first button
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            "&:hover": {
                              backgroundColor: "#DBEAF0",
                            },
                            "&:active": {
                              backgroundColor: "#DBEAF0",
                            },
                          }}
                        >
                          {isLoading
                            ? "Updating Employees"
                            : "Update Employees"}
                        </Button>
                      </Stack>
                    )}
                  </>
                </Box>
              </Fade>
            </Box>
          </Box>
        </DialogContent>

        {openReviewDialog && (
          <Portal>
            <ReviewDialog
              open={openReviewDialog}
              onClose={handleCloseReviewDialog}
              addedUserPrice="+ $10/month"
              addedLocation={true}
              buttonText="Confirm & Add Location"
              isLoading={isLoading}
              buttonLoadingText="Adding Location..."
              addedEmployees={false}
              name={name}
              email={email}
              phone={phone}
              buttonAction=""
              handleCancel={onClose}
              CancelCloseMessage="Are you sure you want to close?"
              CancelCloseSubText="Your changes will not be saved."
            />
          </Portal>
        )}
      </Box>
      <CancelCloseDialog
        open={isCancelCloseOpen}
        onClose={handleCloseCancelCloseDialog}
        buttonAction={handleCancelClose}
        onCloseConfirm={onClose}
        CancelCloseMessage="Are you sure you want to close?"
        CancelCloseSubText="Your changes will not be saved."
      />
    </Dialog>
  );
};

export default EditLocationModal;
