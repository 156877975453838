import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  Card,
  Typography,
  Modal,
  Grid,
  Paper,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Popover,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditHoursModal from "../../../modal/EditHoursModal";
import SingleUserEditHoursModal from "../../../modal/SingleUserEditHoursModal";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useAuth } from "../../../../context/AuthContext";
import LogoUpload from "../../../LogoUpload/LogoUpload";
import { useAccountContext } from "../../../../context/AccountContext"; // Import AppProvider from AccountContext
import EditOutlinedIcon from "@mui/icons-material/Edit";
import EditIcon from "src/public/assets/icons/icons/help-center/edit.png";
import EditProfile from "../../../modal/EditProfile";
import EditSingleUserLocation from "../../../modal/EditSingleUserLocation";
import EditMultiUserLocation from "../../../modal/EditMultiUserLocation";
import { useUserLogo } from "../../../../context/LogoContext";
import secureLocalStorage from "react-secure-storage";
import CloseIcon from "@mui/icons-material/Close";
import hoursIcon from "src/public/assets/icons/icons/help-center/hours.png";
import PopoverButton from "../PopoverButton.js"; // Adjust the import path as needed
import { dismissItem } from "src/components/NewUserChecklist/dismissItem";
import { set } from "lodash";
import { useAppContext } from "../../../../context/AppContext";
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext";
import { useSingleUser } from "src/context/SingleUserContext";
import { handleProfileSave, handleLocationSave } from "./hooks/SingleUserData";
import {
  handleMultiUserSave,
  fetchMultiUserActiveLocation,
} from "./hooks/MultiUserData";
import EditCompanyDialog from "src/components/modal/EditCompanyDialog";
import { handleUpdateContactInfo } from "./hooks/CompanyData";

function Profile({
  accountData,
  onboardingData,
  fetchOnboardingRecord,
  fetchAccountData,
  setAccountData,
  ProfileImage,
}) {
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [isMultiUserEditingLocation, setIsMultiUserEditingLocation] =
    useState(false);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { username, subUserData, setSubUserData } = useAuth();
  const [userData, setUserData] = useState({});
  const [editedHours, setEditedHours] = useState({}); // Initialize with an empty object
  const [businessHours, setBusinessHours] = useState({});
  const [isEditingHours, setIsEditingHours] = useState(false);
  const [isSingleUserEditingHours, setIsSingleUserEditingHours] =
    useState(false);

  const [userMetadata, setUserMetadata] = useState({});

  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const { userLogoUrl, uploadLogo, deleteLogo } = useUserLogo();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [activeLocation, setActiveLocation] = useState(null);
  const [formChanged, setFormChanged] = useState(false);

  const fileInputRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);
  const [shouldRenderButton, setShouldRenderButton] = useState(true);
  const [item, setItem] = useState("addLogo");
  const [editData, setEditData] = useState({
    first_name: "",
    last_name: "",
    title: "", // Role
    phone: "",
    email: "",
    location_id: "",
    name: "",
    company_name: "",
    company_url: "",
    company_email: "",
    company_city: "",
    company_state: "",
    company_email: "",
  });
  const [loading, setLoading] = useState(false);
  const { fetchLatestSingleUserData } = useSingleUser(); // Import the fetchLatestSingleUserData function
  const [editCompanyOpen, setEditCompanyOpen] = useState(false);

  const handleEditCompanyOpen = () => {
    setEditData((prevState) => ({
      ...prevState,
      company_name: accountData.company_name || "",
      company_url: accountData.company_website || "",
      company_city: accountData.company_city || "",
      company_state: accountData.company_state || "",
      company_email: accountData.company_email || "",
    }));
    if (editData) {
      setEditCompanyOpen(true);
    }
  };

  const handleEditCompanyClose = () => {
    setEditCompanyOpen(false);
  };

  const handleChangeTitle = useCallback(
    (e) => {
      const selectedTitle = e.target.value;
      setEditData((prevData) => ({
        ...prevData,
        title: selectedTitle, // Update title directly in editData
      }));
    },
    [], // No dependencies, function is stable
  );

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setEditData((prevEditData) => {
      if (prevEditData.first_name === value) return prevEditData;
      return { ...prevEditData, first_name: value };
    });
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setEditData((prevEditData) => {
      if (prevEditData.last_name === value) return prevEditData;
      return { ...prevEditData, last_name: value };
    });
  };

  // Example condition for conditional rendering
  const handleToggleButton = () => {
    setShouldRenderButton(!shouldRenderButton);
  };

  const handleFileChange = async (event) => {
    if (!subUserData.is_admin) {
      setDialogOpen(true);
      return;
    }
    const file = event.target.files[0];
    if (file) {
      try {
        await uploadLogo(file); // Wait for upload to complete
        handleUpdateChecklist();
        setIsHovered(false); // Set hover to false after upload completes
      } catch (error) {
        console.error("Error uploading the file:", error);
      }
    }
  };

  const handleUpdateChecklist = async () => {
    const userId = user.sub;
    if (accountData.onboarding_complete === false) {
      await dismissItem(item, getAccessTokenSilently, userId);
      await fetchOnboardingRecord();
      setItem("");
      return;
    } else {
      return;
    }
  };

  const handleUploadButtonClick = () => {
    if (!subUserData.is_admin) {
      setDialogOpen(true);
    } else {
      fileInputRef.current?.click();
    }
  };

  const handleDelete = async () => {
    if (userLogoUrl) {
      try {
        await deleteLogo(userLogoUrl);
        setIsHovered(false);
      } catch (error) {
        console.error("Error deleting logo:", error);
      }
    } else {
      console.error("userLogoUrl is missing");
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleEditProfileOpen = async () => {
    setEditData((prevState) => ({
      ...prevState,
      first_name: subUserData.first_name || "",
      last_name: subUserData.last_name || "",
      title: subUserData.title || "",
    }));
    if (editData) {
      setIsEditProfileOpen(true);
    }
  };

  const handleEditProfileClose = () => {
    setIsEditProfileOpen(false);
  };

  const handleEditLocationOpen = () => {
    // Determine the email dynamically based on accountData
    const email = accountData?.single_user
      ? accountData.company_email
      : subUserData.location_email;

    // Update the state with the determined email and other values
    setEditData((prevState) => ({
      ...prevState,
      name: subUserData.assigned_location || "",
      phone: subUserData.location_phone || "",
      email: email, // Use the dynamically determined email
      location_id: subUserData.location_id || "",
    }));

    setIsEditingLocation(true);
  };

  const handleEditLocationClose = () => {
    setIsEditingLocation(false);
  };

  const handleMultiEditLocationOpen = async () => {
    setEditData((prevState) => ({
      ...prevState,
      phone: subUserData.location_phone || "",
      email: subUserData.email || "",
      location_id: subUserData.active_location || "",
    }));
    if (editData) {
      setIsMultiUserEditingLocation(true);
    }
  };

  const handleMultiEditLocationClose = () => {
    setIsMultiUserEditingLocation(false);
  };

  const isSingleUser = accountData?.single_user; // Check if single_user is true in accountData
  const isSingleLocation = accountData?.single_location; // Check if single_user is true in accountData

  ////TO DO - Check if single user / single location is true and fetch approperate content

  const AvatarModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const AvatarContainer = styled(Grid)({
    width: "25vw",
    height: "25vh",
    backgroundColor: "white",
    borderRadius: "8px",
    overflowY: "auto",
    padding: "26px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap", // Allow items to wrap to the next line
  });

  const AvatarHeading = styled(Typography)({
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "16px",
    textAlign: "center",
  });

  const FixedHeader = styled("div")({
    position: "fixed",
    top: "0",
    width: "100%",
    backgroundColor: "white",
    padding: "20px",
    zIndex: "1000",
    textAlign: "center",
  });

  const AvatarPaper = styled(Paper)({
    cursor: "pointer",
    borderRadius: "50%",
    overflow: "hidden",
    margin: "4px", // Adjust the margin as needed
    "&:hover": {
      border: "2px solid #1976D2",
    },
  });

  const AvatarImg = styled("img")({
    width: "100%", // Use 100% to fill the container
    height: "100%", // Use 100% to fill the container
    borderRadius: "50%", // Ensure the image is inside a circular container
    objectFit: "cover", // Use 'cover' to maintain aspect ratio and fill the container
    display: "block", // Ensures that the image does not have extra spacing
  });

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "Phone Number Not Found";
  };

  useEffect(() => {
    const storedUserData = JSON.parse(sessionStorage.getItem("accountData"));
    if (storedUserData) {
      setUserData(storedUserData);
      const locationData = storedUserData.locationData;
      if (locationData) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          location_phone: locationData.phone || "",
        }));
      }
    } else {
      console.error("User data not found in session storage");
    }
  }, [accountData]);

  // Access userData and subUserData wherever you need in your component

  const handleHoursChange = (editedHours) => {
    // Update state or perform actions with editedHours
    // For example, you can update the initialHours state with the edited hours.
    setIsEditingHours(false); // Close the modal after saving the hours
  };

  const updateBusinessHours = () => {
    setIsEditingHours(false);
  };

  const ProfileHeader = styled(Typography)({
    fontWeight: "bold",
    fontSize: "1.2rem",
    marginBottom: "1rem",
    textAlign: "left",
  });

  const ProfileImageWrapper = styled("div")(({ theme }) => ({
    position: "relative",
    marginRight: "15px",
    marginBottom: "5px",
    width: "55px",
    height: "auto",
    borderRadius: "50%",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#48768C", // Adjust gradient colors
      opacity: 0.15, // Adjust overlay opacity
    },
  }));

  const BizLogo = styled("img")({
    width: "100px",
    height: "100px",
    marginRight: "20px",
  });

  const UserCard = styled(Card)({
    paddingTop: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: ".5rem",

    width: "100%",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(191, 218, 231, 0.05)",
    border: "1px solid rgba(173, 216, 230, 0.7)",
  });

  const SingleUserCard = styled(Card)({
    padding: "1rem",
    width: "100%",
    marginBottom: "1rem",
    height: "22.5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(191, 218, 231, 0.05)",
    border: "1px solid rgba(173, 216, 230, 0.7)",
  });

  const InfoCard = styled(Card)({
    padding: "1rem",
    width: "100%",
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    backgroundColor: "rgba(191, 218, 231, 0.05)",
    border: "1px solid rgba(173, 216, 230, 0.7)",
  });

  const SingleUserInfoCard = styled(Card)({
    padding: "1rem",
    width: "100%",
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    backgroundColor: "rgba(191, 218, 231, 0.05)",
    border: "1px solid rgba(173, 216, 230, 0.7)",
  });

  const BizCard = styled(Card)({
    padding: "1rem",
    width: "100%",
    marginTop: "1rem",
    display: "flex",
    height: "22.5%",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    backgroundColor: "rgba(191, 218, 231, 0.05)",
    border: "1px solid rgba(173, 216, 230, 0.7)",
  });

  const EditButton = styled(Button)({
    background: "transparent",
    borderRadius: 50,
    border: "3px solid #ccc",
    color: "#ccc",
    height: 36,
    padding: "0 9px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      borderColor: "#bbb",
      color: "#bbb",
    },
  });

  const Header = styled(Typography)({
    width: "100%",
    textAlign: "left",
    letterSpacing: "-.02em",
  });

  const handleCloseEditHours = () => {
    if (accountData?.single_user) {
      setIsSingleUserEditingHours(false);
      handleReloadComponents();
    } else {
      setIsEditingHours(false);
      handleReloadComponents();
    }
  };

  const handleSaveProfile = async () => {
    setLoading(true);
    try {
      const updatedUserData = await handleProfileSave(
        editData,
        getAccessTokenSilently,
        user.sub,
        subUserData.username,
      );

      setSubUserData(updatedUserData);
      await fetchLatestSingleUserData(); // Update the latest data
      showSnackBar("Profile updated!", true);
      handleEditProfileClose(); // Close the edit modal/dialog
    } catch (error) {
      console.error("Error saving profile:", error);
      showSnackBar(
        error.message || "Failed to update profile. Please try again.",
        false,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSaveLocation = async () => {
    setLoading(true);
    try {
      const updatedUserData = await handleLocationSave(
        editData,
        getAccessTokenSilently,
        subUserData.active_location || subUserData.location_id,
        user.sub,
      );

      // Update the state and UI after a successful save
      setSubUserData(updatedUserData);
      await fetchLatestSingleUserData();
      showSnackBar("Location updated successfully!", true);
      handleEditLocationClose(); // Close the modal/dialog
    } catch (error) {
      console.error("Error saving location:", error);
      showSnackBar(error.message || "Failed to update location", false);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveMultiUser = async () => {
    const activeLocation = subUserData.active_location;
    const userId = user.sub;
    setLoading(true);
    try {
      const updatedUserData = await handleMultiUserSave(
        editData,
        getAccessTokenSilently,
        setSubUserData,
        activeLocation,
        userId,
        username,
      );
      showSnackBar("Location updated successfully!", true);
      handleMultiEditLocationClose(); // Close the modal/dialog
    } catch (error) {
      console.error("Error saving location:", error);
      handleMultiEditLocationClose(); // Close the modal/dialog
      showSnackBar(error.message || "Failed to update location", false);
    } finally {
      setLoading(false);
    }
  };

  const [dataFetched, setDataFetched] = useState(false);

  const handleUpdateCompanyInfo = async () => {
    setLoading(true);
    const userId = user.sub;
    const customerId = accountData.customer_id;

    try {
      // Wait for the updated company info
      const updatedCompanyInfo = await handleUpdateContactInfo(
        editData,
        getAccessTokenSilently,
        userId,
        customerId,
        fetchAccountData,
      );

      // Check if the response is valid and contains expected data
      if (updatedCompanyInfo) {
        showSnackBar("Company Info Saved", true);
        setDataFetched(true);
        handleEditLocationClose(); // Close the modal/dialog
      } else {
        throw new Error("Unexpected response from the server");
      }
    } catch (error) {
      console.error("Error updating company info:", error);
      showSnackBar("Failed to update location", false);
    } finally {
      setLoading(false);
      setDataFetched(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Header
        sx={{
          color: "#48768C",
          fontWeight: 700,
          fontSize: 17.5,
        }}
      >
        Your Profile
      </Header>
      <Header sx={{ color: "#48768C", fontSize: ".9rem", mb: 1 }}>
        Your personal information, company details and location information
      </Header>

      <Box
        sx={{
          overflowY: "auto",
          maxHeight: "80vh",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <Box>
          {/*Multi Location & Multi User User Card*/}
          {!accountData?.single_user && !accountData?.single_location && (
            <UserCard sx={{ mt: 1 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <ProfileImageWrapper>
                    <img
                      src={ProfileImage}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  </ProfileImageWrapper>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    {subUserData &&
                      subUserData.first_name &&
                      subUserData.last_name && (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{
                            marginBottom: "-4px",
                            marginTop: "-8px",
                            fontWeight: 600,
                            lineHeight: "-2%",
                            letterSpacing: "-2%",
                          }}
                        >
                          {`${subUserData.first_name} ${subUserData.last_name}`}
                        </Typography>
                      )}
                    {subUserData?.title && (
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        style={{
                          fontWeight: "bold",
                          fontWeight: 600,
                          lineHeight: "-2%",
                          letterSpacing: "-2%",
                        }}
                      >
                        {subUserData.title}
                      </Typography>
                    )}
                    <Typography
                      color="textSecondary"
                      sx={{
                        fontSize: 13.5,
                        fontWeight: 500,
                        mt: "-3px",
                        lineHeight: "-2%",
                        letterSpacing: "-2%",
                      }}
                    >
                      {(userData?.company_name || "No Company Found") +
                        " | " +
                        (subUserData?.location_name || "No Assigned Location")}
                    </Typography>
                  </div>
                </div>
              </div>
            </UserCard>
          )}

          {/*Single Location & Multi User User Card*/}
          {accountData?.single_location && !accountData?.single_user ? (
            <UserCard sx={{ mt: 1 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <ProfileImageWrapper>
                    <img
                      src={ProfileImage}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  </ProfileImageWrapper>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    {subUserData &&
                      subUserData.first_name &&
                      subUserData.last_name && (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{
                            marginBottom: "-4px",
                            marginTop: "-9px",
                            fontSize: 16,
                            fontWeight: 600,
                          }}
                        >
                          {" "}
                          {`${subUserData.first_name} ${subUserData.last_name}`}
                        </Typography>
                      )}
                    {subUserData?.title && (
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        style={{
                          fontWeight: "bold",
                          fontWeight: 600,
                          lineHeight: "-2%",
                          letterSpacing: "-2%",
                        }}
                      >
                        {subUserData.title}
                      </Typography>
                    )}
                    <Typography
                      color="textSecondary"
                      sx={{
                        fontSize: 13.5,
                        fontWeight: 500,
                        mt: "-3px",
                        lineHeight: "-2%",
                        letterSpacing: "-2%",
                      }}
                    >
                      {userData?.company_name || "No Company Found"}
                    </Typography>
                  </Box>
                </div>
              </div>
            </UserCard>
          ) : null}
          {/*Single Location & Single User  Card*/}

          {isSingleUser ? (
            <SingleUserCard sx={{ mt: 1 }}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ProfileImageWrapper>
                    <img
                      src={ProfileImage}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  </ProfileImageWrapper>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <IconButton
                      onClick={handleEditProfileOpen} // Corrected onClick event
                      sx={{ mr: -1.5, ml: "auto", mt: -1.75 }}
                    >
                      <img
                        src={EditIcon}
                        alt="detailsIcon"
                        style={{
                          border: "1px solid #B0BEC5",
                          width: "auto",
                          height: "28px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "6px",
                          padding: "1px",
                          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                        }}
                      />
                    </IconButton>
                    <Stack sx={{ mt: -3 }}>
                      {subUserData &&
                        subUserData.first_name &&
                        subUserData.last_name && (
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{
                              marginBottom: "-4px",
                              marginTop: "-8px",
                              fontWeight: 600,
                              lineHeight: "-2%",
                              letterSpacing: "-2%",
                            }}
                          >
                            {" "}
                            {`${subUserData.first_name} ${subUserData.last_name}`}
                          </Typography>
                        )}
                      {subUserData?.title && (
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          style={{
                            fontWeight: "bold",
                            fontWeight: 600,
                            lineHeight: "-2%",
                            letterSpacing: "-2%",
                          }}
                        >
                          {subUserData.title}
                        </Typography>
                      )}
                      <Typography
                        color="textSecondary"
                        sx={{
                          fontSize: 13.5,
                          fontWeight: 500,
                          mt: "-3px",
                          lineHeight: "-2%",
                          letterSpacing: "-2%",
                        }}
                      >
                        {userData?.company_name || "No Company Found"}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </SingleUserCard>
          ) : (
            <> {/* Empty fragment for non-SingleUser case */}</>
          )}
        </Box>
        <BizCard>
          <Stack direction="row" sx={{}}>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ fontWeight: 600, lineHeight: "-2%", letterSpacing: "-2%" }}
            >
              Company Details
            </Typography>
            <IconButton
              onClick={handleEditCompanyOpen} // Corrected onClick event
              sx={{
                mr: -1.5,
                ml: "auto",
                mt: -1.5,
                display: subUserData?.is_admin ? "block" : "none",
              }}
            >
              <img
                src={EditIcon}
                alt="detailsIcon"
                style={{
                  border: "1px solid #B0BEC5",
                  width: "auto",
                  height: "28px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "6px",
                  padding: "1px",
                  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                  mb: 1,
                }}
              />
            </IconButton>
            <Box
              sx={{
                width: "auto",
                height: "28px",
                mr: -1.5,
                ml: "auto",
                mt: 0.5,
                display: subUserData?.is_admin ? "none" : "block",
              }}
            >
              {" "}
            </Box>
          </Stack>

          <Typography
            sx={{
              fontSize: 13,
              marginBottom: "5px",
              mt: -1.35,
              lineHeight: "-2%",
              letterSpacing: "-2%",
              fontWeight: 600,
              color: "#48768C",
            }}
          >
            Your company's website and email are automatically added to your
            sent notifications.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: "15px",
              width: "100%",
              maxWidth: "800px",
              lineHeight: "-2%",
              letterSpacing: "-2%",
            }}
          >
            {userLogoUrl ? (
              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  width: "10%",
                  height: "10%",
                  marginTop: 0.5,
                  opacity: 1,
                  "&:hover": {
                    ".edit-logo-btn": { opacity: 1 },
                  },
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  src={userLogoUrl}
                  alt="User Logo"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />

                {isHovered && (
                  <PopoverButton
                    handleUploadButtonClick={handleUploadButtonClick}
                    shouldRenderButton={shouldRenderButton}
                    setIsHovered={setIsHovered}
                    handleDelete={handleDelete}
                  />
                )}
              </Box>
            ) : (
              <Typography
                onClick={handleUploadButtonClick}
                style={{ cursor: "pointer" }}
                variant="caption"
                sx={{
                  mt: 0.25,
                  border: ".25px solid #447c92",
                  padding: "18px",
                  color: "#447c92",
                  backgroundColor: "#EDF7FA",
                  borderRadius: "8px",
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
                  width: "75px",
                  height: "95px",
                  marginRight: "5px",
                  // Add shadow
                }}
              >
                Upload Your Logo
              </Typography>
            )}

            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <Dialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              PaperProps={{ sx: { borderRadius: "14px" } }}
            >
              <DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleCloseDialog}
                  sx={{
                    position: "absolute",
                    right: 2,
                    top: 5,
                    color: "#7589A2",
                    backgroundColor: "transparent",
                    zIndex: 1,
                    borderRadius: "12px",
                    width: "35px",
                    height: "35px",
                    padding: "3px",
                    mx: 0.5,
                    my: 0.25,
                  }}
                >
                  <CloseIcon sx={{ fontSize: "28px" }} />
                </IconButton>

                <Stack
                  direction="row"
                  alignItems="center" // Centers items vertically within the Stack
                  spacing={1} // Adjusts the spacing between the image and text
                  sx={{ mt: 1 }}
                >
                  <img
                    src={hoursIcon}
                    alt="bell"
                    style={{
                      border: "1px solid rgba(173, 216, 230, 0.7)",
                      width: "auto",
                      height: "23px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "10px",
                      padding: "1px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
                      borderRadius: "6px",
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#48768C",
                      fontWeight: 600,
                      textAlign: "center",
                      lineHeight: "-2%",
                      letterSpacing: "-1%",
                      mt: 0,
                    }}
                  >
                    We couldnt process your request
                  </Typography>
                </Stack>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#48768C",
                      fontWeight: 600,
                      lineHeight: "-2%",
                      letterSpacing: "-1%",
                      mt: 2,
                      ml: 0.5,
                      mb: 0.5,
                    }}
                  >
                    Only account administrators can upload or change your
                    companies logo.
                  </Typography>
                </Box>
              </DialogTitle>
              <DialogContent sx={{ padding: 1 }}></DialogContent>
            </Dialog>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                flex: 1,
                marginLeft: "1rem",
                width: "100%",
              }}
            >
              {/* Left column */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "39%",
                  paddingRight: "1%",
                  maxWidth: "695px",
                }}
              >
                {/* Your Company's Name */}
                <Typography
                  sx={{
                    color: "#576369",
                    fontWeight: 600,
                    fontSize: 14.5,
                    mt: 1,
                  }}
                >
                  Your Company's Name
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {userData.company_name || "No Company Found"}
                </Typography>

                {/* City/State */}
                <Typography
                  sx={{
                    paddingRight: "91px",
                    color: "#576369",
                    fontWeight: 600,
                    fontSize: 14.5,
                  }}
                >
                  City/State
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {userData.company_city},{" "}
                  {userData.company_state || "State Not Found"}
                </Typography>
              </Box>

              {/* Right column */}
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                  paddingLeft: "1%",
                }}
              >
                {/* Website URL */}
                <Typography
                  sx={{ color: "#576369", fontWeight: 600, fontSize: 14.5 }}
                >
                  Website URL
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {userData.company_website || "No Company Found"}
                </Typography>

                {/* Company email */}
                <Typography
                  sx={{ color: "#576369", fontWeight: 600, fontSize: 14.5 }}
                >
                  {" "}
                  Company email
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {userData.company_email || "No Email Found"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </BizCard>

        <Box>
          {/* Single User and Single Location */}
          {accountData?.single_location && accountData?.single_user ? (
            <SingleUserInfoCard sx={{ mb: 2 }}>
              <Box
                sx={{
                  mt: -1.5,
                  display: "flex",
                  justifyContent: "flex-end", // Aligns items to the end of the flex container (right side)
                  width: "100%",
                }}
              >
                <IconButton
                  onClick={handleEditLocationOpen} // Corrected onClick event
                  sx={{
                    mr: -1.5,
                    justifyContent: "right",
                    flexDirection: "flex-end",
                  }}
                >
                  <img
                    src={EditIcon}
                    alt="detailsIcon"
                    style={{
                      border: "1px solid #B0BEC5",
                      width: "auto",
                      height: "28px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "6px",
                      padding: "1px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                    }}
                  />
                </IconButton>
              </Box>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  fontWeight: 600,
                  lineHeight: "-2%",
                  letterSpacing: "-2%",
                  mt: -3.75,
                }}
              >
                {userData.company_name}'s Location Information{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#48768C",
                  fontSize: 13,
                  marginBottom: "8px",
                  mt: -0.25,
                  fontWeight: 600,
                }}
              >
                Your location's phone number and hours for the day are
                automatically added to your sent notifications.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  marginRight: "5px",
                  width: "100%",
                  maxWidth: "695px",
                }}
              >
                {/* Left column */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "48%",
                    paddingRight: "1%",
                  }}
                >
                  {/* Location Phone */}
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      sx={{ color: "#576369", fontWeight: 600, fontSize: 14.5 }}
                    >
                      Location Phone
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {(subUserData &&
                        formatPhoneNumber(subUserData.location_phone)) ||
                        "Phone Number Not Found"}
                    </Typography>
                  </Box>

                  {/* Location Email */}
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      sx={{ color: "#576369", fontWeight: 600, fontSize: 14.5 }}
                    >
                      Location Email
                    </Typography>{" "}
                    <Typography variant="body2" color="textSecondary">
                      {(subUserData && subUserData.location_email) ||
                        "No Email Found"}
                    </Typography>
                  </Box>
                </Box>

                {/* Right column */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "48%",
                    paddingLeft: "1%",
                  }}
                >
                  {/* Location Hours */}
                  <Box sx={{ mb: 1, mt: "-60px" }}>
                    <Typography
                      sx={{
                        color: "#576369",
                        fontWeight: 600,
                        fontSize: 14.5,
                        mt: 1.5,
                      }}
                    >
                      Location Hours
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (subUserData.is_admin) {
                          setIsSingleUserEditingHours(true);
                        }
                      }}
                    >
                      {subUserData && subUserData.is_admin
                        ? "View & Edit Hours"
                        : "View Location Hours"}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {isSingleUserEditingHours && (
                <SingleUserEditHoursModal
                  isOpen={isSingleUserEditingHours}
                  onClose={handleCloseEditHours}
                  onUpdateHours={handleHoursChange}
                  locationName={userData.company_name}
                />
              )}
            </SingleUserInfoCard>
          ) : null}

          {/* Single Location Multi User*/}
          {accountData?.single_location && !accountData?.single_user ? (
            <SingleUserInfoCard sx={{ mb: 2 }}>
              <Box
                sx={{
                  mt: -1.5,
                  display: "flex",
                  justifyContent: "flex-end", // Aligns items to the end of the flex container (right side)
                  width: "100%",
                }}
              >
                <IconButton
                  onClick={handleMultiEditLocationOpen} // Corrected onClick event
                  sx={{
                    mr: -1.5,
                    justifyContent: "right",
                    flexDirection: "flex-end",
                  }}
                >
                  <img
                    src={EditIcon}
                    alt="detailsIcon"
                    style={{
                      border: "1px solid #B0BEC5",
                      width: "auto",
                      height: "28px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "6px",
                      padding: "1px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                    }}
                  />
                </IconButton>
              </Box>
              <Typography
                color="textSecondary"
                sx={{ fontWeight: 600, mt: -3.5 }}
              >
                {userData.company_name}'s Location Information{" "}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{
                  fontSize: 13,
                  marginBottom: "8px",
                  fontWeight: 600,
                  mt: -0.25,
                }}
              >
                Your location's phone number and hours for the day are
                automatically added to your sent notifications.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  marginRight: "5px",
                  width: "100%",
                  maxWidth: "695px",
                }}
              >
                {/* Left column */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "48%",
                    paddingRight: "1%",
                  }}
                >
                  {/* Location Phone */}
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      sx={{ color: "#576369", fontWeight: 600, fontSize: 14.5 }}
                    >
                      Location Phone
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {(subUserData &&
                        formatPhoneNumber(subUserData.location_phone)) ||
                        "Phone Number Not Found"}
                    </Typography>
                  </Box>

                  {/* Location Email */}
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      sx={{ color: "#576369", fontWeight: 600, fontSize: 14.5 }}
                    >
                      Location Email
                    </Typography>{" "}
                    <Typography variant="body2" color="textSecondary">
                      {(subUserData && subUserData.email) || "No Email Found"}
                    </Typography>
                  </Box>
                </Box>

                {/* Right column */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "48%",
                    paddingLeft: "1%",
                  }}
                >
                  {/* Location Hours */}
                  <Box sx={{ mb: 1, mt: "-60px" }}>
                    <Typography
                      sx={{
                        color: "#576369",
                        fontWeight: 600,
                        fontSize: 14.5,
                        mt: 1.5,
                      }}
                    >
                      Location Hours
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsSingleUserEditingHours(true)}
                    >
                      {subUserData?.is_admin
                        ? "View & Edit Hours"
                        : "View Location Hours"}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {isSingleUserEditingHours && (
                <SingleUserEditHoursModal
                  isOpen={isSingleUserEditingHours}
                  onClose={() => setIsSingleUserEditingHours(false)}
                  onUpdateHours={handleHoursChange}
                  locationName={userData.company_name}
                />
              )}
            </SingleUserInfoCard>
          ) : null}

          {/* Multi Location */}
          {!accountData?.single_location ? (
            <InfoCard>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  fontWeight: 600,
                  lineHeight: "-2%",
                  letterSpacing: "-2%",
                }}
              >
                Your Active Locations Information
              </Typography>
              <Typography
                color="textSecondary"
                sx={{
                  fontSize: 13,
                  marginBottom: "5px",
                  mt: -0.25,
                  lineHeight: "-2%",
                  letterSpacing: "-2%",
                  fontWeight: 600,
                }}
              >
                Your location's phone number and hours for the day are
                automatically added to your sent notifications.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  marginRight: "5px",
                  width: "100%",
                  maxWidth: "800px",
                }}
              >
                {/* Left column */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "48%",
                    paddingRight: "1%",
                  }}
                >
                  {/* Location Name */}
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      sx={{
                        color: "#576369",
                        fontWeight: 600,
                        fontSize: 14.5,
                        mt: 1,
                      }}
                    >
                      Location Name
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {subUserData && subUserData.location_name
                        ? subUserData.location_name
                        : "Location not specified"}
                    </Typography>
                  </Box>

                  {/* Location Phone */}
                  <Box>
                    <Typography
                      sx={{ color: "#576369", fontWeight: 600, fontSize: 14.5 }}
                    >
                      Location Phone
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {(subUserData &&
                        formatPhoneNumber(subUserData.location_phone)) ||
                        "Phone Number Not Found"}
                    </Typography>
                  </Box>
                </Box>

                {/* Right column */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "55%",
                    paddingLeft: "1%",
                    mt: 1.5,
                  }}
                >
                  {/* Location Email */}
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      sx={{ color: "#576369", fontWeight: 600, fontSize: 14.5 }}
                    >
                      Location Email
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {(subUserData && subUserData.email) || "No Email Found"}
                    </Typography>
                  </Box>

                  {/* View & Edit Hours */}
                  <Box>
                    <Typography
                      sx={{ color: "#576369", fontWeight: 600, fontSize: 14.5 }}
                    >
                      Location Hours
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsEditingHours(true)}
                    >
                      {subUserData.is_admin
                        ? "View & Edit Hours"
                        : "View Location Hours"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </InfoCard>
          ) : null}

          {isEditingHours && (
            <EditHoursModal
              isOpen={isEditingHours}
              onClose={handleCloseEditHours}
              onUpdateHours={handleHoursChange}
              locationName={subUserData.location_name} // Pass the location name here
            />
          )}
        </Box>
      </Box>
      <EditProfile
        isOpen={isEditProfileOpen}
        onClose={handleEditProfileClose}
        editData={editData}
        setEditData={setEditData}
        handleChangeTitle={handleChangeTitle}
        handleFirstNameChange={handleFirstNameChange}
        handleLastNameChange={handleLastNameChange}
        handleSave={handleSaveProfile}
        loading={loading}
      />
      <EditSingleUserLocation
        isOpen={isEditingLocation}
        onClose={handleEditLocationClose}
        handleSave={handleSaveLocation}
        editData={editData}
        setEditData={setEditData}
        accountData={accountData}
        loading={loading}
      />
      <EditMultiUserLocation
        activeLocation={activeLocation}
        username={userData.username}
        isOpen={isMultiUserEditingLocation}
        onClose={handleMultiEditLocationClose}
        editData={editData}
        setEditData={setEditData}
        handleSave={handleSaveMultiUser}
        loading={loading}
        subUserData={subUserData}
        formChanged={formChanged}
        setFormChanged={setFormChanged}
      />

      <EditCompanyDialog
        isOpen={editCompanyOpen}
        onClose={handleEditCompanyClose}
        activeLocation={activeLocation}
        username={userData.username}
        editData={editData}
        setEditData={setEditData}
        handleSave={handleSaveMultiUser}
        loading={loading}
        subUserData={subUserData}
        formChanged={formChanged}
        setFormChanged={setFormChanged}
        handleSubmit={handleUpdateCompanyInfo}
      />
    </Box>
  );
}

const ProtectedRoute = withAuthenticationRequired(Profile, {
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});

export default Profile;
