import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAppContext } from "src/context/AppContext";
import useAddLocation from "src/components/payments/hooks/useAddLocation";
import useAddNewUser from "src/components/payments/hooks/useAddNewUser.js";
import usePlanDetails from "src/components/payments/hooks/usePlanDetails";
import { Button, Box, Typography } from "@mui/material";
import BillingInfoForm from "./BillingInfoForm";
import { SecurityUpdateWarningRounded } from "@mui/icons-material";
import { values } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";

// Load the Stripe object outside the component
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const EditForm = ({
  clientSecret,
  customerId,
  priceId,
  apiUrl,
  onSubscriptionUpdate,
  refreshSubscription,
  onClose,
  subscriptionId,
  refetchSubscriptionDetails,
  errors,
  setWarning,
  warning,
  values,
  fetchBillingHistory,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showSnackBar } = useAppContext();
  const { addNewLocation } = useAddLocation();
  const { addNewUser } = useAddNewUser();
  const { planDetails } = usePlanDetails();
  const [paymentElementLoaded, setPaymentElementLoaded] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [paymentMethodId, setPaymentMethodId] = useState(null);

  useEffect(() => {
    // Ensure the PaymentElement initializes properly before rendering
    const timer = setTimeout(() => {
      setPaymentElementLoaded(true);
    }, 0);

    return () => clearTimeout(timer); // Cleanup the timeout
  }, []);

  useEffect(() => {
    const handleUpdate = async () => {
      if (!paymentMethodId) return;

      try {
        const token = await getAccessTokenSilently();

        const response = await fetch(
          `${apiUrl}/subscriptions/plans/${subscriptionId}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ paymentMethodId }),
          },
        );

        const data = await response.json();

        if (response.ok) {
          showSnackBar(
            "Payment method and subscription updated successfully",
            true,
          );
          refetchSubscriptionDetails();
          fetchBillingHistory(customerId);
          setLoading(false);
          onClose();
        } else {
          console.error("Backend error:", data.error);
          setError(data.error || "Failed to update billing details.");
          showSnackBar("Unable to update card. Please try again", false);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during backend processing:", error.message);
        setError(error.message || "An unexpected error occurred.");
        showSnackBar("Unable to update card. Please try again", false);
        setLoading(false);
      }
    };

    handleUpdate();
  }, [paymentMethodId]); // Dependency array ensures this runs when paymentMethodId changes

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      setError("Stripe has not loaded or clientSecret is missing");
      return;
    }

    if (errors && Object.values(errors).some((value) => value === true)) {
      setWarning(true);
      return;
    }

    setLoading(true);

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message || "Form submission failed");
      }

      const { setupIntent, error: setupError } = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: "if_required",
      });

      if (setupError || !setupIntent.payment_method) {
        throw new Error(
          setupError?.message ||
            "Setup confirmation failed: No payment method found",
        );
      }

      setPaymentMethodId(setupIntent.payment_method); // Update state to trigger useEffect
    } catch (error) {
      console.error("Error during payment setup:", error.message);
      setError(
        error.message || "An unexpected error occurred. Please try again.",
      );
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {clientSecret && (
        <>
          <Box sx={{ width: "100%", pb: 1.25, pt: 1 }}>
            <Typography
              style={{ fontWeight: "bold", color: "#48768C", fontSize: "17px" }}
            >
              New Payment Method
            </Typography>
          </Box>
          <>
            {paymentElementLoaded && (
              <PaymentElement onReady={() => setPaymentElementLoaded(true)} />
            )}
          </>
          {warning && (
            <Box
              sx={{
                flexDirection: "column",
                width: "100%",
                pt: 1.75,
                mb: -1.55,
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "#7D4E4F",
                  fontSize: "16.5px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  letterSpacing: "-.01em",
                }}
              >
                Something went wrong.
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "#7D4E4F",
                  fontSize: "16.5px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  letterSpacing: "-.01em",
                }}
              >
                Please check your billing information and try again.
              </Typography>
            </Box>
          )}
          {paymentElementLoaded && (
            <Button
              type="submit"
              disabled={
                !stripe ||
                !clientSecret ||
                loading ||
                warning ||
                !paymentElementLoaded
              }
              sx={{
                display: "flex",
                border: "1px solid #48768C",
                borderRadius: "10px",
                padding: "8px",
                color: "#48768C",
                backgroundColor: "#FFFFFF",
                mt: 3,
                justifyContent: "center",
                alignItems: "center",
                ml: "auto",
                mr: "auto",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                width: "98%",
                zIndex: 2,
                "&:disabled": {
                  backgroundColor: "#48768C21",
                  color: "#A0A0A0D9",
                },
                "&:hover": {
                  backgroundColor: "#DBEAF0",
                },
                "&:active": {
                  backgroundColor: "#DBEAF0",
                },
              }}
              color="primary"
            >
              {loading ? "Processing..." : "Update Card Details"}
            </Button>
          )}
        </>
      )}
    </form>
  );
};

// Wrapper component to ensure Elements is loaded correctly
const EditFormWrapper = ({
  customerId,
  priceId,
  apiUrl,
  onSubscriptionUpdate,
  onClose,
  clientSecret,
  refreshSubscription,
  subscriptionId,
  refetchSubscriptionDetails,
  errors,
  setWarning,
  warning,
  loading,
  values,
  fetchBillingHistory,
}) => {
  const appearance = {
    theme: "stripe", // You can also try 'night', 'flat', 'none', etc.
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#7D4E4F",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
    },
    rules: {
      ".Label": {
        color: "#6c90a2",
        fontSize: "16.5px",
        marginBottom: "3px",
      },
      ".Input": {
        backgroundColor: "#F2F9FF",
        border: "1px solid #e1e6ef",
        fontSize: "14.5px",
        padding: 0,
        color: "#4E617D",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "10px",
        height: "auto",
        fontFamily: "Source Sans Pro, sans-serif",
      },
      ".Input--invalid": {
        color: "#7D4E4F",
      },
      ".PaymentElement": {
        backgroundColor: "#f9fbfd",
        padding: "12px",
        borderRadius: "4px",
        boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15)",
      },
      ".Input::placeholder": {
        color: "#48768CCC", // Correct selector for placeholder text
      },
    },
  };

  // Enable the skeleton loader UI for optimal loading.
  const loader = "auto";

  return (
    <div>
      {clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance, loader }}
        >
          <EditForm
            clientSecret={clientSecret}
            customerId={customerId}
            priceId={priceId}
            apiUrl={apiUrl}
            onSubscriptionUpdate={onSubscriptionUpdate}
            onClose={onClose}
            refreshSubscription={refreshSubscription}
            subscriptionId={subscriptionId}
            refetchSubscriptionDetails={refetchSubscriptionDetails}
            errors={errors}
            setWarning={setWarning}
            warning={warning}
            loading={loading}
            values={values}
            fetchBillingHistory={fetchBillingHistory}
          />
        </Elements>
      ) : (
        <div>Loading Payment Information...</div>
      )}
    </div>
  );
};

export default EditFormWrapper;
