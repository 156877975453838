import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  CardMedia,
  Skeleton,
  Fade,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useAuth0 } from "@auth0/auth0-react";
import { useAccountContext } from "../../context/AccountContext";
import { convertToUserTimezone } from "../../utils/timezoneConverter";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import { styled } from "@mui/system";
import noMessage from "../../public/assets/illustrations/widgetArt.png";
import MessageDetails from "../../../src/components/modal/MessageDetails.js";
import { useAppContext } from "../../context/AppContext";
import StatusTag from "src/components/color-utils/statusTags";
import CreateCustomerModal from "src/components/modal/CreateCustomerModal";
import saveCustomerData from "../../components/customers/saveCustomerData";
import { useAuth } from "src/context/AuthContext";
import "./styles.css";
import { sub } from "date-fns";
import TooltipWrapper from "src/components/Tooltip/LightTooltip";
import ErrorIcon from "@mui/icons-material/Error";
import PendingRoundedIcon from "@mui/icons-material/PendingRounded";

const StatusIcon = ({ status }) => {
  let icon;
  let color;

  switch (status) {
    case null:
      icon = <HelpOutlineIcon />;
      color = "#7589A2"; // Blue color
      break;
    case "Sent":
      icon = <CheckCircleIcon />;
      color = "#2BBB9E"; // Light green color
      break;
    case "queued":
      icon = <PendingRoundedIcon />;
      color = "#97bac9"; // Light green color
      break;
    case "Failed":
      icon = <ErrorIcon />;
      color = "#aa5869"; // Red color
      break;
    case "Scheduled":
      icon = <AlarmOnIcon />;
      color = "#5B5B62"; // Red color
      break;
    default:
      icon = null;
  }

  return icon ? (
    <span style={{ color, marginRight: "8px" }}>{icon}</span>
  ) : null;
};

export default function CustomerFeed({
  recentActivityData,
  loadingRecentActivity,
  showCustomerFeed,
}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { accountData } = useAccountContext();
  const [customerFeedData, setCustomerFeedData] = useState([]);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMessageDetailsOpen, setIsMessageDetailsOpen] = useState(false);
  const { handleReloadComponents } = useAppContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const { subUserData } = useAuth();

  const [tableVisible, setTableVisible] = useState(false);

  const [visibleRows, setVisibleRows] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [emptyRowCount, setEmptyRowCount] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const filteredRows = recentActivityData?.filter(
        (activity) => activity?.location_id === subUserData?.active_location,
      );

      const screenWidth = window.innerWidth;

      let visibleRowCount = 0;

      if (recentActivityData) {
        if (screenWidth <= 1440) {
          visibleRowCount = 9;
          setTotalRowCount(9); // Maximum of 9 rows for smaller screens
        } else if (screenWidth <= 1599) {
          visibleRowCount = 12; // Maximum of 12 rows for screens between 1400px and 1599px
          setTotalRowCount(12); // Maximum of 12 rows
        } else {
          visibleRowCount = recentActivityData.length; // For larger screens, show all available rows
          setTotalRowCount(12); // Update the max rows for larger screens
        }

        setVisibleRows(filteredRows.slice(0, visibleRowCount)); // Slice the data according to the row count
        setEmptyRowCount(totalRowCount - visibleRows.length);
      }
    };

    handleResize(); // Call initially to set rows on load

    window.addEventListener("resize", handleResize); // Handle resizing dynamically

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, [
    recentActivityData,
    showCustomerFeed,
    totalRowCount,
    visibleRows.length,
    subUserData,
  ]);

  useEffect(() => {
    if (!loadingRecentActivity && recentActivityData) {
      // Delay display to avoid flicker
      const timer = setTimeout(() => setTableVisible(true), 100);
      return () => clearTimeout(timer);
    }
  }, [loadingRecentActivity, recentActivityData, handleReloadComponents]);

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = noMessage;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);

  // Handle saving customer
  const handleSaveCustomer = async (firstName, lastName, location_name) => {
    const success = await saveCustomerData(
      selectedPhoneNumber,
      firstName,
      lastName,
      user.sub,
      location_name,
    );

    if (success) {
      handleCloseModal();
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenMessageDetails = (row) => {
    setSelectedRow(row);
    setIsMessageDetailsOpen(true);
  };

  const handleCloseMessageDetails = () => {
    setIsMessageDetailsOpen(false);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (timeStr) => {
    const time = new Date(timeStr);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return time.toLocaleTimeString(undefined, options);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
  };

  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: "#FFFFFF",
            height: "42.5px",
            letterSpacing: "-1%",
            lineHeight: "-1%",
            minHeight: "65px",
          },
        },
      },
    },
  });

  const StyledHeader = styled(TableCell)({
    color: "#447c92",
    borderBottom: "none",
    letterSpacing: "-2%",
    lineHeight: "-2%",
    height: "39px",
  });

  const StyledRow = styled(TableCell)({
    color: "#5e9fbc",
    fontWeight: 600,
    letterSpacing: "-2%",
    lineHeight: "-2%",
  });

  const StyledHeaderText = styled(Typography)({
    fontWeight: 500,
    fontSize: 13.5,
    align: "center",
    margin: "auto",
    letterSpacing: "-2%",
    lineHeight: "-2%",
    minHeight: "20px",
  });

  const fadeInTransition = {
    opacity: loadingRecentActivity ? 0 : 1,
    transition: "opacity 0.5s ease-in-out",
  };

  return (
    <Box sx={{ background: "#f4fbfd", height: "100%" }}>
      {(loadingRecentActivity || !recentActivityData) && (
        <Fade in={loadingRecentActivity || !recentActivityData} timeout={400}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              align="center"
              sx={{
                width: "100%",
                color: "#447c92",
                fontSize: "1.1rem",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Center text vertically within the Typography
                letterSpacing: "-2%",
              }}
            >
              Loading Your Recent Activity...
            </Typography>

            <LinearProgress
              color="secondary"
              sx={{
                height: 3,
                width: "20%",
                mt: 2, // Add some space between Typography and LinearProgress
                mb: 8,
                "& .MuiLinearProgress-barColorSecondary": {
                  backgroundColor: "#2BBB9E",
                },
              }}
            />
          </Box>
        </Fade>
      )}

      {!loadingRecentActivity &&
        recentActivityData &&
        visibleRows.length > 0 && (
          <Fade in={true} timeout={500}>
            <TableContainer
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                background: "#f4fbfd",
                flexGrow: 1, // Allow it to grow inside its parent
                maxHeight: "75vh",
                overflowY: "auto", // Enable scrolling directly on the container
              }}
            >
              <Table
                size="small"
                sx={{
                  tableLayout: "fixed", // Ensures columns align correctly
                  width: "100%",
                  height: "100%",
                }}
              >
                <ThemeProvider theme={theme}>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TableRow>
                      {[
                        { label: "Date Sent", width: "12%" },
                        { label: "Time Sent", width: "14%" },
                        { label: "Sent To", width: "14%" },
                        { label: "Ticket Number", width: "14%" },
                        { label: "Notification", width: "14%" },
                        ...(accountData && !accountData?.single_location
                          ? [{ label: "Location", width: "14%" }]
                          : []),
                        { label: "Status", width: "10%" },
                      ].map((header, index) => (
                        <StyledHeader
                          key={index}
                          align="center"
                          sx={{ width: header.width, textAlign: "center" }}
                        >
                          <StyledHeaderText>{header.label}</StyledHeaderText>
                        </StyledHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                </ThemeProvider>

                <TableBody
                  sx={{
                    flexDirection: "column",
                    flexGrow: 1, // Allow it to expand to its parent container
                    overflowY: "auto", // Enable scrolling directly on the container
                    background: "#f4fbfd",
                    width: "100%",
                    maxHeight: "100vh",
                  }}
                >
                  {visibleRows
                    .filter(
                      (activity) =>
                        activity.location_id === subUserData.active_location,
                    ) // Filter based on location_id and active_location
                    .map((activity, index) => {
                      const rowData = [
                        formatDate(activity.created_at),
                        formatTime(activity.created_at),
                        activity.customer_name ||
                          formatPhoneNumber(activity.phone_number),
                        activity.ticket_number,
                        <Box
                          height="32px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <StatusTag status={activity.status} />
                        </Box>,
                        accountData && !accountData?.single_location
                          ? activity.location_name
                          : null,
                        <TooltipWrapper status={activity.message_status}>
                          <Box
                            sx={{
                              height: "100%",
                              pt: "5px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <StatusIcon status={activity.message_status} />
                          </Box>
                        </TooltipWrapper>,
                      ].filter(Boolean);

                      return (
                        <TableRow
                          key={index}
                          onClick={() => handleOpenMessageDetails(activity)}
                          sx={{
                            height: `calc(85% / ${totalRowCount})`, // Use totalRowCount to ensure consistent height
                            borderBottom: "none",
                            boxShadow: "none",
                            cursor: "pointer",
                            "&:last-child td, &:last-child th": {
                              border: "none",
                              pb: 1.5,
                            },
                            "&:hover": {
                              backgroundColor: "rgba(173, 216, 230, 0.2)",
                              cursor: "pointer",
                            },
                          }}
                        >
                          {rowData.map((cell, idx) => (
                            <StyledRow
                              key={idx}
                              align="center"
                              sx={{
                                textAlign: "center",
                                borderBottom: "none",
                                boxShadow: "none",
                              }}
                            >
                              {cell}
                            </StyledRow>
                          ))}
                        </TableRow>
                      );
                    })}

                  {visibleRows.length < totalRowCount &&
                    Array.from({ length: emptyRowCount }).map((_, i) => (
                      <TableRow
                        key={`empty-${i}`}
                        sx={{
                          height: `calc(85% / ${totalRowCount})`, // Ensures empty rows match data rows
                          backgroundColor: "#FFFFFF",
                          borderBottom: "none",
                          boxShadow: "none",
                        }}
                      >
                        <TableCell
                          colSpan={accountData?.single_location ? 6 : 7}
                          sx={{
                            borderBottom: "none",
                          }}
                        />
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <MessageDetails
                open={isMessageDetailsOpen}
                selectedRow={selectedRow}
                onClose={handleCloseMessageDetails}
                handleOpenModal={handleOpenModal}
                setSelectedPhoneNumber={setSelectedPhoneNumber}
              />

              <CreateCustomerModal
                open={isModalOpen}
                onClose={handleCloseModal}
                onSaveCustomer={handleSaveCustomer}
                selectedPhoneNumber={selectedPhoneNumber}
                location_name={subUserData?.location_name || ""} // Check if subUserData exists before accessing location_name
              />
            </TableContainer>
          </Fade>
        )}

      {!loadingRecentActivity &&
        recentActivityData &&
        visibleRows.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "100%",
              borderRadius: "10px",
              padding: "20px",
              overflow: "hidden",
              flex: 1, // Ensures it takes up all available space
              minHeight: 0, // Prevents content from expanding beyond limits
              background: "radial-gradient(circle, #EEF6F9, #D4E9F2)",
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                mt: -9,
                mb: -1,
                position: "relative",
                width: {
                  xs: "60vw",
                  sm: "40vw",
                  md: "30vw",
                  lg: "25.05vw", // 10% increase from 22.77vw
                },
                height: {
                  xs: "60vw",
                  sm: "40vw",
                  md: "30vw",
                  lg: "26.44vw", // 10% increase from 24.04vw
                },
              }}
            >
              {/* Skeleton loader */}
              {!imageLoaded && (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "75%",
                    height: {
                      xs: "70vw",
                      sm: "50vw",
                      md: "35vw",
                      lg: "41.25vw",
                    }, // 10% increase from 37.5vw
                    borderRadius: "10px",
                    backgroundColor: "transparent",
                    objectFit: "contain",
                    transition: "opacity 0.3s ease-in-out",
                    opacity: imageLoaded ? 0 : 1,
                  }}
                />
              )}

              {/* Image with fade-in effect */}
              <CardMedia
                component="img"
                src={noMessage}
                sx={{
                  width: { xs: "70vw", sm: "50vw", md: "35vw", lg: "41.25vw" }, // 10% increase from 37.5vw
                  height: "auto",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                  transition: "opacity 0.3s ease-in-out",
                  opacity: imageLoaded ? 1 : 0,
                }}
                onLoad={() => setImageLoaded(true)}
              />
            </Box>
            <Typography
              align="center"
              sx={{
                mt: -9,

                fontSize: 18,
                color: "#447c92",
                mb: "15px",

                textAlign: "center",
                fontWeight: 600,
              }}
            >
              No Recent Activity Yet.
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: 15,
                color: "#447c92",
                mb: "20px",

                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Try sending a few notifications to your customers....your recent
              activity will automatically show here.
            </Typography>
          </Box>
        )}
    </Box>
  );
}
