import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useAppContext } from "../context/AppContext";
import {
  Grid,
  Container,
  Box,
  Paper,
  Typography,
  Stack,
  Fade,
} from "@mui/material";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoginModal from "../components/modal/LoginModal";
import { useAuth } from "../context/AuthContext";
import NewUserSignUp from "src/components/modal/NewUserSignUp/NewUserSignUp"; // Add this import
import { AccountProvider } from "../context/AccountContext"; // Import AppProvider from AccountContext
import { useAccountContext } from "../context/AccountContext"; // Import useAccountContext from AccountContext
import { useSingleUser } from "../context/SingleUserContext"; // Import useSingleUserContext from SingleUserContext
import { useAuth0 } from "@auth0/auth0-react";
import weeklyIcon from "../public/assets/icons/widgets/week.png";
import monthlyIcon from "../public/assets/icons/widgets/month.png";
import pendingIcon from "../public/assets/icons/widgets/pending.png";
import MobileWarningPopup from "../components/modal/MobileWarningPopup";
import fetchGlobalNotifications from "../utils/notificationUtils";
import { useWidgetContext } from "src/context/WidgetContext";

import "./styles.css";

// components
import RecentActivity from "../widgets/feed/RecentActivity";
import MonthlyMessages from "src/widgets/feed/ScheduledMessages";
import PendingApproval from "src/widgets/feed/PendingApproval";

// sections
import { DashboardWidgets } from "../widgets";


import NewAccountSetupDialog from "../components/modal/NewUserSignUp/Refresh/AccountSetupDialog"; 

function DashboardAppPage() {
  const [userData, setUserData] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [percentageChange, setPercentageChange] = useState(0);
  const [newCustomersAdded, setNewCustomersAdded] = useState(0);
  const { user, isAuthenticated } = useAuth0();
  const {
    setAccessToken,
    login,
    username,
    subUserData,
    shouldShowLoginModal,
    setShouldShowLoginModal,
    isLoggedIn,
  } = useAuth();
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const [shouldShowNewUserSignUp, setShouldShowNewUserSignUp] = useState(true);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const [isUserCreated, setIsUserCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Introduce loading state
  const { showNewUserSignUp, setShowNewUserSignUp } = useAccountContext();
  const { reloadComponents } = useAppContext();
  const { fetchLatestSingleUserData } = useSingleUser(); // Import the fetchLatestSingleUserData function
  const [showCustomerFeed, setShowCustomerFeed] = useState(true);
  const [showMonthlyMessages, setShowMonthlyMessages] = useState(false);
  const [showPendingApproval, setShowPendingApproval] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { recentActivityData, loadingRecentActivity } = useWidgetContext();
  const {
    scheduledNotificationsData,
    loadingScheduledNotifications,
    pauseAutoRefresh,
    resumeAutoRefresh,
    fetchAllWidgetTypes,
  } = useWidgetContext();
  const { pendingApprovalData, loadingPendingApproval } = useWidgetContext();
  const {
    recentActivityWidgetCount,
    scheduledNotificationsWidgetCount,
    pendingApprovalWidgetCount,
  } = useWidgetContext();

  const { accountData } = useAccountContext();
  const [showNewSetup, setShowNewSetup] = useState(true)


  const handleOpenNewAccountSetup = () => {
    setShowNewSetup(true)
  }

  const handleCloseNewAccountSetup = () => {
    setShowNewSetup(false)
  }

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // Adjust the width as needed for your definition of "mobile"
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it once on mount to check initial width
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Check if accountData is available and data has been fetched
    if (accountData && !isLoading && showNewUserSignUp === false) {
      // Priority one: Check if the account is single user and there is no username
      if (accountData.single_user && !username) {
        fetchLatestSingleUserData(); // Invoke the fetchLatestSingleUserData function here
      } else {
        // Priority two: Render login modal if not a single user AND there is NO username
        if (!accountData.single_user && !username) {
          setShouldShowLoginModal(true);
        }
      }
    }
  }, [
    accountData,
    username,
    fetchLatestSingleUserData,
    isLoading,
    showNewUserSignUp,
  ]);

  useEffect(() => {
    // Check if accountData is available and data has been fetched
    if (accountData && !isLoading) {
      // Priority one: Check if the account is single user and there is no username
      if (accountData.single_user) {
        fetchLatestSingleUserData(); // Invoke the fetchLatestSingleUserData function here
      }
    }
  }, [accountData, username, fetchLatestSingleUserData, isLoading]);

  useEffect(() => {
    // If accountData is fetched and set, update loading state to false
    if (accountData) {
      setIsLoading(false);
    }
  }, [accountData]);

  const handleReloadComponents = () => {
    reloadComponents(); // Trigger the reload
  };

  const handleLogin = (username, pin) => {
    setIsUserCreated(true); // Assuming that the user is successfully created upon login
    setIsLoginModalOpen(false);
    setIsUserCreated(true);
    setAccessToken(username);
    login(username);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scrollbar
    return () => {
      document.body.style.overflow = ""; // Re-enable scrollbar on component unmount
    };
  }, []);

  if (!isLoggedIn) {
    return (
      <LoginModal
        open={shouldShowLoginModal}
        onClose={() => setShouldShowLoginModal(false)} // Close the modal
        onLogin={() => {
          setShouldShowLoginModal(false); // Close the modal when login is successful
        }}
        accountData={accountData} // Pass the account data if needed
      />
    );
  }

  return (
    <AccountProvider>
      <>
        <Helmet>
          <title>NanoNotify.io</title>
        </Helmet>

        <Box
          sx={{
            width: "100%",
            justifyContent: "space-between",
            marginTop: "-1rem",
            maxHeight: "90vh",
            display: "flex",
            flexDirection: "column",
            paddingBottom: ".5rem",
            borderRadius: "8px",
            pb: 1,
            height: "88vh",
          }}
        >
          {/* Widgets Row */}
          <Box sx={{ width: "100%", display: "flex", gap: 0, mb: 1.5 }}>
            <Box sx={{ flex: 1, maxWidth: "33.33%" }}>
              <DashboardWidgets
                selected={showCustomerFeed}
                title="Your Recent Notifications"
                total={recentActivityWidgetCount}
                fetchData={true}
                icon={<img src={weeklyIcon} alt="weekly icon" style={{}} />}
                onClick={() => {
                  setShowCustomerFeed(true);
                  setShowMonthlyMessages(false);
                  setShowPendingApproval(false);
                }}
              />
            </Box>

            <Box sx={{ flex: 1, maxWidth: "33.33%" }}>
              <DashboardWidgets
                selected={showMonthlyMessages}
                title="Notifications Scheduled For Delivery"
                total={scheduledNotificationsWidgetCount}
                fetchData={true}
                icon={<img src={monthlyIcon} alt="monthly icon" />}
                onClick={() => {
                  setShowCustomerFeed(false);
                  setShowMonthlyMessages(true);
                  setShowPendingApproval(false);
                }}
              />
            </Box>

            <Box sx={{ flex: 1, maxWidth: "33.33%" }}>
              <DashboardWidgets
                selected={showPendingApproval}
                title="Notifications Pending Approval"
                total={pendingApprovalWidgetCount}
                fetchData={true}
                icon={<img src={pendingIcon} alt="pending icon" />}
                onClick={() => {
                  setShowCustomerFeed(false);
                  setShowMonthlyMessages(false);
                  setShowPendingApproval(true);
                }}
              />
            </Box>
          </Box>

          {/* Table Container */}
          <Box
            className="tableContainer"
            sx={{
              ml: "5px",
              flexGrow: 1,
              width: "99%",
              border: "1.25px solid rgba(173, 216, 230, 0.5)",
              opacity: 0.95,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch", // Ensures it takes the full available width
              height: "100%", // or '100vh' if full screen is needed
            }}
          >
            <Fade in={showCustomerFeed} timeout={500}>
              <Box
                sx={{
                  display: showCustomerFeed ? "flex" : "none",
                  flexGrow: 1,
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                <RecentActivity
                  recentActivityData={recentActivityData}
                  loadingRecentActivity={loadingRecentActivity}
                  showCustomerFeed={showCustomerFeed}
                />
              </Box>
            </Fade>

            <Fade in={showMonthlyMessages} timeout={500}>
              <Box
                sx={{
                  display: showMonthlyMessages ? "flex" : "none",
                  flexGrow: 1,
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                <MonthlyMessages
                  scheduledNotificationsData={scheduledNotificationsData}
                  loadingScheduledNotifications={loadingScheduledNotifications}
                  showMonthlyMessages={showMonthlyMessages}
                  pauseAutoRefresh={pauseAutoRefresh}
                  resumeAutoRefresh={resumeAutoRefresh}
                  fetchAllWidgetTypes={fetchAllWidgetTypes}
                />
              </Box>
            </Fade>

            <Fade in={showPendingApproval} timeout={500}>
              <Box
                sx={{
                  display: showPendingApproval ? "flex" : "none",
                  flexGrow: 1,
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                <PendingApproval
                  pendingApprovalData={pendingApprovalData}
                  loadingPendingApproval={loadingPendingApproval}
                  showPendingApproval={showPendingApproval}
                  pauseAutoRefresh={pauseAutoRefresh}
                  resumeAutoRefresh={resumeAutoRefresh}
                  fetchAllWidgetTypes={fetchAllWidgetTypes}
                />
              </Box>
            </Fade>
          </Box>
        </Box>



        {showPopup && <MobileWarningPopup />}

        {showNewUserSignUp ||
          (!accountData && shouldShowLoginModal) ||
          (!accountData?.completed_setup && shouldShowLoginModal && (
            <NewUserSignUp onClose={() => setShowNewUserSignUp(false)} />
          ))}
      </>
    </AccountProvider>
  );
}

const ProtectedRoute = withAuthenticationRequired(DashboardAppPage, {
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});

export default ProtectedRoute;
