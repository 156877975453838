import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import editUser from "../../public/assets/icons/icons/editUser.png";
import userDetailsIcon from "../../public/assets/icons/icons/userDetails.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../../context/AuthContext";
import addLocationIcon from "../../public/assets/icons/icons/newLocation.png";
import { useAppContext } from "../../context/AppContext";
import { useSingleUser } from "../../context/SingleUserContext";
import { AccountProvider } from "../../context/AccountContext";
import { useAccountContext } from "../../context/AccountContext";
import {
  PrimaryButton,
  CancelButton,
} from "src/components/Buttons/ModalButtons";
import { set } from "lodash";

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const EditCompanyDialog = ({
  isOpen,
  onClose,
  handleSave,
  editData,
  setEditData,
  loading,
  formChanged,
  setFormChanged,
  handleSubmit,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [defaultName, setDefaultName] = useState("");
  const [defaultUrl, setDefaultUrl] = useState("");
  const [defaultCity, setDefaultCity] = useState("");
  const [defaultState, setDefaultState] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState({
    company_url: false,
    company_name: false,
    company_city: false,
    company_email: false,
  });

  const [selectedState, setSelectedState] = useState(editData.company_state);
  const [lastKey, setLastKey] = useState("");
  const [keyIndex, setKeyIndex] = useState(0);

  // Handle keyboard navigation
  const handleKeyDown = (event) => {
    const key = event.key.toLowerCase();
    const matchingStates = states.filter((state) =>
      state.toLowerCase().startsWith(key),
    );

    if (matchingStates.length > 0) {
      if (lastKey === key) {
        // If the same key is pressed again, go to the next match
        const nextIndex = (keyIndex + 1) % matchingStates.length;
        const nextState = matchingStates[nextIndex];
        setSelectedState(nextState);
        setKeyIndex(nextIndex);

        // Update the editData with the new selected state
        setEditData((prevEditData) => ({
          ...prevEditData,
          company_state: nextState,
        }));
      } else {
        // If a new key is pressed, reset to the first match
        const nextState = matchingStates[0];
        setSelectedState(nextState);
        setKeyIndex(0);

        // Update the editData with the new selected state
        setEditData((prevEditData) => ({
          ...prevEditData,
          company_state: nextState,
        }));
      }
      setLastKey(key);
    }
  };

  // Handle manual state change from the select dropdown
  const handleStateChange = (e) => {
    const value = e.target.value;

    // Update the editData when state changes manually
    setEditData((prevEditData) => ({
      ...prevEditData,
      company_state: value,
    }));

    // Set error to true if the value is null or an empty string
    setError((prevError) => ({
      ...prevError,
      company_state: value === null || value.trim() === "",
    }));

    // Check if the company state is equal to the default state
    if (value === defaultState) {
      setFormChanged(false);
    } else {
      setFormChanged(true);
    }
  };

  // Effect to ensure formChanged is set correctly based on initial state
  useEffect(() => {
    // Check for each individual condition and update formChanged independently
    if (editData.company_state !== defaultState) {
      setFormChanged(true);
    }
    if (editData.company_url !== defaultUrl) {
      setFormChanged(true);
    }
    if (editData.company_name !== defaultName) {
      setFormChanged(true);
    }
    if (editData.company_city !== defaultCity) {
      setFormChanged(true);
    }
    if (editData.company_email !== defaultEmail) {
      setFormChanged(true);
    }

    // If none of the above conditions were true, set formChanged to false
    if (
      editData.company_state === defaultState &&
      editData.company_url === defaultUrl &&
      editData.company_name === defaultName &&
      editData.company_city === defaultCity &&
      editData.company_email === defaultEmail
    ) {
      setFormChanged(false);
    }
  }, [
    editData.company_state,
    setFormChanged,
    editData.company_url,
    defaultUrl,
    editData.company_name,
    defaultName,
    editData.company_city,
    defaultCity,
    editData.company_email,
    defaultEmail,
  ]);

  useEffect(() => {
    if (isOpen) {
      setDefaultName(editData.company_name);
      setDefaultUrl(editData.company_url);
      setDefaultCity(editData.company_city);
      setDefaultState(editData.company_state);
      setDefaultEmail(editData.company_email);
      setError({
        company_url: false,
        company_name: false,
        company_city: false,
        company_email: false,
      });
      setFormChanged(false);
    }
  }, [isOpen]);

  const handleNameChange = (e) => {
    const value = e.target.value;

    setError((prevError) => ({
      ...prevError,
      company_name: value === null || value.trim() === "",
    }));

    setEditData((prevEditData) => ({
      ...prevEditData,
      company_name: value,
    }));
  };

  const handleCompanyUrlChange = (e) => {
    const value = e.target.value;

    // Regex for validating a URL
    const urlRegex =
      /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z0-9]{2,7}(\/[a-z0-9-]+)*\/?$/i;

    // Check if the value is a valid URL or empty
    setError((prevError) => ({
      ...prevError,
      company_url:
        value === null || value.trim() === "" || !urlRegex.test(value),
    }));

    setEditData((prevEditData) => ({
      ...prevEditData,
      company_url: value,
    }));
  };

  const handleCityChange = (e) => {
    const value = e.target.value;

    // Set error to true if the value is null or an empty string
    setError((prevError) => ({
      ...prevError,
      company_city: value === null || value.trim() === "",
    }));

    setEditData((prevEditData) => ({
      ...prevEditData,
      company_city: value,
    }));
  };

  const handleCompanyEmailChange = (e) => {
    const value = e.target.value;

    // Regex for validating an email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if the value is a valid email or empty
    setError((prevError) => ({
      ...prevError,
      company_email:
        value === null || value.trim() === "" || !emailRegex.test(value),
    }));

    setEditData((prevEditData) => ({
      ...prevEditData,
      company_email: value,
    }));
  };

  const handleSubmitClick = async () => {
    if (
      error.company_name ||
      error.company_url ||
      error.company_city ||
      error.company_email
    ) {
      return;
    } else {
      await handleSubmit();
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "15px" } }}
    >
      <>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>
        <DialogTitle
          sx={{
            padding: 3,
            width: "550px",
            display: "flex",
            alignItems: "center",
            height: "55px",
            backgroundColor: "#FFFFFF",
            pt: 3,
            pb: 2.25,
            color: "#374247",
          }}
        >
          <img
            src={addLocationIcon}
            alt="editUser"
            style={{
              border: "1px solid rgba(173, 216, 230, 0.7)",
              width: "auto",
              height: "30px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "2px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              borderRadius: "6px",
            }}
          />
        </DialogTitle>

        <DialogContent
          sx={{
            padding: 2,
            width: "100%",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "left",
            zIndex: 0,
          }}
        >
          <Typography
            sx={{
              color: "#48768C",
              fontSize: 19,
              mb: 2,
              fontWeight: 600,
              pl: 1.05,
            }}
          >
            Edit Company Details
          </Typography>
          <Box
            sx={{
              borderRadius: "20px",
              border: "1px solid rgba(173, 216, 230, 0.7)",
              py: 2,
              width: "auto",
              borderRadius: "15px",
              mb: 1.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "flex-start",
                width: "100%",
                ml: 1,
                mb: "20px",
              }}
            >
              <img
                src={userDetailsIcon}
                alt="addUserIcon"
                style={{
                  border: "1px solid #B0BEC5",
                  marginLeft: "1px",
                  width: "auto",
                  height: "26px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  padding: "2px",
                  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                  borderRadius: "6px",
                }}
              />
              <Typography
                sx={{
                  ml: 0.5,
                  alignSelf: "flex-end",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#48768C",
                }}
              >
                Company Information
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                mt: 1,
                mb: 0.5,
              }}
            >
              <TextField
                label="Company Name"
                variant="outlined"
                size="small"
                fullWidth
                value={editData.company_name}
                onChange={handleNameChange}
                error={error.company_name}
                inputProps={{ maxLength: 35 }}
                sx={{
                  color: "#000",
                  pb: 0.5,
                  pl: 1,
                  pr: 1,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#2F5C71", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#48768CBA", // Focused border color
                    },
                  },
                  "& input": {
                    width: "100%",
                    textAlign: "left",
                    color: "#2F5C71", // Text color inside the input
                  },
                  "& .MuiInputLabel-root": {
                    color: "#2F5C71", // Default label color
                    textAlign: "center",
                    ml: "8px",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#2F5C71", // Label color when focused
                  },
                }}
              />
            </Box>
            <Stack
              spacing={2}
              direction="row"
              sx={{ width: "100%", padding: 1 }}
            >
              {/* // Left Column */}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  mt: 1,
                }}
              >
                <TextField
                  label="Company Website"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={editData.company_url}
                  onChange={handleCompanyUrlChange}
                  inputProps={{ maxLength: 35 }}
                  error={error.company_url}
                  sx={{
                    color: "#000",
                    pb: 1.5,
                    mr: 1,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#2F5C71", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#48768CBA", // Focused border color
                      },
                    },
                    "& input": {
                      width: "100%",
                      textAlign: "left",
                      color: "#2F5C71", // Text color inside the input
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2F5C71", // Default label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#2F5C71", // Label color when focused
                    },
                  }}
                />

                <TextField
                  label="City"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={editData.company_city}
                  onChange={handleCityChange}
                  error={error.company_city}
                  sx={{
                    color: "#000",
                    pb: 2,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#2F5C71", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#48768CBA", // Focused border color
                      },
                    },
                    "& input": {
                      width: "100%",
                      textAlign: "left",
                      color: "#2F5C71", // Text color inside the input
                    },
                    "& .MuiInputLabel-root": {
                      color: "#2F5C71", // Default label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#2F5C71", // Label color when focused
                    },
                  }}
                />
              </Box>

              {/* // Right Column */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  mt: 1,
                }}
              >
                <TextField
                  label="Company Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={editData.company_email}
                  onChange={handleCompanyEmailChange}
                  inputProps={{ maxLength: 35 }}
                  error={error.company_email}
                  sx={{
                    color: "#000",
                    pb: 1.5,
                    mr: 2,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#2F5C71", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#48768CBA", // Focused border color
                      },
                    },
                    "& input": {
                      width: "100%",
                      textAlign: "left",
                      color: "#2F5C71", // Text color inside the input
                    },

                    "& .MuiInputLabel-root": {
                      color: "#2F5C71", // Default label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#2F5C71", // Label color when focused
                    },
                  }}
                />
                <div onKeyDown={handleKeyDown}>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      pb: 2,
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#2F5C71", // Border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#48768CBA", // Focused border color
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#2F5C71", // Default label color
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#2F5C71", // Label color when focused
                      },
                      "& .MuiSelect-select": {
                        color: "#2F5C71", // Text color inside the select
                      },
                    }}
                  >
                    <InputLabel>State</InputLabel>
                    <Select
                      value={editData.company_state}
                      onChange={handleStateChange}
                      label="State"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            "& .MuiMenuItem-root": {
                              color: "#2F5C71", // Text color
                              "&:hover": {
                                backgroundColor: "#ecf1f3", // Hover background color
                                color: "#2F5C71", // Text color on hover
                                borderRadius: "4px", // Rounded corners
                              },
                              "&.Mui-selected": {
                                backgroundColor: "#d9e2e7", // Selected background color
                                color: "#48768c", // Selected text color
                                borderRadius: "4px", // Rounded corners
                                fontWeight: "bold", // Bold text
                              },
                            },
                          },
                        },
                      }}
                    >
                      {states.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            </Stack>
          </Box>

          <DialogActions
            sx={{
              pt: 4,
              backgroundColor: "#FFFFFF",
              width: "100%",
              mx: "auto",
              my: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CancelButton
              sx={{
                borderRadius: "10px",
                fontSize: "17px",
                mb: 1,
                height: "51px",
                width: "25%",
              }}
              onClick={onClose}
            >
              Cancel
            </CancelButton>
            <PrimaryButton
              sx={{
                width: "80%", // Adjust width as needed
                height: "51px",
                fontSize: "18px",
                mb: 1,
              }}
              onClick={handleSubmitClick}
              variant="contained"
              disabled={
                loading ||
                !formChanged ||
                error.company_name ||
                error.company_url ||
                error.company_city ||
                error.company_email
              }
            >
              {loading ? "Saving..." : "Save Changes"}
            </PrimaryButton>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default EditCompanyDialog;
