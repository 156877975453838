// External component for creating notifications
const NewUserNotification = async (userId, getAccessTokenSilently) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const apiUrl = process.env.REACT_APP_API_URL;

    // Create Notification 1
    await fetch(`${apiUrl}/api/new-user-notification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        title: "Finish Setting Up Your Account",
        description: "Set Your Companies Hours of Operations",
        tag: "New User",
        content: `NanoNotify automatically includes your business's hours of operation with each notification you send to your customers!\n\nMake sure to set your normal hours of operation before you start sending notifications.\n\nWe've already set up some default hours for you, but you can modify them from right here.`,
        userId: userId,
      }),
    });

    // Create Notification 2
    await fetch(`${apiUrl}/api/new-user-notification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        title: "Welcome to NanoNotify!",
        description: "Getting Started",
        tag: "Help",
        content:
          "Check out our getting started section and learn more about how to configure your account.",
        userId: userId,
      }),
    });
  } catch (error) {
    console.error("Error creating notifications for new user:", error);
  }
};

export default NewUserNotification;
