// AffectedAreasForm.js
import { Box, Typography, Select, MenuItem, InputLabel, Stack } from "@mui/material";
import { FormControl } from "@mui/material";
import { styled } from "@mui/system";


const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#E9EDF2",
  marginBottom: 3,
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -1.5px) scale(0.75)",
    paddingLeft: "7px", // Adjust the translateY value as needed
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#48768c", // Set the border color here
    },
    "&:hover fieldset": {
      borderColor: "#6fa3b7", // Set the hover border color here (optional)
    },
    "&.Mui-focused fieldset": {
      borderColor: "#48768c", // Set the focused border color here
    },
  },
}));

const AffectedAreasForm = ({ bugDetails, handleChange }) => {


console.log('BugDetails:', bugDetails)
console.log('Bug Type:', bugDetails.bugType)

    
  return (
    <Box sx={{}}>
        <Stack direction="column" spacing={2} sx={{width:'100%', display:'flex', whiteSpace:'wrap', alignItems:'fit-contain',}} > 
        <Typography
                   

  sx={{
    fontSize: "1.15rem",
    fontWeight: 600,
    pb: "5px",

    width: "100%", // Makes sure it takes up full width
    letterSpacing: "-0.029em", // Adds letter spacing
    color: "#48768c",

  }}
>       
Let us know if you have any issues, and we’ll look into them right away.
      </Typography>
      <Typography 
         color="textSecondary"
      sx={{ fontSize: ".93rem", fontWeight: 600, pl: "2px", pb: "5px", mt: "14px" }}>
       What is the issue your experiencing?
      </Typography>
        </Stack>

      <StyledFormControl sx={{ backgroundColor: "#FFFFFF" }}>
        {!bugDetails.bugType && (
          <InputLabel 
          shrink={false} sx={{ marginTop: "-6px", backgroundColor: "#FFFFFF", border:'none',
            "&.Mui-focused": { 
              color: "#48768c", fontWeight:600, // Change to your desired active color
              border:'none'
            }
          }}
        >
            Choose a category that best describes the issue
          </InputLabel>
        )}
        <Select value={bugDetails.bugType} 
        onChange={handleChange} name="bugType" required size="small" sx={{ backgroundColor: "#FFFFFF", padding: 0,     color: "#48768c",               border:'none'
 
 
 }} MenuProps={{
  PaperProps: {
    sx: {
      maxHeight: '20vh', // Set the desired height for the dropdown
      backgroundColor: "#FFFFFF",
      overflowY: 'scroll', // This ensures the scrollbar is always visible
      scrollbarWidth: 'thin', // Make the scrollbar thinner (optional)
    },
  },
}}
>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }}value="Problems sending notifications">Problems sending notifications</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Certain items are not loading">Certain items are not loading</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Problems signing in">Problems signing in</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Problems with switching location">Problems with switching location</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Unable to update my locations hours">Unable to update my locations hours</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Some things are slow to load">Some things are slow to load</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Fonts and text are unreadable">Fonts and text are unreadable</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Errors in notification contents">Errors in notification contents</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Issues with notification templates not loading">Issues with message templates not loading</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Delays in message delivery to customers">Delays in message delivery to customers</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Difficulties in managing multiple users or locations">Difficulties in managing multiple users or locations</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Failure to save contacts to the address book">Failure to save contacts to the address book</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Errors in sending 'Quick Notify' status to customers">Errors in sending "Quick Notify" status to customers</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Compatibility issues with certain browsers or devices">Compatibility issues with certain browsers or devices</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="App freezes or crashes during heavy usage or specific actions">App freezes or crashes during heavy usage or specific actions</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Incorrect calculation of message dates and times ">Incorrect calculation of message dates and times</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c',                borderBottom: "1.75px solid rgba(173, 216, 230, 0.8)",
 }} value="Other">Something else</MenuItem>
        </Select>
      </StyledFormControl>
    </Box>
  );
};

export default AffectedAreasForm;