import react, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Button,
  Box,
  Modal,
  IconButton,
  Skeleton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "src/context/AuthContext";

const RestartSetupDialog = ({
  open,
  onClose,
  confirmRestart,
  setConfirmRestart,
}) => {
  const {
    setAccessToken,
    login,
    logout: customLogout,
    resetContext,
  } = useAuth();
  const {
    isAuthenticated: isAuth0Authenticated,
    logout: auth0Logout,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    auth0Logout({ returnTo: window.location.origin });
    customLogout();
    resetContext(); // Reset the context to clear user state
  };

  const restartSetup = async () => {
    const userId = user.sub;
    setLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/api/accounts/restart`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ userId }),
      });
      if (!response.ok) {
        throw new Error("Failed to restart account setup");
      }
      const data = await response.json();
      setLoading(false);
      onClose();
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error("Error restarting account setup:", error.message);
      setLoading(false);
    }
  };

  const handleContinueClick = () => {
    if (confirmRestart) {
      setConfirmRestart(false);
    } else {
      setConfirmRestart(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      transitionDuration={{ enter: 300, exit: 300 }}
      PaperProps={{
        style: {
          backgroundColor: "#747C86",
          width: "200px",
          border: "1px solid rgba(173, 216, 230, 0.7)",
          minWidth: "400px",
          borderRadius: "18px",
          bottom: "5%",
          maxWidth: "600px",
        },
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          padding: 3,
          color: "#374247",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            border: "transparent",
            width: "auto",
            height: "44px",
            backgroundColor: "#c6d4dc",
            borderRadius: "6px",
            padding: "1px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            marginBottom: "5px",
          }}
        >
          <GppBadOutlinedIcon
            style={{
              color: "#48768C",
              width: "auto",
              height: "41px",
              justifyContent: "center",
            }}
          />
        </Box>
        {!confirmRestart ? (
          <Typography
            sx={{
              color: "#48768C",
              mb: 0.5,
              fontWeight: 600,
              mt: 1.5,
              fontSize: "18px",
            }}
          >
            Account Setup Not Complete
          </Typography>
        ) : (
          <Typography
            sx={{
              color: "#48768C",
              mb: 0.5,
              fontWeight: 600,
              mt: 1.5,
              fontSize: "18px",
            }}
          >
            Confirm Account Restart
          </Typography>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          mt: -1,
          fontWeight: 500,
          letterSpacing: "0.02em",
          backgroundColor: "#FFFFFF",
          color: "#48768C",
          padding: ".75rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(191, 218, 231, 0.1)",
            border: "1px solid rgba(173, 216, 230, 0.7)",
            borderRadius: "10px",
            fontSize: 15,
            padding: ".75rem",
            pt: 1,
            px: 1.5,
          }}
        >
          {!confirmRestart ? (
            <>
              <Typography
                sx={{
                  fontSize: "15px",
                  mt: 1.5,
                  alignItems: "center",
                  textAlign: "center",
                  width: "auto",
                  fontWeight: 600,
                }}
              >
                Your account isn't quite ready yet.
              </Typography>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14.5px",
                    mt: 1.5,
                    alignItems: "center",
                    textAlign: "center",
                    width: "auto",
                    fontWeight: 500,
                    mb: 3,
                  }}
                >
                  Please complete the setup process to access your account.
                </Typography>
              </Box>
            </>
          ) : (
            <Box>
              <Typography
                sx={{
                  fontSize: "15px",
                  mt: 1.5,
                  alignItems: "center",
                  textAlign: "center",
                  width: "auto",
                  fontWeight: 600,
                }}
              >
                Are you sure you want to restart the setup process?
              </Typography>
              <Typography
                sx={{
                  fontSize: "14.5px",
                  mt: 1.5,
                  alignItems: "center",
                  textAlign: "center",
                  width: "auto",
                  fontWeight: 500,
                  mb: 3,
                }}
              >
                Any progress you've made will be erased.
              </Typography>
            </Box>
          )}

          <Box>
            <></>
          </Box>
          {!confirmRestart ? (
            <Stack
              direction="column"
              spacing={2.5}
              sx={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <Button
                onClick={onClose}
                sx={{
                  display: "flex",
                  border: "1px solid #8C435029",
                  borderRadius: "10px",
                  padding: "8px",
                  color: "#48768C",
                  width: "100%",
                  backgroundColor: "#d9e2e7",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",

                  "&:hover": {
                    backgroundColor: "#ecf1f3",
                  },
                  "&:active": {
                    backgroundColor: "#ecf1f3",
                  },
                }}
                color="primary"
              >
                Continue Setup
              </Button>

              <Button
                onClick={handleContinueClick}
                sx={{
                  marginRight: "10px",
                  border: "1px solid #A4C5D2E8",
                  borderRadius: "10px",
                  padding: "8px",
                  color: "#48768C",
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",

                  "&:hover": { backgroundColor: "#48768C12" },
                  "&:active": { backgroundColor: "#DBEAF0" },
                }}
                color="primary"
              >
                Restart Setup
              </Button>
              <Button
                onClick={handleLogout}
                sx={{
                  marginRight: "5px", // Reduced by 50%
                  border: "1px solid #A4C5D2E8",
                  borderRadius: "9px", // Reduced by 50%
                  padding: "4px", // Reduced by 50%
                  color: "#48768C",
                  width: "50%", // Reduced by 50%
                  backgroundColor: "#f4f6f8",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)", // Adjusted shadow for consistency
                  justifyContent: "center", // Center the text

                  "&:hover": { backgroundColor: "#48768C12" },
                  "&:active": { backgroundColor: "#DBEAF0" },
                }}
                color="primary"
              >
                Sign Out
              </Button>
            </Stack>
          ) : (
            <Stack
              direction="column"
              spacing={2.5}
              sx={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <Button
                onClick={restartSetup}
                sx={{
                  display: "flex",
                  border: "1px solid #8C435029",
                  borderRadius: "10px",
                  padding: "8px",
                  color: "#8C4350",
                  width: "100%",
                  backgroundColor: "#e9d8d9",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",

                  "&:hover": {
                    backgroundColor: "#f4ebec",
                  },
                  "&:active": {
                    backgroundColor: "#ecf1f3",
                  },
                }}
                color="primary"
              >
                {loading ? "Loading..." : "Erase all data and restart"}
              </Button>

              <Button
                onClick={handleContinueClick}
                sx={{
                  marginRight: "10px",
                  border: "1px solid #A4C5D2E8",
                  borderRadius: "10px",
                  padding: "8px",
                  color: "#48768C",
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",

                  "&:hover": { backgroundColor: "#48768C12" },
                  "&:active": { backgroundColor: "#DBEAF0" },
                }}
                color="primary"
              >
                Continue Setup
              </Button>
            </Stack>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RestartSetupDialog;
