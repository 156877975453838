import React from "react";
import { Button, Dialog, DialogContent, DialogActions } from "@mui/material";

function AddAdditionalModal({ open, onClose, item, action }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <h1>Do you want to add another {item}</h1>
      </DialogContent>
      <DialogActions>
        <Button onClick={action}>Yes</Button>
        <Button onClick={onClose}>No</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddAdditionalModal;
