import React from "react";
import { Box, Paper, Typography, Divider, Stack } from "@mui/material";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import { useAuth } from "src/context/AuthContext.js"; // Assuming subUserData is coming from this context
import { useAccountContext } from "src/context/AccountContext.js"; // Assuming accountData is coming from this context

const UpdateLocationCard = ({ updatedName, newUserName, left }) => {
  const { subUserData } = useAuth(); // Assuming subUserData is coming from this context
  const { accountData } = useAccountContext(); // Assuming accountData is coming from this context

  return (
    <Box
      sx={{
        border: "1px solid rgba(191, 218, 231, 0.5)",
        borderRadius: "9px",
        width: "60%",
        height: "24%",
        backgroundColor: "#FFFFFF",
        mt: 1,
        padding: 1.5,
      }}
    >
      <Box
        sx={{
          border: "1.5px solid #BCE4F6",
          borderRadius: "6px",
          width: "83%",
          backgroundColor: "transparent",
          mb: 1.5,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "2px",
            backgroundColor: "transparent",
            color: "#48768C",
            py: "2px",
          }}
        >
          <AddTaskOutlinedIcon
            sx={{
              padding: "2px",
              color: "#7EC08A",
              fontSize: "24px",
              backgroundColor: "transparent",
            }}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#B0B8BC", margin: "0 8px" }}
          />
          <Typography
            sx={{
              fontSize: "13.5px",
              fontWeight: 600,
              color: "#4A6B75",
              letterSpacing: "1%",
              textAlign: "center",
              lineHeight: "-2%",
            }}
          >
            Updated Location
          </Typography>
        </Paper>
      </Box>

      <Box sx={{ mt: 1, height: "auto", display: "flex", mr: 3, mb: 1 }}>
        <AddBusinessRoundedIcon
          sx={{
            color: "#6E8890",
            fontSize: "48px",
            border: "1.5px solid #D5DEE2",
            borderRadius: "6px",
            backgroundColor: "rgba(191, 218, 231, 0.25)",
            padding: "3px",
          }}
        />
        <Box
          sx={{
            ml: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              color: "#4A6B75",
              letterSpacing: "1%",
              textAlign: "left",
              mb: -0.35,
              mt: -0.4,
            }}
          >
            {updatedName}
          </Typography>
          <Stack direction="row" sx={{ mb: -0.5, alignItems: "center" }}>
            <LocalPhoneRoundedIcon
              sx={{ fontSize: "16px", color: "#73787A", mr: 0.35 }}
            />
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 600,
                color: "#868E90",
                letterSpacing: "-0.2",
                textAlign: "left",
              }}
            >
              {subUserData.location_phone}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ mb: -0.19, alignItems: "center" }}>
            <AlternateEmailRoundedIcon
              sx={{ fontSize: "16px", color: "#73787A", mr: 0.45 }}
            />
            <Typography
              sx={{
                fontSize: "13.5px",
                fontWeight: 600,
                color: "#868E90",
                letterSpacing: "-2%",
                textAlign: "left",
              }}
            >
              {subUserData.location_email || subUserData.email || ""}
            </Typography>
          </Stack>
        </Box>
      </Box>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{ borderColor: "#B0B8BC", mx: 0.25, my: 0.75 }}
      />
      <Typography
        sx={{
          fontSize: "15.5px",
          fontWeight: 600,
          color: "#4A6B75",
          letterSpacing: "1%",
          textAlign: "left",
          mb: 0.75,
          mt: 0.55,
        }}
      >
        Employees
      </Typography>

      <Stack direction="column" sx={{ alignItems: "left", display: "flex" }}>
        {!accountData.single_user &&
          Array.isArray(left) &&
          left.map((user, index) => (
            <Stack
              key={user.user_id || index}
              direction="row"
              sx={{
                alignItems: "center",
                display: "flex",
                mr: 2,
                width: "100%", // Take full width of container
              }}
            >
              <Person2RoundedIcon
                sx={{
                  fontSize: "20px",
                  color: "#73787A",
                  mr: 1,
                  backgroundColor: "rgba(191, 218, 231, 0.75)",
                  borderRadius: "50%",
                  padding: "1px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#454D4F",
                  letterSpacing: "1%",
                  textAlign: "left",
                  mr: "5px",
                  minWidth: "40px", // Set a minimum width for consistent alignment
                }}
              >
                {user.first_name || ""} {user.last_name || ""}
              </Typography>
            </Stack>
          ))}

        <Stack direction="row" sx={{ alignItems: "center", display: "flex" }}>
          <Person2RoundedIcon
            sx={{
              fontSize: "20px",
              color: "#73787A",
              mr: 1,
              backgroundColor: "rgba(191, 218, 231, 0.75)",
              borderRadius: "50%",
              padding: "1px",
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: "#454D4F",
              letterSpacing: "1%",
              textAlign: "left",
              mr: "5px",
            }}
          >
            {subUserData.first_name || ""}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: "#454D4F",
              letterSpacing: "1%",
            }}
          >
            {subUserData.last_name || ""}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: "#454D4F",
              letterSpacing: "1%",
              textAlign: "left",
              ml: 6,
            }}
          >
            {newUserName || ""}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default UpdateLocationCard;
