import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/auth/ProtectedRoute";

import DashboardLayout from "./layouts";
import LoginPage from "./pages/LoginPage";
import DashboardAppPage from "./pages/DashboardAppPage";
import CustomersPage from "./pages/CustomersPage";
import Messages from "./pages/Messages";
import Page404 from "./pages/Page404";
import Reports from "./pages/Reports";
import AccountPage from "./pages/AccountPage";
import Signup from "./pages/Signup";
import Profile from "./components/AccountPage/Sections/Profile/Profile";
import AccountManagement from "./components/AccountPage/Sections/AccountManagement/AccountManagement";
import UserNotifications from "./components/AccountPage/Sections/UserNotifications/UserNotifications";
import Notifications from "./components/AccountPage/Sections/InAppNotifications/Notifications";
import Billing from "./components/AccountPage/Sections/Billing";
import ExportData from "./components/AccountPage/Sections/ExportData";
import HelpCenter from "./pages/HelpCenter";
import EditHoursModalWrapper from "./components/modal/EditHoursModalWrapper";

// Import the Elements provider and loadStripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Import the CheckoutForm component
import CheckoutForm from "./components/payments/CheckoutForm";
import PaymentForm from "./components/payments/components/PaymentForm";

const stripeApiKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// Load your Stripe public key
const stripePromise = loadStripe(`${stripeApiKey}`);

export default function Router() {
  return (
    <Routes>
      <Route index element={<Navigate to="dashboard/app" replace />} />
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route
          path="app"
          element={<ProtectedRoute component={DashboardAppPage} />}
        />
        <Route
          path="CustomersPage"
          element={<ProtectedRoute component={CustomersPage} />}
        />
        <Route
          path="Messages"
          element={<ProtectedRoute component={Messages} />}
        />
        <Route
          path="Reports"
          element={<ProtectedRoute component={Reports} />}
        />
        <Route
          path="help-center"
          element={<ProtectedRoute component={HelpCenter} />}
        />
        <Route
          path="Account/*"
          element={<ProtectedRoute component={AccountPage} />}
        >
          <Route index element={<Navigate to="Profile" replace />} />
          <Route
            path="Profile"
            element={<ProtectedRoute component={Profile} />}
          />
          <Route path="AccountManagement" element={<AccountManagement />} />
          <Route path="UserNotifications" element={<UserNotifications />} />
          <Route path="Notifications" element={<Notifications />} />
          <Route path="Billing" element={<Billing />} />
          <Route path="ExportData" element={<ExportData />} />
        </Route>
      </Route>
      {/* Add the route for checkout and wrap the CheckoutForm in the Elements provider */}
      <Route
        path="/checkout"
        element={
          <Elements stripe={stripePromise}>
            <PaymentForm />
          </Elements>
        }
      />
      <Route path="/edit-hours" element={<EditHoursModalWrapper />} />
      <Route path="*" element={<Page404 />} /> {/* 404 route */}
    </Routes>
  );
}
