import React from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TransferList from "./TransferList";

const StepFour = ({
  firstName,
  lastName,
  userName,
  updatePin,
  confirmUpdateNewPin,
  showUpdatePin,
  showConfirmUpdateNewPin,
  isAssignEmployeeVisible,
  isMoveUserVisible,
  setFirstName,
  setLastName,
  setUserName,
  setUpdatePin,
  handleConfirmNewUpdatePinChange,
  handleToggleUpdatePinVisibility,
  toggleShowConfirmNewUpdatePin,
  setIsAssignEmployeeVisible,
  subUserData,
  isUpdateUserButtonEnabled,
  handleUpdateUserClick,
  isUserUpdated,
  newUserNameTaken,
  setNewUserName,
  newUserName,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F2F9FF",
        border: "1.25px solid rgba(173, 216, 230, 0.5)",
        borderRadius: "14px",
        padding: 2,
        mt: 2,
      }}
    >
      {" "}
      <Typography sx={{ color: "#48768C", mb: 1.5, fontWeight: 600 }}>
        Create a username and pin for your account
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          pr: 3,
          ml: 1,
          mt: 1,
        }}
      >
        <TextField
          placeholder="First Name*"
          variant="outlined"
          size="small"
          fullWidth
          defaultValue={subUserData?.first_name}
          disabled
          onChange={(e) => {
            const newValue = e.target.value
              .replace(/[^A-Za-z]/g, "")
              .slice(0, 10);
            setFirstName(newValue);
          }}
          sx={{
            mr: 2,
            color: "#48768C",
            "& input": {
              height: "23.5px",
              textAlign: "left",
              color: "#48768C",
            },
          }}
        />
        <TextField
          placeholder="Last Name*"
          variant="outlined"
          size="small"
          fullWidth
          defaultValue={subUserData?.last_name}
          disabled
          onChange={(e) => {
            const newValue = e.target.value
              .replace(/[^A-Za-z]/g, "")
              .slice(0, 10);
            setLastName(newValue);
          }}
          sx={{
            color: "#48768C",
            "& input": {
              height: "23.5px",
              textAlign: "left",
              color: "#48768C",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "97%",
          ml: 1,
          mb: 2,
          mt: 1,
        }}
      >
        <TextField
          error={newUserNameTaken}
          helperText={newUserNameTaken && "Username Taken"}
          placeholder="Username*"
          size="small"
          variant="outlined"
          fullWidth
          value={newUserName}
          onChange={(e) => {
            const newValue = e.target.value
              .replace(/[^A-Za-z]/g, "")
              .slice(0, 10);
            setNewUserName(newValue);
          }}
          inputProps={{
            maxLength: 15, // Limit to 15 characters
            pattern: "[A-Za-z0-9]*", // Allow letters and numbers
          }}
          sx={{
            color: "#48768C",
            "& input": {
              height: "23.5px",
              textAlign: "left",
              color: "#48768C",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          width: "97%",
          ml: 1,
          mt: 1,
          mb: 0,
        }}
      >
        <TextField
          placeholder="Enter PIN*"
          variant="outlined"
          size="small"
          type={showUpdatePin ? "text" : "password"}
          value={updatePin}
          onChange={(e) => {
            let inputValue = e.target.value.replace(/\D/g, "").slice(0, 4);
            setUpdatePin(inputValue);
          }}
          sx={{
            color: "#48768C",
            "& input": {
              height: "23.5px",
              textAlign: "left",
              color: "#48768C",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleToggleUpdatePinVisibility}
                  edge="end"
                >
                  {showUpdatePin ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ width: "25px" }} />
        <TextField
          placeholder="Confirm PIN*"
          variant="outlined"
          size="small"
          type={showConfirmUpdateNewPin ? "text" : "password"}
          value={confirmUpdateNewPin}
          onChange={handleConfirmNewUpdatePinChange}
          sx={{
            color: "#48768C",
            "& input": {
              height: "23.49px",
              textAlign: "left",
              color: "#48768C",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowConfirmNewUpdatePin} edge="end">
                  {showConfirmUpdateNewPin ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default StepFour;
