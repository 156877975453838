import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Paper,
  IconButton,
  TextField,
  Typography,
  Stack,
  Popover,
  Icon,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import LightTooltip from "@mui/material/Tooltip"; // Make sure you have this imported
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";
import { useAccountContext } from "src/context/AccountContext";
import { useStateManager } from "../hooks/useStateManager";
import IconGrid from "../../IconGrid.js";
import icons from "../../../../../../../public/assets/icons/messageModal";
import IconPopover from "./IconPopover";
import { customTheme } from "./textField";
import FieldSelectorPopover from "./FieldSelectorPopover";
import { RenderSelectedField } from "./RenderSelectedField";
import "./styles.css";

const AddField = ({
  handleKeyDown,
  fieldName,
  handleSetFieldName,
  selectedIcon,
  handleSelectedIcon,
  handleClearFieldName,
  handleSaveFieldData,
  selectedNotificationType,
  handleSelectFieldType,
  setSelectedNotificationType,
  handleClearFieldData,
  fieldDataSaved,
  fieldNamePlaceholder,
  colors,
  enableClearButton,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isEditingIcon, setIsEditingIcon] = useState(false);
  const [newFieldData, setNewFieldData] = useState({ fieldName: "" });
  const [isAddedNewField, setIsAddedNewField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [newFieldAnchorEl, setNewFieldAnchorEl] = useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const [fieldAnchorEl, setFieldAnchorEl] = useState(null);
  const [isEditingFieldName, setIsEditingFieldName] = useState(false);
  const [isEditingContextLabel, setIsEditingContextLabel] = useState(false);
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const { accountData } = useAccountContext();
  const [iconOpen, setIconOpen] = useState(false);
  const [fieldOpen, setFieldOpen] = useState(false);
  const [fieldButtonEnabled, setFieldButtonEnabled] = useState(false);

  useEffect(() => {
    const enableFieldButtons = fieldName !== "" && selectedIcon !== null;
    setFieldButtonEnabled(enableFieldButtons);
  }, [fieldName, selectedIcon]);

  const handleIconPopoverOpen = (event) => {
    setFieldAnchorEl(event.currentTarget); // Set the clicked element as the anchor
    setIconOpen(true); // Open the popover
  };

  // Handle closing of popover
  const handleIconPopoverClose = () => {
    setFieldAnchorEl(null);
    setIconOpen(false);
    setIsEditingIcon(false);
  };

  const handleFieldPopoverOpen = (event) => {
    setFieldAnchorEl(event.currentTarget); // Set the clicked element as the anchor
    setFieldOpen(true); // Open the popover
  };

  // Handle closing of popover
  const handleFieldPopoverClose = () => {
    setFieldAnchorEl(null);
    setFieldOpen(false);
  };

  const renderIcon = (iconName) => {
    if (iconName) {
      const iconPath = icons[iconName];
      return (
        <img src={iconPath} alt={iconName} className="default_field_icon" />
      );
    }
    return null;
  };

  return (
    <>
      {!fieldDataSaved && (
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "5px",
            minWidth: "65%",
            maxWidth: "65%",
            borderRadius: "8px",
            height: "40px",
            backgroundColor: "#F2F9FF",
            border: `1px solid ${colors.borderColor}`,
          }}
        >
          {!selectedIcon && (
            <AddIcon
              onClick={(event) => {
                handleIconPopoverOpen(event);
                setIsEditingIcon(true);
              }}
              className="new_field_icon"
              sx={{
                color: "#7589A2",
                fontSize: "11px",
                backgroundColor: "#FFFFFF",
                ml: 0.55,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                transition:
                  "background-color 0.3s, color 0.3s, box-shadow 0.3s",
                "&:hover": {
                  backgroundColor: "#F5FAFE",
                  cursor: "pointer",
                  color: "#526071",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
            />
          )}

          {/* Selected Icon */}
          {selectedIcon && (
            <Box
              sx={{ display: "flex", ml: 0.55 }}
              onClick={(event) => {
                handleIconPopoverOpen(event);
                setIsEditingIcon(true);
              }}
            >
              {renderIcon(selectedIcon)}
            </Box>
          )}

          {/* Import and use IconPopover */}
          <IconPopover
            fieldOpen={iconOpen}
            fieldAnchorEl={fieldAnchorEl}
            handleIconPopoverClose={handleIconPopoverClose}
            isEditingIcon={isEditingIcon}
            handleSelectedIcon={handleSelectedIcon}
          />

          {/* TextField for New Field Name */}
          <ThemeProvider theme={customTheme}>
            <TextField
              hiddenLabel
              placeholder={fieldNamePlaceholder}
              variant="filled"
              size="small"
              name="fieldName"
              value={fieldName || ""}
              onChange={(e) => {
                const value = e.target.value;
                handleSetFieldName(
                  value.charAt(0).toUpperCase() + value.slice(1),
                );
              }}
              onKeyDown={handleKeyDown}
              sx={{
                alignContent: "center",
                ml: 1,
                width: 411,
                mr: 0.5,
                mt: "3px",
                input: {
                  color: "#48768C",
                  fontWeight: 600,
                  fontSize: "13.5px",
                  padding: 0,
                  alignItems: "center",
                  backgroundColor: "#F2F9FF",
                  fontFamily: "Source Sans Pro, sans-serif",
                },
              }}
            />

            {/* Action Buttons */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "auto",
                justifyContent: "flex-end",
              }}
            >
              <LightTooltip
                title="Select an icon and enter a field name to continue"
                disableHoverListener={fieldButtonEnabled}
              >
                <span>
                  <IconButton
                    disabled={!fieldButtonEnabled}
                    onClick={handleSaveFieldData}
                    className="field_save_button"
                  >
                    <CheckCircleTwoToneIcon
                      sx={{
                        ml: 0.5,
                        fontSize: "21px",
                        border: "1px solid #747C86",
                        borderRadius: "5px",
                        color: !fieldButtonEnabled ? "#BEBEBE" : "#48B09B",
                      }}
                    />
                  </IconButton>
                </span>
              </LightTooltip>
              <IconButton
                disabled={!fieldButtonEnabled && !enableClearButton}
                className="icon_cancel_button"
                onClick={handleClearFieldName}
              >
                <HighlightOffTwoToneIcon
                  sx={{
                    fontSize: "21px",
                    border: "1px solid #747C86",
                    borderRadius: "5px",
                  }}
                />
              </IconButton>
            </Stack>
          </ThemeProvider>
        </Box>
      )}

      {/* Added Field Display */}
      {fieldDataSaved && (
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "5px",
            minWidth: "65%",
            maxWidth: "65%",
            borderRadius: "8px",
            height: "40px",
            backgroundColor: "#FFFFFF",
            border: `1px solid ${colors && colors.borderColor}`,
          }}
        >
          <Box sx={{ display: "flex", ml: 0.55 }}>
            {renderIcon(selectedIcon)}
          </Box>

          <Typography sx={{ mr: 2 }} className="field_title">
            {fieldName}:
          </Typography>

          {RenderSelectedField(selectedNotificationType || "")}

          {/* Action Buttons for Saving and Editing */}
          <Box
            sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }}
          >
            {!selectedNotificationType && (
              <Typography
                onClick={(event) => handleFieldPopoverOpen(event)}
                sx={{
                  border: "1.5px solid #48768C",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  fontSize: "14px",
                  borderRadius: "8px",
                  fontWeight: 700,
                  flexShrink: 0,
                  cursor: "pointer",
                  color: "#48768C",
                  mr: 2,
                  transition: "box-shadow 0.3s, background-color 0.3s",
                  "&:hover": {
                    backgroundColor: "#E6EDF2", // Change background color on hover
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Add box shadow on hover
                  },
                }}
              >
                Select a field type
              </Typography>
            )}
            <Stack direction="row">
              {selectedNotificationType && (
                <IconButton onClick={(event) => handleFieldPopoverOpen(event)}>
                  <SettingsTwoToneIcon className="popover_edit_button" />
                </IconButton>
              )}
            </Stack>

            {/* Cancel Button */}
            <IconButton
              className="icon_cancel_button"
              onClick={handleClearFieldData}
            >
              <HighlightOffTwoToneIcon
                sx={{
                  fontSize: "21px",
                  border: "1px solid #747C86",
                  borderRadius: "5px",
                }}
              />
            </IconButton>
          </Box>
          <FieldSelectorPopover
            fieldOpen={fieldOpen}
            fieldAnchorEl={fieldAnchorEl}
            handleFieldPopoverClose={handleFieldPopoverClose}
            handleSelectFieldType={handleSelectFieldType}
            closePopover={handleFieldPopoverClose}
            onSelectField={handleSelectFieldType}
            selectedNotificationType={selectedNotificationType}
            setSelectedNotificationType={setSelectedNotificationType}
            colors={colors}
          />
        </Box>
      )}
    </>
  );
};

export default AddField;
