import { useEffect, useState } from "react";

const UserLocationCheck = ({
  accountData,
  totalUsers,
  totalLocations,
  onWarningsChange,
  onAlertUpdate,
}) => {
  useEffect(() => {
    const checkWarnings = () => {
      if (
        accountData?.single_user === false ||
        accountData?.single_location === false
      ) {
        const addUserLocationWarning =
          accountData.single_user === false &&
          accountData.single_location === false &&
          totalUsers === 1 &&
          totalLocations === 1;

        if (addUserLocationWarning) {
          onWarningsChange({
            shouldAddUser: false,
            shouldAddLocation: false,
            shouldAddUserLocation: true,
          });
          onAlertUpdate({
            title: "Add Another User & Location to Your Account",
            alertText:
              "Your monthly plan includes two users & locations at no extra cost!",
          });
          return;
        }

        const addUserWarning =
          accountData.single_user === false && totalUsers === 1;
        const addLocationWarning =
          accountData.single_location === false && totalLocations === 1;

        if (addUserWarning) {
          onWarningsChange({
            shouldAddUser: true,
            shouldAddLocation: false,
            shouldAddUserLocation: false,
          });
          onAlertUpdate({
            title: "Add Another User to Your Account",
            alertText: "Your monthly plan includes two users at no extra cost!",
          });
        }

        if (addLocationWarning) {
          onWarningsChange({
            shouldAddUser: false,
            shouldAddLocation: true,
            shouldAddUserLocation: false,
          });
          onAlertUpdate({
            title: "Add Another Location to Your Account",
            alertText:
              "Your monthly plan includes two locations at no extra cost!",
          });
        }
      }
    };

    checkWarnings();
  }, [
    accountData,
    totalUsers,
    totalLocations,
    onWarningsChange,
    onAlertUpdate,
  ]);

  return null; // This component doesn't render anything
};

export default UserLocationCheck;
