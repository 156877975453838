import React, { useEffect } from "react";
import {
  Card,
  Grid,
  Typography,
  Button,
  Stack,
  IconButton,
  Box,
  CardMedia,
} from "@mui/material";
import NewSubscription from "src/components/payments/components/NewSubscription.js";
import EditSubscription from "src/components/payments/components/EditSubscription";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useCancelSubscription from "src/components/payments/hooks/useCancelSubscription.js";
import { useSubscriptionDetails } from "src/components/payments/routes/useSubscriptionDetails";
import { useValidateSubscription } from "src/components/payments/routes/validateSubscription";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import VisaCard from "src/public/assets/icons/icons/visaCard.png";
import MasterCard from "src/public/assets/icons/icons/masterCard.png";
import CreditCard from "src/public/assets/icons/icons/creditCard.png";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
);

const BillingDataCard = ({
  planDetails,
  name,
  status,
  cost,
  reoccurrence,
  paymentMethod,
  subscriptionId,
  customerId,
  priceId,
  onSubscriptionUpdate,
  subscriptionValid,
  stripePromise,
  subscriptionDetails,
  description,
  title,
  refetchSubscriptionDetails,
  billingAddress,
  subUserData,
  billingHistory,
  billingContact,
  fetchBillingHistory,
  checked,
  paymentStatus,
  failedPaymentError,
  handleSubscriptionUpdate,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showNewSubscription, setShowNewSubscription] = React.useState(false);
  const [showEditSubscription, setShowEditSubscription] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleShowNewSubscription = () => {
    setShowNewSubscription(true);
  };

  const handleNewSubscriptionClose = () => {
    setShowNewSubscription(false);
  };

  const handleShowEditSubscription = () => {
    setShowEditSubscription(true);
  };

  const handleEditSubscriptionClose = () => {
    setShowEditSubscription(false);
  };

  const { isCancelling, cancelError, cancelSuccess, cancelSubscription } =
    useCancelSubscription();

  const handleCancelClick = () => {
    if (customerId && subscriptionId) {
      cancelSubscription(customerId, subscriptionId, onSubscriptionUpdate);
    }
  };

  useEffect(() => {
    if (!subscriptionDetails) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [name, status, planDetails, subscriptionDetails]);

  const renderLogo = (brand) => {
    if (!brand) return CreditCard; // Handle null or undefined early
    switch (brand.toLowerCase()) {
      case "visa":
        return VisaCard;
      case "mastercard":
        return MasterCard;
      default:
        return CreditCard;
    }
  };

  return (
    <>
      {!isLoading ? (
        <Box
          sx={{
            display: "flex",
            gap: 2, // Spacing between cards
            width: "100%", // Ensures full-width layout
            justifyContent: "center", // Centers the cards
          }}
        >
          <Card
            sx={{
              width: "fit-content",
              padding: 0.5,
              backgroundColor: "rgba(191, 218, 231, 0.1)",
              border: "1px solid rgba(173, 216, 230, 0.7)",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              whiteSpace: "nowrap", // Prevents wrapping for horizontal scroll
              flexGrow: 1,
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
              }}
            >
              <Typography
                color="textSecondary"
                sx={{
                  ml: 1.25,
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  letterSpacing: "-0.02em",
                  fontSize: "18px",
                  textTransform: "none",
                  mt: 1.75,
                }}
              >
                {title}
              </Typography>
            </Stack>

            <>
              {paymentStatus !== "failed" ? (
                <Typography
                  sx={{
                    ml: 1.25,
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    letterSpacing: "0.02em",
                    fontSize: "14px",
                    textTransform: "none",
                    mt: "-1px",
                    color: "#48768C",
                  }}
                >
                  {description}
                </Typography>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    ml: "10px",
                    mt: "3px",
                    mb: -1,
                  }}
                >
                  <Typography
                    sx={{
                      pr: 2,
                      fontWeight: 600,
                      letterSpacing: "0.02em",
                      fontSize: "13.75px",
                      color: "#48768C",
                      color: "#A65160",
                      whiteSpace: "wrap",
                    }}
                  >
                    {failedPaymentError}
                  </Typography>
                </Box>
              )}
            </>

            <Box sx={{ padding: 1 }}>
              <Box
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  display: "flex-start",
                  mt: 1.5,
                  width: "100%",
                  border: "1px solid rgba(173, 216, 230, 0.7)",
                  py: 1.5,
                  borderRadius: "6px",
                }}
              >
                {subscriptionDetails?.card && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end", // Align items to the bottom
                        width: "100%",
                        ml: 0.75,
                        alignItems: "center",
                      }}
                    >
                      <CardMedia
                        sx={{
                          border:
                            renderLogo(subscriptionDetails?.card?.brand).src ===
                            CreditCard
                              ? "1.95px solid rgba(173, 216, 230, 0.9)"
                              : "1.5px solid rgba(173, 216, 230, 0.9)",
                          width:
                            renderLogo(subscriptionDetails?.card?.brand).src ===
                            CreditCard
                              ? "70px"
                              : "65px",
                          height:
                            renderLogo(subscriptionDetails?.card?.brand).src ===
                            CreditCard
                              ? "70px"
                              : "40px",
                          borderRadius: "5px",
                          ml: 0.5,
                          backgroundColor:
                            renderLogo(subscriptionDetails?.card?.brand).src ===
                            CreditCard
                              ? "#FFFFFF"
                              : "transparent",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexShrink: 0,
                          objectFit: "contain",
                          mr: 0.75,
                        }}
                      >
                        <img
                          src={renderLogo(
                            subscriptionDetails?.card?.brand ?? CreditCard,
                          )}
                          alt="card"
                          style={{
                            width:
                              renderLogo(subscriptionDetails?.card?.brand)
                                .src === CreditCard
                                ? "70px"
                                : "45px",
                            height:
                              renderLogo(subscriptionDetails?.card?.brand)
                                .src === CreditCard
                                ? "70px"
                                : "auto",
                            flexShrink: 0,
                            objectFit: "contain",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexShrink: 0,
                            objectFit: "contain",
                          }}
                        />
                      </CardMedia>

                      <Stack direction="column" sx={{ mb: "2px" }}>
                        <Typography
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            color: "#48768C",
                            fontWeight: 600,
                            letterSpacing: "-0.02em",
                            fontSize: "1rem",
                            textTransform: "none",
                            lineHeight: "-1.5em",
                            mb: -0.75,
                          }}
                        >
                          {subscriptionDetails?.card?.last4
                            ? `${subscriptionDetails.card.brand ? `${subscriptionDetails.card.brand.charAt(0).toUpperCase()}${subscriptionDetails.card.brand.slice(1)}` : ""} Ending in ${subscriptionDetails.card.last4}`
                            : "No credit card on file"}
                        </Typography>

                        <Typography
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            color: "#48768CA8",
                            fontWeight: 600,
                            letterSpacing: "-0.015em",
                            fontSize: ".85rem",
                            textTransform: "none",
                            lineHeight: "-1.5em",
                          }}
                        >
                          Expires:{" "}
                          {`${subscriptionDetails.card.exp_month}/${subscriptionDetails.card.exp_year}`}
                        </Typography>
                      </Stack>

                      <Box
                        sx={{
                          minHeight: "24px", // Slightly larger height
                          minWidth: "58px", // Ensures some width padding
                          maxWidth: "58px",
                          display: subscriptionDetails?.card?.last4
                            ? "flex"
                            : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          px: "12px", // Adds more padding for spacing
                          border: ".75px solid #48768C",
                          borderRadius: "50px",
                          whiteSpace: "nowrap",
                          ml: 2,
                          mb: 2,
                        }}
                      >
                        <Typography
                          align="center"
                          sx={{
                            color: "#48768C",
                            fontWeight: 600,
                            letterSpacing: "-0.01em",
                            fontSize: "12.25px",
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          Default
                        </Typography>
                      </Box>
                      <Box sx={{ ml: "auto" }}>
                        <Button
                          onClick={
                            subscriptionDetails?.card
                              ? handleShowEditSubscription
                              : handleShowNewSubscription
                          }
                          sx={{
                            display: "flex",
                            ml: 5,
                            justifyContent: "center",
                            border: "1px solid #A4C5D2E8",
                            borderRadius: "6px",
                            padding: "1px 0", // Consistent padding
                            color: "#48768C",
                            backgroundColor: "#FFFFFF",
                            fontSize: 13.76,
                            height: "35px", // Fixed height
                            mr: 2,
                            px: 1,
                            width: "fit-content", // Adjust width to content
                            minWidth: "75px", // Ensure a reasonable minimum width
                            whiteSpace: "nowrap", // Prevent text wrapping
                            overflow: "hidden", // Hide overflow content
                            textOverflow: "ellipsis", // Add ellipsis for overflowing text
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            "&:hover": {
                              backgroundColor: "#DBEAF0",
                            },
                            "&:active": {
                              backgroundColor: "#DBEAF0",
                            },
                          }}
                        >
                          {subscriptionDetails?.card ? "Edit" : "Add New Card"}
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            <Elements stripe={stripePromise}>
              <NewSubscription
                open={showNewSubscription}
                onClose={handleNewSubscriptionClose}
                customerId={customerId}
                priceId={priceId}
                onSubscriptionUpdate={onSubscriptionUpdate}
                planDetails={planDetails}
                stripePromise={stripePromise}
                subscriptionId={subscriptionId}
                billingAddress={billingAddress}
              />
              <EditSubscription
                open={showEditSubscription}
                onClose={handleEditSubscriptionClose}
                customerId={customerId}
                priceId={priceId}
                onSubscriptionUpdate={onSubscriptionUpdate}
                planDetails={planDetails}
                stripePromise={stripePromise}
                subscriptionId={subscriptionId}
                refetchSubscriptionDetails={refetchSubscriptionDetails}
                billingAddress={billingAddress}
                subUserData={subUserData}
                billingHistory={billingHistory}
                billingContact={billingContact}
                fetchBillingHistory={fetchBillingHistory}
                handleSubscriptionUpdate={handleSubscriptionUpdate}
              />
            </Elements>
            <Button
              onClick={handleShowNewSubscription}
              sx={{
                display: "none",
                border: "1px solid #48768C",
                borderRadius: "10px",
                padding: "8px",
                color: "#48768C",
                backgroundColor: "#FFFFFF",
                ml: 0.25,
                mt: 2,
                mb: 1,
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "#DBEAF0",
                },
                "&:active": {
                  backgroundColor: "#DBEAF0",
                },
              }}
              color="primary"
            >
              Activate Your Subscription
            </Button>
            <Button
              onClick={handleCancelClick}
              disabled={isCancelling}
              sx={{
                display: "none",
                border: "1px solid #48768C",
                borderRadius: "10px",
                padding: "8px",
                color: "#48768C",
                backgroundColor: "#FFFFFF",
                ml: 0.25,
                mt: 2,
                mb: 1,
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "#DBEAF0",
                },
                "&:active": {
                  backgroundColor: "#DBEAF0",
                },
              }}
              color="primary"
            >
              {isCancelling ? "Cancelling..." : "Cancel Subscription"}
            </Button>
          </Card>
        </Box>
      ) : (
        <Card
          sx={{
            width: "100%",
            padding: 1.5,
            backgroundColor: "rgba(191, 218, 231, 0.1)",
            border: "1px solid rgba(173, 216, 230, 0.7)",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "#48768CBF",
              mr: "5px",
              whiteSpace: "nowrap",
              fontWeight: 500,
              letterSpacing: "-0.03em",
              fontSize: "18px",
              textTransform: "none",
              justifyContent: "center",
              alignItems: "center",
              fontStyle: "italic",
            }}
          >
            Loading billing information...
          </Typography>
        </Card>
      )}
    </>
  );
};

export default BillingDataCard;
