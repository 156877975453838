import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Modal,
  Grid,
  Typography,
  Fade,
  Stack,
  Skeleton,
  CardMedia,
} from "@mui/material";
import moment from "moment-timezone"; // Import moment-timezone
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import isUrl from "validator/lib/isURL"; // Import the isURL function from the validator library
import companyImage from "src/public/assets/companyImage.png";
import useCreateCustomer from "src/components/payments/hooks/useCreateCustomer";
import RestartSetupDialog from "./RestartSetupDialog";

import { getTime } from "date-fns";

const theme = createTheme({
  palette: {
    primary: {
      main: "#48768C", // Set the main color to white
    },
  },
});

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const NewAccountModal = ({ open, onClose, onSave }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [formData, setFormData] = useState({
    company_name: "",
    company_address: "",
    company_email: "",
    company_city: "",
    company_state: "",
    zip_code: "",
    company_website: "",
  });

  const [timeZone, setTimeZone] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true); // Added state for email validation
  const [isEmailFieldDirty, setIsEmailFieldDirty] = useState(false); // Added state to track if email field is dirty
  const [isWebsiteValid, setIsWebsiteValid] = useState(true); // Added state for website validation
  const [loading, setLoading] = useState(false); // State to track loading state

  const [isWebsiteFieldDirty, setIsWebsiteFieldDirty] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { createCustomer } = useCreateCustomer();

  const [showRestartDialog, setShowRestartDialog] = useState(false);
  const [confirmRestart, setConfirmRestart] = useState(false);

  const handleBackdropClick = (event) => {
    // Check if the click is on the backdrop by comparing target and currentTarget
    if (event.target === event.currentTarget) {
      setConfirmRestart(false);
      setShowRestartDialog(true); // Open RestartSetupDialog
    }
  };

  const handleCloseRestartDialog = () => {
    setShowRestartDialog(false); // Close RestartSetupDialog
  };

  const handleSave = async () => {
    setLoading(true); // Start loading state
    try {
      const accessToken = await getAccessTokenSilently(); // Get access token silently
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setTimeZone(browserTimeZone);

      const timestamp = moment().tz(browserTimeZone).format("X");

      // Now send the customerId along with other form data
      const response = await fetch(`${apiUrl}/api/accounts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          ...formData,
          auth0_user: user.sub,
          company_email: formData.company_email,
          company_name: formData.company_name,
          company_city: formData.company_city,
          company_state: formData.company_state,
          company_website: formData.company_website,
          time_zone: browserTimeZone,
          user: user,
          address: formData.company_address,
          firstName: user.firstName,
          lastName: user.lastName,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save account");
      }

      onSave();
      setFormData({
        company_name: "",
        company_email: "",
        company_city: "",
        company_state: "",
        company_website: "",
      });
      onClose();
    } catch (error) {
      console.error("Error saving account:", error);
    } finally {
      setLoading(false); // Set loading state to false when saving is complete
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData({ ...formData, company_email: emailValue });
    setIsEmailValid(validateEmail(emailValue));
    setIsEmailFieldDirty(true); // Set email field as dirty when text is entered
  };

  const handleWebsiteChange = (e) => {
    const websiteValue = e.target.value;
    setFormData({ ...formData, company_website: websiteValue });
    setIsWebsiteValid(isUrl(websiteValue)); // Validate the website using isURL from validator
    setIsWebsiteFieldDirty(true); // Set website field as dirty when text is entered
  };

  useEffect(() => {
    // Check the validity of the form and update the state
    const isValid =
      formData.company_name &&
      formData.company_email &&
      formData.company_city &&
      formData.company_state &&
      formData.company_website &&
      formData.zip_code &&
      formData.company_address &&
      validateEmail(formData.company_email) &&
      isUrl(formData.company_website);

    setIsFormValid(isValid);
  }, [formData]);

  // List of all US states
  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const handleKeyDown = (event) => {
    const firstLetter = event.key.toLowerCase();
    const optionIndex = usStates.findIndex((state) =>
      state.toLowerCase().startsWith(firstLetter),
    );

    if (optionIndex !== -1) {
      setFormData({ ...formData, company_state: usStates[optionIndex] });
    }
  };

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = companyImage;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);

  return (
    <Modal
      open={open}
      onClose={null}
      slotProps={{
        backdrop: {
          onClick: handleBackdropClick, // Disable closing the modal by clicking on the backdrop
        },
      }}
      disableEscapeKeyDown // Disable closing the modal by pressing the escape key
    >
      <Fade in={open} timeout={600}>
        <Box
          sx={{
            outline: "none", // Remove the default focus outline
            alignContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            padding: 2,

            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Added box shadow
            borderRadius: "60px",
            fontSize: 17,
            fontWeight: 600,
            backgroundColor: "#FFFFFF",
            color: "#48768C",
          }}
        >
          <Typography
            sx={{
              mt: 3,
              fontSize: 22,
              textAlign: "center",
              mb: 1,
              fontWeight: 600,
            }}
          >
            Tell Us A Little About Your Company
          </Typography>
          <Box>
            {!imageLoaded && (
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "auto",
                  maxWidth: "50%",
                  maxHeight: "50%",
                  objectFit: "contain",
                  borderRadius: "10px",
                  backgroundColor: "#EEF6F9", // Adjust as needed to match background
                  transition: "opacity 0.3s ease-in-out",
                  opacity: imageLoaded ? 0 : 1,
                }}
              />
            )}
            <CardMedia
              component="img"
              image={companyImage}
              sx={{
                display: "block",
                margin: "auto",
                marginBottom: "20px",
                maxWidth: "50%",
                maxHeight: "50%",
                objectFit: "contain",
                transition: "opacity 0.3s ease-in-out",
                opacity: imageLoaded ? 1 : 0,
              }}
              onLoad={() => setImageLoaded(true)}
            />

            <Typography
              sx={{
                color: "#48768C",
                fontSize: 15,
                fontWeight: 600,
                textAlign: "center",
                mb: 7,
              }}
            >
              Important stuff like your companies website, email and your
              locations phone number are automatically included in your
              notifications for your customers to see.
            </Typography>

            <ThemeProvider theme={theme}>
              <TextField
                label="Company Name"
                variant="outlined"
                size="small"
                value={formData.company_name}
                onChange={(e) =>
                  setFormData({ ...formData, company_name: e.target.value })
                }
                fullWidth
                InputProps={{
                  style: { color: "#48768C", borderRadius: "8px" },
                }}
                InputLabelProps={{ style: { color: "#48768C" } }}
                sx={{
                  mt: -4,
                  mb: 2,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#48768C",
                  },
                  color: "#48768C",
                }}
              />
              <TextField
                label="Street Address"
                variant="outlined"
                size="small"
                value={formData.company_address}
                onChange={(e) =>
                  setFormData({ ...formData, company_address: e.target.value })
                }
                fullWidth
                InputProps={{
                  style: { color: "#48768C", borderRadius: "8px" },
                }}
                InputLabelProps={{ style: { color: "#48768C" } }}
                sx={{
                  mt: 0,
                  mb: 2,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#48768C",
                  },
                  color: "#48768C",
                }}
              />
            </ThemeProvider>

            <Grid container spacing={2}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%", mt: 2, mr: 0.15, ml: 2 }} // Ensure it spans the available width
              >
                <ThemeProvider theme={theme}>
                  <TextField
                    label="City"
                    variant="outlined"
                    size="small"
                    value={formData.company_city}
                    onChange={(e) =>
                      setFormData({ ...formData, company_city: e.target.value })
                    }
                    InputProps={{
                      style: { color: "#48768C", borderRadius: "8px" },
                    }}
                    InputLabelProps={{ style: { color: "#48768C" } }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#48768C",
                      },
                      color: "#48768C",
                      width: "39%", // Set width to adjust on the line
                    }}
                  />
                </ThemeProvider>

                <ThemeProvider theme={theme}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{
                      color: "#48768C",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#48768C",
                      },
                      width: "36%",
                      mr: 2, // Adjust width for consistency
                    }}
                  >
                    <InputLabel
                      sx={{
                        color: "#48768C",
                        mt: -0.35,
                        transform: formData.company_state
                          ? "translate(154px, 4px) scale(0.75)"
                          : "translate(14px, 12px) scale(1)",
                        display: formData.company_state ? "none" : "block",
                      }}
                    >
                      State
                    </InputLabel>
                    <Select
                      value={formData.company_state}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          company_state: e.target.value,
                        })
                      }
                      sx={{
                        color: "#48768C",
                        borderRadius: "8px",
                        width: "100%", // Ensure full width within its container
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    >
                      {usStates.map((state) => (
                        <MenuItem
                          key={state}
                          value={state}
                          sx={{
                            backgroundColor: "#FFFFFF",
                            color: "#48768C",
                            borderRadius: "8px",
                          }}
                        >
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ThemeProvider>

                <ThemeProvider theme={theme}>
                  <TextField
                    label="Zip Code"
                    variant="outlined"
                    size="small"
                    value={formData.zip_code}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only numbers
                      if (/^\d{0,5}$/.test(value)) {
                        setFormData({ ...formData, zip_code: value });
                      }
                    }}
                    InputProps={{
                      style: { color: "#48768C", borderRadius: "8px" },
                      inputProps: {
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        maxLength: 5, // Limit the number of digits to 5
                      }, // Restrict input to numbers only
                    }}
                    InputLabelProps={{ style: { color: "#48768C" } }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#48768C",
                      },
                      color: "#48768C",
                      width: "20%",
                    }}
                  />
                </ThemeProvider>
              </Stack>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <TextField
                    label="Company Email"
                    size="small"
                    variant="outlined"
                    value={formData.company_email}
                    onChange={handleEmailChange}
                    onBlur={() => {
                      setIsEmailFieldDirty(true); // Mark the field as dirty to show validation after blur
                      setIsEmailValid(validateEmail(formData.company_email)); // Validate email
                    }}
                    fullWidth
                    InputProps={{
                      style: { color: "#48768C", borderRadius: "8px" },
                      inputProps: {
                        type: "email",
                      },
                    }}
                    InputLabelProps={{ style: { color: "#48768C" } }}
                    sx={{
                      mb: 2,
                      color: "#48768C",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor:
                          !validateEmail(formData.company_email) &&
                          isEmailFieldDirty
                            ? "#B22222" // Error color
                            : "#48768C", // Default color
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor:
                            !validateEmail(formData.company_email) &&
                            isEmailFieldDirty
                              ? "#B22222"
                              : "#48768C",
                        },
                        "&:hover fieldset": {
                          borderColor:
                            !validateEmail(formData.company_email) &&
                            isEmailFieldDirty
                              ? "#B22222"
                              : "#365D6C", // Darker red on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor:
                            !validateEmail(formData.company_email) &&
                            isEmailFieldDirty
                              ? "#8B0000"
                              : "#1E4D5F", // Darker shade for focused state
                        },
                      },
                    }}
                    error={!isEmailValid} // Set error prop based on email validity
                    helperText={
                      !isEmailValid && isEmailFieldDirty
                        ? "Valid email is required"
                        : ""
                    }
                    FormHelperTextProps={{
                      style: {
                        color:
                          !validateEmail(formData.company_email) &&
                          isEmailFieldDirty
                            ? "#B22222"
                            : undefined,
                      },
                    }}
                  />
                </ThemeProvider>
              </Grid>

              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <TextField
                    label="Company Website"
                    variant="outlined"
                    size="small"
                    value={formData.company_website}
                    onChange={handleWebsiteChange}
                    fullWidth
                    InputProps={{
                      style: { color: "#48768C", borderRadius: "8px" },
                      inputProps: {
                        type: "email",
                      },
                    }}
                    InputLabelProps={{ style: { color: "#48768C" } }}
                    sx={{
                      mb: 2,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor:
                          !isWebsiteValid && isWebsiteFieldDirty
                            ? "#B22222" // Error color
                            : "#48768C", // Default color
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor:
                            !isWebsiteValid && isWebsiteFieldDirty
                              ? "#B22222"
                              : "#48768C",
                        },
                        "&:hover fieldset": {
                          borderColor:
                            !isWebsiteValid && isWebsiteFieldDirty
                              ? "#B22222"
                              : "#365D6C", // Darker red on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor:
                            !isWebsiteValid && isWebsiteFieldDirty
                              ? "#8B0000"
                              : "#1E4D5F", // Darker shade for focused state
                        },
                      },
                    }}
                    error={!isWebsiteValid && isWebsiteFieldDirty}
                    helperText={
                      !isWebsiteValid &&
                      isWebsiteFieldDirty &&
                      "Enter a valid website URL"
                    }
                    FormHelperTextProps={{
                      style: {
                        color:
                          !isWebsiteValid && isWebsiteFieldDirty
                            ? "#B22222"
                            : undefined,
                      },
                    }}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={handleSave}
              variant="contained"
              disabled={!isFormValid || loading}
              sx={{
                backgroundColor: "rgba(72, 118, 140, 0.8)", // Adjusted color with transparency
                padding: 1,
                borderRadius: "15px",
                mt: 2,
                mb: 3,
                width: "90%",
                fontSize: 17,
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "rgba(72, 118, 140, 0.7)", // Adjusted hover color with transparency
                },
                "&:active": {
                  backgroundColor: "rgba(72, 118, 140, 0.5)", // Adjusted active color with transparency
                },
              }}
            >
              {loading ? "Saving Account Data..." : "Continue"}{" "}
            </Button>
          </Box>
          <RestartSetupDialog
            open={showRestartDialog}
            onClose={handleCloseRestartDialog}
            confirmRestart={confirmRestart}
            setConfirmRestart={setConfirmRestart}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

export default NewAccountModal;
