import react, { useState } from "react";
import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Card,
  Stack,
  IconButton,
  Paper,
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const PlanCard = ({
  onClose,
  loading,
  title,
  subtitle,
  price,
  feature1,
  feature2,
  feature3,
  feature4,
  isCurrentPlan,
  buttonAction,
}) => {
  return (
    <Card
      sx={{
        border: "1px solid #E4EAEE",
        backgroundColor: "#FFFFFF",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        maxHeight: "62vh",
        minHeight: "62vh",
        overflowY: "auto",
        boxShadow: isCurrentPlan ? "none" : "0px 4px 12px rgba(0, 0, 0, 0.2)",
        transition: "box-shadow 0.3s ease-in-out",
        width: "45%",
        minWidth: "290px", // Ensure a reasonable minimum width
        maxWidth: "330px", // Limit maximum width
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Typography
          sx={{
            textAlign: "left",
            color: "#48768c",
            fontSize: "21.5px",
            fontWeight: "bold",
            letterSpacing: -0.5,
            mb: 0.25,
          }}
        >
          {title || ""}
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            color: "#6595ab",
            fontSize: "16px",
            fontWeight: "normal",
            letterSpacing: "-.03em",
            mb: 4.5,
          }}
        >
          {subtitle || ""}
        </Typography>

        <Stack spacing={2} sx={{ mb: 4 }}>
          {[feature1, feature2, feature3, feature4].map((feature, index) => (
            <Box
              key={index}
              gap={1}
              sx={{
                display: "flex",
                alignItems: "flex-start", // Aligns the icon to the top of the text
              }}
            >
              <CheckRoundedIcon
                sx={{
                  fontSize: "24px",
                  color: "#2BBB9E",
                  marginTop: "0px", // Fine-tunes alignment for larger fonts if needed
                }}
              />
              <Typography
                sx={{
                  textAlign: "left",
                  color: "#40657a",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {feature || ""}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>

      <Box
        sx={{
          mt: "auto",
          border: ".5px solid #ADC1CB8C",
          borderRadius: "10px",
          pl: 2,
          pt: 1,
          pb: 2,
          backgroundColor: isCurrentPlan ? "#FFFFFFF" : "#F9FBFC",
          mx: -1,
          mb: 1,
        }}
      >
        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
          <Typography
            sx={{
              color: "#48768C",
              fontSize: 36,
              fontWeight: "bold",
              letterSpacing: -1.5,
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            {price || ""}
          </Typography>
          <Typography
            sx={{
              color: "#5A899C",
              fontSize: 17.5,
              fontWeight: "bold",
            }}
          >
            / month
          </Typography>
        </Stack>

        <Box
          sx={{ display: "flex", mt: 2, alignItems: "left", pl: 0.25, pr: 1 }}
        >
          <Button
            sx={{
              border: "1px solid",
              borderRadius: "20px",
              backgroundColor: isCurrentPlan ? "#E0E0E0" : "#2BBB9E", // Gray for disabled state
              borderColor: isCurrentPlan ? "#BDBDBD" : "#29B296", // Muted border for disabled state
              color: isCurrentPlan ? "#9E9E9E" : "#FFFFFF", // Muted text color for disabled state
              fontSize: "16px",
              boxShadow: isCurrentPlan
                ? "none"
                : "0px 4px 8px rgba(0, 0, 0, 0.1)", // No shadow for disabled state
              padding: "8px 15px",
              cursor: isCurrentPlan ? "not-allowed" : "pointer", // Change cursor for disabled state
              opacity: isCurrentPlan ? 0.7 : 1, // Slightly reduced opacity for disabled state
              width: "45%",
              minWidth: "200px", // Ensure a reasonable minimum width
              maxWidth: "250px", // Limit maximum width
              whiteSpace: "nowrap", // Prevent text wrapping
              overflow: "hidden", // Prevent content overflow
              "&:hover": {
                backgroundColor: isCurrentPlan ? "#E0E0E0" : "#26A89E", // No hover effect for disabled state
              },
              "&.Mui-disabled": {
                color: "#676767", // Adjust font color for disabled state
                borderColor: "transparent", // Adjust border color for disabled state
              },
            }}
            onClick={buttonAction}
            disabled={isCurrentPlan}
          >
            {isCurrentPlan ? "Your Current Plan" : "Upgrade Plan"}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default PlanCard;
