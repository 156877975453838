import { useAppContext } from "src/context/AppContext";

export const updateFieldData = async (
  editData,
  user,
  apiUrl,
  getAccessTokenSilently,
  handleFieldPopoverClose,
  handleReloadComponents,
  handleClearNewFieldData,
  showSnackBar,
  setIsLoading,
) => {
  try {
    setIsLoading(true); // Set loading state to true before making the API call

    const {
      notificationType,
      newNotificationType,
      fieldName,
      fieldData,
      updatedFieldName,
      configs,
    } = editData;
    const accessToken = await getAccessTokenSilently();

    const requestBody = {
      notificationType,
      newNotificationType,
      fieldName,
      updatedFieldName,
      fieldData,
      configs,
    };

    const response = await fetch(
      `${apiUrl}/api/user-notifications/update-field-data/${user.sub}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify(requestBody),
      },
    );

    if (!response.ok) {
      setIsLoading(false); // Set loading state to false when request fails
      throw new Error("Failed to save or update data");
    }

    handleFieldPopoverClose();
    handleClearNewFieldData();

    handleReloadComponents();
    showSnackBar("Field updated successfully!", true);
    setIsLoading(false); // Set loading state to false when request fails
  } catch (error) {
    console.error("Error saving data:", error);
    setIsLoading(false); // Set loading state to false when request fails
  }
};

export const addNewField = async (
  newFieldData,
  user,
  apiUrl,
  getAccessTokenSilently,
  handleFieldPopoverClose,
  handleReloadComponents,
  handleClearNewFieldData,
  showSnackBar,
  setIsLoading,
) => {
  try {
    setIsLoading(true); // Set loading state to true before making the API call

    const {
      notificationType,
      fieldName,
      fieldType,
      icon,
      value,
      valueType,
      isRequired,
      messagePrefix,
    } = newFieldData;
    const accessToken = await getAccessTokenSilently();

    const fieldData = {
      icon,
      value,
      fieldType,
      valueType,
      isRequired,
      messagePrefix,
    };

    const requestBody = {
      notificationType,
      fieldName,
      fieldData,
    };

    const response = await fetch(
      `${apiUrl}/api/user-notifications/add-field-data/${user.sub}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify(requestBody),
      },
    );

    if (!response.ok) {
      showSnackBar("Failed to update notification.", false);
      setIsLoading(false); // Set loading state to false when request fails

      throw new Error("Failed to save or update data");
    }

    showSnackBar("Field added successfully!", true);
    handleFieldPopoverClose();
    handleClearNewFieldData();
    handleReloadComponents();
    setIsLoading(false); // Set loading state to false when request fails
  } catch (error) {
    showSnackBar("An unexpected error occurred. Please try again.", false);
    console.error("Error saving data:", error);
    setIsLoading(false); // Set loading state to false when request fails
  }
};

export const deleteField = async (
  editData,
  user,
  apiUrl,
  getAccessTokenSilently,
  handleFieldPopoverClose,
  handleReloadComponents,
  handleClearNewFieldData,
  showSnackBar,
  setIsLoading,
) => {
  try {
    setIsLoading(true); // Set loading state to true before making the API call

    const {
      notificationType,
      fieldName,
      fieldData,
      updatedFieldName,
      configs,
    } = editData;
    const accessToken = await getAccessTokenSilently();

    const requestBody = {
      notificationType,
      fieldName,
      updatedFieldName,
      fieldData,
      configs,
    };

    const response = await fetch(
      `${apiUrl}/api/user-notifications/delete-field/${user.sub}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify(requestBody),
      },
    );

    if (!response.ok) {
      showSnackBar("Failed to update notification.", false);
      setIsLoading(false); // Set loading state to false when request fails

      throw new Error("Failed to save or update data");
    }

    showSnackBar("Field deleted successfully!", true);

    handleFieldPopoverClose();
    handleClearNewFieldData();

    handleReloadComponents();
    setIsLoading(false); // Set loading state to false when request fails
  } catch (error) {
    showSnackBar("Failed to update notification.", false);
    setIsLoading(false); // Set loading state to false when request fails

    console.error("Error saving data:", error);
  }
};

// Function to update field data
export const updateConfigs = async (
  editData,
  user,
  apiUrl,
  getAccessTokenSilently,
  handleFieldPopoverClose,
  handleReloadComponents,
  handleClearNewFieldData,
  newNotificationType,
  showSnackBar,
  setIsLoading,
) => {
  try {
    setIsLoading(true); // Set loading state to true before making the API call

    const { notificationType, newNotificationType, configs } = editData;
    const accessToken = await getAccessTokenSilently();

    const requestBody = {
      notificationType,
      newNotificationType,
      configs: configs,
      new_is_default: configs.new_is_default, // Pass new_is_default if it exists
    };

    const response = await fetch(
      `${apiUrl}/api/user-notifications/update-configs/${user.sub}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify(requestBody),
      },
    );

    if (!response.ok) {
      const data = await response.json(); // Get the response body to check for the specific error message

      if (
        data.error &&
        data.error === "Notification type already exists with that name."
      ) {
        // Show specific error message when notification name is already in use
        showSnackBar("Notification name is already in use", false);
      } else {
        showSnackBar("Failed to update notification.", false);
      }

      throw new Error("Failed to save or update data");
    }

    handleFieldPopoverClose();
    handleClearNewFieldData();
    showSnackBar("Notification updated successfully!", true);

    handleReloadComponents();
    setIsLoading(false); // Set loading state to false when request fails

    setIsLoading(false); // Set loading state to false after request is completed
  } catch (error) {
    console.error("Error saving data:", error);
    setIsLoading(false); // Set loading state to false when request fails
  }
};

//Function to disable Two Way Notifications
export const handleDisableTwoWayNotifications = async ({
  userId,
  setIsLoading,
  setApiError,
  getAccessTokenSilently,
  apiUrl,
  showSnackBar,
  handleCardPopoverClose,
  handleReloadComponents,
}) => {
  setIsLoading(true);
  setApiError(null);

  try {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(
      `${apiUrl}/api/user-notifications/disable-two-way`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      },
    );

    if (!response.ok) {
      throw new Error("Failed to disable two-way notifications");
    }

    const data = await response.json();
    handleCardPopoverClose();

    showSnackBar("Two-way notifications disabled!", true);

    handleReloadComponents();
    setIsLoading(false); // Set loading state to false when request fails
  } catch (error) {
    showSnackBar("Failed to disable Two-way Notification.", false);
    setIsLoading(false); // Set loading state to false when request fails
    console.error("Error disabling two-way notifications:", error);
  } finally {
    setIsLoading(false);
  }
};

export const enableTwoWayNotifications = async ({
  userId,
  setIsLoading,
  setApiError,
  getAccessTokenSilently,
  apiUrl,
  enabledTwoWay,
  handleReloadComponents,
  handleCardPopoverClose,
  showSnackBar,
}) => {
  setIsLoading(true);
  setApiError(null);

  try {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(
      `${apiUrl}/api/user-notifications/enable-two-way`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, enabledTwoWay }),
      },
    );

    const data = await response.json();

    if (response.ok) {
      handleCardPopoverClose();
      handleReloadComponents();
      showSnackBar("Two-way notifications enabled!", true);
    } else {
      console.error("Error enabling two-way notifications:", data.error);
      showSnackBar("Failed to enable Two-way Notification.", false);
      setIsLoading(false); // Set loading state to false when request fails
    }
  } catch (error) {
    console.error("Error enabling two-way notifications:", error);
    setApiError("An error occurred while enabling two-way notifications");
  } finally {
    setIsLoading(false);
  }
};

export const deleteNotification = async (
  userId,
  notificationTitle,
  getAccessTokenSilently,
  handleReloadComponents,
  apiUrl,
  showSnackBar,
  handleCardPopoverClose,
  setIsLoading,
) => {
  try {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(
      `${apiUrl}/api/user-notifications/delete-notification/${userId}/${notificationTitle}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      },
    );

    const data = await response.json();

    if (response.ok) {
      // Handle success response
      handleReloadComponents();
      showSnackBar("Notification Deleted", true);
    } else {
      console.error(
        "Couldnt delete notification. Please try again:",
        data.error,
      );
      showSnackBar("Couldnt delete notification. Please try again:", false);
    }
  } catch (error) {
    console.error(
      "Couldnt delete notification. Please try again:",
      error.message,
    ); // Updated line
    showSnackBar("Error deleting notification. Please try again.", false);
  } finally {
    handleCardPopoverClose();
  }
};
