import React, { useRef, useState, useEffect } from "react";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import { useAccountContext } from "src/context/AccountContext";
import { useAppContext } from "src/context/AppContext";
import { set } from "lodash";

const LogoUploadButton = styled(Button)(({ logoUrl }) => ({
  width: "95px",
  height: "95px",
  borderRadius: "8px",
  backgroundColor: logoUrl ? "transparent" : "#f0f0f0",
  display: "flex",
  flexDirection: "column",
  alignItems: "top",
  justifyContent: "center",
  cursor: "pointer",
  marginLeft: "-10px",

  "&:hover": {
    backgroundColor: logoUrl ? "transparent" : "#e0e0e0",
  },
  "& img": {
    width: "90%",
    height: "90%",
    borderRadius: "8px",
    objectFit: "contain",
  },
}));

const LogoUpload = ({ onLogoUpload }) => {
  const [logoUrl, setLogoUrl] = useState("/assets/genericlogo.png"); // Default placeholder URL
  const fileInputRef = useRef(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const { accountData } = useAccountContext();
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackBar, handleReloadComponents } = useAppContext();

  const fetchLogoUrl = async () => {
    if (!user || !user.sub) {
      console.error("User or user.sub is undefined.");
      return;
    }

    const auth0UserId = user.sub;

    try {
      if (!user || !user.sub) {
        console.error("User or user.sub is undefined.");
        return;
      }

      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/api/logo?auth0UserId=${user.sub}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.ok) {
        const logo = await response.json();

        if (logo && logo.fileUrl) {
          setLogoUrl(logo.fileUrl);
        }
      } else {
        console.error("Error fetching logo. HTTP status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching logo:", error);
    }
  };

  useEffect(() => {
    fetchLogoUrl();
  }, [user]);

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setIsLoading(true);
      try {
        // Obtain the access token
        const accessToken = await getAccessTokenSilently();

        // Obtain auth0UserId from user metadata
        const { sub: auth0UserId } = user;

        // Perform the file upload to your backend
        const formData = new FormData();
        formData.append("logo", file);
        formData.append("auth0UserId", auth0UserId); // Include auth0UserId in the request

        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/upload`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (response.ok) {
          // Logo uploaded successfully, update the UI or handle accordingly
          const result = await response.json();

          if (result.fileUrl) {
            // Notify the parent component about the logo upload
            onLogoUpload(auth0UserId, result.fileUrl);
            setIsLoading(false);
            showSnackBar("Logo uploaded successfully!", true);
          } else {
            console.error("Failed to upload logo. File URL is undefined.");
            setIsLoading(false);
          }
        } else {
          // Handle the error
          console.error("Failed to upload logo");
          showSnackBar("Failed to upload logo. Please try again", false);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error uploading logo:", error);
        showSnackBar("Failed to upload logo. Please try again", false);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <LogoUploadButton
        disabled={isLoading}
        logoUrl={logoUrl}
        onClick={() => fileInputRef.current.click()}
      >
        {logoUrl && logoUrl !== "/assets/genericlogo.png" ? (
          <img src={logoUrl} alt="Your Logo" />
        ) : (
          <Typography
            variant="caption"
            sx={{
              mt: 0.25,
              border: "1px dashed #000",
              padding: "18px",
              color: "#576369",
              backgroundColor: "#f0f0f0",
              borderRadius: "8px",
              fontWeight: 600,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)", // Add shadow
            }}
          >
            Upload Your Logo
          </Typography>
        )}
      </LogoUploadButton>
      {/* Hidden file input for logo upload */}
      <input
        type="file"
        accept="image/*"
        onChange={handleLogoUpload}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
    </>
  );
};

export default LogoUpload;
