// NewUserSignUp.js

import React, { useState, useEffect } from "react";
import WelcomeDialog from "./WelcomeDialog";
import NewAccountModal from "./NewAccountModal";
import NewUserSingleLocation from "./NewUserSingleLocation";
import NewUserMultiLocation from "./NewUserMultiLocation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Modal,
  Typography,
  Fade,
  Skeleton,
  CardMedia,
} from "@mui/material";
import multiLocationImage from "src/public/assets/multiLocationImage.png";
import { useMediaQuery } from "@mui/material"; // Import useMediaQuery hook to check device type
import RestartSetupDialog from "./RestartSetupDialog";

const NewUserSignUp = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [showNewAccountModal, setShowNewAccountModal] = useState(false);

  const [imageLoaded, setImageLoaded] = useState(false);

  const [showRestartDialog, setShowRestartDialog] = useState(false);
  const [confirmRestart, setConfirmRestart] = useState(false);

  const handleBackdropClick = (event) => {
    // Check if the click is on the backdrop by comparing target and currentTarget
    if (event.target === event.currentTarget) {
      setConfirmRestart(false);
      setShowRestartDialog(true); // Open RestartSetupDialog
    }
  };

  const handleCloseRestartDialog = () => {
    setShowRestartDialog(false); // Close RestartSetupDialog
  };

  useEffect(() => {
    const img = new Image();
    img.src = multiLocationImage;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);

  const isMobile = useMediaQuery("(max-width:768px)"); // Check if the device is mobile

  useEffect(() => {
    if (isMobile) {
      // If the device is mobile, set the step to show mobile warning popup
      setStep(0);
    } else {
      // If the device is not mobile, proceed with the regular signup steps
      setStep(1);
    }
  }, [isMobile]);

  const handleWelcomeDialogNext = () => {
    setStep(2);
    setShowNewAccountModal(true);
  };

  const handleNewAccountSave = (formData) => {
    // Handle saving data from the NewAccountModal if needed

    // Determine the next step based on user input
    setStep(3);
  };

  const handleLocationConfirmation = (response) => {
    // Handle user response to location confirmation dialog
    if (response === "no") {
      setStep(4); // No: NewUserSingleLocation
    } else if (response === "yes") {
      setStep(5); // Yes: NewUserMultiLocation
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <WelcomeDialog isOpen={true} onNext={handleWelcomeDialogNext} />;
      case 2:
        return (
          <NewAccountModal
            open={showNewAccountModal}
            onClose={() => setShowNewAccountModal(false)}
            onSave={handleNewAccountSave}
          />
        );
      case 3:
        return (
          <Modal
            open={true}
            onClose={onClose}
            slotProps={{
              backdrop: {
                onClick: handleBackdropClick, // Disable closing the modal by clicking on the backdrop
              },
            }}
            disableEscapeKeyDown // Disable closing the modal by pressing the escape key
          >
            <Fade in={true} timeout={400}>
              <Box
                sx={{
                  outline: "none", // Remove the default focus outline
                  alignContent: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "40%",

                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Added box shadow
                  borderRadius: "30px",
                  fontSize: 17,
                  fontWeight: 600,
                  backgroundColor: "#FFFFFF",
                  color: "#48768C",
                }}
              >
                <DialogTitle
                  sx={{
                    mt: 3,
                    textAlign: "center",

                    mb: -4,
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{ fontSize: 16, alignText: "center", fontWeight: 600 }}
                  >
                    NanoNotify works great with companies that have multiple
                    locations!
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  {!imageLoaded && (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        height: "auto",
                        maxWidth: "60%",
                        maxHeight: "60%",
                        objectFit: "contain",
                        borderRadius: "10px",
                        backgroundColor: "#EEF6F9", // Adjust as needed to match background
                        transition: "opacity 0.3s ease-in-out",
                        opacity: imageLoaded ? 0 : 1,
                      }}
                    />
                  )}
                  <CardMedia
                    component="img"
                    image={multiLocationImage}
                    sx={{
                      display: "block",
                      margin: "auto",
                      marginBottom: "20px",
                      maxWidth: "60%",
                      maxHeight: "60%",
                      objectFit: "contain",
                      transition: "opacity 0.3s ease-in-out",
                      opacity: imageLoaded ? 1 : 0,
                    }}
                    onLoad={() => setImageLoaded(true)}
                  />

                  <DialogContentText
                    sx={{
                      textAlign: "center",
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    Does your company have multiple locations you need to
                    manage?
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  sx={{
                    marginTop: "auto",
                    marginBottom: 3,
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => handleLocationConfirmation("no")}
                    sx={{
                      backgroundColor: "#28354F",

                      color: "#FFFFFF",
                      width: "125px",
                      borderRadius: "10px",
                      fontSize: 14,
                      mr: 10,
                      fontWeight: 600,
                      width: "175px",
                      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",

                      "&:hover": {
                        backgroundColor: "#1C2A40",
                      },
                    }}
                  >
                    Just a single location
                  </Button>
                  <Button
                    onClick={() => handleLocationConfirmation("yes")}
                    sx={{
                      backgroundColor: "#628197",
                      whiteSpace: "nowrap",
                      color: "#FFFFFF",
                      width: "195px",
                      borderRadius: "10px",
                      fontSize: 14,
                      fontWeight: 600,
                      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                      "&:hover": {
                        backgroundColor: "#1C2A40",

                        backgroundColor: "#7589A2",
                      },
                    }}
                  >
                    We have multiple locations
                  </Button>
                </DialogActions>
                <RestartSetupDialog
                  open={showRestartDialog}
                  onClose={handleCloseRestartDialog}
                  confirmRestart={confirmRestart}
                  setConfirmRestart={setConfirmRestart}
                />
              </Box>
            </Fade>
          </Modal>
        );
      case 4:
        return <NewUserSingleLocation onClose={onClose} />;
      case 5:
        return (
          <NewUserMultiLocation onClose={onClose} onNext={() => setStep(6)} />
        );
      default:
        return null;
    }
  };

  return <>{renderStep()}</>;
};

export default NewUserSignUp;
